<div class="top-container">
    <h1 class="mat-display-4">
        {{ media?.name }}
    </h1>

    <div class="buttons" *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">

        <button mat-icon-button *ngIf="hasMediaWorkflow || versionsProcessId.length" 
                [matTooltip]="i18next.t('mediaDetail.workflows')" 
                [matMenuTriggerFor]="workflowMenu">
            <mat-icon>account_tree</mat-icon>
        </button>

        <mat-menu #workflowMenu="matMenu" style="margin-top: 5px">
                
            <button mat-menu-item (click)="displayWorkflowImage(media?.activitiProcessId!)">Media workflow</button>
            <button *ngFor="let vpi of versionsProcessId" mat-menu-item (click)="displayWorkflowImage(vpi.processId)">Version {{vpi.name}} workflow</button>
        </mat-menu>
                
        <button mat-icon-button 
            (click)="toggleNotifications()"
            [matTooltip]="!notificationSubscription?.subscribed ? i18next.t('mediaDetail.subscribe') : i18next.t('mediaDetail.unsubscribe')">
            <mat-icon *ngIf="!notificationSubscription?.subscribed">star_half</mat-icon>
            <mat-icon *ngIf="notificationSubscription?.subscribed">star</mat-icon>
        </button>
                
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>settings</mat-icon>
        </button>
    </div> 
    <mat-menu #menu="matMenu">
        <ng-container *ngIf="availableActions.length">

            <div style="margin: 7px 15px 5px 15px; font-size: 12px">
                {{ "mediaDetail.userActions" | i18next }}
            </div>
            <button *ngFor="let action of availableActions" mat-menu-item (click)="executeAction(action)">
                {{ action.name }}
            </button>
            <mat-divider></mat-divider>
        </ng-container>        
        <button mat-menu-item (click)="setPreviewImage()">{{"mediaDetail.set_preview_image" | i18next}}</button>
        <button mat-menu-item (click)="openRenameMediaDialog()">{{"mediaDetail.rename" | i18next}}</button>
        <button mat-menu-item (click)="openIngestDialog()" *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">{{"mediaDetail.ingest_from_primary" | i18next}}</button>
        <button mat-menu-item (click)="openIngestUploadDialog()">{{"mediaDetail.ingest_from_upload" | i18next}}</button>
        <button mat-menu-item (click)="openRequestPartnerUploadDialog(false)">{{"media_upload.request_freestyle_upload" | i18next}}</button>                
        <button mat-menu-item (click)="openMoveMediaDialog()" *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">{{"mediaDetail.copy_or_move" | i18next}}</button>
        <button mat-menu-item (click)="openOutgestDialog()">{{"mediaDetail.outgest" | i18next}}</button>
        <button mat-menu-item (click)="openExternalImportDialog()" *ngIf="hasExternalImportAvailable">{{"mediaDetail.externalImport" | i18next}}</button>
        <button mat-menu-item (click)="generateTranscript()">{{"mediaDetail.generate_whisper_transcript" | i18next}}</button>
        <button mat-menu-item (click)="openDeleteConfirmationDialog()" class="warn">{{"mediaDetail.delete" | i18next}}</button>
    </mat-menu>
        
</div>

<router-outlet *ngIf="!loading" (activate)="onRouterOutletActivate($event)"></router-outlet>
