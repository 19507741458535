import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MetadataAttachmentType, MetadataDefinition, MetadataReference, MetadataType } from 'src/app/models/metadata';
import { MetadataService } from 'src/app/services/metadata.service';
import i18next from 'i18next';
import { Observable, debounceTime, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { dynamicMetadataValues } from 'src/app/models/constants';

const ALL_OPERANDS = ["LIKE", "UNLIKE", "EQUAL", "GREATER", "LESS", "NOT_EQUAL","GREATER_OR_EQUAL","LESS_OR_EQUAL"]

@Component({
  selector: 'app-metadata-select',
  templateUrl: './metadata.select.component.html',
  styleUrls: ['./metadata.select.component.sass']
})
export class MetadataSelectComponent {

  @Input() additionalMetadataDefinitions:MetadataDefinition[] = []
  metadataDefinitionsForMedia:MetadataDefinition[] = []
  metadataDefinitionsForVersion:MetadataDefinition[] = []
  metadataDefinitionsForStream:MetadataDefinition[] = []
  metadataDefinitionsForUser:MetadataDefinition[] = []
  autocomplete: Observable<any[]>
  autocompleteControl:FormControl  
  @Input() allowMultipleOnMultiValued:boolean = false
  @Input() formula:boolean = false
  @Output() formulaChange = new EventEmitter<boolean>()
  @Input() forceCurrentDate:boolean = false
  @Input() allowCurrentDate:boolean = false
  @Input() onlyAttachedTo:MetadataAttachmentType[] = []
  @Input() selection:MetadataDefinition|undefined
  @Output() selectionChange = new EventEmitter<MetadataDefinition>()
  @Input() metadataDefinitionFilter:any = (md:MetadataDefinition) => true
  @Input() value:any
  @Output() valueChange = new EventEmitter<any>()
  @Input() allowOperatorSelection:boolean = false
  @Input() operator?:string;
  @Output() operatorChange = new EventEmitter<string>();
  @Input() allowEmpty:boolean = true
  
  operands:string[] = ALL_OPERANDS
  @Input() disallowedOperands:string[] = [];

  displayMediaMetadata:boolean = true
  displayMediaVersionMetadata:boolean = true
  displayMediaStreamMetadata:boolean = true
  displayUserMetadata:boolean = true
  metadataTypes:typeof MetadataType = MetadataType;
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
  i18next = i18next
  JSON = JSON
  group: FormGroup<any>;

  constructor(
    private metadataService:MetadataService,
    private userService:UserService
  ) {
    this.autocompleteControl = new FormControl(this.value)
    this.group = new FormGroup({
      autocompleteControl: this.autocompleteControl
    })
    this.autocomplete = this.autocompleteControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {

          if (this.selection?.type === 'USER') {
            const stringVal = typeof val === 'string' ? val : (val as User).email!
            const r = this.filterUser(stringVal)
            return r
          } else {
            const r = this.filterMetadataReference(val+'' || '', this.selection?.referencedMetadataGroup!.id!)
            console.log('r', r)
            return r
          }
        })       
      )           
  }

  ngOnChanges() {
    this.displayMediaMetadata = this.metadataDefinitionsForMedia.length > 0 && (this.onlyAttachedTo.length == 0 || this.onlyAttachedTo.indexOf(MetadataAttachmentType.MEDIA) > -1)
    this.displayMediaVersionMetadata = this.metadataDefinitionsForVersion.length > 0 && (this.onlyAttachedTo.length == 0 || this.onlyAttachedTo.indexOf(MetadataAttachmentType.MEDIA_VERSION) > -1)
    this.displayMediaStreamMetadata = this.metadataDefinitionsForStream.length > 0 && (this.onlyAttachedTo.length == 0 || this.onlyAttachedTo.indexOf(MetadataAttachmentType.MEDIA_STREAM) > -1)
    this.displayUserMetadata = this.metadataDefinitionsForUser.length > 0 && (this.onlyAttachedTo.length == 0 || this.onlyAttachedTo.indexOf(MetadataAttachmentType.USER) > -1)
    
    if (this.selection?.type === MetadataType.DATE && this.forceCurrentDate) {
      this.value = '||currentDate||'
      this.valueChange.emit(this.value)
    }
    if (this.selection?.type === MetadataType.BOOLEAN) {
      this.operands = ["EQUAL"]
      this.selectOperand("EQUAL")
    } else {
      this.operands = ALL_OPERANDS
    }
    console.log('ngOnChanges()')
  }

  metadataSelectionChanged(selection:MetadataDefinition) {
    this.value = undefined
    this.selectionChange.emit(selection)
  }

  ngOnInit() {
    if (this.disallowedOperands.length > 0) {
      this.operands = this.operands.filter(o => this.disallowedOperands?.indexOf(o) === -1)
    }
    this.metadataService
      .metadataDefinitions
      .sort((a, b) => {
        const as = `${a.referencedMetadataGroupEntity?.attachmentType} > ${a.name}`
        const bs = `${b.referencedMetadataGroupEntity?.attachmentType} > ${b.name}`
        return as.localeCompare(bs)
      })
      .filter(this.metadataDefinitionFilter)
      .forEach(m => {
        if (m.parentGroup?.attachmentType === MetadataAttachmentType.MEDIA) {
          this.metadataDefinitionsForMedia.push(m)
        } else if (m.parentGroup?.attachmentType === MetadataAttachmentType.MEDIA_VERSION) {
          this.metadataDefinitionsForVersion.push(m)
        } else if (m.parentGroup?.attachmentType === MetadataAttachmentType.MEDIA_STREAM) {
          this.metadataDefinitionsForStream.push(m)
        } else if (m.parentGroup?.attachmentType === MetadataAttachmentType.USER) {
          this.metadataDefinitionsForUser.push(m)
        }
      })
      this.metadataDefinitionsForMedia.sort((a, b) => a.name.localeCompare(b.name))
      this.metadataDefinitionsForVersion.sort((a, b) => a.name.localeCompare(b.name))
      this.metadataDefinitionsForStream.sort((a, b) => a.name.localeCompare(b.name))
      this.metadataDefinitionsForUser.sort((a, b) => a.name.localeCompare(b.name))

      this.ngOnChanges()
  }
  
  filterUser(val: string): Observable<any[]> {
    console.log('filter', val)
    return this.userService.getAutocompleteData('ALL', val)
     .pipe(
        map(response => response
          .filter(option => option.email?.toLowerCase().indexOf(val.toLowerCase()) === 0)
        ),
        map(response => [dynamicMetadataValues.currentUserDefinition, ...response])
     )
  }

  filterMetadataReference(val: string, metadataReferenceId:number): Observable<any[]> {
    console.log('filter', val)

    return this.metadataService.getAutocompleteData(metadataReferenceId)
     .pipe(
       map(response => response.filter(option => { 
        console.log('option', option)
         return option.representation?.toLowerCase().indexOf(val.toLowerCase()) === 0
       })),
       map(response => [dynamicMetadataValues.currentUserReferencedValueDefinition, 
          dynamicMetadataValues.currentMediaReferencedValueDefinition, 
          dynamicMetadataValues.currentTargetReferencedValueDefinition, 
          ...response])       
     )
  }

  compareObjects(o1: any, o2: any) {
    return o1.id == o2.id
  }  

  displayAutocomplete(value:MetadataReference) {
    console.log('displayAutocomplete', value)
    return value?.representation ?? ''
  }

  displayUserAutocomplete(value:any) {
    console.log('displayAutocomplete', value)
    return value?.email ?? ''
  }

  selectOperand(operand:string): void {
    this.operator = operand
    this.operatorChange.emit(operand)
  }

}
