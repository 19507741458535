import { Media } from "./media";
import { MetadataAttachmentType } from "./metadata";

export interface HistoryEntry {
    json?: any;
    type?: HistoryEntryType;
    modifiedBy?: string;
    createdAt: string;
    metadataGroup?: number;
    targetType?: MetadataAttachmentType;
    targetId?: number;
    mediaId?: number;
    media?: Media;
    structuredOperations?: Operation[];
    label?: string;
    computedTitle?: string;
    computedLink?: string;
}

export interface Operation {
    description:string
}

export enum HistoryEntryType {
    COMMENT_ANSWER = 'COMMENT_ANSWER',
    METADATA_UPDATE = 'METADATA_UPDATE',
    NEW_SAVED_SEARCH_ENTRY = 'NEW_SAVED_SEARCH_ENTRY'    
}