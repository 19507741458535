import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { filter, forkJoin, tap } from 'rxjs';
import { Media } from 'src/app/models/media';
import { MetadataAttachmentType, MetadataDefinition, MetadataType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { Search } from 'src/app/models/search';
import { MediaService } from 'src/app/services/media.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent {

  result: Page<Media> = {}
  loading: boolean = true
  dataSource: Media[] = []
  dashboard: Search|undefined;
  metadataLoading: boolean = true
  availableMultivaluedFields: MetadataDefinition[] = [];
  breakdownOn: MetadataDefinition|undefined;

  constructor(private mediaService: MediaService,
    private userService: UserService,
    private metadataService: MetadataService,
    private router: Router) { }

  ngOnInit(): void {
    forkJoin([this.getDashboard(),
      this.userService.getUserPreferences().pipe(tap(s => {
        if (s) this.dashboard = s.savedSearch
      })),
      this.metadataService.getMetadataGroups().pipe(tap(() => {
        this.metadataLoading = false
        this.availableMultivaluedFields = this.metadataService
          .metadataDefinitions
          .filter(md => md.parentGroup?.attachmentType === MetadataAttachmentType.MEDIA 
            && md.type == MetadataType.MULTI_VALUED)
      }))]).subscribe(s => {
        this.loading = false
      })
  }

  getAllowedValues() {
    if (!this.breakdownOn) return ['']
    return ['NOT SET', ...this.breakdownOn!.allowedValues!]
      .filter(g => this.getDatasource(g).length)
  }

  loadMore() {
    this.result!.pageable!.pageNumber!++
    this.mediaService.getDashboard(this.result.pageable!.pageNumber!)
    .pipe(tap(result => {
      this.result = result
      this.dataSource = [...this.dataSource, ...result.content!]
      console.log('set datasource to', this.dataSource)
    })).subscribe();
  }

  getDatasource(filterValue:string) {
    if (!this.breakdownOn) return this.dataSource
    console.log(this.breakdownOn)
    return this.dataSource.filter(e => {
      
      if (e.dynamicMetadatas && e.dynamicMetadatas[this.breakdownOn?.parentGroup?.id!]) {
        return e.dynamicMetadatas[this.breakdownOn!.parentGroup!.id][this.breakdownOn!.name] === (filterValue === 'NOT SET' ? '' : filterValue)
      } else {
        return filterValue === 'NOT SET'
      }
    })
  }
 
  clickedRow(row:any): void {
    console.log(row)
    this.router.navigate([`/media/${row.id}`]);
  }

  getDashboard(): any {
    return this.mediaService.getDashboard(this.result.pageable?.pageNumber ?? 0)
      .pipe(tap(result => {
        this.result = result
        this.loading = false
        this.dataSource = this.result.content ?? []
        console.log('set datasource to', this.dataSource)        
      }));
  }
}
