import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import i18next from 'i18next';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { HookScript, HookType } from 'src/app/models/hooks';
import { Page } from 'src/app/models/page';
import { HookService } from 'src/app/services/hook.service';

@Component({
  selector: 'app-hooks',
  templateUrl: './hooks.component.html',
  styleUrls: ['./hooks.component.sass']
})
export class HooksComponent {

  result: Page<HookScript> = {}
  loading: boolean = true
  dataSource: HookScript[] = []
  columnsToDisplay: string[] = ['id', 'type', 'description', 'tools']
  
  constructor(private hookService: HookService,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.getHooks();
  }

  editHook(element:HookScript) {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: i18next.t("hooks.editDialog.title"),
        text: i18next.t("hooks.editDialog.text"),
        label: i18next.t("hooks.editDialog.label"),
        type: SimpleTextInputType.MONOSPACED_EDIT,
        value: element.script
      },
      maxWidth: '80vw',
      maxHeight: '80vh',
      height: '80%',
      width: '80%',      
    });

    dialogRef.afterClosed().subscribe(script => {
      console.log('The dialog was closed', script);
      if (script !== undefined) {
        element.script = script
        this.hookService.persistHook(element).subscribe(value => {
          this.getHooks()
        })
      }
    });        
  }
  
  getHooks(): void {
    this.hookService.getHooks()
      .subscribe(result => {
        this.result = result
        this.loading = false
        const existingKeys = this.result.content?.map(h => h.type?.toString())
        const missingKeys:Array<HookScript> = []
        console.log('existing', existingKeys)
        for (let key of Object.keys(HookType)) {
          if (existingKeys?.indexOf(key) === -1) {
            missingKeys.push({
              type: key as HookType
            })
          }
        }
        this.dataSource = [...this.result.content!, ...missingKeys]
        console.log('set datasource to', this.dataSource)
      });
  }
}
