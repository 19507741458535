import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { Page } from 'src/app/models/page';
import { UserTaskType } from 'src/app/models/usertask';
import { UserTaskService } from 'src/app/services/usertask.service';

@Component({
  selector: 'app-usertasktypes',
  templateUrl: './usertasktypes.component.html',
  styleUrls: ['./usertasktypes.component.sass']
})
export class UserTaskTypesComponent {

  loading: boolean = true
  dataSource: UserTaskType[] = []
  columnsToDisplay: string[] = [/*'id', */'name', 'tools']
  result: Page<UserTaskType> = {}

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public userTaskService: UserTaskService,
  ) {}

  ngOnInit() {
    this.load()
  }

  load() {
    this.userTaskService.getUserTaskTypes().subscribe(types => {
      this.result = types
      this.dataSource = this.result.content ?? []
      this.loading = false
    })    
  }

  deleteTaskType(taskType:UserTaskType, event:Event) {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'tasktypes.confirm_delete',
        content: `tasktypes.confirm_delete_message`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.userTaskService.deleteUserTaskType(taskType.id!).subscribe(_ => {
          this.load()
        })
      }
    });
  }

  addTaskType() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'tasktypes.create',
        text: 'tasktypes.enter_name',
        label: 'tasktypes.name_placeholder',
        value: '',
        type: SimpleTextInputType.TEXT,            
      },
    });

    dialogRef.afterClosed().subscribe(label => {
      console.log('The dialog was closed', label);
      if (label) {
        this.userTaskService.persistUserTaskType({label})
          .subscribe(_ => this.load());
      }
    });     
  }

}
