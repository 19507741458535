import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimecodedComment } from 'src/app/models/comment';
import { Media } from 'src/app/models/media';
import { CommentService } from 'src/app/services/comment.service';

@Component({
  selector: 'app-timecoded-comment-block',
  templateUrl: './timecoded.comment.block.component.html',
  styleUrls: ['./timecoded.comment.block.component.sass']
})
export class TimecodedCommentBlockComponent {

  @Input() video:any = "";
  @Input() media:Media|undefined;
  @Input() comment:TimecodedComment|undefined;
  @Output() commentChange = new EventEmitter<TimecodedComment>();

  timecodeChanged() {
    this.commentChange.emit(this.comment)
  }

  cancelSelection() {
    this.comment = {
      comment: "",
      deleted: false,
      showableToPartners: false,
      tcIn: "00:00:00-00",
      tcOut: "00:00:00-00"
    }
  }

}
