import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.sass']
})
export class DocumentationComponent {
  
  documentationFile:string

  constructor(private router: Router,
              @Inject(I18NEXT_SERVICE) private i18next: ITranslationService) {
    console.log('this.i18next.language', this.i18next.language)
    //
    if (router.url === '/documentation/user') this.documentationFile = `assets/md/documentation.user.${this.i18next.language}.md`
    else this.documentationFile = `assets/md/documentation.admin.${this.i18next.language}.md`
  }

  onLoad($event:any) {
    console.log($event)

    console.log(document.querySelectorAll("section a"))
    document.querySelectorAll(".documentation-nav a").forEach((value) => value.addEventListener("click", (event) => {
      const url = value.getAttribute("href") as string
      if (url.indexOf('swagger') === -1) {
        event.preventDefault()
        this.router.navigateByUrl(url)
      }
    }))
    
    document.querySelectorAll(".toc a").forEach((value) => value.addEventListener("click", (event) => {
      event.preventDefault()
      const hash = (event.target as any).hash.substring(1)
      console.log('clicked', hash)
      let elmnt = document.querySelector(`[name='${hash}']`) as HTMLElement
      const drawer = document.querySelector('.mat-drawer-content') as HTMLElement
      this.scrollTo(drawer, elmnt.offsetTop, 100);
    }))
  }
  
  scrollTo(element:HTMLElement, to:number, duration:number) {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(() => {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        this.scrollTo(element, to, duration - 10);
    }, 10);
}

  onError($event:any) {
    console.log($event)
  }
}
