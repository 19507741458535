<h1 mat-dialog-title>{{data.group.name}}</h1>

<mat-dialog-content class="content">

    <mat-form-field appearance="fill">
        <mat-label>{{"usergroups.name" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.group.name">
    </mat-form-field>

    
        
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{"usergroups.cancel" | i18next}}</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"usergroups.ok" | i18next}}</button>
    </div>
</mat-dialog-content>