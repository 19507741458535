<div class="main" [ngClass]="{'is-handset': isHandset}">
  <div class="global-loader" *ngIf="loading && currentUser">
    <mat-spinner class="content"></mat-spinner> 
  </div> 
  <div id="reopener" (click)="animateSidenav()">
    <mat-icon>chevron_right</mat-icon>
  </div>

  <mat-sidenav-container class="main">
    <mat-sidenav mode="side" class="no-scrollbar" opened #sidenav *ngIf="currentUser" [mode]="isHandset ? 'over' : 'side'">
      <div id="collapser" (click)="animateSidenav()">
        <mat-icon>chevron_left</mat-icon>
      </div>
      <app-menu></app-menu>
    </mat-sidenav>
    <mat-sidenav-content *ngIf="!loading">
      <div *ngIf="isHandset && currentUser" style="position: relative; left: -10px; z-index: 900000; width: 100px;">
        <button mat-icon-button (click)="sidenav?.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>      
      <ng-container>
        <router-outlet></router-outlet>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>