import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Configuration } from '../models/configuration';
import { MediaExpectedStreamDefinition, MediaVersionDefinition } from '../models/media';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class VersionDefinitionService {
  
  versionsDefinition:Page<MediaVersionDefinition> = {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  get(forceReload=false): Observable<Page<MediaVersionDefinition>> {
    if (this.versionsDefinition.totalElements && !forceReload) {
      return of(this.versionsDefinition)
    }
    return this.http.get<Page<MediaVersionDefinition>>(`/api/versionDefinitions`)
      .pipe(
        tap(result => {
          this.versionsDefinition = result
        }),
        catchError(this.errorService.handleError<Page<MediaVersionDefinition>>('MediaVersionDefinition', {}))
      );
  }

  persistVersionDefinitionOrders(ids: number[]) {
    const url = `/api//versionDefinitions/order`;
    return this.http.post<void>(url, ids)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistOrders ids=${ids}`))
      );
  }

  create(version:MediaVersionDefinition): Observable<MediaVersionDefinition> {
    return this.http.post<MediaVersionDefinition>(`/api/versionDefinition`, version)
      .pipe(
        tap(_ => this.log('fetched MediaVersionDefinition')),
        catchError(this.errorService.handleError<MediaVersionDefinition>('MediaVersionDefinition', {}))
      );
  }

  deleteExpectedStream(id: number) {
    return this.http.delete<void>(`/api/versionDefinitions/expectedStreamDefinitions/${id}`, {})
      .pipe(
        tap(_ => this.log('fetched deleteExpectedStream')),
        catchError(this.errorService.handleError<void>('deleteExpectedStream'))
      );
  }

  deleteVersion(id: number) {
    return this.http.delete<void>(`/api/versionDefinitions/${id}`, {})
      .pipe(
        tap(_ => this.log('fetched deleteVersion')),
        catchError(this.errorService.handleError<void>('deleteVersion'))
      );
  }

  updateExpectedStream(stream: MediaExpectedStreamDefinition) {
    return this.http.put<MediaVersionDefinition>(`/api/expectedStreamDefinitions/${stream.id}`, stream)
      .pipe(
        tap(_ => this.log('fetched updateExpectedStream')),
        catchError(this.errorService.handleError<MediaVersionDefinition>('updateExpectedStream', {}))
      );
  }

  addExpectedStreamTo(versionDefinitionId: number, expectedStream: MediaExpectedStreamDefinition, 
      alternativeFor: MediaExpectedStreamDefinition|undefined=undefined) {
    let url = `/api/versionDefinitions/${versionDefinitionId}/expectedStreamDefinition`;
    if (alternativeFor) {
      url += `?alternativeFor=${alternativeFor.id}`
    }
    return this.http.post<MediaVersionDefinition>(url, expectedStream)
      .pipe(
        tap(_ => this.log('fetched addExpectedStreamTo')),
        catchError(this.errorService.handleError<MediaVersionDefinition>('addExpectedStreamTo', {}))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
