import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import i18next from 'i18next';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { Configuration, ConfigurationKey } from 'src/app/models/configuration';
import { Page } from 'src/app/models/page';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})
export class ConfigurationComponent {
  
  result: Page<Configuration> = {}
  loading: boolean = true
  dataSource: Configuration[] = []
  columnsToDisplay: string[] = ['id', 'key', 'value']

  constructor(private configurationService:ConfigurationService,
    private dialog:MatDialog) {}

  ngOnInit(): void {
    this.getConfigurations();
  }

  getConfigurations() {
    this.configurationService.getConfigurations(true).subscribe(c => {
      this.result = c
      this.loading = false
      const existingKeys = this.result.content?.map(h => h.key?.toString())
      const missingKeys:Array<Configuration> = []
      console.log('existing', existingKeys)
      for (let key of Object.keys(ConfigurationKey)) {
        if (existingKeys?.indexOf(key) === -1) {
          missingKeys.push({
            key: key as ConfigurationKey,
            value: ''
          })
        }
      }
      this.dataSource = [...this.result.content!, ...missingKeys]
      console.log('set datasource to', this.dataSource)
    })
  }

  editConfiguration(element:Configuration) {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: i18next.t("configuration.editDialog.title"),
        text: i18next.t("configuration.editDialog.text"),
        label: i18next.t("configuration.editDialog.label"),
        type: SimpleTextInputType.MONOSPACED_EDIT,
        value: element.value
      },
      maxWidth: '80vw',
      maxHeight: '80vh',
      height: '80%',
      width: '80%',      
    });

    dialogRef.afterClosed().subscribe(value => {
      console.log('The dialog was closed', value);
      if (value !== undefined) {
        element.value = value
        this.configurationService.persistConfiguration(element).subscribe(v => {
          this.getConfigurations()
        })
      }
    });        
  }
}
