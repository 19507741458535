import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { MetadataAttachmentType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { UserActionDefinition } from 'src/app/models/user.action';
import { UserActionsService } from 'src/app/services/user.actions.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.sass']
})
export class ActionsComponent {

  loading: boolean = true
  dataSource: UserActionDefinition[] = []
  columnsToDisplay: string[] = [/*'id', */'name', 'description', 'attachmentType', 'tools']
  result: Page<UserActionDefinition> = {}

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public userActionService: UserActionsService
  ) {}

  ngOnInit() {
    this.load()
  }

  load() {
    this.userActionService.getActions().subscribe(actions => {
      this.result = actions
      this.dataSource = this.result.content ?? []
      this.loading = false
    })    
  }

  deleteAction(action:UserActionDefinition, event:Event) {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'actions.confirm_delete',
        content: `actions.confirm_delete_message`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.userActionService.deleteAction(action.id!).subscribe(_ => {
          this.load()
        })
      }
    });
  }

  addAction() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'actions.create',
        text: 'actions.enter_name',
        label: 'actions.name_placeholder',
        type: SimpleTextInputType.TEXT,
        value: ''
      },
    });

    dialogRef.afterClosed().subscribe(name => {
      console.log('The dialog was closed', name);
      if (name) {
        this.userActionService.save({name, attachableTo: MetadataAttachmentType.MEDIA})
          .subscribe(_ => this.load());
      }
    });     
  }

  clickedRow(row:any): void {
    this.router.navigate([`/actions/${row.id}`]);
  }

}
