<h1 mat-dialog-title>{{ "location.editLocation" | i18next }}</h1>

<mat-dialog-content class="content">

    <mat-form-field appearance="fill">
        <mat-label>{{"location.locationName" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.name">
    </mat-form-field>

    <mat-checkbox [(ngModel)]="data.location.hidden" style="margin-bottom: 15px; margin-right: 20px">
        {{ "location.hidden" | i18next }}
    </mat-checkbox>

    <mat-form-field appearance="fill">
        <mat-label>{{"location.locationType" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.location.type">
            <mat-option value="LOCAL">
                {{"location.locationTypes.local" | i18next}}
            </mat-option>
            <mat-option value="S3">
                {{"location.locationTypes.s3" | i18next}}
            </mat-option>
            <mat-option value="FTP">
                {{"location.locationTypes.ftp" | i18next}}
            </mat-option>
        </mat-select>
        
    </mat-form-field>

    <!-- LOCAL fields -->

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'LOCAL'">
        <mat-label>{{"location.path" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.path">
    </mat-form-field>

    <!-- S3 fields -->

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'S3'">
        <mat-label>{{"location.s3bucketName" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.s3bucketName">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'S3'">
        <mat-label>{{"location.s3accessKey" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.s3accessKey">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'S3'">
        <mat-label>{{"location.s3accessSecret" | i18next}}</mat-label>
        <input matInput type="password" [(ngModel)]="data.location.s3accessSecret">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'S3'">
        <mat-label>{{"location.s3region" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.s3region">
    </mat-form-field>

    <!-- FTP fields -->

    <mat-checkbox *ngIf="data.location.type == 'FTP'" [(ngModel)]="data.location.ftpPassive" style="margin-bottom: 15px; margin-right: 20px">
        {{ "location.ftpPassive" | i18next }}
    </mat-checkbox>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'FTP'">
        <mat-label>{{"location.ftpHost" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.ftpHost">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'FTP'">
        <mat-label>{{"location.ftpPort" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.ftpPort">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'FTP'">
        <mat-label>{{"location.ftpLogin" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.location.ftpLogin">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.location.type == 'FTP'">
        <mat-label>{{"location.ftpPassword" | i18next}}</mat-label>
        <input matInput type="password" [(ngModel)]="data.location.ftpPassword">
    </mat-form-field>

</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"global.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"global.ok" | i18next}}</button>
</div>