<div *ngIf="loading" class="loader">
    <mat-spinner></mat-spinner>
</div>

<div>
    <div class="version-title-container">

        <ng-container>
            <button (click)="selectVersion(version)" mat-flat-button [color]="version.id == selectedVersion.id ? 'primary' : ''" 
                *ngFor="let version of parentContainer?.media?.versions" style="margin-right: 10px; margin-bottom: 10px;">
                {{version.name}}
            </button>            
            <button [matMenuTriggerFor]="createVersionMenu" 
                    *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length"
                    mat-flat-button style="margin-right: 10px;">
                <mat-icon>add</mat-icon>&nbsp;
                {{ "mediaDetail.newVersion" | i18next }}
            </button>
            <div *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">
                <button class="editVersionButton" mat-icon-button [matMenuTriggerFor]="versionMenu">
                    <mat-icon>settings</mat-icon>
                </button>
            </div>  

            <mat-menu #createVersionMenu="matMenu" style="margin-top: 5px">

                <button mat-menu-item (click)="openCreateVersionDialog()">{{ "mediaDetail.newManualVersion" | i18next }}</button>
                
                <ng-container *ngIf="versionDefinitions.totalElements">
                    <mat-divider></mat-divider>

                    <div style="margin: 7px 15px 5px 15px; font-size: 12px">
                        {{ "mediaDetail.newVersionFromDefinition" | i18next }}
                    </div>
                    <button *ngFor="let versionDefinition of versionDefinitions.content" mat-menu-item (click)="openCreateVersionFromDefinitionDialog(versionDefinition)">
                        {{ versionDefinition.name }}
                    </button>
                </ng-container>
            </mat-menu>
            <mat-menu #versionMenu="matMenu">

                <div *ngIf="availableActionsForStreams.length || availableActionsForVersion.length" style="margin: 7px 15px 5px 15px; font-size: 12px">
                    {{ "mediaDetail.userActions" | i18next }}
                </div>

                <ng-container *ngIf="availableActionsForVersion.length">

                    <button *ngFor="let action of availableActionsForVersion" mat-menu-item (click)="executeAction(action, this.selectedVersion?.id!)">
                        {{ action.name }}
                    </button>
                </ng-container>        

                <ng-container *ngIf="availableActionsForStreams.length">
                
                    <button *ngFor="let action of uniqueActionsForStreams" mat-menu-item (click)="executeActionOnStreams(action)">
                        {{ action.name }}
                    </button>
                </ng-container>                     

                <mat-divider *ngIf="availableActionsForStreams.length || availableActionsForVersion.length"></mat-divider>
                                                
                <button mat-menu-item (click)="editCurrentVersion()">{{ "mediaDetail.editVersion" | i18next }}</button>
                <button mat-menu-item (click)="addAlternativeStream('VIDEO')">{{ "mediaDetail.addAlternativeVideo" | i18next }}</button>
                <button mat-menu-item (click)="addAlternativeStream('AUDIO')">{{ "mediaDetail.addAlternativeAudio" | i18next }}</button>
                <button mat-menu-item (click)="openExternalImportDialog()">{{"mediaDetail.externalImport" | i18next}}</button>                
                <button mat-menu-item (click)="openDeleteVersionConfirmationDialog()" class="warn">{{ "mediaDetail.deleteVersion" | i18next }}</button>
            </mat-menu>
        </ng-container>
    </div>

    <div class="container" id="container">
        <div class="columns" id="left-column">

            <mat-card style="margin-bottom: 20px" class="top-container-heights">
                <mat-card-content style="display: flex; flex-direction: column; flex-grow: 1;">
                    <div class="video-parent-container">

                        <div id="additional-commands">
                            {{ 'player.goToFrameText' | i18next }}<br />
                            {{ 'player.previousFrame' | i18next }}<br />
                            {{ 'player.nextFrame' | i18next }}<br />
                        </div>                        
                        <div class="video-container" id="video-container">
                            <div class="drawing-board">
                                <canvas id="drawing-board"></canvas>
                            </div>
                            <div id="subtitle-render">

                            </div>
                            <a (click)="playPause()" id="video-overlay"><mat-icon class="icon-display">play_circle</mat-icon></a>
                            <video playsinline id="video" [poster]="'/api/static/variants/' + parentContainer?.media?.id + '-small.png'" current-time="0" width="100%"></video>
                        </div>
                    </div>

                    <div class="seekbarContainer">
                        <div id="seekbarPreview"></div>
                        <div id="seekbar" class="seekbar grow">
                            <div id="seekbarBackground" class="seekbarBackground"></div>
                            <span class="tc currentTC">{{currentTC}}</span>
                            <span class="playhead"></span>
                            <span class="commentHighlight"></span>
                            <span class="tc duration">{{duration}}</span>
                        </div>

                        <div class="tools">

                            <button mat-mini-fab color="primary" (click)="nextFrame($event, true)" [matTooltip]="i18next.t('player.previousFrame')">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" (click)="nextFrame($event)" [matTooltip]="i18next.t('player.nextFrame')">
                                <mat-icon>navigate_next</mat-icon>
                            </button>

                            <button mat-mini-fab color="primary" (click)="fullscreen($event)" [matTooltip]="i18next.t('player.goFullscreen')">
                                <mat-icon>fullscreen</mat-icon>
                            </button>
                        </div>                        
                    </div>

                    <div class="bottom">

                        <mat-form-field appearance="fill" style="margin-right: 10px;" class="grow">
                            <mat-label>{{ "mediaDetail.videos" | i18next  }}</mat-label>
                            <mat-select [(value)]="selectedVideo">
                                <mat-option *ngFor="let video of videoVariants; let indexOfelement=index;" [value]="video"
                                    (onSelectionChange)="trackChanged($event)">
                                    {{ video?.representation }} #{{indexOfelement+1}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" style="margin-right: 10px;" class="grow">
                            <mat-label>{{ "mediaDetail.audios" | i18next  }}</mat-label>
                            <mat-select [(value)]="selectedAudio">
                                <mat-option *ngFor="let audio of audioVariants; let indexOfelement=index;" [value]="audio"
                                    (onSelectionChange)="trackChanged($event)">
                                    {{ audio.representation }} #{{indexOfelement+1}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!--mat-form-field appearance="fill" style="margin-right: 10px;" class="grow">
                            <mat-label>Audio tracks</mat-label>
                            <mat-select [(value)]="selectedTrack">
                                <mat-option *ngFor="let audio of audios" [value]="audio.code"
                                    (onSelectionChange)="audioTrackChanged($event)">
                                    {{audio.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field-->

                        <mat-form-field appearance="fill" *ngIf="subtitles.length">
                            <mat-label>{{ "mediaDetail.subtitleTracks" | i18next  }}</mat-label>
                            <mat-select [(value)]="selectedSubtitle">
                                <mat-option *ngFor="let subtitle of subtitles; let indexOfelement=index;" [value]="subtitle.code"
                                    (onSelectionChange)="subtitleTrackChanged($event, subtitle)">
                                    <span *ngIf="!subtitle.external">{{subtitle.representation ?? subtitle.name}}</span>
                                    <span *ngIf="subtitle.external">{{subtitle.representation ?? subtitle.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
        <div class="columns" id="splitter">
            <div class="closers">
                <mat-icon id="close-left">chevron_left</mat-icon>
                <mat-icon id="close-right">chevron_right</mat-icon>
            </div>
            <span class="dragger" id ="dragger"></span>
        </div>
        <div class="columns" style="position: relative; min-width: 400px" id="right-column">
            <div class="tabs">
                <div>
                    <ng-container *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">
                        <a [class.selected]="selectedTab == tabs.METADATAS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.metadatasTooltip')" (click)="selectTab(Tabs.METADATAS, 'metadatas')"><mat-icon>infos</mat-icon></a>
                        <a [class.selected]="selectedTab == tabs.VERSIONS_METADATAS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.versionsMetadatasTooltip')" (click)="selectTab(Tabs.VERSIONS_METADATAS, 'versions')"><mat-icon>translate</mat-icon></a>
                        <a [class.selected]="selectedTab == tabs.STREAMS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.streamsTooltip')" (click)="selectTab(Tabs.STREAMS, 'streams')"><mat-icon>mediation</mat-icon></a>                    
                        <a [class.selected]="selectedTab == tabs.UPLOADS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.uploadsTooltip')" (click)="selectTab(Tabs.UPLOADS, 'uploads')"><mat-icon>shopping_cart</mat-icon></a>                    
                    </ng-container>
                    <a [class.selected]="selectedTab == tabs.DOWNLOADS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.downloadsTooltip')" (click)="selectTab(Tabs.DOWNLOADS, 'downloads')"><mat-icon>download</mat-icon></a>                    
                    <a [class.selected]="selectedTab == tabs.COMMENTS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.commentsTooltip')" (click)="selectTab(Tabs.COMMENTS, 'comments')"><mat-icon>comment</mat-icon></a>
                    <ng-container *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">
                        <a [class.selected]="selectedTab == tabs.ACTIVITY" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.activity')" (click)="selectTab(Tabs.ACTIVITY, 'activity')"><mat-icon>event</mat-icon></a>
                        <a [class.selected]="selectedTab == tabs.SUBTITLES" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.subtitlesTooltip')" (click)="selectTab(Tabs.SUBTITLES, 'subtitles')"><mat-icon>closed_captions</mat-icon></a>
                        <a [class.selected]="selectedTab == tabs.LOCATIONS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.locationsTooltip')" (click)="selectTab(Tabs.LOCATIONS, 'locations')"><mat-icon>storage</mat-icon></a>                    
                        <a [class.selected]="selectedTab == tabs.TASKS" 
                            class="tab"
                            matTooltipPosition="above" 
                            [matTooltip]="i18next.t('mediaDetail.tasksTooltip')" 
                            (click)="selectTab(Tabs.TASKS, 'tasks')">
                            <mat-icon 
                                matBadgeOverlap="false" 
                                matBadgeSize="small"
                                [matBadge]="parentContainer?.pendingTasks?.length" 
                                [matBadgeHidden] = "parentContainer?.pendingTasks?.length === 0"
                                matBadgeColor="warn">manage_history</mat-icon>
                        </a>
                        <a [class.selected]="selectedTab == tabs.USER_TASKS" matTooltipPosition="above" [matTooltip]="i18next.t('mediaDetail.userTasksTooltip')" (click)="selectTab(Tabs.USER_TASKS, 'userTasks')"><mat-icon>task_alt</mat-icon></a>                    
                    </ng-container>
                </div>
            </div>
            <mat-card id="right-panel" class="top-container-heights">
                <mat-card-content *ngIf="selectedTab == tabs.SUBTITLES" style="overflow: auto;">
                    <app-subtitle-editor 
                        [video]="currentPlayer" 
                        [media]="parentContainer?.media" 
                        [mediaElement]="currentSubtitleMediaElement"></app-subtitle-editor>
                </mat-card-content>
                <mat-card-content *ngIf="selectedTab == tabs.UPLOADS" style="overflow: auto;">
                    <div>
                        <app-media-uploads></app-media-uploads>
                    </div>
                </mat-card-content>
                <mat-card-content *ngIf="selectedTab == tabs.DOWNLOADS" style="overflow: auto;">
                    <div>
                        <h2>{{ 'mediaDetail.downloadable_file_title' | i18next }}</h2>
                        <span *ngIf="!spareFiles.length">{{ 'mediaDetail.no_downloadable_file' | i18next }}</span>
                        <ul class="downloads">
                            <li *ngFor="let spareFile of spareFiles">
                                {{spareFile.filename}} <span *ngIf="spareFile.representation">(<span class="code">{{spareFile.representation}}</span>)</span>
                                <a style="transform: scale(0.8) translateY(8px);" mat-icon-button [href]="'/api/static/' + spareFile.filename" target="_blank">
                                    <mat-icon>download</mat-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </mat-card-content>                
                <mat-card-content *ngIf="selectedTab == tabs.LOCATIONS" style="overflow: auto;">
                    <div>
                        <app-media-streams></app-media-streams>
                    </div>
                </mat-card-content>
                <mat-card-content *ngIf="selectedTab == tabs.TASKS" style="overflow: auto; margin: 10px; padding: 0;">
                    <div>
                        <app-tasks [embedded]="true" [mediaIdFilter]="parentContainer?.media?.id ?? 0"></app-tasks>
                    </div>
                </mat-card-content>
                <mat-card-content *ngIf="selectedTab == tabs.COMMENTS">
                    <div class="comments" id="comments-container">
                        <span *ngIf="!loading && !comments?.content?.length" style="color: var(--text-secondary-color)">
                            <mat-icon style="position: relative; top: 6px; margin-right: 3px">warning</mat-icon>&nbsp; 
                            {{ "commentPanel.empty" | i18next  }}
                        </span>                    
                        <ul id="comments">
                            <li *ngFor="let comment of filteredComments" (click)="selectComment(comment)"
                                (mouseenter)="highlightComment(comment)" (mouseleave)="highlightComment(null)"
                                [attr.data-commentId]="comment.id"
                                [class.hasAnswers]="comment.answers?.length">
                                <div class="commentProfileContainer">
                                    <app-profile-picture [matTooltip]="comment.createdBy?.email ?? ''" [user]="comment.createdBy"></app-profile-picture>
                                    <div style="flex-grow: 1">
                                        <div class="commentContainer">
                                            <span class="tc">{{comment.tcIn}} &bullet; {{comment.tcOut}}</span> 
                                            <span class="tc" *ngIf="comment.mediaStream"> &bullet; {{comment.mediaStream?.representation}}</span> 
                                            <span class="tc" *ngIf="comment.mediaVersion"> &bullet; {{comment.mediaVersion?.name}}</span> 
                                            <span class="tc" *ngIf="comment.showableToPartners"> &bullet; PUBLIC</span> 
                                            <mat-icon *ngIf="comment.base64Drawing" class="drawing-presence">edit_square</mat-icon><br />
                                            <div class="comment">{{comment.comment}}</div>
                                            <span class="createdAt"
                                                [matTooltip]="(comment.createdAt! | date:dateFormat)!">{{comment.createdAt! | formatDate}}</span>
                                            <ng-container *ngIf="comment.answers?.length">
                                                &bullet; {{ 'comment.answers' | i18next: { count: comment.answers?.length } }}
                                            </ng-container>
                                            <div class="timecodedCommentButtons">
                                                <button mat-icon-button (click)="answerComment(comment, $event)">
                                                    <mat-icon>reply</mat-icon>
                                                </button>
                                                <button mat-icon-button (click)="deleteComment(comment)" 
                                                        *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length && comment.createdBy?.id == currentUser?.id">
                                                    <mat-icon>delete_forever</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ul class="answers">
                                    <li *ngFor="let answer of comment.answers">
                                        <div class="answerContainer">
                                            <div class="answerProfileContainer" style="flex-grow: 1">
                                                <span class="comment">{{answer.comment}}</span><br />
                                                <span class="createdBy">{{answer.createdBy?.email}}</span> &bullet;
                                                <span class="createdAt"
                                                    [matTooltip]="(answer.createdAt! | date:dateFormat)!">{{answer.createdAt! | formatDate}}</span>                                        
                                            </div>
                                            <app-profile-picture [user]="answer.createdBy"></app-profile-picture>                                            
                                        </div>                                            
                                    </li>
                                </ul>                                
                            </li>
                        </ul>
                    </div>

                    <div id="write-comments-container">
                        <div *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length" id="write-comments">
                            <div id="comment-splitter" (click)="toggleCommentPane()">
                                <mat-icon id="close-comment">expand_more</mat-icon>
                            </div>                        
                            <app-timecoded-comment-block [video]="currentPlayer" [comment]="selectedComment"
                                (commentChange)="highlightCommentDelayed()"
                                [media]="parentContainer?.media"></app-timecoded-comment-block>
            
                            <div class="commentButtons" *ngIf="selectedComment!.id === undefined" id="commentButtons">
                                <button mat-fab extended color="primary" (click)="saveComment()" class="save">
                                    <mat-icon>save</mat-icon>&nbsp;{{ "commentPanel.new" | i18next  }}
                                </button>
                                <button *ngIf="!drawing" mat-fab extended color="primary" (click)="startDrawing()" class="save">
                                    <mat-icon>draw</mat-icon>&nbsp;{{ "commentPanel.draw" | i18next  }}
                                </button>

                                <button *ngIf="drawing" mat-fab extended color="primary" (click)="stopDrawing()" class="save">
                                    <mat-icon>draw</mat-icon>&nbsp;{{ "commentPanel.stopDrawing" | i18next  }}
                                </button>

                                <div style="flex-grow: 1;"></div>

                                <button mat-icon-button style="color: var(--text-secondary-color)" [matMenuTriggerFor]="commentMenu" #commentMenuInfo="matMenuTrigger">
                                    <mat-icon>tune</mat-icon>
                                </button>
                            </div>

                            <mat-menu #commentMenu="matMenu" class="icon-menu">
                                <div style="margin: 7px 15px 5px 15px; font-size: 12px">
                                    {{ "mediaDetail.createCommentOn" | i18next }}
                                </div>
                                                        
                                <button mat-menu-item (click)="commentedTrack = undefined">
                                    <span class="icon-container">
                                        {{ "mediaDetail.commentOnWholeMedia" | i18next }}
                                        <mat-icon *ngIf="!commentedTrack">check</mat-icon>
                                    </span>
                                </button>
                                <button *ngFor="let track of tracks" mat-menu-item (click)="commentedTrack = track">
                                    <span class="icon-container">
                                        {{track.representation ?? track}}
                                        <mat-icon *ngIf="commentedTrack == track">check</mat-icon>
                                    </span>
                                </button>
                                <mat-divider></mat-divider>
                                <div>
                                    <mat-checkbox [(ngModel)]="commentTiedToVersion" (click)="$event.stopPropagation()" style="margin-top: 8px; margin-right: 20px">
                                        {{ "mediaDetail.commentTiedToVersion" | i18next }}
                                    </mat-checkbox>
                                </div>
                                <div>
                                    <mat-checkbox [(ngModel)]="selectedComment.showableToPartners" (click)="$event.stopPropagation()" style="margin-top: 8px; margin-right: 20px">
                                        {{ "mediaDetail.commentViewableByPartners" | i18next }}
                                    </mat-checkbox>
                                </div>
                            </mat-menu>                        
                        </div>

                        <div class="commentButtons" id="commentButtons" *ngIf="selectedComment!.id !== undefined && intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">
                            <button mat-fab extended color="primary" (click)="saveComment()" class="edit">
                                <mat-icon>save</mat-icon>&nbsp;{{"commentPanel.edit" | i18next}}
                            </button>
                            <button mat-fab extended color="primary" (click)="cancelSelection()" class="cancel">
                                <mat-icon>cancel</mat-icon>&nbsp;{{"commentPanel.cancel" | i18next}}
                            </button>
                        </div>
                    
                    </div>
                </mat-card-content>

                <mat-card-content *ngIf="selectedTab == tabs.USER_TASKS" style="max-height: 100%; overflow: auto">
                    <app-usertasks [media]="parentContainer?.media"></app-usertasks>
                </mat-card-content>

                <mat-card-content *ngIf="selectedTab == tabs.ACTIVITY" style="max-height: 100%; overflow: auto">
                    <app-history-viewer [media]="parentContainer?.media"></app-history-viewer>
                </mat-card-content>

                <mat-card-content *ngIf="selectedTab == tabs.VERSIONS_METADATAS" style="max-height: 100%; overflow: auto">

                    <div *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">

                        <!-- version related metadatas -->
                        <div style="position: relative; margin-top: 10px;" *ngIf="selectedVersion.id">
                            <app-metadata-display 
                                [element]="selectedVersion"
                                [targetId]="selectedVersion.id"
                                [targetType]="metadataAttachmentTypes.MEDIA_VERSION"
                                [externalId]="selectedVersion.externalId"
                                (onMetadataUpdated)="reload()">
                            </app-metadata-display>                  


                            <!-- individual groups -->
                            <ng-container *ngFor="let group of groupedVersionMetadataGroups">
                                <div class="groupedMetadataGroup" 
                                    *ngIf="!(group.externalDatasourceURL !== undefined && !selectedVersion.externalId)">
                                    <app-metadata-display 
                                        [element]="selectedVersion"
                                        [targetId]="selectedVersion.id"
                                        [targetType]="metadataAttachmentTypes.MEDIA_VERSION"
                                        [externalId]="selectedVersion.externalId"
                                        [grouped]="true"
                                        [remote]="group.externalDatasourceURL !== undefined"
                                        [limitToGroup]="group"
                                        (onMetadataUpdated)="reload()">
                                    </app-metadata-display>
                                </div>        
                            </ng-container>                            
                        </div>
                    </div>
                </mat-card-content>

                <mat-card-content *ngIf="selectedTab == tabs.METADATAS" style="max-height: 100%; overflow: auto">

                    <div *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length">
                        <!-- media metadatas -->
                        <div style="position: relative; margin-bottom: 30px;">
                            <app-metadata-display 
                                [element]="parentContainer?.media"
                                [targetId]="parentContainer?.media?.id"
                                [externalId]="parentContainer?.media?.externalId"
                                [targetType]="metadataAttachmentTypes.MEDIA"
                                (onMetadataUpdated)="reload()">
                            </app-metadata-display>
                        </div>

                        <!-- individual groups -->
                        <ng-container *ngFor="let group of groupedMediaMetadataGroups">
                            <div style="margin-bottom: 30px; margin-top: 30px; position: relative;" 
                                 *ngIf="!(group.externalDatasourceURL !== undefined && !this.parentContainer?.media?.externalId)">
                                <app-metadata-display 
                                    [element]="parentContainer?.media"
                                    [targetId]="parentContainer?.media?.id"
                                    [targetType]="metadataAttachmentTypes.MEDIA"
                                    [externalId]="parentContainer?.media?.externalId"
                                    [grouped]="true"
                                    [remote]="group.externalDatasourceURL !== undefined"
                                    [limitToGroup]="group"
                                    (onMetadataUpdated)="reload()">
                                </app-metadata-display>
                            </div>        
                        </ng-container>

                    </div>
                </mat-card-content>

                <mat-card-content *ngIf="selectedTab == tabs.STREAMS" style="overflow: auto; margin-left: 5px; position: relative;">
                    <div *ngIf="intersect(currentUser?.privileges, ['WRITE_PRIVILEGE']).length && selectedVersion.id != null && selectedVersion.audio && selectedVersion.video">
                        <div>

                            <h2 style="margin-top: 5px;">
                                {{"mediaDetail.video" | i18next}}
                            </h2>

                            <div style="margin-bottom: 20px">

                                <div style="margin-bottom: 20px">
                                    {{"mediaDetail.Stream" | i18next}} #{{selectedVersion.video?.streamIndex}} {{"mediaDetail.of" | i18next}} <span class="code">{{selectedVersion.video?.fromFilename}}</span><br/>
                                </div>

                                <div style="position: relative">
                                    <app-metadata-display 
                                        [element]="selectedVersion?.video"
                                        [targetId]="selectedVersion?.video?.id"
                                        [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                        mediaStreamType="VIDEO"
                                        (onMetadataUpdated)="reload()">
                                    </app-metadata-display>   
                                </div>                                  

                                <!-- individual groups -->
                                <ng-container *ngFor="let group of groupedStreamMetadataGroups">
                                    <div style="margin-bottom: 30px; margin-top: 30px; position: relative;" 
                                        *ngIf="!(group.externalDatasourceURL !== undefined && !this.parentContainer?.media?.externalId)">
                                        <app-metadata-display 
                                            [element]="selectedVersion?.video"
                                            [targetId]="selectedVersion?.video?.id"
                                            [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                            mediaStreamType="VIDEO"
                                            [grouped]="true"
                                            [limitToGroup]="group"
                                            (onMetadataUpdated)="reload()">
                                        </app-metadata-display>
                                    </div>        
                                </ng-container>                                
                            </div>

                            <ng-container *ngIf="selectedVersion.video?.alternatives?.length">
                                <h2>
                                    Alternative videos
                                </h2>

                                <div style="margin-bottom: 20px" *ngFor="let stream of selectedVersion.video?.alternatives">

                                    <div style="margin-bottom: 20px" *ngIf="stream.status === 'RECEIVED'">
                                        Stream #{{stream.streamIndex}} of <span class="code">{{stream.fromFilename}}</span><br/>
                                    </div>

                                    <div style="position: relative">
                                        <app-metadata-display 
                                            [element]="stream"
                                            [targetId]="stream.id"
                                            [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                            mediaStreamType="VIDEO"
                                            (onMetadataUpdated)="reload()">
                                        </app-metadata-display>   
                                    </div>  
                                    
                                    <!-- individual groups -->
                                    <ng-container *ngFor="let group of groupedStreamMetadataGroups">
                                        <div style="margin-bottom: 30px; margin-top: 30px; position: relative;" 
                                            *ngIf="!(group.externalDatasourceURL !== undefined && !this.parentContainer?.media?.externalId)">
                                            <app-metadata-display 
                                                [element]="stream"
                                                [targetId]="stream.id"
                                                [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                                mediaStreamType="VIDEO"
                                                [grouped]="true"
                                                [limitToGroup]="group"
                                                (onMetadataUpdated)="reload()">
                                            </app-metadata-display>
                                        </div>        
                                    </ng-container>                                         
                                </div>
                            </ng-container>

                            <h2>
                                {{"mediaDetail.audio" | i18next}}
                            </h2>
        
                            <div style="margin-bottom: 20px">
                                
                                <div style="margin-bottom: 20px">
                                    {{"mediaDetail.Stream" | i18next}} #{{selectedVersion.audio?.streamIndex}} {{"mediaDetail.of" | i18next}} <span class="code">{{selectedVersion.audio?.fromFilename}}</span><br/>
                                </div>
                                <div style="position: relative">
                                    <app-metadata-display 
                                        [element]="selectedVersion?.audio"
                                        [targetId]="selectedVersion?.audio?.id"
                                        [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                        mediaStreamType="AUDIO"
                                        (onMetadataUpdated)="reload()">
                                    </app-metadata-display>    
                                </div>      
                                
                                <!-- individual groups -->
                                <ng-container *ngFor="let group of groupedStreamMetadataGroups">
                                    <div style="margin-bottom: 30px; margin-top: 30px; position: relative;" 
                                        *ngIf="!(group.externalDatasourceURL !== undefined && !this.parentContainer?.media?.externalId)">
                                        <app-metadata-display 
                                            [element]="selectedVersion?.audio"
                                            [targetId]="selectedVersion?.audio?.id"
                                            [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                            mediaStreamType="AUDIO"
                                            [grouped]="true"
                                            [limitToGroup]="group"
                                            (onMetadataUpdated)="reload()">
                                        </app-metadata-display>
                                    </div>        
                                </ng-container>                                    
                            </div>
                        </div>
        
                        <ng-container *ngIf="selectedVersion.audio?.alternatives?.length">
                            <h2>
                                Alternative audios
                            </h2>

                            <div style="margin-bottom: 20px" *ngFor="let stream of selectedVersion.audio?.alternatives">

                                <div style="margin-bottom: 20px" *ngIf="stream.status === 'RECEIVED'">
                                    Stream #{{stream.streamIndex}} of <span class="code">{{stream.fromFilename}}</span><br/>
                                </div>

                                <div style="position: relative">
                                    <app-metadata-display 
                                        [element]="stream"
                                        [targetId]="stream.id"
                                        [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                        mediaStreamType="AUDIO"
                                        (onMetadataUpdated)="reload()">
                                    </app-metadata-display>   
                                </div>    

                                <!-- individual groups -->
                                <ng-container *ngFor="let group of groupedStreamMetadataGroups">
                                    <div style="margin-bottom: 30px; margin-top: 30px; position: relative;" 
                                        *ngIf="!(group.externalDatasourceURL !== undefined && !this.parentContainer?.media?.externalId)">
                                        <app-metadata-display 
                                            [element]="stream"
                                            [targetId]="stream.id"
                                            [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                            mediaStreamType="AUDIO"
                                            [grouped]="true"
                                            [limitToGroup]="group"
                                            (onMetadataUpdated)="reload()">
                                        </app-metadata-display>
                                    </div>        
                                </ng-container>                                               
                            </div>
                        </ng-container>

                        <div *ngIf="selectedVersion.id != null && selectedVersion.subtitles?.length">
                            <h2>
                                {{ "mediaDetail.subtitles" | i18next  }}
                            </h2>
                            <div *ngFor="let stream of selectedVersion.subtitles" class="stream">

                                <div style="margin-bottom: 20px">
                                    {{"mediaDetail.Stream" | i18next}} #{{stream?.streamIndex}} {{"mediaDetail.of" | i18next}} <span class="code">{{stream?.fromFilename}}</span><br/>
                                </div>
                                <div style="position: relative">
                                    <app-metadata-display 
                                        [element]="stream"
                                        [targetId]="stream?.id"
                                        [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                        mediaStreamType="SUBTITLE"
                                        (onMetadataUpdated)="reload()">
                                    </app-metadata-display> 
                                </div>          
                                
                                <!-- individual groups -->
                                <ng-container *ngFor="let group of groupedStreamMetadataGroups">
                                    <div style="margin-bottom: 30px; margin-top: 30px; position: relative;" 
                                        *ngIf="!(group.externalDatasourceURL !== undefined && !this.parentContainer?.media?.externalId)">
                                        <app-metadata-display 
                                            [element]="stream"
                                            [targetId]="stream?.id"
                                            [targetType]="metadataAttachmentTypes.MEDIA_STREAM"
                                            mediaStreamType="SUBTITLE"
                                            [grouped]="true"
                                            [limitToGroup]="group"
                                            (onMetadataUpdated)="reload()">
                                        </app-metadata-display>
                                    </div>        
                                </ng-container>                                        
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
    
        </div>
    </div>
</div>

