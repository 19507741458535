import { Component, Inject } from '@angular/core';

import { Page } from 'src/app/models/page';
import { Location } from 'src/app/models/media';
import { LocationService } from 'src/app/services/location.service';
import i18next from 'i18next';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.sass']
})
export class LocationsComponent {

  result: Page<Location> = {}
  loading: boolean = true
  dataSource: Location[] = []
  columnsToDisplay: string[] = ['id', 'type', 'name', 'tools']

  constructor(private locationService: LocationService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getLocations();
  }

  deleteLocation(element:Location) {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'location.confirmDelete',
        content: `location.confirmDeleteMessage`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.locationService.deleteLocation(element.id!).subscribe(s => {
          this.getLocations()
        })
      }
    });    
  }

  editLocation(params:Location) {
    console.log('openReportDetail()')
    const location = cloneDeep(params)
    const dialogRef = this.dialog.open(LocationDialog, {
      data: {
        location
      }, 
      minWidth: '50vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        result = result.location
        this.locationService.saveLocation(result).subscribe(_ => this.getLocations())
      }
    });     
  }
  
  getLocations(): void {
    this.locationService.getLocations(true)
      .subscribe(result => {
        this.result = result
        this.loading = false
        this.dataSource = this.result.content ?? []
        console.log('set datasource to', this.dataSource)
      });
  }
}

export interface LocationDialogData {
  location:Location;
}

@Component({
  selector: 'dialog-location',
  templateUrl: './dialog-location.html',
  styleUrls: [ './dialog-location.sass' ]
})
export class LocationDialog {
  
  i18next = i18next

  constructor(
    public dialogRef: MatDialogRef<LocationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: LocationDialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
