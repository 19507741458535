import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface SimpleImageDialogData {
    title: string;
    text: string;
    imageBase64: string;
}

@Component({
    selector: 'dialog-simple-image',
    templateUrl: './simple.image.dialog.html',
})
export class SimpleImageDialog {
    constructor(
        public dialogRef: MatDialogRef<SimpleImageDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleImageDialogData,
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
