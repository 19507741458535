<div *ngIf="result?.numberOfElements; else firstLoad">

    <div class="buttons">
        <button mat-fab class="add" matTooltip="Load more" (click)="loadMore()" *ngIf="!result.last">
            <mat-icon>arrow_downward</mat-icon>
        </button>
    </div>

    <h1>
        {{"dashboard.your_dashboard" | i18next}}
        <span *ngIf="dashboard?.name"> "{{dashboard?.name}}"</span>
    </h1>

    <div>
        <mat-form-field appearance="fill">
            <mat-label>{{"dashboard.breakdown" | i18next}}</mat-label>
            <mat-select [(ngModel)]="breakdownOn">
                <mat-option value="">
                    {{"dashboard.none" | i18next}}
                </mat-option>
                <mat-option *ngFor="let fields of availableMultivaluedFields" [value]="fields">
                    {{fields.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="columns">
        <div class="column" *ngFor="let allowedValue of getAllowedValues()">
            <h2>{{allowedValue}}</h2>

            <mat-card class="mat-elevation-z8" *ngFor="let element of getDatasource(allowedValue)" [routerLink]="'/media/' + element.id">
                <mat-card-header>
                    <mat-card-title>{{element.name}}</mat-card-title>
                    <mat-card-subtitle>{{element.versions?.length}} version(s)</mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image [src]="'/api/static/variants/' + element.id + '-small.png'" onerror="this.src='/assets/pixel.png'" />
            </mat-card>

        </div>
    </div>
</div>

<ng-template #firstLoad>
    <div *ngIf="loading; else empty">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #empty>
    <h2>{{"dashboard.empty_title" | i18next}}</h2>
    <p>{{"dashboard.empty_message" | i18next}}</p>
</ng-template>