<h1 mat-dialog-title style="margin-bottom: 0">{{ "partnerUpload.title" | i18next }}</h1>

<mat-dialog-content class="upload-dialog" id="content">

    <div style="margin-bottom: 10px">
        <ng-container *ngIf="data.upload.expectedStreams.length">
            {{ "partnerUpload.awaiting" | i18next: { count: data.upload.expectedStreams.length} }}
            <span class="code">{{data.upload.media.value}}</span>:
        </ng-container>
    </div>

    <div class="code" style="margin-bottom: 10px; margin-left: 10px;">
        <ul>
            <li *ngFor="let stream of data.upload.expectedStreams">
                {{stream.representation}}
            </li>
        </ul>
    </div>
    <div class="requester-message" style="margin-bottom: 30px" *ngIf="data.upload.message">
        {{ "partnerUpload.requesterMessage" | i18next }}<br/>
        {{data.upload.message}}
    </div>
    <div style="flex-grow: 1; display: flex; width: 100%; flex-direction: column;">
        <div 
            [class.small]="!(status === uploadSessionStatus.INITIAL || status === uploadSessionStatus.ANALYZING)" 
            *ngIf="status === uploadSessionStatus.INITIAL || status === uploadSessionStatus.ANALYZING || status === uploadSessionStatus.ANALYZED" 
            id="drop">
            <input type="file" id="file" (change)="uploadFile($event)" class="inputfile" multiple>
            <label for="file">
                <mat-icon>upload</mat-icon>
                <span>{{ "partnerUpload.dropZone" | i18next }}</span>
            </label>
        </div>
        <div *ngIf="status === uploadSessionStatus.ANALYZING" style="margin-top: 20px;">
            {{ "partnerUpload.analyzing" | i18next }} : {{ (analyzeProgress*100).toFixed(2) }}%
        </div>
        <div *ngIf="status === uploadSessionStatus.ANALYZED">
            <ng-container *ngFor="let file of analyzedFiles; let i = index">
                
                <div class="code" style="margin-top: 20px; margin-bottom: 5px;" *ngIf="this.data.upload.imf && i === 0">
                    IMF PACKAGE
                </div>

                <div class="code" style="margin-top: 20px; margin-bottom: 5px;" *ngIf="!this.data.upload.imf || mappableTracks?.get(file.name)?.length">
                    {{file.name}}

                    <mat-icon style="transform: translateY(7px); cursor: pointer" 
                        [matTooltip]="trackErrors.get(i + '-General-0') ?? ''"
                        matTooltipPosition="after"
                        *ngIf="trackErrors.has(i + '-General-0')">warning</mat-icon>                    
                </div>
                
                <!-- freestyle delivery -->
                <ng-container *ngIf="!data.upload.expectedStreams.length">
                    <div *ngFor="let track of mappableTracks.get(file.name)">
                        {{track['@type']}} (Stream #{{track['StreamOrder']}}) 
                        <button mat-icon-button style="position: relative; top: 7px;" 
                            (click)="describeStream(i, track)"
                            [matTooltip]="i18next.t('partnerUpload.describe')">
                            <mat-icon>settings</mat-icon>          
                        </button>
                        <mat-icon style="transform: translateY(7px); cursor: pointer" 
                            [matTooltip]="trackErrors.get(i + '-' + track['@type'] + '-' + track['StreamOrder']) ?? ''"
                            matTooltipPosition="after"
                            *ngIf="trackErrors.has(i + '-' + track['@type'] + '-' + track['StreamOrder'])">warning</mat-icon>
                    </div>
                </ng-container>
                <!-- constrained delivery -->
                <ng-container *ngIf="data.upload.expectedStreams.length">
                    <div *ngFor="let track of mappableTracks.get(file.name)" style="margin-left: 30px">
                        {{track['@type']}} (Stream #{{track['StreamOrder']}}) 
                        <mat-icon class="arrow">arrow_forward</mat-icon>
                        <mat-form-field appearance="fill">
                            <mat-label>{{ "partnerUpload.expectedStream" | i18next }}</mat-label>
                            <mat-select [ngModel]="mediaExpectedStreamMapping.get(i + '-' + track['@type'] + '-' + track['StreamOrder'])" 
                                        (ngModelChange)="mediaExpectedStreamMapping.set(i + '-' + track['@type'] + '-' + track['StreamOrder'], $event)">
                            <mat-option *ngFor="let expectedStream of getExpectedStreamOfType(track['@type'])" [value]="expectedStream">
                                {{expectedStream.representation}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>                  
                    </div>
                </ng-container>
                
                <div *ngIf="mappableTracks.get(file.name)?.length === 0 && expectedStreamsPerType.get('SPARE_FILE')?.length">
                    <mat-icon class="arrow">arrow_forward</mat-icon>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ "partnerUpload.expectedStream" | i18next }}</mat-label>
                        <mat-select [ngModel]="mediaExpectedStreamMapping.get(i + '-File')" 
                                    (ngModelChange)="mediaExpectedStreamMapping.set(i + '-File', $event)">
                          <mat-option *ngFor="let expectedStream of expectedStreamsPerType.get('SPARE_FILE')" [value]="expectedStream">
                              {{expectedStream.representation}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
        <div *ngIf="status === uploadSessionStatus.UPLOADING" style="position: relative; top: 55px; text-align: center;">
            <mat-spinner style="display: inline-block; margin-right: 5px"></mat-spinner> 
            <span style="position: absolute; top: 21px; left: 0; right: 0; text-align: center; font-size: 14px;">
                ({{currentFileIndex+1}}/{{analyzedFiles.length}})<br/>
                <span style="font-weight: bold;">{{progress}}%</span>
            </span>
        </div>
        <div *ngIf="status === uploadSessionStatus.UPLOADED" style="text-align: center; position: relative; top: 60px;" class="success">
            <mat-icon style="transform: scale(3);">verified</mat-icon>
            <div style="margin-top: 30px">{{ "partnerUpload.succeeded" | i18next }}</div>
        </div>
    </div>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button *ngIf="status === uploadSessionStatus.ANALYZED" (click)="triggerUpload()">{{ "partnerUpload.upload" | i18next }}</button>
    <button mat-button *ngIf="status === uploadSessionStatus.ANALYZED" (click)="triggerUpload(true)">{{ "partnerUpload.upload_3rd_party" | i18next }}</button>
    <button mat-button (click)="onNoClick()">{{ "partnerUpload.close" | i18next }}</button>
</div>
