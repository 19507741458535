import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Location } from '../models/media';
import { OutgestProfile } from '../models/outgest.profile';
import { Task } from '../models/task';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class OutgestService {

  private outgestUrl = 'api/outgest'

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }


  deleteProfile(id: number): Observable<any> {
    return this.http.delete<void>(`/api/outgest/profile/${id}`)
    .pipe(
        catchError((error: any): Observable<any> => {
          return of({error})
        })
    );
  }
  
  persistProfile(profile: OutgestProfile) {
    const url = `/api/outgest/profile`;
    return this.http.put<void>(url, profile)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistProfile`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  triggerOutgestTask(outgestProfileId:number, 
    mediaId:number, 
    destinationLocationId: number, 
    videoStreamIds:number[], 
    audioStreamIds:number[],
    subtitleStreamIds:number[],
    mediaElementIds:number[],
    selectedPrerollId:number,
    tcIn:number,
    tcOut:number,
    outputFilenamePrefix:string,
    createSpareFile:boolean,
    spareFileAccessibleToPartners:boolean): Observable<Task> {
    const url = `${this.outgestUrl}`;
    return this.http.post<Task>(url, {
      outgestProfileId,
      destinationLocationId, 
      mediaId,
      videoStreamIds,
      audioStreamIds,
      subtitleStreamIds,
      mediaElementIds,
      prerollMediaId: selectedPrerollId,
      tcIn,
      tcOut,
      outputFilenamePrefix,
      createSpareFile,
      spareFileAccessibleToPartners
    })
      .pipe(
        catchError(this.errorService.handleError<Task>(`triggerOutgestTask`))
      );
        
  }

  getOutgestProfiles(): Observable<Page<OutgestProfile>> {
    return this.http.get<Page<OutgestProfile>>(`${this.outgestUrl}/profiles`)
      .pipe(
        tap(_ => this.log('fetched locations')),
        catchError(this.errorService.handleError<Page<OutgestProfile>>('locations', {}))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
