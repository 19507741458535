<h1 mat-dialog-title>{{ data.creation ? ('mediaDetail.schema_detail.create' | i18next) : ('mediaDetail.schema_detail.edit' | i18next) }}</h1>
<mat-dialog-content class="content">

    <mat-form-field appearance="fill">
        <mat-label>{{"mediaDetail.schema_detail.type" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.definition.type" cdkFocusInitial>
            <mat-option *ngFor="let type of Object.keys(data.metadataTypes)" [value]="type">
                {{type}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"mediaDetail.schema_detail.name" | i18next}}</mat-label>
        <input class="code" matInput [(ngModel)]="data.definition.name">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"mediaDetail.schema_detail.label" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.definition.label">
    </mat-form-field>

    <!-- TO IMPLEMENT LATER -->
    <!--mat-form-field appearance="fill" *ngIf="data.definition.type === 'MULTI_VALUED'">
        <mat-label>{{"mediaDetail.schema_detail.default_value" | i18next}}</mat-label>
        <mat-select [(ngModel)]="data.definition.defaultValue">
            <mat-option *ngFor="let allowedValue of data.definition.allowedValues" [value]="allowedValue">
                {{allowedValue}}
            </mat-option>
        </mat-select>        
    </mat-form-field-->

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'EXTRACTED_FROM_STREAM'">
        <mat-label>{{"mediaDetail.schema_detail.extracted_from" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.definition.extractedFrom">
            <mat-option *ngFor="let type of extractableFields" [value]="type">
                {{type}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-checkbox class="checkbox" [(ngModel)]="data.definition.searchable">{{"mediaDetail.schema_detail.searchable" | i18next}}</mat-checkbox>
    <mat-checkbox class="checkbox" [(ngModel)]="data.definition.representative">{{"mediaDetail.schema_detail.representative" | i18next}}</mat-checkbox>
    <mat-checkbox class="checkbox" [(ngModel)]="data.definition.displayIfEmpty">{{"mediaDetail.schema_detail.displayIfEmpty" | i18next}}</mat-checkbox>
    <mat-checkbox class="checkbox" *ngIf="data.definition.type === 'MULTI_VALUED'" [(ngModel)]="data.definition.translated">{{"mediaDetail.schema_detail.translated" | i18next}}</mat-checkbox>

    <mat-form-field appearance="fill">
        <mat-label>{{"mediaDetail.schema_detail.column" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.definition.columnWidth">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'COMPUTED'">
        <mat-label>{{"mediaDetail.schema_detail.rendering" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.definition.renderingComponent">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'EXTERNAL_KEY_VALUE'">
        <mat-label>{{"mediaDetail.schema_detail.externalEndpointURL" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.definition.externalEndpointURL">
    </mat-form-field>    

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'MULTI_VALUED' || data.definition.type === 'COMPUTED'">
        <mat-label>{{"mediaDetail.schema_detail.allowedValues" | i18next}}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Enter a new value">
            <mat-chip-row *ngFor="let allowedValue of data.definition.allowedValues" (removed)="removeAllowedValue(allowedValue)" [editable]="true">
                {{allowedValue}}
                <button matChipRemove [attr.aria-label]="'remove ' + allowedValue">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('mediaDetail.schema_detail.new_value')" [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                (matChipInputTokenEnd)="addAllowedValue($event)" />
        </mat-chip-grid>
    </mat-form-field>
    
    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'REFERENCE'">
        <mat-label>{{"mediaDetail.schema_detail.roles_allowed" | i18next}}</mat-label>

        <mat-chip-grid #chipGrid aria-label="Enter a new value">
            <mat-chip-row *ngFor="let role of data.definition.addReferencesRestrictedToRoles" (removed)="removeRole('addReferencesRestrictedToRoles', role)" [editable]="true">
                {{role}}
                <button matChipRemove [attr.aria-label]="'remove ' + role">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('mediaDetail.schema_detail.placeholder_roles')" [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                [matAutocomplete]="autoMetadata" />
            <mat-autocomplete #autoMetadata="matAutocomplete" (optionSelected)="addRole('addReferencesRestrictedToRoles', $event)">
                <mat-option *ngFor="let role of data.roles" [value]="role.name">
                    {{role.name}}
                </mat-option>
            </mat-autocomplete>                
        </mat-chip-grid>        
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>{{"mediaDetail.schema_detail.allowed_edit" | i18next}}</mat-label>

        <mat-chip-grid #chipGrid aria-label="Enter a new value">
            <mat-chip-row *ngFor="let role of data.definition.editingRestrictedToRoles" (removed)="removeRole('editingRestrictedToRoles', role)" [editable]="true">
                {{role}}
                <button matChipRemove [attr.aria-label]="'remove ' + role">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('mediaDetail.schema_detail.placeholder_roles')" [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                [matAutocomplete]="autoMetadata" />
            <mat-autocomplete #autoMetadata="matAutocomplete" (optionSelected)="addRole('editingRestrictedToRoles', $event)">
                <mat-option *ngFor="let role of data.roles" [value]="role.name">
                    {{role.name}}
                </mat-option>
            </mat-autocomplete>                
        </mat-chip-grid>        
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>{{"mediaDetail.schema_detail.display_restricted_to_roles" | i18next}}</mat-label>

        <mat-chip-grid #chipGridDisplayRestriction aria-label="Enter a new value">
            <mat-chip-row *ngFor="let role of data.definition.displayRestrictedToRoles" (removed)="removeRole('displayRestrictedToRoles', role)" [editable]="true">
                {{role}}
                <button matChipRemove [attr.aria-label]="'remove ' + role">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('mediaDetail.schema_detail.placeholder_user_roles')" [matChipInputFor]="chipGridDisplayRestriction"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                [matAutocomplete]="autoMetadataDisplayRestriction" />
            <mat-autocomplete #autoMetadataDisplayRestriction="matAutocomplete" (optionSelected)="addRole('displayRestrictedToRoles', $event)">
                <mat-option *ngFor="let role of data.roles" [value]="role.name">
                    {{role.name}}
                </mat-option>
            </mat-autocomplete>                              
        </mat-chip-grid>
    </mat-form-field>
        
    <mat-form-field appearance="fill" *ngIf="data.definition.referencedMetadataGroupEntity?.attachmentType === 'MEDIA_STREAM' || data.definition.parentGroup?.attachmentType === 'MEDIA_STREAM'">
        <mat-label>{{"mediaDetail.schema_detail.only" | i18next}}</mat-label>


        <mat-chip-grid #chipGrid aria-label="Enter a new value">
            <mat-chip-row *ngFor="let allowedValue of data.definition.displayRestrictedToMediaStreamTypes" (removed)="removeDisplayedOnStreamType(allowedValue)" [editable]="true">
                {{allowedValue}}
                <button matChipRemove [attr.aria-label]="'remove ' + allowedValue">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('settings.new_column')" [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                [matAutocomplete]="autoMetadata" />
            <mat-autocomplete #autoMetadata="matAutocomplete" (optionSelected)="addDisplayedOnStreamType($event)">
                <mat-option *ngFor="let allowedValue of ['VIDEO', 'AUDIO', 'SUBTITLE']" [value]="allowedValue">
                    {{allowedValue}}
                </mat-option>
            </mat-autocomplete>                
        </mat-chip-grid>        
    </mat-form-field>    

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'COMPUTED'">
        <mat-label>{{"mediaDetail.schema_detail.formula" | i18next}}</mat-label>
        <textarea cdkTextareaAutosize matInput style="font-family: 'Courier New', Courier, monospace;" [(ngModel)]="data.definition.formula"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'REFERENCE' || data.definition.type === 'MULTI_ENTITIES'">
        <mat-label>{{"mediaDetail.schema_detail.reference" | i18next}}</mat-label>
        <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput 
               [(ngModel)]="data.definition.referencedMetadataGroupEntity" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMetadataGroup">
            <mat-option *ngFor="let option of referenceables" [value]="option">
                {{option?.name ?? 'None'}}
            </mat-option>
        </mat-autocomplete>        
    </mat-form-field>    
    
    <!-- // TODO searchable, editingRestrictedToRole -->

    <mat-form-field appearance="fill" *ngIf="data.definition.type === 'MULTI_VALUED'">
        <mat-label>{{"mediaDetail.schema_detail.workflow" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.definition.workflowStepToTrigger">
    </mat-form-field>

</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"mediaDetail.schema_detail.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"mediaDetail.schema_detail.ok" | i18next}}</button>
</div>