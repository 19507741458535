<h1 mat-dialog-title>{{"mediaDetail.dialog_outgest.outgest" | i18next}}</h1>
<mat-dialog-content style="min-width: 600px;">
  <div [className]="data.user?.group?.defaultLocation ? 'hidden-field' : ''">
    <p>{{"mediaDetail.dialog_outgest.output_location" | i18next}}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{"mediaDetail.dialog_outgest.destination" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.destinationLocation" [compareWith]="compareFn">
        <mat-option *ngFor="let location of data.locations" [value]="location">
            {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <p>{{"mediaDetail.dialog_outgest.select_outgest_profile" | i18next}}</p>
  <mat-form-field appearance="fill">
    <mat-label>{{"mediaDetail.dialog_outgest.outgest_profile" | i18next}}</mat-label>
    <mat-select [(ngModel)]="data.selectedOutgestProfile" [compareWith]="compareFn">
      <mat-option *ngFor="let profile of data.outgestProfiles" [value]="profile">
          {{profile.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>  
  <br/>

  <ng-container *ngIf="data.selectedOutgestProfile?.hasPreroll">
    <p>{{"mediaDetail.dialog_outgest.preroll" | i18next}}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{"mediaDetail.dialog_outgest.use_as_preroll" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.selectedPreroll">
        <mat-option *ngFor="let preroll of data.prerolls" [value]="preroll">
            {{preroll.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>  
    <br/>
  </ng-container>

  <ng-container *ngIf="data.selectedOutgestProfile?.numberOfFiles">
    <p>{{"mediaDetail.dialog_outgest." | i18next}} {{data.selectedOutgestProfile.numberOfFiles}} {{"mediaDetail.dialog_outgest.input_file" | i18next}}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{"dialog_outgest.input_files" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.selectedFiles" multiple>
        <mat-option *ngFor="let file of data.availableFiles" [value]="file">
          {{file.filename}} {{"mediaDetail.dialog_outgest.containing" | i18next}} {{file.streams?.length}} {{"mediaDetail.dialog_outgest.stream" | i18next}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
  </ng-container>

  <ng-container *ngIf="data.selectedOutgestProfile?.numberOfVideoStreams && !data.streamSelectionHidden">
    <p>{{"mediaDetail.dialog_outgest.pick" | i18next}} {{data.selectedOutgestProfile.numberOfVideoStreams}} {{"mediaDetail.dialog_outgest.video_stream" | i18next}}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{"mediaDetail.dialog_outgest.Video_stream" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.selectedVideoStreams" multiple>
        <mat-option *ngFor="let stream of data.availableVideoStreams" [value]="stream">
          Stream #{{stream.streamIndex}} from {{stream.fromFilename}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
  </ng-container>
  
  <ng-container *ngIf="data.selectedOutgestProfile?.numberOfAudioStreams && !data.streamSelectionHidden">
    <p>{{"mediaDetail.dialog_outgest.pick" | i18next}} {{data.selectedOutgestProfile.numberOfAudioStreams}} {{"mediaDetail.dialog_outgest.audio_stream" | i18next}}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{"mediaDetail.dialog_outgest.Audio_stream" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.selectedAudioStreams" multiple>
        <mat-option *ngFor="let stream of data.availableAudioStreams" [value]="stream">
          {{"mediaDetail.dialog_outgest.Stream" | i18next}} #{{stream.streamIndex}} {{"mediaDetail.dialog_outgest.from" | i18next}} {{stream.fromFilename}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.selectedOutgestProfile?.numberOfSubtitlesStreams && !data.streamSelectionHidden">
    <p>{{"mediaDetail.dialog_outgest.pick" | i18next}} {{data.selectedOutgestProfile.numberOfSubtitlesStreams}} {{"mediaDetail.dialog_outgest.subtitle_stream" | i18next}}</p>
    <mat-form-field appearance="fill">
      <mat-label>{{"mediaDetail.dialog_outgest.Subtitle_stream" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.selectedSubtitleStreams" multiple>
        <mat-option *ngFor="let stream of data.availableSubtitleStreams" [value]="stream">
          {{"mediaDetail.dialog_outgest.Stream" | i18next}} #{{stream.streamIndex}} {{"mediaDetail.dialog_outgest.from" | i18next}} {{stream.fromFilename}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
  </ng-container>

  <ng-container *ngIf="data.selectedOutgestProfile?.hasMarkInAndMarkOut">
    <p>{{"mediaDetail.dialog_outgest.mark_in_out_text" | i18next}}</p>
    <div style="display: flex;">
      <app-timecode-input style="margin-right: 10px; flex-grow: 1" [label]="i18next.t('mediaDetail.dialog_outgest.tc_in')" [(ngModel)]="data.tcIn"></app-timecode-input>
      <app-timecode-input style="flex-grow: 1" [label]="i18next.t('mediaDetail.dialog_outgest.tc_out')" [(ngModel)]="data.tcOut"></app-timecode-input>
    </div>
  </ng-container>

  <mat-form-field appearance="fill">
    <mat-label>{{"mediaDetail.dialog_outgest.output_prefix" | i18next}}</mat-label>
    <input matInput [(ngModel)]="data.outputFilenamePrefix">
  </mat-form-field>

  <div>
    <mat-checkbox class="checkbox" [(ngModel)]="data.createSpareFile">{{"mediaDetail.dialog_outgest.create_spare_file" | i18next}}</mat-checkbox>    
    <mat-checkbox class="checkbox" [(ngModel)]="data.spareFileAccessibleToPartners">{{"mediaDetail.dialog_outgest.spare_file_accessible_to_partners" | i18next}}</mat-checkbox>    
  </div>

</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{"mediaDetail.dialog_outgest.cancel" | i18next}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"mediaDetail.dialog_outgest.ok" | i18next}}</button>
</div>  