<h1>{{"mediaDetail.schema_detail.group" | i18next}}</h1>
<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="!loading">
    <div class="center-buttons" [class.shown]="orderChanged">
        <button mat-fab extended class="add" (click)="saveOrderChange()">
            Save order change
        </button>
    </div>

    <div class="buttons" *ngIf="!currentGroup.system">
        <button *ngIf="currentGroup.attachmentType === metadataAttachmentTypes.REFERENCEABLE" mat-fab class="add" matTooltip="Add a new entry" (click)="openMetadataReferenceDialog()">
            <mat-icon>post_add</mat-icon>
        </button>
        <button *ngIf="currentGroup.attachmentType === metadataAttachmentTypes.REFERENCEABLE" mat-fab class="add" matTooltip="List entries" (click)="openMetadataReferenceListDialog()">
            <mat-icon>list</mat-icon>
        </button>
        <button mat-fab class="add" [matTooltip]="i18next.t('mediaDetail.schema_detail.edit_group')" (click)="openMetadataGroupDialog()">
            <mat-icon>edit_note</mat-icon>
        </button>
        <button mat-fab class="add" [matTooltip]="i18next.t('mediaDetail.schema_detail.add')" (click)="openMetadataDefinitionDialog()">
            <mat-icon>add</mat-icon>
        </button>

        <button mat-fab [matTooltip]="i18next.t('mediaDetail.schema_detail.delete')" (click)="deleteMetadataGroup()">
            <mat-icon>delete_forever</mat-icon>
        </button>        
    </div>

    <span class="code">{{currentGroup.name}}</span> {{"mediaDetail.schema_detail.attached_to" | i18next}} <span class="code">{{currentGroup.attachmentType}}</span>.

    <table mat-table #table [dataSource]="datasource" class="mat-elevation-z8" 
            style="margin-bottom: 50px"
            cdkDropList
            [cdkDropListData]="datasource"
            (cdkDropListDropped)="dropTable($event)">

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

        <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon class="reorder" style="pointer-events: all;" cdkDragHandle>reorder</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.schema_detail.name" | i18next}} </th>
            <td mat-cell *matCellDef="let element"> <span class="code">{{element.name}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.schema_detail.label" | i18next}} </th>
            <td mat-cell *matCellDef="let element"> {{element.label}} </td>
        </ng-container>    


        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.schema_detail.type" | i18next}} </th>
            <td mat-cell *matCellDef="let element"> <span class="code">{{element.type}}</span> </td>
        </ng-container>        

        <ng-container matColumnDef="values">
            <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.schema_detail.values" | i18next}} </th>
            <td mat-cell *matCellDef="let element"> {{element.allowedValues}} </td>
        </ng-container>

        <ng-container matColumnDef="tools">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="openMetadataDefinitionDialog(element, false)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteMetadataDefinition(element)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" cdkDrag [cdkDragData]="row">
            <ng-template cdkDragPreview>
                <div class="drag-placeholder">
                    <span>{{row.name}}</span>
                </div>
            </ng-template>                  
        </tr>
    </table>
</div>