<h1>{{"locations.Locations" | i18next}}</h1>

<div class="buttons">
  <button mat-fab class="add" matTooltip="Add a new location" (click)="editLocation({})">
      <mat-icon>add</mat-icon>
  </button>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="result?.numberOfElements; else firstLoad">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"locations.id" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{"locations.type" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.type}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{"locations.name" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>    

    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editLocation(element)">
            <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteLocation(element)">
            <mat-icon>delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>


<ng-template #firstLoad>
    <div *ngIf="loading; else empty">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #empty>
    <p>{{"empty.locations" | i18next}}</p>
</ng-template>