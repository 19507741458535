<h1>{{"roles.title" | i18next}}</h1>

<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="!loading">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"roles.id" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{"roles.name" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.name}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="privileges">
      <th mat-header-cell *matHeaderCellDef> {{"roles.privileges" | i18next}} </th>
      <td mat-cell *matCellDef="let element">
        <ul>
            <ng-container *ngFor="let privilege of element.privileges; let i = index; let size = count">
                <li *ngIf="i <= 2">
                    {{privilege.name}}
                </li>
            </ng-container>
            <li *ngIf="element.privileges.length > 3">
                {{ 'roles.and_x_more' | i18next: { count: element.privileges.length - 3 }  }}
            </li>
        </ul>
      </td>
    </ng-container>    
    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editRole(element)">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteRole(element)">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </td>
    </ng-container>
    
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>

<button mat-fab class="add" (click)="addRole()">
    <mat-icon>add</mat-icon>
</button>

