<h1>
    <span>{{"notifications.title" | i18next}}</span>

    <button style="transform: scale(0.85) translateY(3px); margin-left: 10px" 
        aria-label="Notify" mat-icon-button
        (click)="notifyMe()">
        <mat-icon>notifications</mat-icon>
    </button>                
</h1>


<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>


<mat-card *ngIf="!loading" style="margin-bottom: 40px">
    <mat-card-content>
        <div *ngIf="!notifications || !notifications.content?.length" style="margin: 15px 15px 8px 15px;">
            {{ 'notifications.empty' | i18next }}
        </div>
            
        <ul class="noList" *ngIf="notifications">
            <li *ngFor="let entry of notifications.content; let i = index;" 
                [class.new]="entry.createdAt > lastCheck?.lastCheck!">
                <h2>
                    <markdown>
                        {{ entry.computedTitle }}
                        <span *ngIf="entry.createdAt > lastCheck?.lastCheck!" class="newHighlight code">NEW</span>
                        <a mat-icon-button class="link" [routerLink]="entry.computedLink" 
                            matTooltipPosition="after" [matTooltip]="i18next.t('notifications.link')"
                            style="transform: scale(0.7) translateY(8px);">
                            <mat-icon>link</mat-icon>
                        </a>                        
                    </markdown>
                </h2>
                <ul>
                    <li *ngFor="let operation of entry.structuredOperations">
                        <markdown>{{operation.description}}</markdown>
                    </li>
                </ul>
                <div>
                    <markdown>
                        {{entry.label}} 
                        <span class="pointer" [matTooltip]="(entry.createdAt | date: dateFormat) ?? ''">{{ entry.createdAt | formatDate }}</span>,
                        {{'activity.by' | i18next}} 
                        <span class="code">{{entry.modifiedBy}}</span>&#160;
                    </markdown>
                </div>
            </li>
        </ul>
    </mat-card-content>
</mat-card>