<h1>{{"settings.hello" | i18next}} {{user.username}} !</h1>

<div *ngIf="!loading; else firstLoad">

    <h2>{{"settings.profile_picture" | i18next}}</h2>

    <div class="profilePictureContainer">
        <div class="profilePictureUploader">
            <app-profile-picture [user]="user"></app-profile-picture>
            <input type="file" id="file" (change)="uploadProfilePicture($event)" class="inputfile">
            <label for="file">
                <mat-icon>upload</mat-icon>
            </label>
        </div>
        <button class="small-button" [ngClass]="profilePictureDeleting ? 'working' : ''" extended mat-fab (click)="deleteProfilePicture()" *ngIf="user.hasProfilePicture">
            <mat-icon>delete_forever</mat-icon>
            {{"settings.delete" | i18next}}
            <mat-spinner></mat-spinner>
        </button>        
    </div>

    <h2>{{"settings.filter" | i18next}}</h2>

    <mat-slide-toggle [(ngModel)]="defaultFilterDisabled" (change)="defaultFilterChange($event)"> &nbsp;&nbsp; {{"settings.disable" | i18next}}</mat-slide-toggle>

    <h2 style="margin-top: 30px">{{"settings.saved_search" | i18next}}</h2>

    <mat-form-field appearance="fill" style="min-width: 500px;">
        <mat-label>{{"settings.pick_up" | i18next}}</mat-label>
        <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput [formControl]="dashboardSavedSearch"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    &nbsp;
    <button class="small-button" [ngClass]="dashboardSaving ? 'working' : ''" extended mat-fab
        (click)="saveDashboardSource()">
        <mat-icon>save</mat-icon>
        {{"settings.save" | i18next}}
        <mat-spinner></mat-spinner>
    </button>

    <h2>{{"settings.display_on_media" | i18next}}</h2>

    <mat-form-field appearance="fill" style="width: 60vw">
        <mat-label>{{"settings.available" | i18next}}</mat-label>
        <mat-chip-grid #chipGrid aria-label="Enter a new value">
            <mat-chip-row *ngFor="let metadata of selectedMediaMetadatas" (removed)="removeSelectedMediaMetadatas(metadata)" [editable]="true">
                {{metadata.label}}
                <button matChipRemove [attr.aria-label]="'remove ' + metadata.label">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('settings.new_column')" [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                [matAutocomplete]="autoMetadata" />
            <mat-autocomplete #autoMetadata="matAutocomplete" (optionSelected)="addSelectedMediaMetadatas($event)">
                <mat-option *ngFor="let metadata of allMediaMetadatas" [value]="metadata">
                    {{metadata.label}}
                </mat-option>
            </mat-autocomplete>                
    
        </mat-chip-grid>
    </mat-form-field>

    &nbsp;
    <button class="small-button" [ngClass]="mediaColumnsToDisplaySaving ? 'working' : ''" extended mat-fab
        (click)="saveMediaColumnsToDisplay()">
        <mat-icon>save</mat-icon>
        {{"settings.save" | i18next}}
        <mat-spinner></mat-spinner>
    </button>

    <h2>{{"settings.display_on_expected" | i18next}}</h2>

    <mat-form-field appearance="fill" style="width: 60vw">
        <mat-label>{{"settings.available" | i18next}}</mat-label>
        <mat-chip-grid #expectedStreamsChipGrid aria-label="Enter a new value">
            <mat-chip-row *ngFor="let metadata of selectedExpectedStreamsMetadatas" (removed)="removeSelectedExpectedStreamsMetadatas(metadata)" [editable]="true">
                {{metadata.label}}
                <button matChipRemove [attr.aria-label]="'remove ' + metadata.label">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input [placeholder]="i18next.t('settings.new_column')" [matChipInputFor]="expectedStreamsChipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" matChipInputAddOnBlur="true"
                [matAutocomplete]="autoMetadataExpectedStreams" />
            <mat-autocomplete #autoMetadataExpectedStreams="matAutocomplete" (optionSelected)="addSelectedExpectedStreamsMetadatas($event)">
                <mat-option *ngFor="let metadata of allExpectedStreamsMetadatas" [value]="metadata">
                    {{metadata.label}}
                </mat-option>
            </mat-autocomplete>                
    
        </mat-chip-grid>
    </mat-form-field>

    &nbsp;
    <button class="small-button" [ngClass]="expectedStreamsColumnsToDisplaySaving ? 'working' : ''" extended mat-fab
        (click)="saveExpectedStreamsColumnsToDisplay()">
        <mat-icon>save</mat-icon>
        {{"settings.save" | i18next}}
        <mat-spinner></mat-spinner>
    </button>

    <ng-container *ngIf="savedSearches?.numberOfElements">
        <h2>{{"settings.your" | i18next}} {{savedSearches?.numberOfElements}} {{"settings.saved" | i18next}}</h2>


        <table mat-table [dataSource]="savedSearchesDataSource" class="mat-elevation-z8" style="margin-bottom: 60px;">
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="deleteSavedSearch(element)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
    </ng-container>
</div>
<ng-template #firstLoad>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>