import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { ReportingDatapoint, ReportingParameters } from '../models/reporting';
import { MetadataService } from './metadata.service';
import {ErrorService} from "./error.service";

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  persistParameters(parameters:ReportingParameters) {
    let url = `/api/reports/parameters`;
    return this.http.post<ReportingParameters>(url, parameters)
      .pipe(
        catchError(this.errorService.handleError<ReportingParameters>(`persistParameters params=${parameters}`))
      );
  }

  constructor(
    private http: HttpClient,
    private metadataService: MetadataService,
    private errorService: ErrorService
  ) {}
  
  getReport(reportId:number, startDate:string, endDate:string): Observable<ReportingDatapoint[]> {
    return this.http.get<ReportingDatapoint[]>(`/api/reports/execute?reportId=${reportId}&startDate=${startDate}&endDate=${endDate}`)
      .pipe(
        catchError(this.errorService.handleError<ReportingDatapoint[]>('Report', []))
      );
  }
  
  allReports(): Observable<ReportingParameters[]> {
    return this.http.get<ReportingParameters[]>(`/api/reports`)
      .pipe(
        tap(r => {
          for (let reporting of r) {
            // reporting.breakdownProperty?.id
            let breakdownProperty = this.metadataService.metadataDefinitions.find(md => md.id === reporting.breakdownProperty?.id)
            let intervalProperty = this.metadataService.metadataDefinitions.find(md => md.id === reporting.intervalProperty?.id)
            reporting.breakdownPropertyEntity = breakdownProperty
            reporting.intervalPropertyEntity = intervalProperty
          }
        }),
        catchError(this.errorService.handleError<ReportingParameters[]>('Report', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
