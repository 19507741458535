import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Configuration, ConfigurationKey, VersionInformation } from '../models/configuration';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  
  private configurationUrl = 'api/configuration'
  configurations:Array<Configuration> = []

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  persistConfiguration(element: Configuration) {
    let url = `${this.configurationUrl}/${element.key}`;
    return this.http.post<void>(url, element)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistConfiguration title=${element.id}`))
      );
  }

  getConfiguration(key: string, ifset:((value:string) => void)|undefined=undefined):string|undefined {
    const matching = this.configurations?.filter(c => c.key == key)
    if (matching.length > 0) {
      if (ifset !== undefined) {
        try {
          ifset(matching[0].value)
        } catch (error) {
          console.error(`Error executing ifset for ${key}: ${error}`)
        }
      }
      return matching[0].value
    }
    return undefined
  }
  
  getVersion():Observable<VersionInformation> {
    return this.http.get<VersionInformation>(`${this.configurationUrl}/version`)
      .pipe(
        catchError(this.errorService.handleError<VersionInformation>('getVersionSHA', {gitCommitId: 'ERROR'}))
      );    
  }

  getConfigurations(all:boolean=false): Observable<Page<Configuration>> {
    return this.http.get<Page<Configuration>>(`${this.configurationUrl}/${all ? 'all' : 'frontend'}`)
      .pipe(
        tap(c => this.configurations = c.content ?? []),
        catchError(this.errorService.handleError<Page<Configuration>>('Configuration', {}))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
