<h1>{{"hooks.Hooks" | i18next }}</h1>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="!loading">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"hooks.id" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{ 'hooks.type' | i18next }} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.type}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> {{ 'hooks.description' | i18next }} </th>
      <td mat-cell *matCellDef="let element"><p [innerHtml]="'hooks.descriptions.' + element.type | i18next" style="padding: 10px 5px 2px 5px"></p></td>
    </ng-container>    

    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editHook(element)">
            <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>        
    
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>


<ng-template #firstLoad>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>