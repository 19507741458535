import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { delay } from "rxjs";
import { ConfigurationService } from "src/app/services/configuration.service";
import { MediaService } from "src/app/services/media.service";
import { SimpleUploaderService } from "src/app/services/simple.uploader.service";

export interface SimpleUploadDialogData {
    title: string;
    mediaId: number;
}

@Component({
    selector: 'dialog-simple-upload',
    templateUrl: './simple.upload.dialog.html',
    styleUrls: ['./simple.upload.dialog.sass']
})
export class SimpleUploadDialog {
    
    uploading:boolean = false
    complete:boolean = false
    progress:number = 0

    constructor(
        public dialogRef: MatDialogRef<SimpleUploadDialog>,
        private simpleUploadService: SimpleUploaderService,
        private configurationService: ConfigurationService,
        private mediaService: MediaService,
        @Inject(MAT_DIALOG_DATA) public data: SimpleUploadDialogData,
    ) { }

    uploadFile(event: Event) {
        this.uploading = true
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            console.log("FileUpload -> files", fileList);
            this.simpleUploadService.uploadMediaFile(this.data.mediaId, fileList[0])
                .subscribe((event:HttpEvent<any>) => {
                    console.log(JSON.stringify(event))
                    if (event.type === HttpEventType.UploadProgress) {
                        console.log('progress', event.loaded, event.total)
                        this.progress = Math.ceil(event.loaded/event.total!*100.0)
                    } else if (event.type === HttpEventType.Response) {
                        console.log('complete')
                        this.complete = true

                        this.configurationService.getConfigurations().subscribe(c => {
                            const localisationToScan = c.content?.filter(c => c.key == 'DEFAULT_LOCALISATION_TO_SCAN')[0].value!
                            this.mediaService.triggerMediaScan(this.data.mediaId ?? -1, event.body.filename, parseInt(localisationToScan))
                            .pipe(delay(200)).subscribe((_:any) => {});                                                    
                        })

                    }                
            })
        }
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}
