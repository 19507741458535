import { Component, ElementRef, HostListener, Inject, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import i18next from "i18next";
import { Observable, debounceTime, distinctUntilChanged, startWith, switchMap } from "rxjs";

export interface SimpleListDialogData {
    loader: (val:string) => Observable<Map<String, String>>;
    title: string;
    label: string;
    text: string;
    initialValue: string;
}

@Component({
    selector: 'dialog-simple-list',
    templateUrl: './simple.list.dialog.html',
    styleUrls: ['simple.list.dialog.sass']
})
export class SimpleListDialog {
    
    i18next = i18next;
    loading: boolean = true;
    field = new FormControl('');
    filteredOptions: any = null;
    @ViewChild('input') input: ElementRef | undefined;

    constructor(
        public dialogRef: MatDialogRef<SimpleListDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleListDialogData,
    ) {}

    ngOnInit() {
        if (this.data.initialValue) {
            this.field.setValue(this.data.initialValue)
        }
        this.filteredOptions = this.field.valueChanges.pipe(
            startWith(this.data.initialValue ?? ''),
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(val => {
                console.log('val', val)
                const r = this.data.loader(val ?? '')
                console.log(r)
                return r
            })
        );
    }

    @HostListener('document:keydown.enter')
    enterShortcut() {
        this.dialogRef.close(this.field.value)
    }
    
    ngAfterViewInit() {
        setTimeout(() => this.input?.nativeElement.focus(), 200)
    }

    displayAutocomplete(value: any) {
        console.log('displayPartnerAutocomplete', value)
        return value?.value ?? ''
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
