import { MatDialog } from "@angular/material/dialog"
import { formatDistance, parseISO } from "date-fns"
import { SimpleTextDialog } from "../components/dialogs/simple.text.dialog"
import i18next from "i18next"
import { fr, de, enUS } from 'date-fns/locale'
import { throttle } from "lodash"
import { MediaInfoFields } from "../models/mediainfo.fields"

let locale = enUS

export function initUtils() {
    const localeStr = i18next.t('global.locale')    
    locale = (localeStr == 'fr') ? fr :
        (localeStr == 'de') ? de : enUS
    console.log('locale', localeStr, locale)
}
export function getLocale() {
    return locale
}

export function secondsToTimecode(secondsIn: number, separator:string=":", frameSeparator:string="-", timecodeOffset=0):string {
    secondsIn = secondsIn + timecodeOffset - (1.0/25.0)
    secondsIn = Math.max(timecodeOffset, secondsIn)
    let hour = Math.floor(secondsIn / 3600)
    let minutes = Math.floor((secondsIn - (hour * 3600)) / 60)
    let seconds = Math.floor((secondsIn - (hour * 3600) - (minutes * 60)))
    let frames = Math.floor((secondsIn - (hour * 3600) - (minutes * 60) - seconds) * 25)
    return `${pad(hour)}${separator}${pad(minutes)}${separator}${pad(seconds)}${frameSeparator}${pad(frames)}`
}

export function isRightGranted(a: string[] | undefined, b: string[] | undefined): boolean {
    if (!a || !b) return true
    var setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x)).length > 0;
}

export function confirmDialog(dialog: MatDialog, text: string, callbackIfConfirmed:Function) {

    const dialogRef = dialog.open(SimpleTextDialog, {
        data: {
            title: i18next.t('utils.question') || "",
            text,
            confirm: true
        },
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result) {
            callbackIfConfirmed()
        }
    });
}

export function messageDialog(dialog: MatDialog, title:string, text: string, 
    copiable:boolean=false,
    callback:Function|undefined=undefined) {
    const dialogRef = dialog.open(SimpleTextDialog, {
        data: {
            title,
            text,
            copiable
        },
    });
    
    if (callback) {
        dialogRef.afterClosed().subscribe(result => {
            callback()
        })
    }
}

export function timecodeToSeconds(tc:string):number {
    var hours = parseInt(tc.substring(0, 2))
    var minutes = parseInt(tc.substring(3, 5))
    var seconds = parseInt(tc.substring(6, 8))
    var frames = parseInt(tc.substring(9))
    return hours*3600 + minutes*60 + seconds + frames/25    
}

export function pad(n: number) {
    if (n < 10) return `0${n}`
    return `${n}`
}


export function intersect(a: any[] | undefined, b: any[] | undefined) {
    if (!a || !b) return []
    var setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
}

export function formatDate(date: string, end:Date=new Date(), addSuffix:boolean=true) {
    if (!date || !end) return ""
    return formatDistance(parseISO(date), end, { addSuffix, locale })
}

export function handleTableResponsivness() {
    var tables = document.querySelectorAll('.maybe-table-responsive')
    const resizeObserver = new ResizeObserver(entries => {
        throttle(() => {
            entries.forEach(entry => {
                const t = entry.target
                const cw = t.querySelector('thead')?.clientWidth ?? 0
                console.log('cw', cw, t.clientWidth)
                if (cw === 0) return
                if (t.clientWidth >= cw) {
                    t.classList.remove('table-responsive')
                } else {
                    if (!t.classList.contains('table-responsive')) {
                        t.classList.add('table-responsive')
                    }
                }
            })
        }, 200)()
    })
    for (const t of tables.values()) {
        resizeObserver.observe(t)
    }
}