<h1 mat-dialog-title>{{data.title | i18next}}</h1>
<mat-dialog-content>
  <p>{{data.text | i18next}}</p>
  
  <ul cdkDropList (cdkDropListDropped)="drop($event)">
    <li *ngFor="let item of data.list" cdkDrag>
      <div style="flex-grow: 1">{{item.representation}}</div>
      <div class="drag-handle" cdkDragHandle>
        <mat-icon>drag_handle</mat-icon>
      </div>
    </li>
  </ul>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"simple_list_dialog.cancel_list_dialog" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data.list" cdkFocusInitial>{{"simple_list_dialog.ok_list_dialog" | i18next}}</button>
  </div>  
</mat-dialog-content>