export enum FFProbeFields {
    codec_name = 'codec_name',
    nb_read_frame = 'nb_read_frame',
    codec_type = 'codec_type',
    sample_fmt = 'sample_fmt',
    codec_long_name = 'codec_long_name',
    sample_rate = 'sample_rate',
    pix_fmt = 'pix_fmt',
    profile = 'profile',
    color_space = 'color_space',
    coded_width = 'coded_width',
    coded_height = 'coded_height',
    field_order = 'field_order',
    r_frame_rate = 'r_frame_rate',
    display_aspect_ratio = 'display_aspect_ratio',
    duration = 'duration',
    bit_rate = 'bit_rate',
    start_pts = 'start_pts',
    time_base = 'time_base',
    color_range = 'color_range',
    duration_ts = 'duration_ts',
    nb_frames = 'nb_frames',
}