<h1 mat-dialog-title>
  {{ 'condition_detail_dialog.title' | i18next }}
</h1>
<mat-dialog-content>

  <div style="margin-bottom: 20px">
    <mat-checkbox class="checkbox" 
      [disabled]="availabilityForced"
      [(ngModel)]="data.condition.requiredForActionAvailability">{{"condition_detail_dialog.requiredForActionAvailability" | i18next}}</mat-checkbox>
  </div> 

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"dialog_create.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"dialog_create.ok" | i18next}}</button>
  </div>  
</mat-dialog-content>