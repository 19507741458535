<h1 mat-dialog-title>{{data.group.name}}</h1>

<mat-dialog-content class="content">
    <form [formGroup]="data.form">

        <mat-form-field appearance="fill">
            <mat-label>{{"mediaDetail.schema_detail.attachment_type" | i18next}}</mat-label>
            <mat-select formControlName="attachmentType">                              
                <mat-option *ngFor="let type of Object.keys(attachmentTypes)" [value]="type">
                    {{type}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>        
        <mat-form-field appearance="fill">
            <mat-label>{{"mediaDetail.schema_detail.name" | i18next}}</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>
        <br/>
        <mat-checkbox matInput style="margin-bottom: 20px" formControlName="externalDatasource">{{"mediaDetail.schema_detail.external" | i18next}}</mat-checkbox>
        <br/>
        <mat-form-field appearance="fill">
            <mat-label>{{"mediaDetail.schema_detail.external_url" | i18next}}</mat-label>
            <input matInput formControlName="externalDatasourceURL" />
        </mat-form-field>
        <br/>
        <div style="margin-bottom: 20px">
            <mat-checkbox matInput formControlName="displayGrouped">{{"mediaDetail.schema_detail.display" | i18next}}</mat-checkbox>
            <mat-checkbox matInput formControlName="displayCollapsed">{{"mediaDetail.schema_detail.collapsed" | i18next}}</mat-checkbox>
        </div>
    </form>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{"mediaDetail.schema_detail.cancel" | i18next}}</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"mediaDetail.schema_detail.ok" | i18next}}</button>
    </div>
</mat-dialog-content>