import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Configuration } from '../models/configuration';
import { UserActionDefinition } from '../models/user.action';
import { Media } from '../models/media';
import { User } from '../models/user';
import { MetadataAttachmentType, MetadataDefinition } from '../models/metadata';
import { QualityCheck } from '../models/qc';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class QualityCheckService {
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }
  
  deleteProfile(id: number) {
    return this.http.delete<void>(`/api/qcProfiles/${id}`, {})
      .pipe(
        tap(_ => {}),
        catchError(this.errorService.handleError<void>('deleteProfile'))
      );
  }
  
  save(profile: QualityCheck) {
    let url = `/api/qcProfiles`
    if (profile.id) url += `/${profile.id}`
    const method = profile.id ? 'PUT' : 'POST'

    return this.http.request<void>(method, url, {body: profile})
      .pipe(
        catchError(this.errorService.handleError<void>('profile'))
      );
  }
  
  getProfile(profileId: number):Observable<QualityCheck> {
    let url = `/api/qcProfiles/${profileId}`
    return this.http.get<QualityCheck>(url)
      .pipe(
        catchError(this.errorService.handleError<QualityCheck>('QualityCheck'))
      );
  }
  
  getProfiles(page:number=0): Observable<Page<QualityCheck>> {
    let url = `/api/qcProfiles?page=${page}&sort=id,desc`
    return this.http.get<Page<QualityCheck>>(url)
      .pipe(
        catchError(this.errorService.handleError<Page<QualityCheck>>('UserActionDefinition', {}))
      );
  }

  getAvailableActionsFor(type:MetadataAttachmentType, id:number): Observable<UserActionDefinition[]> {
    return this.http.get<UserActionDefinition[]>(`/api/userActions/matching/${type}/${id}`)
      .pipe(
        tap(_ => {}),
        catchError(this.errorService.handleError<UserActionDefinition[]>('UserActionDefinition', []))
      );
  }

  executeAction(action: UserActionDefinition, type:MetadataAttachmentType, id:number) {
    return this.http.post<void>(`/api/userAction/${action.id}/execute/on/${type}/${id}`, {})
      .pipe(
        tap(_ => {}),
        catchError(this.errorService.handleError<void>('UserActionDefinition'))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
