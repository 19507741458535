<h1 mat-dialog-title>{{"mediaDetail.schema_detail.metadata_references" | i18next}}</h1>

<mat-dialog-content class="content">
    
    <h2>{{references.numberOfElements}} {{"mediaDetail.schema_detail.references" | i18next}}</h2>

    <ul>
        <li *ngFor="let reference of references.content">
            {{reference.representation}} <span class="code">{{reference.id}}</span>
        </li>
    </ul>

</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"mediaDetail.schema_detail.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"mediaDetail.schema_detail.ok" | i18next}}</button>
</div>
