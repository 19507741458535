<h1 mat-dialog-title>{{data.report.id ? ("reporting.editReport" | i18next) : ("reporting.newReport" | i18next)}}</h1>

<mat-dialog-content class="content">

    <mat-form-field appearance="fill">
        <mat-label>{{"reporting.reportName" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.report.reportName">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"reporting.reportType" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.report.graphType">
            <mat-option value="line">
                {{"reporting.reportTypes.line" | i18next}}
            </mat-option>
            <mat-option value="pie">
                {{"reporting.reportTypes.pie" | i18next}}
            </mat-option>
            <mat-option value="spline">
                {{"reporting.reportTypes.spline" | i18next}}
            </mat-option>
            <mat-option value="bar">
                {{"reporting.reportTypes.bar" | i18next}}
            </mat-option>
            <mat-option value="area">
                {{"reporting.reportTypes.area" | i18next}}
            </mat-option>
            <mat-option value="area-spline">
                {{"reporting.reportTypes.areaSpline" | i18next}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="data.report.graphType != 'pie' && data.report.graphType">
        <mat-label>{{"reporting.interval" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.report.interval">
            <mat-option value="day">
                {{"reporting.intervals.day" | i18next}}
            </mat-option>
            <mat-option value="week">
                {{"reporting.intervals.week" | i18next}}
            </mat-option>
            <mat-option value="month">
                {{"reporting.intervals.month" | i18next}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>{{"reporting.intervalProperty" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.report!.intervalProperty!.id">
            <mat-option *ngFor="let definition of metadataDefinitionsInterval" [value]="definition.id">
                {{definition.label}}
            </mat-option>
        </mat-select>
    </mat-form-field>    

    <mat-form-field appearance="fill">
        <mat-label>{{"reporting.breakdownProperty" | i18next}}</mat-label>

        <mat-select [(ngModel)]="data.report!.breakdownProperty!.id">
            <mat-option [value]="undefined">
                —
            </mat-option>
            <mat-option *ngFor="let definition of metadataDefinitionsBreakdown" [value]="definition.id">
                {{definition.label}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"reporting.colorPattern" | i18next}}</mat-label>
        <textarea cdkTextareaAutosize matInput style="font-family: 'Courier New', Courier, monospace;" [(ngModel)]="data.report.colorPattern"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"reporting.customCSS" | i18next}}</mat-label>
        <textarea cdkTextareaAutosize matInput style="font-family: 'Courier New', Courier, monospace;" [(ngModel)]="data.report.customCss"></textarea>
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{"global.cancel" | i18next}}</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"global.ok" | i18next}}</button>
    </div>
</mat-dialog-content>