<mat-dialog-content class="content">
    <h1>Metadata group</h1>
    
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.group!.name" />
    </mat-form-field>

    <br/>
    <mat-form-field appearance="fill">
        <mat-label>Attachment type</mat-label>
        <mat-select [(ngModel)]="data.group!.attachmentType">
            <mat-option *ngFor="let type of Object.keys(data.metadataAttachmentTypes)" [value]="type">
                {{type}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
    </div>
</mat-dialog-content>
