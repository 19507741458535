import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs";
import { MetadataAttachmentType, MetadataDefinition, MetadataGroup, MetadataReference, MetadataType } from "src/app/models/metadata";
import { Page } from "src/app/models/page";
import { User } from "src/app/models/user";
import { MetadataService } from "src/app/services/metadata.service";
import { prepareForm } from "../metadata.display/metadata.display.component";
import { UserService } from "src/app/services/user.service";
import { isRightGranted } from "src/app/utils/utils";
import i18next from 'i18next';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

export interface UserMetadataDialogData {
    creation: boolean;
    showVersionRelatedForm: boolean;
    metadataGroups: MetadataGroup[];
    metadataForm: any;
    deleteable: boolean;
    metadataTypes: typeof MetadataType;
    currentUser: User;
    metadataAttachmentTypes: typeof MetadataAttachmentType;
    filteredOptions: Map<string, Observable<Array<any>>>;
}

@Component({
    styleUrls: ['./user.metadatas.dialog.sass'],
    selector: 'dialog-user-metadatas',
    templateUrl: './user.metadatas.dialog.html',
})

export class UserMetadataDialog {
    i18next = i18next
    isHandset: boolean = false;  

    constructor(
        public dialogRef: MatDialogRef<UserMetadataDialog>,
        private metadataService: MetadataService,
        public userService: UserService,
        private fb: FormBuilder,
        private dialog: MatDialog, 
        private responsive: BreakpointObserver,
        @Inject(MAT_DIALOG_DATA) public data: UserMetadataDialogData,
    ) { 
    }

    log = console.log
    isRightGranted = isRightGranted
    metadataForm:any
    metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
    metadataTypes: typeof MetadataType = MetadataType;
    canDisplayMetadata:Map<number, boolean> = new Map<number, boolean>()
    shownFields:number = 0

    ngOnInit() {

        this.responsive.observe(Breakpoints.Handset)
            .subscribe(result => {
                this.isHandset = false;
                if (result.matches) {
                    this.isHandset = true;
                }
            });    
        for (let group of this.data.metadataGroups) {
            for (let metadataDefinition of group.metadatas ?? []) {
                this.canDisplayMetadata.set(metadataDefinition.id!, this.canDisplay(metadataDefinition))
                if (this.data.metadataForm.controls.hasOwnProperty(group.id) !== undefined && 
                    this.data.metadataForm.controls[group.id].controls.hasOwnProperty(metadataDefinition.name) &&
                    metadataDefinition.type !== MetadataType.MULTI_ENTITIES && 
                    metadataDefinition.type !== MetadataType.EXTRACTED_FROM_STREAM && 
                    metadataDefinition.type !== MetadataType.COMPUTED) {
                    this.shownFields++
                }
            }
        }
    }

    displayAutocomplete(value: MetadataReference) {
        console.log('displayAutocomplete', value)
        return value?.representation ?? ''
    }

    addReference(definition:MetadataDefinition, sourceForm:any, parentGroup:MetadataGroup, event:Event) {
        event.preventDefault()
        event.stopPropagation()
        console.log('add', definition.referencedMetadataGroup, event)

        // populate form
        const filteredOptions = new Map<string, any>()

        this.metadataForm = prepareForm(this.metadataService, 
            this.userService,
            this.fb, [definition.referencedMetadataGroupEntity!],
            this.userService.currentUser!,
            filteredOptions,
            {},
            definition.referencedMetadataGroupEntity!.id);

        const dialogRef = this.dialog.open(UserMetadataDialog, {
            data: {
                creation: true,
                filteredOptions,
                metadataGroups: [definition.referencedMetadataGroupEntity],
                metadataForm: this.metadataForm,
                metadataTypes: this.metadataTypes,
                metadataAttachmentTypes: this.metadataAttachmentTypes
            },
        });

        dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result) {
            console.log(result.value);
            this.metadataService.persistMetadataReference(definition.referencedMetadataGroupEntity!.id, {
                dynamicMetadatas: result.value[definition.referencedMetadataGroupEntity!.id]
            }).subscribe((r:any) => {
                console.log('created', sourceForm, parentGroup, r, definition)
                sourceForm.controls[parentGroup.id].controls[definition.name].setValue(r)
            })
        }
        });        
    }

    canDisplay(metadataDefinition:MetadataDefinition) {
        if (!metadataDefinition.displayRestrictedToRoles || metadataDefinition.displayRestrictedToRoles.length === 0) {
            return true
        }
        for (let group of metadataDefinition.displayRestrictedToRoles) {
            if (this.userService.currentUser?.group?.id?.toString() === group) return true
        }
        return false
    }

    displayUserAutocomplete(value: any) {
        console.log('displayUserAutocomplete', value)
        return value?.email
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

