import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { MetadataAttachmentType, MetadataDefinition, MetadataGroup, MetadataReference } from '../models/metadata';
import { Page } from '../models/page';
import { ErrorService } from './error.service';

@Injectable({ providedIn: 'root' })
export class MetadataService {
  
  private metadataGroupsUrl = 'api/metadataGroups';
  private metadataGroupUrl = 'api/metadataGroup';
  private metadataReferenceUrl = 'api/metadataReference';
  private metadataUrl = 'api/metadata';
  public metadataDefinitions:MetadataDefinition[] = [];
  public metadataGroups:MetadataGroup[] = [];
  private groupCached:boolean = false

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  createMetadataGroup(group: MetadataGroup) {
    const url = `/api/metadataGroup`;
    return this.http.post<void>(url, group)
      .pipe(
        catchError(this.errorService.handleError<void>(`createMetadataDefinition search=${group}`))
      );
  }

  deleteMetadataGroup(id: number) {
    const url = `/api/metadataGroup/${id}`;
    return this.http.delete<void>(url)
      .pipe(
        catchError(this.errorService.handleError<void>(`deleteMetadataGroup search=${id}`))
      );
  }
  
  deleteMetadataDefinition(id: number) {
    const url = `/api/metadataDefinition/${id}`;
    return this.http.delete<void>(url)
      .pipe(
        catchError(this.errorService.handleError<void>(`deleteMetadataDefinition search=${id}`))
      );
  }

  persistMetadataDefinitionOrders(metadataGroupId:number, ids: number[]) {
    const url = `/api/metadataGroup/${metadataGroupId}/order`;
    return this.http.post<void>(url, ids)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistOrders ids=${ids}`))
      );
  }
  
  persistMetadataGroupOrders(ids: number[]) {
    const url = `/api/metadataGroups/order`;
    return this.http.post<void>(url, ids)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistOrders ids=${ids}`))
      );
  }

  refreshExternalData(targetType:string, targetId:number) {
    const url = `/api/${targetType === 'MEDIA' ? 'media' : 'mediaVersion'}/${targetId}/refreshRemoteMetadata`;
    return this.http.post<void>(url, null)
      .pipe(
        catchError(this.errorService.handleError<void>(`refreshExternalData id=${targetId}`))
      );    
  }

  loadExternalData(externalDatasourcedGroups: MetadataGroup[], externalId:number) {
    console.log('loadExternalData', externalDatasourcedGroups)
    if (externalId == -1) return of()
    const allLoaders = externalDatasourcedGroups
      .map(ds => this.http.get<any>(ds.externalDatasourceURL?.replace('{externalId}', `${externalId}`)!)
      .pipe(
        map(data => {
          return {
            data, 
            datasource: ds
          }
        }),
        catchError(err => { throw err })
      ))
      return allLoaders
  }  

  createMetadataDefinition(metadataGroupId:number, definition:MetadataDefinition, 
                           referencedMetadataGroup:MetadataGroup|undefined=undefined) {
    let url = `/api/metadataGroup/${metadataGroupId}/metadataDefinition`;
    if (referencedMetadataGroup)
      url += `?referencedMetadataGroupId=${referencedMetadataGroup.id}`
    return this.http.post<void>(url, definition)
      .pipe(
        catchError(this.errorService.handleError<void>(`createMetadataDefinition search=${metadataGroupId}`))
      );
  }  

  updateMetadataDefinition(definition:MetadataDefinition, referencedMetadataGroup:MetadataGroup|undefined=undefined) {
    definition = {...definition}
    definition.referencedMetadataGroupEntity = undefined
    definition.referencedMetadataGroup = undefined
    let url = `/api/metadataDefinition/${definition.id}`;
    if (referencedMetadataGroup)
      url += `?referencedMetadataGroupId=${referencedMetadataGroup.id}`    
    return this.http.put<void>(url, definition)
      .pipe(
        catchError(this.errorService.handleError<void>(`updateMetadataDefinition search=${definition.id}`))
      );
  }  
      
  getMetadataGroups(forceReload:boolean=false): Observable<Page<MetadataGroup>> {
    if (this.groupCached && !forceReload) return of({content: this.metadataGroups, numberOfElements: this.metadataGroups.length})
    this.metadataGroups = []
    return this.http.get<Page<MetadataGroup>>(`${this.metadataGroupsUrl}?sort=id,ASC&pageSize=50`)
      .pipe(
        tap(m => {
          this.metadataDefinitions = []
          m.content?.forEach(g => {
            this.metadataGroups.push(g)
            const metadataBackup = g.metadatas
            g.metadatas = []
            const parentGroupReference:MetadataGroup = JSON.parse(JSON.stringify(g))
            g.metadatas = metadataBackup
            g.metadatas?.forEach(m => {
                m.parentGroup = parentGroupReference
                this.metadataDefinitions.push(m)
            })
          })
          this.metadataDefinitions.forEach(d => {
            d.referencedMetadataGroupEntity = this.metadataGroups.find(g => g.id === d.referencedMetadataGroup?.id)
            //console.log('aaaa', 'definition', d)
          })
          this.groupCached = true
        }),
        catchError(this.errorService.handleError<Page<MetadataGroup>>('getMetadataGroups', {}))
      );
  }

  getMetadataDefinitionFromCache(name:string):MetadataDefinition {
    const result = this.metadataDefinitions.filter(m => m.name === name)
    // console.log(this.metadataDefinitions, name, result)
    return result[0]
  }

  getMetadataGroup<Data>(id: number): Observable<MetadataGroup> {
    const url = `${this.metadataGroupUrl}/${id}`;
    return this.http.get<MetadataGroup>(url)
      .pipe(
        tap(h => {
          
        }),
        catchError(this.errorService.handleError<MetadataGroup>(`getMetadataGroup id=${id}`))
      );
  }

  getMetadataReference<Data>(id: number): Observable<MetadataReference> {
    const url = `${this.metadataReferenceUrl}/${id}`;
    return this.http.get<MetadataGroup>(url)
      .pipe(
        tap(h => {
          const outcome = h ? 'fetched' : 'did not find';
          console.log(`${outcome} media id=${id}`);
        }),
        catchError(this.errorService.handleError<MetadataGroup>(`getMetadataReference id=${id}`))
      );
  }  

  listMetadataReferences<Data>(id: number): Observable<Page<MetadataReference>> {
    const url = `${this.metadataGroupUrl}/${id}/references`;
    return this.http.get<Page<MetadataReference>>(url)
      .pipe(
        tap(h => {
          const outcome = h ? 'fetched' : 'did not find';
          console.log(`${outcome} media id=${id}`);
        }),
        catchError(this.errorService.handleError<Page<MetadataReference>>(`listMetadataReferences id=${id}`))
      );
  }  

  getAutocompleteData<Data>(id:number): Observable<MetadataReference[]> {
    const url = `${this.metadataGroupUrl}/${id}/autocomplete`;
    return this.http.get<MetadataReference[]>(url)
      .pipe(
        tap(h => {
          const outcome = h ? 'fetched' : 'did not find';
          console.log(`${outcome} media id=${id}`);
        }),
        catchError(this.errorService.handleError<MetadataReference[]>(`getAutocompleteData id=${id}`))
      );
  }

  getExternalAutocompleteData<Data>(endpoint:string, externalId:string, val:string): Observable<any[]> {
    const url = endpoint.replace('{q}', val).replace('{externalId}', externalId);
    return this.http.get<any[]>(url)
      .pipe(
        catchError(this.errorService.handleError<any[]>(`getExternalAutocompleteData`))
      );
  }

  persistMetadataGroup(group: MetadataGroup) {
    const url = `/api/metadataGroup/${group.id}`;
    return this.http.put<MetadataGroup>(url, group)
      .pipe(
        catchError(this.errorService.handleError<MetadataGroup>(`persistMetadataGroup title=${name}`))
      );
  }

  persistMetadataReference<Data>(metadataGroupId:number, datas:any): Observable<Data> {
    const url = `/api/metadataReference/${metadataGroupId}`;
    return this.http.post<Data>(url, datas)
      .pipe(
        catchError(this.errorService.handleError<Data>(`persistMetadataReference title=${name}`))
      );
  }

  persistMetadatasForEntity<Data>(datas:any): Observable<Data> {
    // datas
    let entriesToDelete = []
    for (let key of Object.keys(datas)) {
      let subdatas = datas[key]
      for (let subkey of Object.keys(subdatas)) {
        if (subdatas[subkey] == '[deleted]') {
          delete subdatas[subkey]
          entriesToDelete.push(subkey)
        }
      }
    }
    const url = `${this.metadataUrl}`;
    return this.http.post<Data>(url, {
      datas,
      entriesToDelete
    })
      .pipe(
        catchError(this.errorService.handleError<Data>(`persistMetadatasForEntity title=${name}`))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
