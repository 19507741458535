<h1 mat-dialog-title>{{data.title | i18next}}</h1>
<mat-dialog-content>
  <p>{{data.text | i18next}}</p>
  
  <div style="background: white">
    <img [src]="'data:image/svg+xml;base64,' + data.imageBase64" />
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"simple_image_dialog.ok_image_dialog" | i18next}}</button>
  </div>  
</mat-dialog-content>