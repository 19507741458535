<div style="display: flex; gap: 15px;">
    <mat-form-field appearance="fill">
        <mat-label>{{ ("actionDetail.metadata" | i18next)}}
            <ng-container *ngIf="selection?.parentGroup">
                > {{ selection?.parentGroup?.attachmentType }}
            </ng-container>
        </mat-label>
        <mat-select [(ngModel)]="selection" [compareWith]="compareObjects" (selectionChange)="metadataSelectionChanged($event.value)">
            <mat-option value="" *ngIf="allowEmpty">
                —
            </mat-option>
            <mat-option *ngFor="let definition of additionalMetadataDefinitions" [value]="definition">
                {{definition.label}}
            </mat-option>
            <mat-optgroup label="Media" *ngIf="displayMediaMetadata">
                <mat-option *ngFor="let definition of metadataDefinitionsForMedia" [value]="definition">
                    {{definition.label}}
                </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Version" *ngIf="displayMediaVersionMetadata">
                <mat-option *ngFor="let definition of metadataDefinitionsForVersion" [value]="definition">
                    {{definition.label}}
                </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Stream" *ngIf="displayMediaStreamMetadata">
                <mat-option *ngFor="let definition of metadataDefinitionsForStream" [value]="definition">
                    {{definition.label}}
                </mat-option>
            </mat-optgroup>
            <mat-optgroup label="User" *ngIf="displayUserMetadata">
                <mat-option *ngFor="let definition of metadataDefinitionsForUser" [value]="definition">
                    {{definition.label}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="allowOperatorSelection">
        
        <button mat-raised-button
            style="position: relative; top: 10px;"
            color="secondary" 
            [matMenuTriggerFor]="menu"
            *ngIf="selection">
            {{operator ?? '=' | i18next}}
        </button>    
        <mat-menu #menu="matMenu" style="margin-top: 5px">
            <button *ngFor="let operand of operands" 
                    mat-menu-item
                    (click)="selectOperand(operand)">{{operand | i18next}}</button>
        </mat-menu>
    </ng-container>

    <ng-container *ngIf="selection">
        <ng-container [ngSwitch]="selection.type">
            <mat-form-field appearance="fill" *ngSwitchCase="[metadataTypes.TEXT, metadataTypes.LONG_TEXT, metadataTypes.EXTRACTED_FROM_STREAM].includes(selection.type!) ? selection.type : !selection.type">
                <mat-label>{{selection.label}}</mat-label>
                <input matInput [(ngModel)]="value" (change)="valueChange.emit(value);">
            </mat-form-field>
            <ng-container *ngSwitchCase="metadataTypes.DATE">
                
                <div style="display: flex; gap: 15px;">


                    <mat-form-field appearance="fill" *ngIf="!forceCurrentDate && !formula">
                        <mat-label>{{selection.label}}</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="value" (change)="valueChange.emit(value);">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="!forceCurrentDate && formula">
                        <mat-label>{{selection.label}}</mat-label>
                        <span matTextSuffix>&nbsp;{{'metadataSelect.days' | i18next}}</span>
                        <input matInput type="number" [(ngModel)]="value" (change)="valueChange.emit(value);">
                        <mat-hint>{{ 'metadataSelect.dateFormulaHint' | i18next }}</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="forceCurrentDate">
                        <mat-label>{{selection.label}}</mat-label>
                        <input matInput [value]="i18next.t('currentDate')" disabled>
                    </mat-form-field>

                    <mat-checkbox *ngIf="!forceCurrentDate" 
                        style="position: relative; top: 10px;"
                        (ngModelChange)="formulaChange.emit($event);"
                        class="checkbox" [(ngModel)]="formula">{{ 'metadataSelect.INTERVAL' | i18next }}</mat-checkbox>
                </div>
            </ng-container>
            
            <mat-form-field appearance="fill" *ngSwitchCase="[metadataTypes.MULTI_VALUED, metadataTypes.COMPUTED].includes(selection.type!) ? selection.type : !selection.type">
                <mat-label>{{selection.label}}</mat-label>
                <mat-select [(ngModel)]="value" (selectionChange)="valueChange.emit($event.value)"
                    [multiple]="allowMultipleOnMultiValued">
                    <mat-option value="">
                        —
                    </mat-option> 
                    <ng-container *ngIf="selection.translated">
                        <mat-option *ngFor="let value of selection.allowedValues" [value]="value">
                            {{'multi_valued.' + value | i18next}}
                        </mat-option>                     
                    </ng-container>                    
                    <ng-container *ngIf="!selection.translated">
                        <mat-option *ngFor="let value of selection.allowedValues" [value]="value">
                            {{value}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="fill" *ngSwitchCase="metadataTypes.REFERENCE" [formGroup]="group">
                <mat-label>{{selection.label}}</mat-label>
                <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput formControlName="autocompleteControl" [(ngModel)]="value" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAutocomplete" (optionSelected)="valueChange.emit($event.option.value)">
                    <mat-option *ngFor="let option of autocomplete | async" [value]="option">
                        <mat-icon *ngIf="option.meta">star_rate</mat-icon>
                        {{option.representation}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngSwitchCase="metadataTypes.USER" [formGroup]="group">
                <mat-label>{{selection.label}}</mat-label>
                <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput formControlName="autocompleteControl" [(ngModel)]="value" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUserAutocomplete" (optionSelected)="valueChange.emit($event.option.value)">
                    <mat-option *ngFor="let option of autocomplete | async" [value]="option">
                        <mat-icon *ngIf="option.meta">star_rate</mat-icon>
                        {{option.email}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            
            <mat-checkbox 
                style="position: relative; top: 9px; margin-right: 10px;"
                *ngSwitchCase="metadataTypes.BOOLEAN" 
                class="checkbox" [(ngModel)]="value"
                (ngModelChange)="valueChange.emit($event);">{{selection.label}}</mat-checkbox>
            
            <div *ngSwitchDefault>
                {{"filter_component.unknown_type" | i18next}} {{ selection.type }}
            </div>
        </ng-container>
    </ng-container>
</div>