<h1 mat-dialog-title>
  Create version from definition
</h1>
<mat-dialog-content>
   
  <p>Are you sure you want to create a {{data.versionDefinition.name}} version containing {{data.versionDefinition.expectedStreamDefinitions?.length}} streams?</p>
  <div *ngIf="containsAlternatives">
    <p>This version contains alternative streams, please confirm the streams you expect.</p>

    <mat-form-field appearance="fill" *ngFor="let stream of streamsContainingAlternatives">
      <mat-label>Select alternative</mat-label>
      <mat-select [ngModel]="alternativesSelections.get(stream.id!)" 
                  (ngModelChange)="alternativesSelections.set(stream.id!, $event)">
        <mat-option [value]="stream">
          <span>{{stream.representation}}</span>
        </mat-option>                  
        <mat-option *ngFor="let alternative of stream.alternatives" [value]="alternative">
          <span>{{alternative.representation}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="alternativesSelections" cdkFocusInitial>Ok</button>
  </div>  
</mat-dialog-content>