import { Component, ElementRef } from '@angular/core';
import { MediaDetailContainerComponent } from '../media-detail-container/media-detail-container.component';
import { Media, MediaElement, MediaElementCopiedToLocation } from 'src/app/models/media';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { MediaService } from 'src/app/services/media.service';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { MetadataAttachmentType } from 'src/app/models/metadata';
import { confirmDialog, formatDate } from 'src/app/utils/utils';
import i18next from 'i18next';

declare var window:any;

@Component({
  selector: 'app-media-streams',
  templateUrl: './media-streams.component.html',
  styleUrls: ['./media-streams.component.sass']
})
export class MediaStreamsComponent {

  loading:boolean = true
  formatDate = formatDate
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
  copiedLocations: MediaElementCopiedToLocation[] = []

  constructor(public parentContainer: MediaDetailContainerComponent,
    private mediaService: MediaService,
    private elementRef:ElementRef,
    public dialog: MatDialog) { }
    
  reload() {
    this.getMedia(this.parentContainer?.media?.id!, true)
  }

  ngOnInit() {
    this.refreshCopiedMedia()
  }

  refreshCopiedMedia() {
    const allCopies = this.parentContainer?.media?.elements?.flatMap(e => e.copiedToLocations!) ?? []
    const copiesPerLocation = new Map<number, MediaElementCopiedToLocation>()
    allCopies.forEach(c => {
      if (copiesPerLocation.has(c.location?.id!)) {
        // keep the oldest one
        if (copiesPerLocation.get(c.location?.id!)?.createdAt! < c.createdAt!) {
          return
        }
      }
      copiesPerLocation.set(c.location?.id!, c)
    })
    this.copiedLocations = [...copiesPerLocation.values()]
    console.log('this.copiedLocations', this.copiedLocations)        
  }

  deleteCopiedMedia(media:Media, copiedMediaElement:MediaElementCopiedToLocation, event:Event) {
    event.preventDefault()
    event.stopPropagation()
    confirmDialog(this.dialog, 
      i18next.t('mediaDetail.media_streams.delete_copy')!, () => {

        for (let mediaElement of media.elements ?? []) {
          mediaElement.copiedToLocations = mediaElement?.copiedToLocations
            ?.filter(e => e.location?.id !== copiedMediaElement?.location?.id!)
        }
        this.refreshCopiedMedia()
        
        this.mediaService.deleteMediaCopyFromLocation(media.id!, copiedMediaElement?.location?.id!)
          .subscribe(_ => {
            this.parentContainer.getPendingTaskForMedia(this.parentContainer?.media?.id ?? -1)
          })
      })    
  }

  getMedia(id:number, forceReload:boolean): void {
    this.loading = true
    this.parentContainer.getMedia(id, forceReload, result => {
      this.loading = false;
    })
  }  

  openMetadata(element:MediaElement, mediaInfo:boolean=false): void {
    console.log('openMetadata');

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: { 
        title: 'Metadata',
        type: SimpleTextInputType.MONOSPACED,        
        text: JSON.stringify(mediaInfo ? element.mediaInfoMetadatas : element.fullMetadatas, null, "  "),
        monospaced: true        
      },
    });

    dialogRef.afterOpened().subscribe(() => {

    })

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }  

  changeMediaElementAccessibility(element:MediaElement): void {
    element.spareFileAccessibleToPartners = !element.spareFileAccessibleToPartners
    this.mediaService.changeMediaElementAccessibility(element.id!, element.spareFileAccessibleToPartners!)
      .subscribe(s => {})
  }

  editMetadata(element:MediaElement): void {
    const metadataDisplay = this.elementRef!.nativeElement
      .querySelector(`[data-element-id='${element!.id!}']`)
    console.log(metadataDisplay);
    const metadataDisplayComponent = window.ng.getComponent(metadataDisplay)
    console.log(metadataDisplayComponent)
    metadataDisplayComponent.openUserMetadataDialog()
  }

  deleteMediaElement(element:MediaElement) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.media_streams.confirm',
        content: `mediaDetail.media_streams.sure_to_delete`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        element.deleted = true
        this.mediaService.deleteMediaElement(element.id!).subscribe(s => {
          this.getMedia(this.parentContainer?.media?.id!, true)
        })
      }
    });

  }

}
