<h1>Reporting</h1>

<mat-spinner *ngIf="loading"></mat-spinner>

<div *ngIf="!loading">
    <mat-card style="margin-top: 10px; margin-bottom: 20px">
        <mat-card-content>

            <mat-form-field appearance="fill">
                <mat-label>{{"reporting.selectReport" | i18next}}</mat-label>
        
                <mat-select [(ngModel)]="currentReport">
                    <mat-option *ngFor="let report of allReports" [value]="report">
                        {{report.reportName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="fill">
                <mat-label>{{"reporting.startDate" | i18next}}</mat-label>
                <input matInput [matDatepicker]="startPicker" [(ngModel)]="startDate">
                <mat-datepicker-toggle matIconSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="fill">
                <mat-label>{{"reporting.endDate" | i18next}}</mat-label>
                <input matInput [matDatepicker]="endPicker" [(ngModel)]="endDate">
                <mat-datepicker-toggle matIconSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>

            &nbsp;
            <button mat-fab extended color="primary" (click)="execute()" [disabled]="!startDate || !endDate || !currentReport">
                <mat-icon>save</mat-icon>
                {{"reporting.run" | i18next}}
            </button>
        
        </mat-card-content>
    </mat-card>

    <mat-card style="margin-top: 10px; margin-bottom: 60px" *ngIf="reportLoading || reportLoaded">
        <mat-card-content style="position: relative">
            <div class="buttons">
                <button mat-icon-button (click)="openReportDetail()" *ngIf="currentReport">
                    <mat-icon>settings</mat-icon>
                </button>            
            </div>
            <mat-spinner *ngIf="reportLoading"></mat-spinner>
            <div *ngIf="reportLoaded" style="min-height: calc( 100vh - 310px );" id="chart"></div>
        </mat-card-content>
    </mat-card>


    <div class="bottom-buttons">
        <button mat-fab class="add" [matTooltip]="i18next.t('reporting.newReport')" (click)="openReportDetail({})">
            <mat-icon>add</mat-icon>
        </button>
    </div>    
</div>
