import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default.renderer',
  templateUrl: './default.renderer.component.html',
  styleUrls: ['./default.renderer.component.sass']
})
export class DefaultRendererComponent {

  @Input() data:any;
  
}
