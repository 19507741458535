
export interface OutgestProfile {
    id?: number;
    hasPreroll: boolean;
    numberOfFiles: number;
    numberOfAudioStreams: number;
    numberOfVideoStreams: number;
    numberOfSubtitlesStreams: number;
    name: string;
    extension?: string;
    hasMarkInAndMarkOut: boolean;
    type?: OutgestProfileType
}

export enum OutgestProfileType {
    FFMPEG = 'FFMPEG'
}

