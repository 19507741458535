import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Page } from 'src/app/models/page';
import { Search } from 'src/app/models/search';
import { User } from 'src/app/models/user';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationDialog } from '../../components/dialogs/confirm.dialog';
import { FormControl } from '@angular/forms';
import { debounceTime, delay, distinctUntilChanged, forkJoin, map, startWith, switchMap, tap } from 'rxjs';
import { MetadataService } from 'src/app/services/metadata.service';
import { MetadataAttachmentType, MetadataDefinition } from 'src/app/models/metadata';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import i18next from 'i18next';
import { SimpleUploaderService } from 'src/app/services/simple.uploader.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-my.settings',
  templateUrl: './my.settings.component.html',
  styleUrls: ['./my.settings.component.sass']
})
export class MySettingsComponent {

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  user:User
  profilePictureUploadProgress:number = 0;
  loading:boolean = true;
  selectedMediaMetadatas:Array<MetadataDefinition> = [];
  selectedExpectedStreamsMetadatas:Array<MetadataDefinition> = [];
  allMediaMetadatas:Array<MetadataDefinition> = [];
  allExpectedStreamsMetadatas:Array<MetadataDefinition> = [];
  mediaColumnsToDisplaySaving:boolean = false;
  expectedStreamsColumnsToDisplaySaving:boolean = false;
  dashboardSaving:boolean = false;
  savedSearches: Page<Search>|undefined;
  savedSearchesDataSource: Search[] = []
  columnsToDisplay: string[] = ['name', 'tools']
  dashboardSavedSearch:FormControl
  filteredOptions: any;
  defaultFilterDisabled: boolean = false;
  i18next = i18next
  profilePictureDeleting: boolean = false;

  constructor(private userService:UserService,
    private searchService:SearchService,
    private metadataService:MetadataService,
    private simpleUploadService:SimpleUploaderService,
    public dialog: MatDialog) {
    
    this.user = userService.currentUser!
    this.dashboardSavedSearch = new FormControl('')
    
    forkJoin([this.loadSavedSearch(),
      this.loadMetadatasGroups()])
        .subscribe(s => {
          this.allMediaMetadatas.push({
            name: 'name',
            label: i18next.t('settings.Name') || '',
          }, {
            name: 'elementCount',
            label: i18next.t('settings.element_count') || '',
          }, {
            name: 'streamCount',
            label: i18next.t('settings.stream_count') || '',
          }, {
            name: 'createdBy',
            label: i18next.t('settings.created_by') || '',
          })
          this.allExpectedStreamsMetadatas.push({
            name: 'creationDate',
            label: i18next.t('settings.creation_date') || '',
          }, {
            name: 'createdBy',
            label: i18next.t('settings.created_by') || '',
          })
          metadataService.metadataDefinitions.forEach(md => {
            if (md.parentGroup?.attachmentType === MetadataAttachmentType.MEDIA)
              this.allMediaMetadatas.push(md)
            else if (md.parentGroup?.attachmentType === MetadataAttachmentType.MEDIA_STREAM)
              this.allExpectedStreamsMetadatas.push(md)
          })
          this.loadCurrentPreferences().subscribe(s => {
            this.loading = false
          })
        });

    this.filteredOptions = this.dashboardSavedSearch.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
          console.log('val', val)
          const r = searchService.getSavedSearch().pipe(map(rr => rr.content))
          console.log('r', r)
          return r
      })
    );    
  }

  defaultFilterChange(event:MatSlideToggleChange) {
    this.userService.persistDefaultFilterDisabled(event.checked)
      .subscribe( s => {})
  }

  addSelectedMediaMetadatas(event:MatAutocompleteSelectedEvent) {
    this.selectedMediaMetadatas.push(event.option.value)
  }

  addSelectedExpectedStreamsMetadatas(event:MatAutocompleteSelectedEvent) {
    this.selectedExpectedStreamsMetadatas.push(event.option.value)
  }
  
  removeSelectedMediaMetadatas(metadata:MetadataDefinition) {
    console.log('removeSelectedMediaMetadatas', metadata)
    this.selectedMediaMetadatas.splice(this.selectedMediaMetadatas.indexOf(metadata), 1)
  }

  removeSelectedExpectedStreamsMetadatas(metadata:MetadataDefinition) {
    console.log('removeSelectedMediaMetadatas', metadata)
    this.selectedExpectedStreamsMetadatas.splice(this.selectedExpectedStreamsMetadatas.indexOf(metadata), 1)
  }

  saveDashboardSource() {
    console.log('saveDashboardSource', this.dashboardSavedSearch.value.id)
    this.dashboardSaving = true
    this.userService.persistUserDashboard(this.dashboardSavedSearch.value.id)
      .pipe(delay(1000))
      .subscribe( s => {
        this.dashboardSaving = false
      })
  }

  saveMediaColumnsToDisplay() {
    this.mediaColumnsToDisplaySaving = true
    this.userService.persistUserMediaColumnsToDisplay(this.selectedMediaMetadatas.map(m => m.name))
      .pipe(delay(1000))
      .subscribe( s => {
        this.mediaColumnsToDisplaySaving = false
      })
  }

  saveExpectedStreamsColumnsToDisplay() {
    this.expectedStreamsColumnsToDisplaySaving = true
    this.userService.persistUserExpectedStreamsColumnsToDisplay(this.selectedExpectedStreamsMetadatas.map(m => m.name))
      .pipe(delay(1000))
      .subscribe( s => {
        this.expectedStreamsColumnsToDisplaySaving = false
      })
  }

  displayAutocomplete(value: any) {
    console.log('displayAutocomplete', value)
    return value?.name ?? ''
  }

  deleteSavedSearch(element:Search) {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'settings.confirm',
        content: `settings.sure_to_delete`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.searchService.deleteSavedSearch(element.id!).subscribe(s => {
          this.loadSavedSearch().subscribe(s => {})
        })
      }
    });
  }

  loadCurrentPreferences() {
    return this.userService.getUserPreferences(true).pipe(tap(s => {
      if (!s) return
      this.dashboardSavedSearch.setValue(s.savedSearch)
      this.defaultFilterDisabled = s.defaultFilterDisabled
      if (s.columnsDisplayedOnMedias) {
        this.selectedMediaMetadatas = s.columnsDisplayedOnMedias
          .map(m => this.allMediaMetadatas.filter(mm => mm.name === m)[0])
      }
      if (s.columnsDisplayedOnExpectedStreams) {
        this.selectedExpectedStreamsMetadatas = s.columnsDisplayedOnExpectedStreams
          .map(m => this.allExpectedStreamsMetadatas.filter(mm => mm.name === m)[0])
          .filter(es => es !== null && es != undefined)
        console.log('this.selectedExpectedStreamsMetadatas', this.selectedExpectedStreamsMetadatas)
      }
    }))
  }

  loadMetadatasGroups() {
    return this.metadataService.getMetadataGroups()
  }

  loadSavedSearch() {
    return this.searchService.getSavedSearch().pipe(tap(s => {
      this.savedSearches = s
      this.savedSearchesDataSource = s.content!
    }))
  }

  deleteProfilePicture() {
    this.profilePictureDeleting = true
    this.userService.deleteProfilePicture().pipe(delay(1000)).subscribe(_ => {
      this.profilePictureDeleting = false
      this.user.hasProfilePicture = false
      this.userService.userChanged(this.user)
    })
  }

  uploadProfilePicture(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
        console.log("FileUpload -> files", fileList);
        this.simpleUploadService.uploadProfilePicture(fileList[0])
            .subscribe((event:HttpEvent<any>) => {
                console.log(JSON.stringify(event))
                if (event.type === HttpEventType.UploadProgress) {
                    console.log('progress', event.loaded, event.total)
                    this.profilePictureUploadProgress = Math.ceil(event.loaded/event.total!*100.0)
                } else if (event.type === HttpEventType.Response) {
                    console.log('complete')
                    this.profilePictureUploadProgress = 100
                    this.user.hasProfilePicture = true
                    this.userService.userChanged(this.user)
                }                
        })
    }
  }  
}

