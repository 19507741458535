import { Component, Inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { addMonths, format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { MetadataAttachmentType, MetadataDefinition, MetadataType } from 'src/app/models/metadata';
import { ReportingParameters } from 'src/app/models/reporting';
import { MetadataService } from 'src/app/services/metadata.service';
import { ReportingService } from 'src/app/services/reporting.service';
import i18next from 'i18next';

declare var c3:any;

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.sass']
})
export class ReportingComponent {

  loading:boolean = false;
  reportLoading:boolean = false;
  reportLoaded:boolean = false;
  currentReport:ReportingParameters|undefined;
  allReports:ReportingParameters[] = [];
  startDate:Date = addMonths(new Date(), -1);
  endDate:Date = addMonths(new Date(), 1);
  i18next = i18next;

  constructor(private reportingService:ReportingService,
    public dialog: MatDialog) {}

  ngOnInit() {
    this.loadReports()
  }

  loadReports() {
    this.reportingService.allReports().subscribe(r => {
      this.allReports = r;
      this.currentReport = this.allReports[0]
      this.loading = false;
    })
  }

  openReportDetail(params:ReportingParameters=this.currentReport!) {
    console.log('openReportDetail()')
    const report = cloneDeep(params)
    if (!report.breakdownProperty?.id) {
      report.breakdownProperty = {}
    }
    if (!report.intervalProperty) {
      report.intervalProperty = {}
    }
    const dialogRef = this.dialog.open(ReportingParameterDialog, {
      data: {
        report
      }, 
      minWidth: '50vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        result = result.report
        this.reportingService.persistParameters({
          id: result.id,
          breakdownProperty: result.breakdownProperty.id ? {id: result.breakdownProperty.id} : undefined,
          intervalProperty: {id: result.intervalProperty.id},
          colorPattern: result.colorPattern,
          customCss: result.customCss,
          graphType: result.graphType,
          interval: result.interval,
          reportName: result.reportName,
        }).subscribe(_ => {
          this.loadReports()
        })
      }
    });        
  }

  execute() {
    this.reportLoading = true
    this.reportingService.getReport(this.currentReport?.id!, 
        format(this.startDate!, 'yyyy-MM-dd'), 
        format(this.endDate!, 'yyyy-MM-dd')).subscribe(r => {
      console.log(r)
      const dates = [...new Set(r.map(r => r.date))]
      const sampleArray = [...dates.map(_ => 0)]
      const breakDowns = new Map<string, number[]>()
      r.forEach(l => {
        if (!breakDowns.has(l.breakdown)) {
          breakDowns.set(l.breakdown, [...sampleArray])
        }
        const currentArray = breakDowns.get(l.breakdown)!
        currentArray[dates.indexOf(l.date)] = l.count
      })
      const columns:any = [
        ['x', ...dates]
      ]
      breakDowns.forEach((value, key) => {
        columns.push([key, ...value])
      })
      this.reportLoaded = true
      this.reportLoading = false      
      
      if (this.currentReport?.customCss) {
        document.getElementById("reportingStyle")?.remove()
        var head = document.getElementsByTagName('head')[0];
        var s = document.createElement('style');
        s.setAttribute("id", "reportingStyle")
        s.setAttribute('type', 'text/css');
        s.appendChild(document.createTextNode(this.currentReport?.customCss));
        head.appendChild(s);
      }

      const options:any = {
        data: {
            x: 'x',
            columns,
            type: this.currentReport?.graphType,
            groups: [
                [...breakDowns.keys()]
            ],
            order: function (t1:any, t2:any) {
              return t1.id < t2.id;
            }
        },
        grid: {
            y: {
                lines: [{value:0}]
            }
        },
        axis: {
          x: {
              type: 'timeseries',
              tick: {
                  format: '%Y-%m-%d'
              }
          }
        }
      }
      if (this.currentReport?.colorPattern) {
        options.color = {
          pattern: JSON.parse(this.currentReport.colorPattern)
        }
      }
      setTimeout(() => {
        c3.generate(options);
      }, 100)
    })
  }
  
}

export interface ReportingParametersDialogData {
  report:ReportingParameters;
}

@Component({
  selector: 'dialog-reporting-parameter',
  templateUrl: './dialog-reporting-parameter.html',
  styleUrls: [ './dialog-reporting-parameter.sass' ]
})
export class ReportingParameterDialog {

  Object = Object
  metadataDefinitions:MetadataDefinition[] = []
  metadataDefinitionsBreakdown:MetadataDefinition[] = []
  metadataDefinitionsInterval:MetadataDefinition[] = []
  
  constructor(
    public dialogRef: MatDialogRef<ReportingParameterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ReportingParametersDialogData,
    private metadataService:MetadataService
  ) {}

  ngOnInit() {
    this.metadataDefinitions = this.metadataService
      .metadataDefinitions
      .filter(md => md.parentGroup?.attachmentType !== MetadataAttachmentType.REFERENCEABLE &&
                    // Not supported right now 
                    !(md.parentGroup?.attachmentType !== MetadataAttachmentType.MEDIA_VERSION && md.type == MetadataType.REFERENCE) &&
                    md.type !== MetadataType.COMPUTED)
      this.metadataDefinitionsBreakdown = this.metadataDefinitions.filter(md => md.type == MetadataType.MULTI_VALUED)
      this.metadataDefinitionsInterval = this.metadataDefinitions.filter(md => md.type == MetadataType.DATE)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
