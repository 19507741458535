<h1>{{ "versions_definition.title" | i18next}}</h1>


<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>    

<ng-container *ngIf="!loading">
    <div *ngIf="!mediaVersions?.content?.length">
        {{ "versions_definition.empty" | i18next}}
    </div>
    
    <div *ngIf="mediaVersions?.content?.length" style="margin-bottom: 50px">
        <ul class="versions">
            <li *ngFor="let version of mediaVersions?.content">
                
                <h2>
                    {{version.name}} 
                </h2>

                <div *ngIf="!version.expectedStreamDefinitions?.length" style="margin-top: 15px; margin-bottom: 25px;">
                    {{ "versions_definition.no_expected_stream" | i18next}}
                </div>

                <ul *ngIf="version.expectedStreamDefinitions?.length" class="streams">
                    <li *ngFor="let stream of version.expectedStreamDefinitions" class="stream">
                        
                        <span>{{stream.representation}}</span>

                        <span class="streamButtons">
                            <button mat-icon-button [matMenuTriggerFor]="streamMenu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </span>
                        
                        <mat-menu #streamMenu="matMenu" style="margin-top: 5px">
                            <button mat-menu-item (click)="editExpectedStreamDefinition(stream)">Edit stream</button>
                            <button mat-menu-item (click)="addExpectedStreamDefinition(version, stream.type!, stream)">Add an alternative stream</button>
                        </mat-menu>

                        <ul>
                            <li *ngFor="let alternative of stream.alternatives" class="alternative" style="margin-left: 10px">                    
                                <mat-menu #alternativeStreamMenu="matMenu" style="margin-top: 5px">
                                    <button mat-menu-item (click)="editExpectedStreamDefinition(alternative)">Edit alternative</button>
                                    <button mat-menu-item (click)="deleteExpectedStreamDefinition(alternative)">Delete alternative</button>
                                </mat-menu>                                            
                                <mat-icon [matMenuTriggerFor]="alternativeStreamMenu" matTooltip="Alternative (click to edit)" style="transform: scale(0.8) translateY(8px); margin-right: 3px;" >
                                    alt_route
                                </mat-icon>
                                <span>{{alternative.representation}}</span>
                                <button mat-icon-button [matMenuTriggerFor]="alternativeStreamMenu" class="alternativeButtons" style="position: relative; top: 8px">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div style="margin-top: 20px" class="addExpectedStreamButtons">
                    <button mat-fab class="add" [matTooltip]="i18next.t('versions_definition.add_video')" (click)="addExpectedStreamDefinition(version, 'VIDEO')">
                        <mat-icon>movie</mat-icon>
                    </button>
                    <button mat-fab class="add" [matTooltip]="i18next.t('versions_definition.add_audio')" (click)="addExpectedStreamDefinition(version, 'AUDIO')">
                        <mat-icon>graphic_eq</mat-icon>
                    </button>
                    <button mat-fab class="add" [matTooltip]="i18next.t('versions_definition.add_subtitle')" (click)="addExpectedStreamDefinition(version, 'SUBTITLE')">
                        <mat-icon>closed_caption</mat-icon>
                    </button>  
                    <button mat-fab class="add" [matTooltip]="i18next.t('versions_definition.add_spare')" (click)="addExpectedStreamDefinition(version, 'SPARE_FILE')">
                        <mat-icon>description</mat-icon>
                    </button>                    
                </div>

                <button mat-icon-button (click)="deleteVersion(version)" class="delete">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </li>
        </ul>
    </div>    
</ng-container>

<div class="buttons">
    <button mat-fab class="add" [matTooltip]="i18next.t('versions_definition.add')" (click)="addVersionDefinition()">
        <mat-icon>add</mat-icon>
    </button>
    <button mat-fab class="add" [matTooltip]="i18next.t('versions_definition.order')" (click)="reorderVersionDefinitions()">
        <mat-icon>low_priority</mat-icon>
    </button>
</div>