import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MetadataAttachmentType, MetadataDefinition, MetadataType } from 'src/app/models/metadata';
import { UserMetadataDialog } from '../dialogs/user.metadatas.dialog';
import { MetadataService } from 'src/app/services/metadata.service';
import { FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { prepareForm } from '../metadata.display/metadata.display.component';
import { handleTableResponsivness } from 'src/app/utils/utils';
import i18next from 'i18next';

@Component({
  selector: 'app-multi-entity-metadata',
  templateUrl: './multi.entity.metadata.component.html',
  styleUrls: ['./multi.entity.metadata.component.sass']
})
export class MultiEntityMetadataComponent {
  @Input() metadataDefinition:MetadataDefinition|undefined
  @Input() datas:any[] = []
  @Input() targetId:number|undefined
  @Input() editable:boolean = true
  @Input() targetType:string|undefined
  @Output() onRowAdded:EventEmitter<any> = new EventEmitter();
  @Output() onRowDeleted:EventEmitter<any> = new EventEmitter();
  @ViewChild('style', {static: false}) style:ElementRef<any>|undefined;
  datasource:MatTableDataSource<any> = new MatTableDataSource(this.datas)
  entityColumns: string[] = []
  underlyingMetadataDefinitions:Map<string,MetadataDefinition> = new Map()
  metadataTypes:typeof MetadataType = MetadataType
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType
  columnsToDisplay: string[] = [];
  dateFormat = i18next.t('global.dateFormat')

  constructor(private metadataService:MetadataService,
    private userService:UserService,
    public dialog: MatDialog,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    console.log('metadataDefinition', this.metadataDefinition)
    this.entityColumns = this.entityColumns.concat(...this.metadataDefinition?.referencedMetadataGroupEntity?.metadatas?.map(m => m.name) ?? [])
    this.columnsToDisplay = [...this.entityColumns, 'tools']
    this.metadataDefinition?.referencedMetadataGroupEntity?.metadatas?.forEach(m => this.underlyingMetadataDefinitions.set(m.name, m))
    this.datasource.data = this.datas
    console.log('columnsToDisplay', this.columnsToDisplay)
  }

  ngAfterViewInit() {
    handleTableResponsivness()
    window.onresize = () => {
      handleTableResponsivness()
    }
  }

  getMetadataDefinition(name:string) {
    return this.metadataService.getMetadataDefinitionFromCache(name)
  } 

  deleteRow(entity:any) {
    const index = this.datasource.data.indexOf(entity)
    console.log('delete', entity, index, this.datasource.data)
    if (index >= 0) {
      this.datasource.data.splice(index, 1)
      this.datasource.data = this.datasource.data
      this.persistMetadatas(() => this.onRowDeleted.emit(entity))  
    }
  }

  editRow(entity:any) {
    console.log('edit', this.metadataDefinition)
    const filteredOptions = new Map<string, any>()
    const metadataGroups = [this.metadataDefinition?.referencedMetadataGroupEntity!]
    const data:any = {}
    data[this.metadataDefinition?.referencedMetadataGroupEntity!.id!] = entity
    let metadataForm = prepareForm(this.metadataService, 
      this.userService,
      this.fb, metadataGroups,
      this.userService.currentUser!,
      filteredOptions,
      data,
      this.targetId);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        const updated = result.value[this.metadataDefinition?.referencedMetadataGroupEntity!.id!]
        this.datasource.data[this.datasource.data.indexOf(entity)] = updated
        this.datasource.data = this.datasource.data
        this.persistMetadatas(() => this.onRowAdded.emit(entity))
      }
    });    
  }

  addRow() {
    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = [this.metadataDefinition?.referencedMetadataGroupEntity!]
    
    let metadataForm = prepareForm(this.metadataService, 
      this.userService,
      this.fb, metadataGroups,
      this.userService.currentUser!,
      filteredOptions,
      this.datas,
      this.targetId);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        const entity = result.value[this.metadataDefinition?.referencedMetadataGroupEntity!.id!]
        this.datasource.data = this.datasource.data.concat(entity)
        this.persistMetadatas(() => this.onRowAdded.emit(entity))
      }
    });
  }

  persistMetadatas(callback:Function) {

    const metadatasToSubmit:{[key: string]: any} = {}
    const parentMetadataGroupId = this.metadataDefinition?.parentGroup!.id!
    metadatasToSubmit[parentMetadataGroupId] = {}
    metadatasToSubmit[parentMetadataGroupId] = {}
    metadatasToSubmit[parentMetadataGroupId]['targetType'] = this.targetType
    metadatasToSubmit[parentMetadataGroupId]['targetId'] = this.targetId
    metadatasToSubmit[parentMetadataGroupId][this.metadataDefinition!.name] = this.datasource.data

    this.metadataService.persistMetadatasForEntity(
      metadatasToSubmit).subscribe(() => callback);    
  }

  getUnderlyingMetadataDefinition(key:string):MetadataDefinition {
    return this.underlyingMetadataDefinitions.get(key)!
  }
}
