<h1 mat-dialog-title>{{'mediaDetail.ingest_new' | i18next}}</h1>
<mat-dialog-content>
  <p>
    {{'mediaDetail.file_pattern' | i18next}}
    <span class="code">{{'mediaDetail.file_pattern_sample' | i18next}}</span>
  </p>
  <mat-form-field appearance="fill">
    <mat-label>{{'mediaDetail.pattern' | i18next}}</mat-label>
    <input matInput [(ngModel)]="data.pattern" [disabled]="data.uploadRequest !== undefined">
  </mat-form-field>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'ingestDialog.more_options' | i18next}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field appearance="fill">
      <mat-label>{{"ingestDialog.locations" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.location">
        <mat-option *ngFor="let location of locations" [value]="location">
          {{ location.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="data.uploadRequests">
      <mat-label>{{"ingestDialog.partnerUpload" | i18next}}</mat-label>
      <mat-select [(ngModel)]="data.uploadRequest">
        <mat-option *ngFor="let uploadRequest of data.uploadRequests" [value]="uploadRequest">
          #{{ uploadRequest.id }} {{"ingestDialog.for" | i18next}} {{uploadRequest.partner.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-expansion-panel>  
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{"simple_text_dialog.cancel_text_dialog" | i18next}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"simple_text_dialog.ok_text_dialog" | i18next}}</button>
</div>  