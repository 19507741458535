<h1 mat-dialog-title>Workflow</h1>
<mat-dialog-content>  
  <mat-spinner *ngIf="!image"></mat-spinner>
  <div style="background: white" *ngIf="image" style="margin: 0 auto;">
    <img [src]="'data:image/svg+xml;base64,' + image" style="margin-left: -150px; margin-top: -50px;" />
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{"simple_image_dialog.ok_image_dialog" | i18next}}</button>
</div>  