import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaStream } from '../models/media'
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CreateMediaResult, CreateVersionResult, MediaVersion, Media, MediaElement, MediaVersionModificationRequest } from '../models/media';
import { Page } from '../models/page';
import { Task } from '../models/task';
import { SearchCondition } from '../models/search.condition';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class MediaService {
  
  private mediasUrl = 'api/medias';
  private mediaElementsUrl = 'api/mediaElement';
  private mediaExpectedStreamUrl = 'api/stream';
  private dashboardUrl = 'api/dashboard';
  private mediaUrl = 'api/media';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  getProposedVersionsForExpectedStreams(mediaId:number): Observable<MediaVersion[]> {
    return this.http.get<MediaVersion[]>(`/api/media/${mediaId}/proposedVersionsForExpectedStreams`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<MediaVersion[]>('getProposedVersionsForExpectedStreams', []))
      );
  }
  
  attachExternalId(mediaId: number, externalId: any) {
    return this.http.put<void>(`/api/media/${mediaId}/attachExternalId/${externalId}`, undefined)
      .pipe(
        catchError(this.errorService.handleError<void>(`attachExternalId id=${mediaId}`))
      );
  }
  
  attachExternalIdToVersion(mediaId: number, versionId:number, externalId: any) {
    return this.http.put<void>(`/api/media/${mediaId}/version/${versionId}/attachExternalId/${externalId}`, undefined)
      .pipe(
        catchError(this.errorService.handleError<void>(`attachExternalId id=${mediaId}`))
      );
  }
  
  setPreviewImage(mediaId: number, versionId: number, timecode: number) {
    return this.http.post<void>(`/api/media/${mediaId}/${versionId}/setPreviewImage?timecode=${timecode}`, undefined)
      .pipe(
        catchError(this.errorService.handleError<void>(`setPreviewImage id=${mediaId}`))
      );
  }
  
  generateWhisperTranscript(mediaId: number, versionId: number) {
    return this.http.post<void>(`/api/media/${mediaId}/${versionId}/generateWhisperTranscript`, undefined)
      .pipe(
        catchError(this.handleError<void>(`generateWhisperTranscript id=${mediaId}`))
      );
  }

  deleteMediaCopyFromLocation(mediaId: number, locationId: number) {
    return this.http.post<void>(`/api/media/${mediaId}/deleteCopyFromLocation/${locationId}`, undefined)
      .pipe(
        catchError(this.errorService.handleError<void>(`deleteMediaElementCopyFromLocation id=${mediaId}`))
      );
  }

  createVersionFromDefinition(versionDefinitionId: number, mediaId: number, datas:any):Observable<void> {
    return this.http.post<void>(`/api/media/${mediaId}/versionFromDefinition/${versionDefinitionId}`, datas)
      .pipe(
        catchError(this.errorService.handleError<void>(`createVersionFromDefinition id=${mediaId}`))
      );
  }
  

  getMedias(page:number=0, sort:string='id,DESC,false'): Observable<Page<Media>> {
    const [sortColumn, sortOrder, sortIsMetadata] = sort.split(',')
    const sortParam = sortIsMetadata === 'true' ? `sortMetadata=${sortColumn},${sortOrder}` : `sort=${sortColumn},${sortOrder}`
    return this.http.get<Page<Media>>(`${this.mediasUrl}?page=${page}&${sortParam}`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<Page<Media>>('getMedias', {}))
      );
  }

  getMediasUsableAsPreroll(): Observable<Page<Media>> {
    return this.http.get<Page<Media>>(`${this.mediasUrl}/usableAsPreroll`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<Page<Media>>('getMedias', {}))
      );
  }
  
  createVersions(mediaId: number, versions: MediaVersion[]) {
    const url = `${this.mediaUrl}/${mediaId}/versions`;
    return this.http.post<MediaVersion[]>(url, versions)
      .pipe(
        catchError(this.errorService.handleError<MediaStream>(`createVersions id=${mediaId}`))
      );
  }
  
  searchMedias(conditions:SearchCondition[], sort:string='id,DESC,false', page:number=0): Observable<Page<Media>> {
    const [sortColumn, sortOrder, sortIsMetadata] = sort.split(',')
    const sortParam = sortIsMetadata === 'true' ? `sortMetadata=${sortColumn},${sortOrder}` : `sort=${sortColumn},${sortOrder}`
    return this.http.put<Page<Media>>(`${this.mediasUrl}/search?${sortParam}&page=${page}`, conditions)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<Page<Media>>('getMedias', {}))
      );
  }

  deleteMedia(id:number): Observable<Media> {
    return this.http.delete<Media>(`${this.mediasUrl}/${id}`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<Media>('deleteMedia', {}))
      );
  }

  deleteMediaElement(id:number): Observable<MediaElement> {
    return this.http.delete<Media>(`${this.mediaElementsUrl}/${id}`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<Media>('deleteMedia', {}))
      );
  }

  changeMediaElementAccessibility(id:number, accessible:boolean): Observable<MediaElement> {
    return this.http.put<Media>(`${this.mediaElementsUrl}/${id}/accessibleToPartners?value=${accessible}`, {})
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.handleError<Media>('deleteMedia', {}))
      );
  }  

  deleteMediaExpectedStream(id:number): Observable<MediaStream> {
    return this.http.delete<MediaStream>(`${this.mediaExpectedStreamUrl}/${id}`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<MediaStream>('deleteMedia', {}))
      );
  }

  deleteMediaVersion(mediaId:number, versionId: number) {
    return this.http.delete<Media>(`${this.mediasUrl}/${mediaId}/${versionId}`)
      .pipe(
        tap(_ => this.log('fetched medias')),
        catchError(this.errorService.handleError<Media>('deleteMedia', {}))
      );
  }

  getDashboard(page:number): Observable<Page<Media>> {
    return this.http.get<Page<Media>>(`${this.dashboardUrl}?page=${page}&sort=id,DESC`)
      .pipe(
        tap(_ => this.log('fetched getDashboard')),
        catchError(this.errorService.handleError<Page<Media>>('getDashboard', {}))
      );
  }

  triggerMediaScan<Data>(id: number, path: string, locationId: number = 2): Observable<void> {
    const url = `${this.mediaUrl}/${id}/scan`;
    return this.http.post<void>(url, {
      locationId,
      path
    })
      .pipe(
        catchError(this.errorService.handleError<void>(`triggerMediaScan id=${id}`))
      );
  }

  triggerMediaMove<Data>(id: number, locationId: number, copy:boolean, versionId:number|undefined=undefined): Observable<void> {
    let url = `${this.mediaUrl}/${id}/move/${locationId}?1=1`;
    if (copy) url += '&copy=true'
    if (versionId) url += `&mediaVersionId=${versionId}`
    return this.http.post<void>(url, {})
      .pipe(
        catchError(this.errorService.handleError<void>(`triggerMediaMove id=${id}`))
      );
  }

  createMedias(datas: any) {
    const url = `api/medias`;
    return this.http.post<CreateMediaResult>(url, datas)
      .pipe(
        catchError(this.errorService.handleError<CreateMediaResult>(`CreateMediaResult title=${name}`))
      );
  }

  createMedia<Data>(name: string, externalId:number|undefined=undefined): Observable<CreateMediaResult> {
    const url = `${this.mediaUrl}`;
    return this.http.post<CreateMediaResult>(url, {
      name,
      externalId
    })
      .pipe(
        catchError(this.errorService.handleError<CreateMediaResult>(`CreateMediaResult title=${name}`))
      );
  }

  createMediaExpectedStream<Media>(mediaId:number, mediaExpectedStream: MediaStream, alternativeFor:number|undefined=undefined): Observable<MediaStream> {
    let url = `${this.mediaUrl}/${mediaId}/expectedStream`;
    if (alternativeFor) {
      url += `?alternativeFor=${alternativeFor}`
    }
    return this.http.post<MediaStream>(url, mediaExpectedStream)
      .pipe(
        catchError(this.errorService.handleError<MediaStream>(`createMediaExpectedStream title=${name}`))
      );
  } 

  updateMedia<Data>(media:Media): Observable<Media> {
    const url = `${this.mediasUrl}/${media.id}`;
    const updatedMedia = {
      id: media.id,
      name: media.name,
    }
    return this.http.put<Media>(url, updatedMedia)
      .pipe(
        catchError(this.errorService.handleError<CreateMediaResult>(`Media title=${name}`))
      );
  }  

  getMedia<Data>(id: number): Observable<Media> {
    const url = `${this.mediaUrl}/${id}`;
    return this.http.get<Media>(url)
      .pipe(
        tap(m => {
          m.elements?.forEach(el => el.streams?.forEach(s => s.fromFilename = el.filename))
          m.expectedStreams?.forEach(es => {
            es.allPreviousVersions = []
            if (es.status == 'RECEIVED') es.allPreviousVersions.push(es)
            let current = es
            while (current.previousVersion) {
              es.allPreviousVersions.push(current.previousVersion)
              current = current.previousVersion
            }
          })
          m.versions?.forEach(v => {
            if (v.video) {
              v.video!.fromFilename = m.elements?.flatMap(e => e.streams).find(s => s?.id === v.video?.id)?.fromFilename
              v.video.allPreviousVersions = []
              let currentVideo = v.video
              while (currentVideo.previousVersion) {
                v.video.allPreviousVersions.push(currentVideo)
                currentVideo = currentVideo.previousVersion
              }
              v.video.alternatives?.forEach(a => {
                a.allPreviousVersions = []
                while (a.previousVersion) {
                  a.allPreviousVersions?.push(a.previousVersion)
                  a = a.previousVersion
                }
              })
            }
            if (v.audio) {
              v.audio!.fromFilename = m.elements?.flatMap(e => e.streams).find(s => s?.id === v.audio?.id)?.fromFilename
              v.audio.allPreviousVersions = []
              let currentAudio = v.audio
              while (currentAudio.previousVersion) {
                v.audio.allPreviousVersions.push(currentAudio)
                currentAudio = currentAudio.previousVersion
              }
              v.audio.alternatives?.forEach(a => {
                a.allPreviousVersions = []
                while (a.previousVersion) {
                  a.allPreviousVersions?.push(a.previousVersion)
                  a = a.previousVersion
                }
              })
            }
            if (v.subtitles) {
              for (const sub of v.subtitles) {
                sub.fromFilename = m.elements?.flatMap(e => e.streams).find(s => s?.id === sub?.id)?.fromFilename
              }
            }
          })
        }),
        catchError(this.errorService.handleError<Media>(`getMedia id=${id}`))
      );
  }

  getWorkflowImage<Data>(id: number): Observable<string> {
    const url = `api/process/${id}/image`;
    const httpOptions : Object = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'text/plain; charset=utf-8'
      }),
      responseType: 'text'
    }    
    return this.http.get<string>(url, httpOptions)
      .pipe(
        tap(h => {
          const outcome = h ? 'fetched' : 'did not find';
          console.log(`${outcome} media id=${id}`);
        }),
        catchError(this.errorService.handleError<string>(`getMediaWorkflowImage id=${id}`))
      );
  }

  getPendingTaskForMedia<Data>(id: number): Observable<Task[]> {
    const url = `${this.mediaUrl}/${id}/tasks/pending`;
    return this.http.get<Task[]>(url)
      .pipe(
        catchError(this.errorService.handleError<Task[]>(`getPendingTaskForMedia id=${id}`))
      );
  }  

  createVersion<Data>(version:MediaVersionModificationRequest, mediaId:number): Observable<Media> {
    const url = `${this.mediaUrl}/${mediaId}/version?`;
    return this.http.post<CreateVersionResult>(url, version)
      .pipe(
        catchError(this.errorService.handleError<CreateVersionResult>(`CreateVersionResult version=${version}`))
      );
  }

  updateVersion<Data>(version:MediaVersion, mediaId:number, audioId: number): Observable<Media> {
    const url = `${this.mediaUrl}/${mediaId}/version/${version.id}?audioId=${audioId}`;
    return this.http.put<CreateVersionResult>(url, version)
      .pipe(
        catchError(this.errorService.handleError<CreateVersionResult>(`CreateVersionResult version=${version}`))
      );
  }  

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`MediaService: ${message}`);
  }
}
