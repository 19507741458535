import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import i18next from "i18next";
import { Location } from "src/app/models/media";
import { UploadRequest } from "src/app/models/upload.request";
import { ConfigurationService } from "src/app/services/configuration.service";
import { LocationService } from "src/app/services/location.service";

export interface IngestDialogData {
    pattern: string;
    uploadRequests: UploadRequest[];
    uploadRequest: UploadRequest;
    location: Location;
}

@Component({
    selector: 'dialog-ingest',
    templateUrl: './ingest.dialog.html',
    styleUrls: ['ingest.dialog.sass']
})
export class IngestDialog {

    i18next = i18next
    locations: Location[] = [];

    constructor(
        public dialogRef: MatDialogRef<IngestDialog>,
        private locationService: LocationService,
        private configurationService: ConfigurationService,
        @Inject(MAT_DIALOG_DATA) public data: IngestDialogData,
    ) { }

    ngOnInit():void {
        this.locationService.getLocations(false)
            .subscribe(locations => { 
                this.locations = locations.content ?? []
                this.configurationService.getConfigurations().subscribe(c => {
                    const locationId = parseInt(c.content?.filter(c => c.key == 'DEFAULT_LOCALISATION_TO_SCAN')[0].value!)
                    this.data.location = this.locations.filter(l => l.id === locationId)[0]
                })                
            })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
