<h1 mat-dialog-title>
  <span>{{data.title | i18next}}</span>

  <button style="transform: scale(0.5) translateY(8px)" 
    aria-label="Add" mat-fab
    (click)="addCondition()">
    <mat-icon>add</mat-icon>
  </button>
</h1>
<mat-dialog-content>
  <p *ngIf="data.text">{{data.text | i18next}}</p>
  
  <div *ngFor="let definition of data.definitions" style="display: flex">
    <app-metadata-select 
      [allowMultipleOnMultiValued]="true"
      [(selection)]="definition!.definition" 
      [(value)]="definition.value"
      [onlyAttachedTo]="data.attachedTo"></app-metadata-select> 
      <button style="transform: scale(0.8) translateY(2px)" 
            aria-label="Remove" mat-icon-button
            (click)="removeCondition(definition)">
        <mat-icon>delete_forever</mat-icon>
      </button>                  
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"simple_list_dialog.cancel_list_dialog" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data.definitions">{{"simple_list_dialog.ok_list_dialog" | i18next}}</button>
  </div>  
</mat-dialog-content>