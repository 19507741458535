import { Component } from '@angular/core';
import { Router } from '@angular/router';
import i18next from 'i18next';
import { UserService } from 'src/app/services/user.service';
import { UserTaskService } from 'src/app/services/usertask.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent {

  loading: boolean = true

  constructor(private router: Router,
      private userTaskService: UserTaskService,
      public userService: UserService) {
    if (!userService.currentUser) {
      this.router.navigate(['login']);
    }
  }

}
