import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Media } from 'src/app/models/media';
import { MetadataAttachmentType, MetadataDefinition, MetadataGroup, MetadataReference, MetadataType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { User } from 'src/app/models/user';
import { MetadataService } from 'src/app/services/metadata.service';
import { UserMetadataDialog } from '../dialogs/user.metadatas.dialog';
import { Observable, debounceTime, delay, distinctUntilChanged, forkJoin, map, startWith, switchMap } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { intersect, isRightGranted } from 'src/app/utils/utils';
import i18next from 'i18next';

@Component({
  selector: 'app-metadata-display',
  templateUrl: './metadata.display.component.html',
  styleUrls: ['./metadata.display.component.sass']
})
export class MetadataDisplayComponent {

  @Input() element:any;
  @Input() targetType:MetadataAttachmentType|undefined;
  @Input() targetId:number|undefined;
  @Input() externalId:string|undefined;
  @Input() mediaStreamType:string|undefined;
  @Input() remote:boolean = false;
  @Input() noButton:boolean = false;
  @Input() grouped:boolean = false;
  @Input() limitToGroup:MetadataGroup|undefined;
  @Output() onMetadataUpdated:EventEmitter<void> = new EventEmitter();
  data:any;
  metadataForm:any;
  metadataGroups: MetadataGroup[] = [];
  metadataTypes:typeof MetadataType = MetadataType;
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
  hasMultiEntitiesMetadatas:boolean = false;
  displayedGroups: Map<number, boolean> = new Map()
  hasData:boolean = false;
  JSON = JSON;
  error:boolean = false;
  dateFormat = i18next.t('global.dateFormat');
  metadataRefreshing: boolean = false;
  canDisplayMetadata:Map<number, boolean> = new Map<number, boolean>()
  reload:boolean = false
  hidden: boolean = false;
  
  constructor(private metadataService:MetadataService,
    private router: Router,
    private userService:UserService,
    public dialog: MatDialog,
    private fb: FormBuilder) {
  }

  ngOnChanges():void {
    if (!this.element!) return
    this.data = this.element!.dynamicMetadatas
    this.getMetadataGroups()
    for (let group of this.metadataGroups) {
      for (let metadataDefinition of group.metadatas ?? []) {
          this.canDisplayMetadata.set(metadataDefinition.id!, this.canDisplay(metadataDefinition))
      }
    }
    this.forceReload()
  }

  private forceReload() {
    setTimeout(() => this.reload = true);
    setTimeout(() => this.reload = false);
  }  

  toggleGroup(groupId:number) {
    const open = this.displayedGroups.has(groupId) && this.displayedGroups.get(groupId) === true
    this.displayedGroups.set(groupId, !open)
  }

  canDisplay(metadataDefinition:MetadataDefinition) {
    if (!metadataDefinition.displayRestrictedToRoles || metadataDefinition.displayRestrictedToRoles.length === 0) {
        return true
    }
    return intersect(this.userService.currentUser?.roles, metadataDefinition.displayRestrictedToRoles).length > 0
  }  

  getMultiEntitiesMetadata(datas:any, metadataGroupId:number, metadataDefinitionName:string) {
    if (!datas) return []
    if (!datas.hasOwnProperty(metadataGroupId)) return []
    let result = datas[metadataGroupId][metadataDefinitionName]
    if (result === "") return []
    return datas[metadataGroupId][metadataDefinitionName]
  }

  addMultiEntitiesMetadata(datas:any, metadataGroupId:number, metadataDefinitionName:string, row:any) {
    if (!(datas[metadataGroupId][metadataDefinitionName] instanceof Array))
      datas[metadataGroupId][metadataDefinitionName] = []
    datas[metadataGroupId][metadataDefinitionName].push(row)
  }

  removeMultiEntitiesMetadata(datas:any, metadataGroupId:number, metadataDefinitionName:string, row:any) {
    if (!(datas[metadataGroupId][metadataDefinitionName] instanceof Array))
      datas[metadataGroupId][metadataDefinitionName] = []
    datas[metadataGroupId][metadataDefinitionName].splice(row, 1)
  }  

  getMetadataGroups(): void {
    this.metadataService.getMetadataGroups()
      .subscribe(metadataGroups => {
        this.metadataGroups = metadataGroups.content!
          .filter(g => !this.limitToGroup || this.limitToGroup.id === g.id)
          .filter(g => g.attachmentType == this.targetType && 
            (g.externalDatasource ?? false) === this.remote && 
            (g.displayGrouped ?? false) === this.grouped)!
        
        const metadataCount = this.metadataGroups.flatMap(m => m.metadatas).length
        if (metadataCount === 0) {
          this.hidden = true
        }
        console.log('metadataGroups.content?.length', this.targetType, this.grouped, this.metadataGroups.flatMap(m => m.metadatas).length)
        this.hasMultiEntitiesMetadatas = this.metadataGroups.flatMap(m => m.metadatas).filter(me => me?.type == MetadataType.MULTI_ENTITIES).length > 0
        this.hasData = false
        this.metadataGroups.forEach(mg => {
          if (!this.data || !this.data.hasOwnProperty(mg.id)) return
          if (this.grouped) this.displayedGroups.set(mg.id, true)
          const subdatas = this.data[mg.id]
          for (let key of Object.keys(subdatas)) {
            if (key == 'targetId' || key == 'targetType') continue
            // console.log('subdatas[key]', key, subdatas[key])
            if (subdatas[key] && subdatas[key] != "") {
              this.hasData = true
            }
          }

          const displayForcedCount = mg.metadatas?.reduce((acc, metadataDefinition) => {
            console.log('metadataDefinition.displayIfEmpty', metadataDefinition.name, metadataDefinition.displayIfEmpty)
            acc += metadataDefinition.displayIfEmpty ? 1 : 0
            return acc
          }, 0)
          console.log('displayForcedCount', displayForcedCount)
          if (displayForcedCount && displayForcedCount > 0) {
            this.hasData = true
          }
        })
      })
  }

  refreshRemoteMetadata(targetType:MetadataAttachmentType, targetId:number) {
    console.log('refreshRemoteMetadata')
    this.metadataRefreshing = true
    this.metadataService.refreshExternalData(targetType, targetId)
      .pipe(delay(500))
      .subscribe(r => {
        this.onMetadataUpdated.emit()
        this.metadataRefreshing = false
      })
  }

  navigateToSearch(metadataGroupId:number, value:number) {    
    this.router.navigate(['search'], { 
      queryParams: {
        searchId: metadataGroupId,
        searchValue:value
      }
    });
  }

  openUserMetadataDialog(): void {
    const metadataGroups = this.metadataGroups?.filter(g => g.attachmentType === this.targetType)
    this.openSpecificUserMetadataDialog(metadataGroups, this.targetId!, this.data, this.mediaStreamType!)
  }

  openReferenceableMetadataDialog(definition:MetadataDefinition, currentGroup:MetadataGroup, data:any) {
    const referencedGroup = definition.referencedMetadataGroupEntity!
    const structuredData:any = {}
    structuredData[referencedGroup.id!] = data[currentGroup.id!][definition.name].dynamicMetadatas
    this.openSpecificUserMetadataDialog([referencedGroup], data[currentGroup.id!][definition.name].id, structuredData, 'REFERENCEABLE')
  }

  openSpecificUserMetadataDialog(metadataGroups:MetadataGroup[], targetId:number, datas:any, targetType:string): void {
    console.log('openSpecificUserMetadataDialog', datas);

    const fullDatas:any = datas
    //fullDatas[metadataGroups[0].id] = datas

    // populate form
    const filteredOptions = new Map<string, any>()
    
    this.metadataForm = prepareForm(this.metadataService, 
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      fullDatas,
      targetId,
      targetType,
      false,
      this.externalId);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes,
      },
      width: '80vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log(result.value);
        this.metadataService.persistMetadatasForEntity(
          this.metadataForm.value).subscribe(() => {
            this.onMetadataUpdated.emit()
          });
      }
    });
  }
}

function filterMetadataReference(metadataService:MetadataService, val: string, metadataReferenceId:number): Observable<any[]> {
  console.log('filter', val)

  return metadataService.getAutocompleteData(metadataReferenceId)
   .pipe(
     map(response => response.filter(option => { 
      console.log('option', option)
       return option.representation?.toLowerCase().indexOf(val.toLowerCase()) === 0
     }))
   )
}

function filterUser(userService:UserService, val: string): Observable<any[]> {
  console.log('filter', val)
  return userService.getAutocompleteData('ALL', val)
   .pipe(
     map(response => response.filter(option => { 
      console.log('option', option)
       return option.email?.toLowerCase().indexOf(val.toLowerCase()) === 0
     }))
   )
}

function filterExternalKeyValue(metadataService:MetadataService, url:string, externalId:string, val: string): Observable<any[]> {
  // console.log('filter', val)
  return metadataService.getExternalAutocompleteData(url, externalId, val)
   .pipe(
     map(response => response.filter(option => { 
      console.log('option', option)
       return option.value.toLowerCase().indexOf(val.toLowerCase()) === 0
     }))
  )
}

export function prepareForm(
  metadataService:MetadataService,
  userService:UserService,
  fb: FormBuilder,
  metadataGroups: MetadataGroup[],
  currentUser: User,
  filteredOptions: Map<string, any>,
  datas:any,
  targetId:number|undefined=undefined,
  mediaStreamType:string|undefined=undefined,
  onlyRepresentative:boolean=false,
  externalId:string|undefined=undefined) {

  const filteredOptionsGroupMapping: Map<string, MetadataDefinition> = new Map<string, MetadataDefinition>()
  let group: { [k: string]: any } = {}
  if (metadataGroups) {
      console.log('metadataGroups: ', metadataGroups)

      for (const metadataGroup of metadataGroups) {
          if (metadataGroup.metadatas) {
              let subgroup: { [k: string]: any } = {}
              for (const metadataDefinition of metadataGroup.metadatas) {
                const editable = isRightGranted(metadataDefinition.editingRestrictedToRoles, currentUser?.roles)
                  if (mediaStreamType) {
                    if (metadataDefinition.displayRestrictedToMediaStreamTypes?.indexOf(mediaStreamType)) {
                      continue
                    }
                  }
                  if (onlyRepresentative) {
                    if (!metadataDefinition.representative) {
                      continue
                    }
                  }

                  // preset values if set
                  subgroup[`${metadataDefinition.name}`] = 
                      datas != null && datas.hasOwnProperty(metadataGroup.id) && 
                      datas[metadataGroup.id] != null && datas[metadataGroup.id].hasOwnProperty(metadataDefinition.name) ?
                        [{ value: datas[metadataGroup.id][metadataDefinition.name], disabled: !editable }] :
                        [{ value: '', disabled: !editable}]

                  // prepare autocomplete references 
                  if (metadataDefinition.type === MetadataType.REFERENCE ||
                      metadataDefinition.type === MetadataType.USER ||
                      metadataDefinition.type === MetadataType.EXTERNAL_KEY_VALUE) {
                      console.log('REFERENCE', metadataDefinition)
                      const key = `${metadataGroup.id}.${metadataDefinition.name}`
                      filteredOptions.set(key, [])
                      filteredOptionsGroupMapping.set(key, metadataDefinition)
                  }
              }
              if (targetId) {
                  subgroup['targetType'] = metadataGroup.attachmentType
                  subgroup['targetId'] = targetId
              }
              group[metadataGroup.id] = fb.group(subgroup)
          }
      }
  }

  let metadataForm = fb.group(group)

  console.log('setting autocomplete listeners')
  for (const key of filteredOptions.keys()) {
    const field = metadataForm.get(key)!
    console.log('key', key, field)
    filteredOptions.set(key, field.valueChanges
      .pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(val => {
          console.log('val', val)
          const definition = filteredOptionsGroupMapping.get(key)!
          console.log('definition', definition)
          if (definition.type === 'USER') {
            const stringVal = typeof val === 'string' ? val : (val as User).email!
            const r = filterUser(userService, stringVal)
            console.log('r', r)
            return r
          } else if (definition.type === 'EXTERNAL_KEY_VALUE') {
            const r = filterExternalKeyValue(metadataService, definition.externalEndpointURL!, externalId!, val)
            console.log('r', r)
            return r            
          } else {
            const r = filterMetadataReference(metadataService, val + '' || '', definition.referencedMetadataGroup!.id!)
            console.log('r', r)
            return r
          }
        })
      ))
  }    
  return metadataForm
}
