import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { cloneDeep } from 'lodash';
import { UserTask, UserTaskType } from '../models/usertask';
import { MetadataType } from '../models/metadata';

@Injectable({ providedIn: 'root' })
export class UserTaskService {
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient) { }

  persistUserTask<UserTask>(task: any): Observable<UserTask> {
    const url = `/api/usertasks`;
    const taskToPersist = cloneDeep(task) as any;
    if (taskToPersist.assignedTo) {
      taskToPersist.assignedTo = { id: task.assignedTo?.id };
    }
    if (taskToPersist.mediaStream) {
      taskToPersist.mediaStream = { id: task.mediaStream?.id };
    }
    
    for (let assignability of taskToPersist.assignableTo ?? []) {
      if (assignability.metadata!.type == MetadataType.REFERENCE && assignability.value) {
        // they're containing the whole object
        assignability.value = (assignability.value as any).id
        assignability.metadata!.referencedMetadataGroup = undefined
      }
    }        
    return this.http.post<UserTask>(url, taskToPersist)
      .pipe(
        catchError(this.handleError<UserTask>(`persistUserTask`))
      );
  }

  persistUserTaskType<UserTaskType>(task: any): Observable<UserTaskType> {
    const url = `/api/usertasktypes`;
    const taskToPersist = cloneDeep(task) as any;
    if (task.assignedTo) {
      taskToPersist.assignedTo = { id: task.assignedTo?.id };
    }
    
    return this.http.post<UserTaskType>(url, taskToPersist)
      .pipe(
        catchError(this.handleError<UserTaskType>(`persistUserTaskType`))
      );
  }

  deleteUserTask(id: number) {
    return this.http.delete<void>(`/api/usertasks/${id}`, {})
      .pipe(
        tap(_ => {}),
        catchError(this.handleError<void>('deleteUserTask'))
      );
  }

  deleteUserTaskType(id: number) {
    return this.http.delete<void>(`/api/usertasktypes/${id}`, {})
      .pipe(
        tap(_ => {}),
        catchError(this.handleError<void>('deleteUserTaskType'))
      );
  }

  getUserTasksForMedia(mediaId:number): Observable<Page<UserTask>> {
    return this.http.get<Page<UserTask>>(`/api/media/${mediaId}/usertasks?sort=createdAt,asc`)
      .pipe(
        catchError(this.handleError<Page<UserTask>>('getUserTasksForMedia', {}))
      );
  }

  getUserTaskTypes(): Observable<Page<UserTaskType>> {
    return this.http.get<Page<UserTaskType>>(`/api/usertasktypes?sort=id,asc`)
      .pipe(
        catchError(this.handleError<Page<UserTaskType>>('getUserTaskTypes', {}))
      );
  }

  getUserTasksForUser(userId:number): Observable<Page<UserTask>> {
    return this.http.get<Page<UserTask>>(`/api/usertasks?sort=createdAt,asc`)
      .pipe(
        catchError(this.handleError<Page<UserTask>>('getUserTasksForUser', {}))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
