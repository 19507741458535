import i18next from "i18next"

i18next.on('initialized', () => {
  dynamicMetadataValues.currentUserDefinition.email = i18next.t('currentUser')
  dynamicMetadataValues.currentUserReferencedValueDefinition.representation = i18next.t('fromCurrentUser')
  dynamicMetadataValues.currentMediaReferencedValueDefinition.representation = i18next.t('fromCurrentMedia')
  dynamicMetadataValues.currentTargetReferencedValueDefinition.representation = i18next.t('fromCurrentTarget')
})

export const dynamicMetadataValues ={
  currentUserDefinition: {
    meta: true,
    currentUser: true,
    tag: '||currentUser||',
    email: i18next.t('currentUser')
  },
  currentUserReferencedValueDefinition: {
    meta: true,
    currentUser: true,
    tag: '||currentUser||',
    representation: i18next.t('fromCurrentUser')
  },
  currentTargetReferencedValueDefinition: {
    meta: true,
    currentMedia: true,
    tag: '||currentTarget||',
    representation: i18next.t('fromCurrentTarget')
  },
  currentMediaReferencedValueDefinition: {
    meta: true,
    currentMedia: true,
    tag: '||currentMedia||',
    representation: i18next.t('fromCurrentMedia')
  },
}
