<h1 mat-dialog-title>{{"partnerUpload.request" | i18next}}</h1>
<mat-dialog-content>
  <p>{{"partnerUpload.select_partner" | i18next}}</p>

  <mat-form-field appearance="fill">

    <mat-label>{{"media_upload.partner" | i18next }}</mat-label>

    <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput [formControl]="form.partner" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPartnerAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.email}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>    

  <mat-form-field appearance="fill">
    <mat-label>{{"media_upload.qc" | i18next}}</mat-label>
    <mat-select [formControl]="form.qc">
      <mat-option *ngFor="let qc of qcs" [value]="qc">
        {{ qc.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <p>Add a message for him</p>
  
  <mat-form-field appearance="fill">
    <mat-label>Message</mat-label>
    <textarea matInput [formControl]="form.message"></textarea>
  </mat-form-field>

  <mat-checkbox matInput style="margin-bottom: 20px" [formControl]="form.allowStreaming">{{"media_upload.allow_streaming" | i18next }}</mat-checkbox>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"partnerUpload.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="form" cdkFocusInitial>{{"partnerUpload.ok" | i18next}}</button>
  </div>  
</mat-dialog-content>