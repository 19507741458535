import { Injectable } from '@angular/core';

import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SimpleUploaderService {

  constructor(private http: HttpClient,
    private userService:UserService) { }

  uploadMediaFile(mediaId:number, file:File) { 
    return this.uploadFileToURL(`/api/media/${mediaId}/upload`, file)
  }

  uploadProfilePicture(file:File) { 
    return this.uploadFileToURL(`/api/user/profilePicture`, file)
  }

  uploadFileToURL(url:string, file:File) { 
    const form = new FormData();
    form.append('file', file);

    const req = new HttpRequest(
      'POST',
      url,
      form,
      {
        reportProgress: true,
        headers: new HttpHeaders({
          "Authorization": `Bearer ${this.userService.currentUser!.accessToken}`
         })        
      })
    return this.http.request(req)
  }
}