import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Location } from 'src/app/models/media';
import { LocationService } from "src/app/services/location.service";

export interface MoveMediaDialogData {
    locationId: string;
    locations?: Location[];
    copy: boolean;
    onlyCurrentVersion: boolean;
    noVersion: boolean;
}

@Component({
    selector: 'move-media-dialog',
    templateUrl: './move.media.dialog.html',
})
export class MoveMediaDialog {
    constructor(
        public dialogRef: MatDialogRef<MoveMediaDialog>,
        private locationService:LocationService,
        @Inject(MAT_DIALOG_DATA) public data: MoveMediaDialogData,
    ) { }

    ngOnInit() {
        this.locationService.getLocations().subscribe(locations => {
            this.data.locations = locations.content
        })        
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
