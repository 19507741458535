import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Page } from 'src/app/models/page';
import { PartnerUploadService } from 'src/app/services/partner.upload.service';
import { S3UploaderService } from 'src/app/services/s3uploader.service';
import { UploadEvent, UploadEventType, UploadRequest, UploadRequestStatus } from 'src/app/models/upload.request';
import { MediaStream } from 'src/app/models/media';
import i18next, { i18n } from 'i18next';
import { Observable, Subscription, concatAll, delay, from, interval, last, lastValueFrom, map, mergeMap, of, pipe, tap } from 'rxjs';
import { el } from 'date-fns/locale';
import { Router } from '@angular/router';
import { MetadataService } from 'src/app/services/metadata.service';
import { prepareForm } from 'src/app/components/metadata.display/metadata.display.component';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder } from '@angular/forms';
import { UserMetadataDialog } from 'src/app/components/dialogs/user.metadatas.dialog';
import { MetadataAttachmentType, MetadataGroup, MetadataType } from 'src/app/models/metadata';
import { MediaInfoFields } from 'src/app/models/mediainfo.fields';

declare var MediaInfo:any;

enum UploadSessionStatus {
  INITIAL = 'INITIAL',
  ANALYZING = 'ANALYZING',
  ANALYZED = 'ANALYZED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
}

export interface UploadDialogData {
  upload: UploadRequest;
}

@Component({
  selector: 'app-partner-upload',
  templateUrl: './partner-upload.component.html',
  styleUrls: ['./partner-upload.component.sass']
})
export class PartnerUploadComponent {
  loading: boolean = true;
  result: Page<UploadRequest> = {};
  dataSource: UploadRequest[] = [];
  columnsToDisplay: string[] = ['id', 'media', 'status', 'creationDate', 'forPartner', 'tools' ]
  dateFormat = i18next.t('global.dateTimeFormat')
  filters = [UploadRequestStatus.PENDING, UploadRequestStatus.UPLOADED]
  filterBy = UploadRequestStatus.PENDING

  constructor(private partnerUploadService: PartnerUploadService,
              private router: Router,
              public dialog: MatDialog) {
  }


  ngOnInit(): void {
    this.getPartnerUploads();
  }

  openMedia(event:MouseEvent, element:UploadRequest) {
    event.preventDefault()
    event.stopImmediatePropagation()
    console.log(element)
    this.router.navigate(['media/' + element.media.id]);    
  }
  
  getPartnerUploads(): void {
    this.loading = true
    this.partnerUploadService.getUploadRequestsForConnectedUser(this.filterBy)
      .subscribe(result => {
        this.result = result
        this.dataSource = this.result.content ?? []
        this.loading = false
      });
  }

  clickedRow(row:UploadRequest): void {

    if (row.status !== UploadRequestStatus.PENDING) {
      return
    }

    console.log('openUploadDialog');

    const dialogRef = this.dialog.open(UploadDialog, {
      data: {
        upload: row
      },
      minWidth: '80vw',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(path => {
      console.log('The dialog was closed', path);
      this.getPartnerUploads()
    });        
  }

}

@Component({
  selector: 'dialog-upload',
  templateUrl: './dialog-upload.html',
  styleUrls: ['./dialog-upload.sass']
})
export class UploadDialog {

  expectedStreamsPerType: Map<string, MediaStream[]> = new Map();
  trackErrors: Map<string, string> = new Map();
  mappableTracks: Map<string, any[]> = new Map();
  generalTracks: Map<string, any[]> = new Map();
  mappableMediaInfo: Map<string, string> = new Map();
  analyzedFiles: File[] = []
  pendingFiles: FileList | undefined;
  currentFileIndex: number = 0;
  i18next = i18next
  metadataForm:any
  metadataTypes:typeof MetadataType = MetadataType
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType
  metadataGroups: MetadataGroup[] = []
  
  constructor(
    public dialogRef: MatDialogRef<UploadDialog>,
    private s3uploaderService: S3UploaderService,
    private partnerUploadService: PartnerUploadService,
    private metadataService: MetadataService,
    private userService: UserService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  describeStream(index:number, track:any) {
    console.log('————— ', track['Format'], track)

    const stream:MediaStream = {}
    stream.type = track['@type'] === 'Video' ? 'VIDEO' : 
      track['@type'] === 'Audio' ? 'AUDIO' :
      track['@type'] === 'Text' && track['Format'] !== 'PDF' ? 'SUBTITLE' :
      'SPARE_FILE';

    const trackDescription = `${index}-${track['@type']}-${track['StreamOrder']}`
    if (this.mediaExpectedStreamDescription.has(trackDescription)) {
      stream.dynamicMetadatas = this.mediaExpectedStreamDescription.get(trackDescription)
    }
    this.openUserMetadataDialog(stream, trackDescription)
  }

  openUserMetadataDialog(element:MediaStream, trackDescription:string): void {
    console.log('openUserMetadataDialog', trackDescription);

    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = element.type === 'SPARE_FILE' ? 
      this.metadataGroups?.filter(g => g.attachmentType === 'SPARE_FILE') :
      this.metadataGroups?.filter(g => g.attachmentType === 'MEDIA_STREAM')
 
    this.metadataForm = prepareForm(this.metadataService,
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      element.dynamicMetadatas,
      element.id,
      element.type,
      true);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log('dialog value: ', result.value);
        this.mediaExpectedStreamDescription.set(trackDescription, result.value)
      }
    });
  }    

  getMetadataGroups(): void {
    this.metadataService.getMetadataGroups()
      .subscribe(metadataGroups => {
        this.metadataGroups = metadataGroups.content!
          .filter(g => g.attachmentType == 'MEDIA_STREAM' || g.attachmentType === 'SPARE_FILE')!
      })
  }

  checkPendingFiles() {
    console.log('checkPendingFiles()')
    for (const file of this.pendingFiles!) {
      console.log('file', file)
      if (file.name === 'ASSETMAP.xml') {
        this.data.upload.imf = true
      }
    }
    console.log('checkPendingFiles() ended')
  }

  ngOnInit(): void {
    console.log(this.data.upload)
    this.getMetadataGroups()

    setTimeout(() => {
      const dropArea = document.getElementById('drop') as HTMLElement
      console.log('dropArea', dropArea)
      dropArea.addEventListener('dragover', (ev) => {
        ev.preventDefault()
        dropArea.classList.add('dragover')
      })
      dropArea.addEventListener('dragenter', (ev) => {
        dropArea.classList.add('dragover')
      })
      dropArea.addEventListener('dragleave', (ev) => {
        dropArea.classList.remove('dragover')
      })
      dropArea.addEventListener('drop', async (ev) => {
        ev.preventDefault()
        dropArea.classList.remove('dragover')
        console.log('drop', ev.dataTransfer?.files, ev)
        if (ev.dataTransfer?.files.length) {
          this.pendingFiles = ev.dataTransfer?.files!;
          this.checkPendingFiles()
          for (const file of this.pendingFiles) {
            this.file = file
            await this.prepareUpload()
          }
        }
      })
    }, 100)

    MediaInfo(
      {
        format: 'JSON',
        locateFile: (path:any, prefix:any) => prefix + path, // Make sure WASM file is loaded from CDN location
      },
      (mediainfo:any) => {
        console.log('media', mediainfo)
        this.mediaInfo = mediainfo
      }
    )
  }
  
  mediaInfo:any
  status: UploadSessionStatus = UploadSessionStatus.INITIAL
  progress:string = '0'
  analyzeProgress:number = 0
  uploadSessionStatus = UploadSessionStatus
  analyzedMedia:any
  mediaExpectedStreamMapping = new Map<string, any>()
  mediaExpectedStreamDescription = new Map<string, any>()
  file: File | undefined;

  async uploadFile(event: Event) {
    const content = document.getElementById('content')
    content!.style.maxHeight = '480px'
    this.status = UploadSessionStatus.ANALYZING
    const element = event.currentTarget as HTMLInputElement;
    this.pendingFiles = element.files!;
    this.checkPendingFiles()
    if (element.files?.length) {
      this.pendingFiles = element.files;
      for (const file of this.pendingFiles) {
        this.file = file
        await this.prepareUpload()
      }
    }
  }

  async prepareUpload() {

    console.log(new Date().toISOString(), "prepareUpload()")
    const getSize = () => this.file!.size
  
    const readChunk = (chunkSize:number, offset:number) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event:any) => {
          if (event.target.error) {
            reject(event.target.error)
          }
          resolve(new Uint8Array(event.target.result))
        }
        reader.readAsArrayBuffer(this.file!.slice(offset, offset + chunkSize))
        // console.log(new Date().toISOString(), "readChunk()", offset, this.file!.size, offset/this.file!.size)
        this.analyzeProgress = offset/this.file!.size
        this.status = UploadSessionStatus.ANALYZING
      })

    const extension = this.file!.name.split(".").pop()?.toLowerCase()
    if (extension === "wav") {
      // hardcoded for now for performance reasons
      this.analyzedMedia = {
        "creatingLibrary": {
          "name": "OpenMAM",
          "version": "0.1"
        },
        "media": {
          "@ref": "",
          "track": [
            {
              "@type": "General",
              "AudioCount": "1",
              "Format": "Wave"
            },
            {
              "@type": "Audio",
              "Format": "PCM",
            }
          ]
        }
      }
    } else {
      const result = await this.mediaInfo.analyzeData(getSize, readChunk)
      console.log(new Date().toISOString(), "analyzeData()")
      this.analyzedMedia = JSON.parse(result)
      console.log(JSON.stringify(this.analyzedMedia, null, "  "))
      console.log(this.analyzedFiles.length, ' == ', this.pendingFiles?.length)
    }
    this.setMappableTracks(this.file!)
    this.refreshExpectedStreams()
    if (this.analyzedFiles.length >= (this.pendingFiles?.length ?? 0)) {
      this.status = UploadSessionStatus.ANALYZED
      this.executeQC()
    }
  }

  executeQC() {
    console.log('executeQC()', this.analyzedFiles)
    if (!this.data.upload.qualityCheck) return
    for (const condition of this.data.upload.qualityCheck.conditions!) {
      const field = condition.metadataDefinition?.extractedFrom?.substring(12)! as MediaInfoFields
      const trackToCheck = MediaInfoFields.trackToCheckFor(field)
      const fieldToCheck = MediaInfoFields.fieldToCheckFor(field)
      let i = 0
      for (const file of this.analyzedFiles) {
        const tracks = [...this.mappableTracks.get(file.name)!, ...this.generalTracks.get(file.name)!]
        for (const track of tracks) {
          console.log(track)
          if (track['@type'] !== trackToCheck) continue
          const value = MediaInfoFields.castValue(field, track[fieldToCheck])
          const goalValue = MediaInfoFields.castValue(field, condition.metadataValue)
          console.log('value', value)
          let conditionMet = false
          if (condition.operator === 'EQUAL' && value === goalValue) {
            conditionMet = true
          } else if (condition.operator === 'GREATER' && value > goalValue) {
            conditionMet = true
          } else if (condition.operator === 'LESS' && value < goalValue) {
            conditionMet = true
          }
          if (!conditionMet) {
            const operator = i18next.t('partnerUpload.conditionNotMetOperators.' + condition.operator)
            const qc = this.data.upload.qualityCheck.name
            const error = i18next.t('partnerUpload.conditionNotMet', { key: fieldToCheck, operator, qc, value, goalValue })
            // const trackKey = track['@type'] === 'General' ? 'General' : track['StreamOrder']
            this.trackErrors.set(`${i}-${track['@type']}-${track['StreamOrder'] ?? '0'}`, error)            
          }
        }
        i++
      }
    }
  }

  async triggerUpload(skipUpload:boolean=false) {
    this.status = UploadSessionStatus.UPLOADING
    /*
    const letters = of('a', 'b', 'c', 'd', 'e', 'f', 'g', 'h');
    await lastValueFrom(letters.pipe(
      mergeMap((event, index) => 
        of([event, index]).pipe(delay(1000 + Math.random()*5000))
      , 2)
    ).pipe(tap(a => console.log('a', a))))
    
    console.log('a')
    */
   
    let url = this.dialogRef.componentInstance.data.upload.presignedUploadURL
    this.currentFileIndex = 0
    for (const currentFile of this.analyzedFiles) {
      console.log("FileUpload -> files", currentFile);
      this.progress = '0'
      if (skipUpload) {
        await this.endUpload(currentFile, skipUpload)
        this.currentFileIndex++
      } else {
        const CONCURRENCY = 2
        await new Promise( resolve => setTimeout(resolve, 300) );
        await new Promise(async (resolve) => {
          let currentLoaded = 0
          const observables = from(this.s3uploaderService.uploadfileAWSS3(
            this.data.upload, 
            currentFile))
          await lastValueFrom(observables.pipe(
            mergeMap((event, _) => 
              event.pipe()
            , CONCURRENCY)
          ).pipe(tap(async (event:any) => {
            console.log('uploadfiles3', event)
            if (event.type === UploadEventType.PROGRESS) {
              currentLoaded = event.value
              console.log('progress comp', currentLoaded, currentFile.size)
              this.progress = (currentLoaded/currentFile.size*100.0).toFixed(2)
            } else if (event.type === UploadEventType.ENDED) {
              console.log('----> ended')
              url = await this.endUpload(currentFile, skipUpload)                   

              this.currentFileIndex++
              this.progress = '0'
              delete this.data.upload.uploadId 
              // here timeout
              // await new Promise( resolve => setTimeout(resolve, 2000) );                
              resolve(undefined)
            }
          })))
        })
      }
    }    
  }

  async endUpload(currentFile:File, uploadSkipped:boolean) {
    const mappingData:{[key: string]: number} = {}
    const mappingDescriptionData:{[key: string]: number} = {}
    this.mediaExpectedStreamMapping.forEach((value:any, key:string) => {
      const index = key.substring(0, key.indexOf('-'))
      const relativeKey = key.substring(key.indexOf('-')+1)
      if (index == this.currentFileIndex.toString())
        mappingData[relativeKey] = value.id
    })
    this.mediaExpectedStreamDescription.forEach((value:any, key:string) => {
      const index = key.substring(0, key.indexOf('-'))
      const relativeKey = key.substring(key.indexOf('-')+1)
      if (index == this.currentFileIndex.toString())
      mappingDescriptionData[relativeKey] = value
    })
    console.log('mappingData', mappingData)
    console.log('mappingDescriptionData', mappingDescriptionData)
    const status = uploadSkipped ? UploadRequestStatus.PENDING_3RD_PARTY_UPLOAD : UploadRequestStatus.UPLOADED
    let presignedURL = ''
    // here timeout
    // await new Promise( resolve => setTimeout(resolve, 2000) );
    await new Promise<void>(async (resolve) => {
      this.partnerUploadService.updateUploadRequestStatus(this.data.upload.id, status, {
          mapping: mappingData,
          mappingDescription: mappingDescriptionData,
          filename: currentFile.name,
          imf: this.data.upload.imf,
          filesize: currentFile.size,
          mediaInfo: this.mappableMediaInfo.get(currentFile.name)
        })
        .subscribe((r:any) => {
          if (this.analyzedFiles.length == this.currentFileIndex+1) {
            this.status = UploadSessionStatus.UPLOADED
          }
          presignedURL = r.presignedUploadURL
          resolve()
        })       
    })
    return presignedURL
  }

  refreshExpectedStreams() {
    const types = ['Video', 'Audio', 'Text', 'SPARE_FILE']
    for (const type of types) {
      this.expectedStreamsPerType.set(type, this.getExpectedStreamOfType(type))
    }
  }

  getExpectedStreamOfType(type:string) {
    const streamType = type === 'Video' ? 'VIDEO' : 
      type === 'Audio' ? 'AUDIO' :
      type === 'Text' ? 'SUBTITLE' : 'SPARE_FILE'
    return this.data.upload.expectedStreams.filter(es => es.type === streamType)
  }

  setMappableTracks(file:File) {
    // console.log(this.analyzedMedia.media)
    const r = this.analyzedMedia.media.track
      .filter((t:any) => t['@type'] === 'Video' || t['@type'] === 'Audio' || 
        (t['@type'] === 'Text' && t['Format'] !== 'PDF'))
    
    let i = 0;
    const tracks = []
    for (let track of r) {
      if (!track['StreamOrder']) track['StreamOrder'] = i
      tracks.push(track)
      i++;
    }
    this.mappableTracks.set(file.name, tracks)
    this.mappableMediaInfo.set(file.name, this.analyzedMedia)

    const generalTracks = this.analyzedMedia.media.track.filter((t:any) => t['@type'] === 'General')
    this.generalTracks.set(file.name, generalTracks ?? [])

    this.analyzedFiles.push(file)
  }

}

