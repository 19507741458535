import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Configuration } from '../models/configuration';
import { UserActionDefinition } from '../models/user.action';
import { Media } from '../models/media';
import { User } from '../models/user';
import { MetadataAttachmentType, MetadataDefinition } from '../models/metadata';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class UserActionsService {
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }
  
  deleteAction(id: number) {
    return this.http.delete<void>(`/api/userActions/${id}`, {})
      .pipe(
        tap(_ => {}),
        catchError(this.errorService.handleError<void>('UserActionDefinition'))
      );
  }
  
  save(action: UserActionDefinition) {
    let url = `/api/userActions`
    if (action.id) url += `/${action.id}`
    const method = action.id ? 'PUT' : 'POST'

    for (const c of action.conditions ?? []) {
      const metadata:MetadataDefinition = {...c.metadata!}
      metadata.referencedMetadataGroupEntity = undefined    
      c.metadata = metadata
      if (c.value === '') c.unset = true
    }

    for (const c of action.consequences ?? []) {
      const metadata:MetadataDefinition = {...c.metadata!}
      metadata.referencedMetadataGroupEntity = undefined    
      c.metadata = metadata
      if (c.value === '') c.unset = true
    }

    return this.http.request<void>(method, url, {body: action})
      .pipe(
        catchError(this.errorService.handleError<void>('UserActionDefinition'))
      );
  }
  
  getAction(actionId: number):Observable<UserActionDefinition> {
    let url = `/api/userActions/${actionId}`
    return this.http.get<UserActionDefinition>(url)
      .pipe(
        catchError(this.errorService.handleError<UserActionDefinition>('UserActionDefinition'))
      );
  }
  
  getActions(page:number=0): Observable<Page<UserActionDefinition>> {
    let url = `/api/userActions?page=${page}&sort=creationDate,desc`
    return this.http.get<Page<UserActionDefinition>>(url)
      .pipe(
        catchError(this.errorService.handleError<Page<UserActionDefinition>>('UserActionDefinition', {}))
      );
  }

  getAvailableActionsFor(type:MetadataAttachmentType, id:number): Observable<UserActionDefinition[]> {
    return this.http.get<UserActionDefinition[]>(`/api/userActions/matching/${type}/${id}`)
      .pipe(
        tap(_ => {}),
        catchError(this.errorService.handleError<UserActionDefinition[]>('UserActionDefinition', []))
      );
  }

  executeAction(action: UserActionDefinition, type:MetadataAttachmentType, id:number) {
    return this.http.post<void>(`/api/userAction/${action.id}/execute/on/${type}/${id}`, {})
      .pipe(
        tap(_ => {}),
        catchError(this.errorService.handleError<void>('UserActionDefinition'))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
