import { Component, Input } from '@angular/core';
import { format } from 'date-fns';
import i18next from 'i18next';
import { HistoryEntry, Operation } from 'src/app/models/history';
import { Media, MediaStream } from 'src/app/models/media';
import { MetadataAttachmentType, MetadataType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { HistoryService } from 'src/app/services/history.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { formatDate } from 'src/app/utils/utils';
import { NotificationBaseComponent } from '../notification.base.component';

@Component({
  selector: 'app-history-viewer',
  templateUrl: './history.viewer.component.html',
  styleUrls: ['./history.viewer.component.sass']
})
export class HistoryViewerComponent extends NotificationBaseComponent {

  @Input() media:Media|undefined;
  data: Page<HistoryEntry> = {};
  loading: boolean = true
  formatDate = formatDate
  
  constructor(
    protected override historyService:HistoryService,
    protected override metadataService:MetadataService
  ) {
    super(historyService, metadataService)
  }

  ngOnInit() {
    this.historyService.getHistory(this.media?.id!).subscribe(r => {
      this.data = r
      const mediaMap = new Map<number,Media>()
      mediaMap.set(this.media!.id!, this.media!)
      this.computeOperations(this.data, mediaMap)
      this.loading = false
    })
  }
}
