<h1 *ngIf="!embedded">{{"tasks.Tasks" | i18next}} </h1>

<div class="buttons" *ngIf="!embedded">
  <button mat-fab (click)="previousPage()">
      <mat-icon>chevron_left</mat-icon>
  </button>
  <button mat-fab (click)="nextPage()">
      <mat-icon>chevron_right</mat-icon>
  </button>
</div>   

<div *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<mat-menu #toolsMenu="matMenu" style="margin-top: 5px">
  <ng-template matMenuContent let-task="task">
    <button mat-menu-item (click)="copyClipboard(task)">ID: {{task.id}}</button>
    <button mat-menu-item (click)="restart(task)">{{ "tasks.restart" | i18next }}</button>
    <button mat-menu-item (click)="cancel(task)">{{ "tasks.cancel" | i18next }}</button>
    <button mat-menu-item (click)="openError(task)">{{ "tasks.logs" | i18next }}</button>
    <button mat-menu-item (click)="openAdditionalProperties(task)">{{ "tasks.additionalProperties" | i18next }}</button>
  </ng-template>
</mat-menu>

<table mat-table [dataSource]="dataSource" [class.table-responsive]="!embedded" [class.mat-elevation-z8]="!embedded"
       *ngIf="!loading && dataSource.length" style="margin-bottom: 50px;">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.id" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef> 
        
      </th>
      <td mat-cell *matCellDef="let element"> 
        <a mat-icon-button (menuOpened)="menuOpened()" (menuClosed)="menuClosed()" [matMenuTriggerFor]="toolsMenu" [matMenuTriggerData]="{task: element}">
          <mat-icon>more_vert</mat-icon>
        </a>        
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.type" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.type}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="mediaElement">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.element" | i18next }} </th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.mediaElement?.filename">{{element.mediaElement?.filename}} </td>
    </ng-container>

    <ng-container matColumnDef="bandwidth">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.bandwidth" | i18next }} </th>
      <td mat-cell *matCellDef="let element" style="text-align: right">
        <span *ngIf="element.bytesPerSecond">{{element.bytesPerSecond | formatBytes}}/s</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="lockedBy">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.locked" | i18next }} </th>
      <td mat-cell *matCellDef="let element" class="monospaced"> {{element.lockedBy}} </td>
    </ng-container>    
    
    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.created" | i18next }} </th>
      <td mat-cell *matCellDef="let element">
        <span class="pointer" [matTooltip]="(element.creationDate | date: 'yyyy/MM/dd hh:mm:ss') ?? ''">
          {{element.creationDate! | formatDate}}
        </span>
      </td>
    </ng-container>    
    
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.started" | i18next }} </th>
      <td mat-cell *matCellDef="let element">
        <span class="pointer" [matTooltip]="(element.startDate | date: 'yyyy/MM/dd hh:mm:ss') ?? ''">
          {{element.startDate! | formatDate}}
        </span>
      </td>
    </ng-container>    

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.progress" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> 
        <div class="progress" [matTooltip]="(element.progress ?? 0) + '%'">
          <div [ngStyle]="{width: (element.progress ?? 0) + '%'}"></div>
        </div>
      </td>
    </ng-container>    
    
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.duration" | i18next }} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.status === 'WORKING'">
          {{eta(element)}}
        </span>
        <span class="pointer" [matTooltip]="(element.endDate | date: 'yyyy/MM/dd hh:mm:ss') ?? ''" *ngIf="element.status !== 'WORKING'">
          {{formatDate(element.endDate ?? now(), parseISO(element.startDate ?? element.creationDate!), false)}}
        </span>
      </td>
    </ng-container>    

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ "tasks.status" | i18next }} </th>
      <td mat-cell *matCellDef="let element">
        <span class="code">
          {{element.status}}
          <a (click)="openError(element)" matTooltip="Display error detail">
            <mat-icon *ngIf="element.status === 'FAILED'">warning</mat-icon>
          </a>
        </span>
      </td>
    </ng-container>    
    
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>

<mat-card *ngIf="!loading && !dataSource.length">
  <mat-card-content>
      {{ 'tasks.empty' | i18next }}
  </mat-card-content>
</mat-card>
