import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormulaRenderer } from '../formula.renderer.interface';

@Component({
  selector: 'app-progress-renderer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './progress.renderer.component.html',
  styleUrls: ['./progress.renderer.component.sass']
})
export class ProgressRendererComponent implements FormulaRenderer {
  
  data: any;
  total: number = 0;
  Object = Object;

  ngOnInit() {
    console.log('ProgressRendererComponent', this.data)

    for (const d of this.data) {
      this.total += d.count
    }
    
  }

}
