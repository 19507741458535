import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { Search } from '../models/search';
import { Page } from '../models/page';
import {ErrorService} from "./error.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  searchUrl: string = '/api/search';

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  getSavedSearchById(id:number): Observable<Search> {
    return this.http.get<Search>(`${this.searchUrl}/${id}`)
      .pipe(
        catchError(this.errorService.handleError<Search>('getSavedSearch', {}))
      );
  }

  getSavedSearch(): Observable<Page<Search>> {
    return this.http.get<Page<Search>>(`${this.searchUrl}/my`)
      .pipe(
        catchError(this.errorService.handleError<Page<Search>>('getSavedSearch', {}))
      );
  }

  persistSearch<Data>(search:Search): Observable<Data> {
    const url = `${this.searchUrl}`;
    search.deleted = false
    return this.http.post<Data>(url, search)
      .pipe(
        catchError(this.errorService.handleError<Data>(`persistSearch search=${search}`))
      );
  }  

  deleteSavedSearch(id: number) {
    const url = `${this.searchUrl}/${id}/delete`;
    return this.http.post<Search>(url, null)
      .pipe(
        catchError(this.errorService.handleError<Search>(`deleteSavedSearch search=${id}`))
      );
  }

  private log(message: string) {
    console.log(`TaskService: ${message}`);
  }
}
