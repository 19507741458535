import { FFProbeFields } from "./ffprobe.fields";

export interface MetadataDefinitionAndValue {
    definition?: MetadataDefinition;
    value?: any;
}

export interface MetadataDefinition {
    id?: number;
    name: string;
    label?: string;
    formula?: string;
    defaultValue?: string;
    extractedFrom?: string;
    orderNumber?: number;
    searchable?: boolean;
    representative?: boolean;
    columnWidth?: number;
    externalEndpointURL?: string;
    type?: MetadataType;
    displayIfEmpty?:boolean;
    allowedValues?: string[];
    referencedMetadataGroup?: IdAndToString;
    referencedMetadataGroupEntity?: MetadataGroup;
    renderingComponent?: string;
    displayRestrictedToRoles?: string[];
    editingRestrictedToRoles?: string[];
    addReferencesRestrictedToRoles?: string[];
    displayRestrictedToMediaStreamTypes?: string[];
    parentGroup?: MetadataGroup;    
    workflowStepToTrigger?: string;
    translated?: boolean;
}

export interface IdAndToString {
    id?: number;
    value?: string;
}
export interface MetadataGroup {
    id: number;
    name?: string;
    metadatas?: MetadataDefinition[];
    attachmentType?:MetadataAttachmentType;
    displayGrouped?:boolean;
    displayCollapsed?:boolean;
    externalDatasource?:boolean;
    system?:boolean;
    externalDatasourceURL?:string;
}
export interface MetadataReference {
    id: number;
    representation?: string;
    dynamicMetadatas?: any;
}
export enum MetadataType {
    TEXT = 'TEXT',
    LONG_TEXT = 'LONG_TEXT',
    TIMECODE = 'TIMECODE',
    LINK = 'LINK',
    IMAGE = 'IMAGE',
    BOOLEAN = 'BOOLEAN',
    USER = 'USER',
    DATE = 'DATE',
    MULTI_VALUED = 'MULTI_VALUED',
    MULTI_ENTITIES = 'MULTI_ENTITIES',
    EXTERNAL_KEY_VALUE = 'EXTERNAL_KEY_VALUE',
    EXTRACTED_FROM_STREAM = 'EXTRACTED_FROM_STREAM',
    REFERENCE = 'REFERENCE',
    COMPUTED = 'COMPUTED'
}
export enum MetadataAttachmentType {
    MEDIA = 'MEDIA',
    MEDIA_STREAM = 'MEDIA_STREAM',
    MEDIA_VERSION = 'MEDIA_VERSION',
    REFERENCEABLE = 'REFERENCEABLE',
    SPARE_FILE = 'SPARE_FILE',
    USER = 'USER',
}  