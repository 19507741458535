import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {Privilege, Role, User, UserGroup, UserPreferences} from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Page } from '../models/page';
import { ErrorService } from './error.service';
import {Router} from "@angular/router";

@Injectable({ providedIn: 'root' })
export class UserService {
  
  execChange: Subject<any> = new Subject<User>();
  currentUser:User|undefined;
  currentPreferences:UserPreferences|undefined;
  private userUrl = '/api/users';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
      private http: HttpClient,
      private errorService: ErrorService,
      private router: Router) {
      const auth = localStorage.getItem('auth')
      if (auth && auth != "undefined") {
          this.currentUser = JSON.parse(auth)
          this.userChanged(this.currentUser!)
      }
  }

  userChanged(data: User|undefined) {
    localStorage.setItem('auth', JSON.stringify(data))
    this.execChange.next(data);
  }

  deleteProfilePicture() {
    return this.http.delete<void>(`/api/user/profilePicture`)
    .pipe(
        tap(_ => this.log('fetched users')),
        catchError((error: any): Observable<any> => {
          return of({error})
        })
    );
  }
  
  getUserById(id: number):Observable<User> {
    return this.http.get<User>(`/api/user/${id}`)
      .pipe(
        tap(_ => this.log('fetched userGroups')),
        catchError(this.errorService.handleError<User>('users', {}))
      );
  }
  
  getUserGroups(): Observable<Page<UserGroup>> {
    return this.http.get<Page<UserGroup>>(`/api/userGroups`)
      .pipe(
        tap(_ => this.log('fetched userGroups')),
        catchError(this.errorService.handleError<Page<UserGroup>>('users', {}))
      );
  }

  getUsers(fromGroupId:number|undefined=undefined): Observable<Page<User>> {
    const url = fromGroupId ? 
      `/api/users?sort=id,desc&groupId=${fromGroupId}` : 
      `/api/users?sort=id,desc`
    return this.http.get<Page<User>>(url)
      .pipe(
        tap(_ => this.log('fetched users')),
        catchError(this.errorService.handleError<Page<User>>('users', {}))
      );
  }

  deleteRole(id: number): Observable<any> {
    return this.http.delete<void>(`/api/roles/${id}`)
    .pipe(
        tap(_ => this.log('fetched users')),
        catchError((error: any): Observable<any> => {
          return of({error})
        })
    );
  }

  createInvitationLink(userId: number):Observable<any> {
    return this.http.post<User>(`/api/user/${userId}/invitationLink`, null)
      .pipe(
          tap(_ => this.log('createInvitationLink')),
          catchError(this.errorService.handleError<User>('createInvitationLink'))
      );
  }  

  confirmInvitationLink(payload:{token:string, email:string, password:string}):Observable<void> {
    return this.http.post<void>(`/api/user/validateInvitationLink`, payload)
      .pipe(
          tap(_ => this.log('validateInvitationLink')),
          catchError(this.errorService.handleError<void>('validateInvitationLink'))
      );
  }



  deleteUser(id: number) {
      return this.http.delete<User>(`/api/user/${id}`)
          .pipe(
              tap(_ => this.log('fetched users')),
              catchError(this.errorService.handleError<User>('deleteUser'))
          );
  }

  persistUser<Data>(user:User): Observable<User> {
      const url = `/api/user`;
      return this.http.post<User>(url, user)
          .pipe(
              catchError(this.errorService.handleError<User>(`createUser user=${user}`))
          );
  }

  getAutocompleteData<Data>(roleName:string, prefix:string): Observable<User[]> {
    const url = `/api/users/role/${roleName}?prefix=${prefix}`;
    return this.http.get<User[]>(url)
      .pipe(
        tap(h => {
          const outcome = h ? 'fetched' : 'did not find';
          console.log(`${outcome} media prefix=${prefix}`);
        }),
        catchError(this.errorService.handleError<User[]>(`getAutocompleteData prefix=${prefix}`))
      );
  }

  doLogout() {
    this.currentUser = undefined
    this.userChanged(this.currentUser)
    localStorage.removeItem('auth')
    this.router.navigate(['login']);
  }

  doLogin<Data>(login:string, password:string): Observable<User> {
    const url = `/api/authenticate`;
    return this.http.post<User>(url, {
      login, password
    })
      .pipe(
        catchError(this.errorService.handleError<User>(`doLogin`))
      ).pipe(
        map(result => {
          console.log('userChanged', result)
          this.currentUser = result
          this.userChanged(result)
          localStorage.setItem('auth', JSON.stringify(this.currentUser))
          return result
        })
      );
  }

  changePassword<Data>(userId:number, password:string): Observable<User> {
    const url = `/api/user/${userId}/password`;
    return this.http.post<User>(url, {
      password
    })
    .pipe(
      catchError(this.errorService.handleError<User>(`doLogin`))
    )
  }

  getUserPreferences<Data>(forceRefresh:boolean=false): Observable<UserPreferences> {
    if (this.currentPreferences) return of(this.currentPreferences!)
    const url = `/api/users/me/preferences`;
    return this.http.get<UserPreferences>(url)
      .pipe(
        catchError(this.errorService.handleError<UserPreferences>(`getUserPreferences`))
      ).pipe(
        map(result => {
          this.currentPreferences = result
          return result
        })
      );
  }  

  persistUserMediaColumnsToDisplay(columns: string[]) {
    const url = `/api/users/me/columnsToDisplayOnMedia`;
    return this.http.put<void>(url, columns)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistUserDashboard`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  persistUserExpectedStreamsColumnsToDisplay(columns: string[]) {
    const url = `/api/users/me/columnsToDisplayOnExpectedStreams`;
    return this.http.put<void>(url, columns)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistUserDashboard`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  persistRole(role: Role) {
    const url = `/api/roles`;
    return this.http.put<void>(url, role)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistRole`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  deleteGroup(id: number): Observable<any> {
    return this.http.delete<void>(`/api/userGroups/${id}`)
    .pipe(
        tap(_ => this.log('fetched users')),
        catchError((error: any): Observable<any> => {
          return of({error})
        })
    );
  }
  
  persistGroup(group: UserGroup) {
    const url = `/api/userGroups`;
    return this.http.put<void>(url, group)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistGroup`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  persistDefaultFilterDisabled(defaultFilterDisabled: boolean) {
    const url = `/api/users/me/defaultFilterDisabled`;
    return this.http.put<void>(url, defaultFilterDisabled)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistDefaultFilterDisabled`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  persistUserDashboard<Data>(searchId:number): Observable<void> {
    const url = `/api/users/me/dashboard?searchId=${searchId}`;
    return this.http.put<void>(url, null)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistUserDashboard`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }    

  private log(message: string) {
    console.log(`UserService: ${message}`);
  }

  getPrivileges(): Observable<Privilege[]> {
    const url = `/api/privileges/all`;
    return this.http.get<Privilege[]>(url)
        .pipe(
            catchError(this.errorService.handleError<Privilege[]>(`getPrivileges`))
        ).pipe(
            map(result => {
              return result
            })
        );
  }

  getRoles(): Observable<Role[]> {
    const url = `/api/roles/all`;
    return this.http.get<Role[]>(url)
        .pipe(
            catchError(this.errorService.handleError<Role[]>(`getRoles`))
        ).pipe(
            map(result => {
              return result
            })
        );
  }
}
