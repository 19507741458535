import { Component, ViewChild,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { interval, tap } from 'rxjs';
import { Configuration, VersionInformation } from 'src/app/models/configuration';
import { NotificationLastCheck } from 'src/app/models/notification';
import { User } from 'src/app/models/user';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { intersect } from 'src/app/utils/utils';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent {
  language: string = this.i18next.language;
  languages: string[] = this.getLanguages(Object.assign([], this.i18next.options.supportedLngs));
  currentUser: User | undefined;
  customLogo: string|undefined;
  showBaseline: boolean = true;
  loading:boolean = true;
  notificationsLastCheck:NotificationLastCheck|undefined
  intersect = intersect
  @ViewChild('collapser') collapser:HTMLElement|undefined;
  version: VersionInformation|undefined
  lastNotificationDate: string|undefined
  configurationMenuOpened:boolean = false
  
  constructor(private userService: UserService,
              private configurationService: ConfigurationService,
              private notificationService:NotificationService,
              private route: ActivatedRoute, 
              private router:Router,
              @Inject(I18NEXT_SERVICE) private i18next: ITranslationService
             ) {
    this.currentUser = userService.currentUser
    console.log('current user menu', this.currentUser)
    userService.execChange.subscribe((value) => {
      console.log('menu', this.currentUser)
      this.currentUser = value
      this.setRoles()
    })
    
    this.notificationService.getNotificationsLastCheck().subscribe(c => this.notificationsLastCheck = c)
    interval(10000).subscribe( _ => {
      if (this.currentUser === undefined) return
      this.notificationService.getNotificationsLastCheck().subscribe(c => {
        this.notificationsLastCheck = c
        if (Notification.permission === "granted") {
          console.log('notification check', c?.last?.createdAt, '>', (this.lastNotificationDate ?? '0'))
          if (c && c.last && c.last.createdAt > (this.lastNotificationDate ?? '0')) {
            console.log(this.lastNotificationDate, c.last.createdAt)
            this.lastNotificationDate = c.last.createdAt
            const notification = new Notification(this.i18next.t('notifications.new')!.toString());
            notification.onclick = () => {
              this.router.navigateByUrl('/notifications')
            }
          }
        }
      })
    })
    this.setRoles()

    configurationService.getVersion().subscribe(version => {
      this.version = version
    });

    configurationService.getConfigurations().subscribe(c => {
      this.showBaseline = c.content?.filter(c => c.key == 'SHOW_BASELINE')[0]?.value === 'true' ?? true
      this.customLogo = c.content?.filter(c => c.key == 'CUSTOM_LOGO')[0]?.value
      const customCSS = c.content?.filter(c => c.key == 'CUSTOM_CSS')[0]?.value
      console.log('customCSS', customCSS)
      if (customCSS) {
        var head = document.getElementsByTagName('head')[0];
        var s = document.createElement('style');
        s.setAttribute('type', 'text/css');
        s.appendChild(document.createTextNode(customCSS));
        head.appendChild(s);
        this.loading = false
      }
    })
  }

  ngAfterViewInit() {

    setTimeout(() => {
      for (const l of this.links) {
        if (l.additionalActivePath) {
          l.selected = this.router.isActive(l.additionalActivePath, {
            paths: 'subset', 
            queryParams: 'subset', 
            fragment: 'ignored', 
            matrixParams: 'ignored'
          })                
        }
        if (l.submenu) {
          for (const ll of l.submenu) {
            const check = this.router.isActive(ll.target, {
              paths: 'subset', 
              queryParams: 'subset', 
              fragment: 'ignored', 
              matrixParams: 'ignored'
            })
            // console.log(`check ${ll.target} —> ${check}`)
            if (check) {
              l.opened = true
              break
            }       
          }
        }
      }
    }, 10)
  }

  setRoles() {
    let element = document.getElementById('root')!
    this.currentUser?.roles?.forEach(role => {
      element.classList.add(role)
    });
    this.links.filter(link => link.submenu).forEach(link => {
      link.availableSubmenuCount = link.submenu
        .filter((sublink:any) => intersect(this.currentUser?.privileges, sublink.privileges).length)
        .length
    })
  }

  getLanguages(languages: string[]){
      var toReturn = [];
      for(var lang of languages){
          // cimode est dans la liste des langages de i18next
          // permet d'afficher les mot cle dans la pages
          if(lang === 'cimode'){
              continue;
          }
          toReturn.push(lang);
      }
      return toReturn;
  }
  changeLanguage(lang: string){
    if (lang !== this.i18next.language) {
      this.i18next.changeLanguage(lang).then(x => {
        document.location.reload();
      });
    }
  }

  collapse() {
    console.log('collapse')
    
  }

  unblurAll() {
    (document.activeElement as any).blur()
  }

  changeTheme(event:any) {
    (document.activeElement as any).blur()
    event.preventDefault()
    let element = document.getElementById('root')!
    if (element.className === 'dark-theme')
      element.className = 'light-theme'
    else 
      element.className = 'dark-theme'
    
    localStorage.setItem('currentTheme', element.className)
  }

  logout(event:any) {
    event.preventDefault()
    this.userService.doLogout()
  }

  adminLinks:any[] = [    
    {
      title: this.i18next.t('menu.hooks'),
      icon: 'phishing',
      target: '/hooks',
      privileges: ['MANAGE_HOOKS_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.transcoding_profiles'),
      icon: 'flip_to_front',
      target: '/transcoding_profiles',
      privileges: ['ADMIN_TRANSCODING']
    },
    {
      title: this.i18next.t('menu.qc_profiles'),
      icon: 'checklist',
      target: '/qc_profiles',
      privileges: ['ADMIN_QC_PROFILES']
    },
    {
      title: this.i18next.t('menu.usertasktypes'),
      icon: 'playlist_add_check',
      target: '/usertasktypes',
      privileges: ['ADMIN_TASK_TYPES']
    },
    {
      title: this.i18next.t('menu.translations'),
      icon: 'language',
      target: '/translations',
      privileges: ['ADMIN_TRANSLATIONS']
    },
    {
      title: this.i18next.t('menu.roles'),
      icon: 'security',
      target: '/roles',
      privileges: ['ADMIN_USERS']
    },
    {
      title: this.i18next.t('menu.configuration'),
      icon: 'settings',
      target: '/settings',
      privileges: ['DISPLAY_CONFIGURATION']
    }
  ]

  configurationLinks:any[] = [
    {
      title: this.i18next.t('menu.settings'),
      icon: 'person',
      target: '/me',
      privileges: ['MY_SETTINGS_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.location'),
      icon: 'folder_copy',
      target: '/locations',
      privileges: ['ADMIN_LOCATION_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.metadata'),
      icon: 'extension',
      target: '/metadata_schema',
      privileges: ['ADMIN_METADATA_SCHEMA_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.versions'),
      icon: 'inventory_2',
      target: '/versions_schema',
      privileges: ['ADMIN_VERSION_STRUCTURE']
    },
    {
      title: this.i18next.t('menu.actions'),
      icon: 'rebase_edit',
      target: '/actions',
      privileges: ['ADMIN_USER_ACTIONS']
    },

    {
      title: this.i18next.t('menu.users'),
      icon: 'group',
      target: '/users',
      customRight: () => this.currentUser?.groupAdmin,
      privileges: ['ADMIN_USERS']
    },  
  
    {
      title: this.i18next.t('menu.usergroups'),
      icon: 'groups',
      target: '/usergroups',
      privileges: ['ADMIN_USERS']
    },      
  ]
    
  links:any[] = [{
      title: this.i18next.t('menu.myspace'),
      icon: 'home',
      target: '/home',
      privileges: ['READ_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.notifications'),
      icon: 'notifications',
      target: '/notifications',
      badge: true, 
      badgeAttribute: () => this.notificationsLastCheck?.count,
      privileges: ['READ_PRIVILEGE', 'NOTIFICATIONS_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.dashboard'),
      icon: 'dashboard',
      target: '/dashboard',
      privileges: ['DASHBOARD_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.media'),
      icon: 'play_circle',
      target: '/search',
      additionalActivePath: '/media',
      privileges: ['PROGRAM_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.tasks'),
      icon: 'task',
      target: '/tasks',
      privileges: ['DISPLAY_TASKS_PRIVILEGE']
    },
    {
      title: this.i18next.t('menu.reporting'),
      icon: 'bar_chart',
      target: '/reporting',
      privileges: ['DISPLAY_REPORTING']
    },
    {
      title: this.i18next.t('menu.partnerUpload'),
      icon: 'upload',
      target: '/partner_upload',
      privileges: ['DISPLAY_PARTNER_UPLOAD']
    },
    {
      title: this.i18next.t('menu.configurationMenu'),
      icon: 'tune',
      submenu: this.configurationLinks,
    },  
    
    {
      title: this.i18next.t('menu.admin'),
      icon: 'settings',
      submenu: this.adminLinks,
    },  
    
    {
      title: this.i18next.t('menu.documentation'),
      icon: 'history_edu',
      target: '/documentation/admin',
      privileges: ['DISPLAY_DOCUMENTATION']
    },  
  ]

}
