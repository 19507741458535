import { Media, MediaVersion, MediaStream } from "./media";
import { IdAndToString, MetadataDefinition } from "./metadata";
import { User } from "./user";

export interface UserTaskDefinition {
    id?: number;
    taskType?: UserTaskType;
    assignableTo?: UserTaskMetadataDefinition[];
    assignedTo?: MetadataDefinition;
}

export interface UserTaskType {
    id?: number;
    label?: string;
}

export interface UserTask {
    id?: number;
    label?: string;
    taskType?: UserTaskType;
    assignedTo?: User;
    assignableTo?: UserTaskMetadataDefinition[];
    createdBy?: User;
    dueDate?: string;
    createdAt?: string;
    completedAt?: string;
    media?: Media;
    mediaVersion?: IdAndToString;
    mediaStream?: IdAndToString;
    status?: Status;
}

export interface UserTaskMetadataDefinition {
    id?: number;
    metadata?: MetadataDefinition;
    value?: any;
}

export interface UserTaskType {
    id?: number;
    label?: string;
    createdBy?: string;
    createdAt?: string;
}

export enum Status {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    BLOCKED = 'BLOCKED',
    COMPLETED = 'COMPLETED',
}