import { Dialog } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { messageDialog } from 'src/app/utils/utils';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.sass']
})
export class OnboardComponent {

  email:string = ''
  password:string = ''
  token: string | null = null
  loading:boolean = false
  
  constructor(private route: ActivatedRoute,
    private userService:UserService,
    private dialog:MatDialog) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (!params.has('token')) {
        document.location = "/"
      }
      this.token = params.get('token')
    })
  }

  doOnboard(event:Event) {
    event.stopImmediatePropagation()
    event.stopPropagation()
    this.loading = true
    this.userService.confirmInvitationLink({
      email: this.email,
      token: this.token!,
      password: this.password
    }).subscribe(_ => {
      messageDialog(this.dialog, 
        'onboard.succeeded', 'onboard.succeeded_text', 
        false, () => document.location.href = '/')
    })
  }
}
