<h1>{{"hooks.metadata_schemas.user_defined" | i18next}}</h1>

<div class="buttons">
  <button mat-fab class="add" matTooltip="Add a new group" (click)="openMetadataGroupDialog()">
      <mat-icon>add</mat-icon>
  </button>
</div>

<div class="center-buttons" [class.shown]="orderChanged">
  <button mat-fab extended (click)="saveOrderChange()">
      Save order change
  </button>
</div>

<table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="result?.numberOfElements; else firstLoad" style="margin-bottom: 50px"
       cdkDropList
       [cdkDropListData]="dataSource"
       (cdkDropListDropped)="dropTable($event)">

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.metadata_schema.id" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> 
          <mat-icon class="reorder" style="pointer-events: all;" cdkDragHandle>reorder</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.metadata_schema.name" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="attachmentType">
      <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.metadata_schema.type" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.attachmentType}}</span> </td>
    </ng-container>    
    
    <ng-container matColumnDef="system">
      <th mat-header-cell *matHeaderCellDef> {{"mediaDetail.metadata_schema.system" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> 
        <mat-checkbox class="checkbox" [(ngModel)]="element.system" disabled></mat-checkbox>
      </td>
    </ng-container>    
    
    <tr mat-row (click)="clickedRow(row)" *matRowDef="let row; columns: columnsToDisplay;" cdkDrag [cdkDragData]="row">
      <ng-template cdkDragPreview>
        <div class="drag-placeholder">
          <span>{{row.name}}</span>
        </div>
      </ng-template>      
    </tr>
</table>

<ng-template #firstLoad>
    <div *ngIf="loading; else empty">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #empty>
    <h1>{{"empty.hell" | i18next}}</h1>
    <p>{{"empty.metadatas" | i18next}}</p>
</ng-template>