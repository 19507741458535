import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatBytes' })
export class FormatBytesPipe implements PipeTransform {
    
    transform(value:number): string {
        return this.formatBytes(value);
    }

    formatBytes(bytes:number) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
