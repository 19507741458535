export enum ConfigurationKey {
    TTML_SHIFT = 'TTML_SHIFT',
    DISABLE_HLS_SUBTITLES = 'DISABLE_HLS_SUBTITLES',
    FIRST_FRAME_TIMECODE_METADATA_LOCATION = 'FIRST_FRAME_TIMECODE_METADATA_LOCATION',
    FORCED_TIMECODE_OFFSET = 'FORCED_TIMECODE_OFFSET',
    BASE_URL = 'BASE_URL',
    CUSTOM_LOGO = 'CUSTOM_LOGO',
    SHOW_BASELINE = 'SHOW_BASELINE',
    CUSTOM_CSS = 'CUSTOM_CSS',
    EXTERNAL_IMPORT_ENDPOINT = 'EXTERNAL_IMPORT_ENDPOINT',
    PARTNER_UPLOAD_LOCATION = 'PARTNER_UPLOAD_LOCATION',
    ACTIVITI_MEDIA_WORKFLOW_KEY = 'ACTIVITI_MEDIA_WORKFLOW_KEY',
    ACTIVITI_VERSION_WORKFLOW_KEY = 'ACTIVITI_VERSION_WORKFLOW_KEY',
    PARTNER_UPLOAD_INGEST_LOCATION = 'PARTNER_UPLOAD_INGEST_LOCATION',
    DEFAULT_LOCALISATION_TO_SCAN = 'DEFAULT_LOCALISATION_TO_SCAN',
    PROFILE_PICTURE_INGEST_LOCATION = 'PROFILE_PICTURE_INGEST_LOCATION',
}

export interface Configuration {
    id?: number;
    key: ConfigurationKey;
    value: string;
}
export interface VersionInformation {
    gitCommitId: string;
}