import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { addMilliseconds, parseISO } from 'date-fns';
import i18next from 'i18next';
import { delay } from 'rxjs';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { Page } from 'src/app/models/page';
import { Task, TaskStatus } from 'src/app/models/task';
import { TaskService } from 'src/app/services/task.service';
import { formatDate } from 'src/app/utils/utils';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.sass']
})
export class TasksComponent {

  result: Page<Task> = {}
  loading: boolean = true
  dataSource: Task[] = []
  columnsToDisplay: string[] = ['tools', 'type', 'progress', 'bandwidth', 'status', 'creationDate', 'startDate', 'endDate', 'mediaElement', 'lockedBy']
  formatDate = formatDate
  parseISO = parseISO
  now = () => new Date().toISOString()
  @Input() embedded:boolean = false;
  @Input() mediaIdFilter:number = 0;
  queryParams: ParamMap | undefined;
  menuOpen:boolean = false
  destroyed:boolean = false


  constructor(private taskService: TaskService,
    private route: ActivatedRoute, 
    private dialog:MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.destroyed = false
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.queryParams = params
      const mediaIdParam = parseInt(this.queryParams?.get('mediaId') ?? '0')
      if (mediaIdParam > 0) this.mediaIdFilter = mediaIdParam
    })
    this.getTasks();
  }

  ngOnDestroy(): void {
    console.log('component destroyed')
    this.destroyed = true
  }

  copyClipboard(task:Task) {
    navigator.clipboard.writeText(task.id?.toString() ?? '')    

    this.snackBar.open(i18next.t('tasks.clipboard'), i18next.t('tasks.hide') ?? 'Ok', {
      duration: 2000,
    });    
  }
  
  nextPage() {
    if (this.result.last) return
    this.loading = true
    this.result.pageable!.pageNumber!++
    this.getTasks()
  }

  previousPage() {
    if (this.result.first) return
    this.loading = true
    this.result.pageable!.pageNumber!--
    this.getTasks()
  }

  eta(element:Task) {
    if (element.status != TaskStatus.WORKING || !element.startDate || !element.progress) return ""
    let duration = new Date().getTime() - parseISO(element.startDate).getTime()
    let eta = duration*(1+(100-element.progress))/100
    // console.log(element.id, duration, eta)
    return formatDate(this.now(), addMilliseconds(new Date(), eta), false)
  }

  restart(element:Task) {
    this.taskService.restart(element).subscribe(_ => {
      this.getTasks(0)
      this.snackBar.open(i18next.t('tasks.restarted'), i18next.t('tasks.hide') ?? 'Ok', {
        duration: 2000,
      });          
    })
  }

  cancel(element:Task) {
    this.taskService.cancel(element).subscribe(_ => {
      this.getTasks(0)
      this.snackBar.open(i18next.t('tasks.canceled'), i18next.t('tasks.hide') ?? 'Ok', {
        duration: 2000,
      });          
    })
  }

  menuOpened() {
    console.log('menu opened')
    this.menuOpen = true
  }

  menuClosed() {
    console.log('menu closed')
    this.menuOpen = false
  }

  openAdditionalProperties(element:Task) {
    this.dialog.open(SimpleTextDialog, {
      data: { 
        title: 'Additional properties',
        text: JSON.stringify(element.additionalJobInputs, null, '  '),
        type: SimpleTextInputType.MONOSPACED,                   
      },
    });
  }

  openError(element:Task) {
    this.dialog.open(SimpleTextDialog, {
      data: { 
        title: 'Logs',
        text: element.errorReason ?? 'No logs for this task yet.',
        type: SimpleTextInputType.MONOSPACED,                    
      },
    });
  }

  getTasks(delayParam:number=500): void {
    if (delayParam > 0) this.loading = true
    this.taskService.getTasks(this.result.pageable?.pageNumber ?? 0, this.mediaIdFilter)
      .pipe(delay(delayParam))
      .subscribe(result => {
        this.result = result
        this.loading = false
        if (!this.menuOpen) {
          this.dataSource = this.result.content ?? []
        }
        if (!this.destroyed) setTimeout(() => this.getTasks(0), 2000)
      });
  }

}
