import { Component, Input, ViewChild } from '@angular/core';
import { FormulaRendererDirective } from './formula.renderer.directive';
import { FormulaRenderer } from './formula.renderer.interface';
import { ProgressRendererComponent } from './progress.renderer/progress.renderer.component';
import { DefaultRendererComponent } from './default.renderer/default.renderer.component';

@Component({
  selector: 'app-formula-renderer',
  templateUrl: './formula.renderer.component.html',
  styleUrls: ['./formula.renderer.component.sass']
})
export class FormulaRendererComponent {

  @ViewChild(FormulaRendererDirective, {static: true}) formulaRenderer!: FormulaRendererDirective;

  @Input() data:any;
  @Input() component:string|undefined;
  
  FORMULA_RENDERER_REGISTRY = new Map<string, any>();
  
  ngOnInit() {
    this.FORMULA_RENDERER_REGISTRY.set('ProgressRendererComponent', ProgressRendererComponent)
    this.FORMULA_RENDERER_REGISTRY.set('DefaultRendererComponent', DefaultRendererComponent)
    
    this.loadComponent()
  }

  ngOnChanges():void {
    console.log('this.forceReload()')
    this.forceReload()
  }

  private forceReload() {
    this.loadComponent()
  }  

  loadComponent() {
    console.log(this.formulaRenderer)
    const viewContainerRef = this.formulaRenderer.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<FormulaRenderer>(
      this.FORMULA_RENDERER_REGISTRY.get(this.component ?? 'DefaultRendererComponent')
    );
    componentRef.instance.data = this.data;
  }

  
}
