<table mat-table [dataSource]="datasource" class="maybe-table-responsive table-responsive mat-elevation-z2" style="margin-top: 10px; margin-bottom: 25px;">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container *ngFor="let column of entityColumns" [matColumnDef]="column">
        <!-- th mat-header-cell [ngStyle]="column === 'reference' ? { width: '10px' } : {}" *matHeaderCellDef>{{getUnderlyingMetadataDefinition(column).label}}</th -->
        <th mat-header-cell 
            [ngStyle]="getMetadataDefinition(column).columnWidth ? { width: getMetadataDefinition(column).columnWidth + 'px' } : {}" 
            *matHeaderCellDef>
            {{getUnderlyingMetadataDefinition(column).label}}
        </th>
        <td mat-cell *matCellDef="let element" [ngSwitch]="getMetadataDefinition(column).type">

            <ng-container *ngSwitchCase="metadataTypes.DATE">
                {{element[column] | date: dateFormat}}
            </ng-container>
            <ng-container *ngSwitchCase="metadataTypes.BOOLEAN">
                <div style="text-align: center">
                    <mat-checkbox class="checkbox" [(ngModel)]="element[column]" disabled></mat-checkbox>
                </div>
            </ng-container>            

            <ng-container *ngSwitchDefault>
                {{element[column]}}
            </ng-container>
            
        </td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef style="width: 10px">
            &nbsp;
        </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;">

            <a mat-icon-button class="small" [matMenuTriggerFor]="lineMenu" *ngIf="editable">
                <mat-icon>more_vert</mat-icon>
            </a>
            <mat-menu #lineMenu="matMenu">
                <button mat-menu-item (click)="editRow(element)">{{ "mediaDetail.editRow" | i18next }}</button>
                <button mat-menu-item (click)="deleteRow(element)">{{ "mediaDetail.deleteRow" | i18next }}</button>
            </mat-menu>
        </td>
    </ng-container>    

    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

    <ng-container mat-footer-cell matColumnDef="footer">
        <td [colSpan]="columnsToDisplay.length" *matFooterCellDef>
            <button mat-fab extended color="primary" (click)="addRow()" class="small save" style="margin: 15px 0 15px 0">
                <mat-icon>add</mat-icon>&nbsp;{{ "mediaDetail.addRow" | i18next }}
            </button>
        </td>
    </ng-container>
    
    <ng-container *ngIf="editable">
        <tr mat-footer-row *matFooterRowDef="['footer']">
        </tr>
    </ng-container>

</table>
