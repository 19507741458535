import { Component, Inject, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MetadataAttachmentType, MetadataDefinition, MetadataGroup, MetadataReference, MetadataType } from 'src/app/models/metadata';
import { MetadataService } from 'src/app/services/metadata.service';
import { ConfirmationDialog } from '../../components/dialogs/confirm.dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { UserMetadataDialog } from 'src/app/components/dialogs/user.metadatas.dialog';
import { prepareForm } from 'src/app/components/metadata.display/metadata.display.component';
import { FormBuilder } from '@angular/forms';
import { Role, User, UserGroup } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { Page } from 'src/app/models/page';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import i18next from 'i18next';
import { FFProbeFields } from 'src/app/models/ffprobe.fields';
import { MediaInfoFields } from 'src/app/models/mediainfo.fields';

@Component({
  selector: 'app-metadata-schema-detail',
  templateUrl: './metadata-schema-detail.component.html',
  styleUrls: ['./metadata-schema-detail.component.sass']
})
export class MetadataSchemaDetailComponent {

  constructor(private route: ActivatedRoute, 
    private metadataService: MetadataService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog) {
    }

    loading:boolean = true
    roles: Role[] = []
    metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
    metadataTypes: typeof MetadataType = MetadataType;
    currentGroup: MetadataGroup = {id: -1}
    currentUser: User | undefined;
    columnsToDisplay: string[] = ['order', 'name', 'label', 'type', 'values', 'tools']
    datasource: MetadataDefinition[] = []
    metadataForm:any;
    orderChanged:boolean = false;
    i18next = i18next;

    @ViewChild('table') table: MatTable<MetadataDefinition>|undefined;
    
  ngOnInit(): void {
    this.currentUser = this.userService.currentUser
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id:number = parseInt(params.get('id') ?? '-1')
      this.getMetadataGroup(id)
      this.userService.getRoles().subscribe(r => this.roles = r || [])
    });
  }

  saveOrderChange() {
    this.orderChanged = false
    this.metadataService.persistMetadataDefinitionOrders(this.currentGroup.id, this.datasource.map(md => md.id!)).subscribe()
  }

  dropTable(event: CdkDragDrop<MetadataDefinition[]>) {
    const prevIndex = this.datasource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.datasource, prevIndex, event.currentIndex);
    this.table?.renderRows();
    this.orderChanged = true
  }

  openMetadataReferenceListDialog() {

    const dialogRef = this.dialog.open(ListMetadataReferencesDialog, {
      data: {
        metadataGroupId: this.currentGroup.id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log(result.value);
      }
    });
  }

  openMetadataReferenceDialog() {
    console.log('openUserMetadataDialog');

    // populate form
    const filteredOptions = new Map<string, any>()
    
    this.metadataForm = prepareForm(this.metadataService, 
      this.userService,
      this.fb, [this.currentGroup],
      this.currentUser!,
      filteredOptions,
      {},
      this.currentGroup.id);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        creation: true,
        filteredOptions,
        metadataGroups: [this.currentGroup],
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log(result.value);
        this.metadataService.persistMetadataReference(this.currentGroup.id, {
          dynamicMetadatas: result.value[this.currentGroup.id]
        }).subscribe(() => {
          this.getMetadataGroup(this.currentGroup.id)
        })
      }
    });
  }

  openMetadataDefinitionDialog(metadataDefinition:MetadataDefinition={name:""}, creation:boolean=true): void {
    console.log('openMetadataDefinitionDialog');

    // use hydrated value
    let hydratedMetadataDefinition = this.metadataService.metadataDefinitions.filter(m => m.id == metadataDefinition?.id)[0]
    if (hydratedMetadataDefinition) {
      metadataDefinition = hydratedMetadataDefinition
    }

    if (metadataDefinition.formula) {
      console.log(metadataDefinition.formula)
      metadataDefinition.formula = metadataDefinition.formula
        .replaceAll("\\n", "\n")
        .replaceAll("\\t", "\t")
    }

    const dialogRef = this.dialog.open(CreateMetadataDefinitionDialog, {
      data: {
        definition: metadataDefinition,
        metadataTypes: MetadataType,
        roles: this.roles,
        creation,
        referencedMetadataGroup: metadataDefinition.referencedMetadataGroup
      },
    });

    console.log('metadataDefinition.referencedMetadataGroupEntity', metadataDefinition.referencedMetadataGroupEntity)
    console.log('metadataDefinition.referencedMetadataGroup', metadataDefinition.referencedMetadataGroup)

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result == undefined) return
      if (result.creation) {
        this.metadataService.createMetadataDefinition(this.currentGroup.id, result.definition, 
          result.definition.referencedMetadataGroupEntity).subscribe(s => {
            this.metadataService.getMetadataGroups(true).subscribe(_ => this.getMetadataGroup(this.currentGroup.id))
        })
      } else {
        this.metadataService.updateMetadataDefinition(result.definition, 
          result.definition.referencedMetadataGroupEntity).subscribe(s => {
            this.metadataService.getMetadataGroups(true).subscribe(_ => this.getMetadataGroup(this.currentGroup.id))
        })
      }
    });    
  }

  deleteMetadataDefinition(element:MetadataDefinition):void {
    console.log('deleteMetadataDefinition')

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.schema_detail.confirm',
        content: `mediaDetail.schema_detail.sure_to_delete`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.metadataService.deleteMetadataDefinition(element.id!).subscribe(s => {
          this.getMetadataGroup(this.currentGroup.id)
        })
      }
    });
  }
  
  deleteMetadataGroup() {

    console.log('deleteMetadataDefinition')

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.schema_detail.confirm',
        content: `mediaDetail.schema_detail.sure_to_delete_group`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.metadataService.deleteMetadataGroup(this.currentGroup.id!).subscribe(_ => {
          this.router.navigate([ '/metadata_schema' ])
        })
      }
    });
  }

  openMetadataGroupDialog():void {

    let form: { [k: string]: any } = {}
    form['name'] = this.currentGroup.name
    form['attachmentType'] = this.currentGroup.attachmentType
    form['externalDatasource'] = this.currentGroup.externalDatasource
    form['externalDatasourceURL'] = this.currentGroup.externalDatasourceURL
    form['displayGrouped'] = this.currentGroup.displayGrouped
    form['displayCollapsed'] = this.currentGroup.displayCollapsed

    const dialogRef = this.dialog.open(EditMetadataGroupDialog, {
      data: {
        group: this.currentGroup,
        form: this.fb.group(form)
      }, 
      minWidth: '50vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.currentGroup.displayCollapsed = result.form.value.displayCollapsed
        this.currentGroup.name = result.form.value.name
        this.currentGroup.attachmentType = result.form.value.attachmentType
        this.currentGroup.displayGrouped = result.form.value.displayGrouped
        this.currentGroup.externalDatasource = result.form.value.externalDatasource
        this.currentGroup.externalDatasourceURL = result.form.value.externalDatasourceURL
        this.metadataService.persistMetadataGroup(this.currentGroup).subscribe(_ => {})
      }
    });    
  }  

  getMetadataGroup(id:number): void {
    this.metadataService.getMetadataGroup(id)
      .subscribe(result => {
        this.currentGroup = result
        if (this.currentGroup.system) {
          this.columnsToDisplay = ['name', 'label', 'type', 'values']
        }
        this.datasource = result.metadatas ?? []
        this.loading = false
      })
  }

}

export interface MetadataGroupDialogData {
  group:MetadataGroup;
  form:any;
}

@Component({
  selector: 'dialog-edit-metadatagroup',
  templateUrl: './dialog-edit-metadatagroup.html',
  styleUrls: [ './dialog-edit-metadatagroup.sass' ]
})
export class EditMetadataGroupDialog {

  Object = Object
  attachmentTypes: typeof MetadataAttachmentType = MetadataAttachmentType;

  constructor(
    public dialogRef: MatDialogRef<EditMetadataGroupDialog>,
    public metadataService: MetadataService,
    @Inject(MAT_DIALOG_DATA) public data: MetadataGroupDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface CreateMetadataDefinitionDialogData {
  definition:MetadataDefinition,
  referencedMetadataGroup:MetadataGroup,
  metadataTypes: MetadataType,
  creation: boolean,
  roles: Role[]
}

@Component({
  selector: 'dialog-metadata-definition',
  templateUrl: './dialog-metadata-definition.html',
  styleUrls: [ './dialog-metadata-definition.sass' ]
})
export class CreateMetadataDefinitionDialog {

  Object = Object
  i18next = i18next

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  referenceables: Array<MetadataGroup|undefined> = []
  extractableFields:string[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateMetadataDefinitionDialog>,
    public metadataService: MetadataService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: CreateMetadataDefinitionDialogData,
  ) {
    this.extractableFields = [...Object.keys(FFProbeFields).map(k => `ffprobe > ${k.toString()}`),
      ...Object.keys(MediaInfoFields).map(k => `mediaInfo > ${k.toString()}`)]
  }

  ngOnInit() {
    this.referenceables = [/*undefined, */...this.metadataService.metadataGroups
      .filter(g => g.attachmentType === MetadataAttachmentType.REFERENCEABLE)]
  }

  displayMetadataGroup(value: MetadataGroup) {
    console.log('displayAutocomplete', value)
    return value?.name ?? 'None'
  }

  addRole(field:string, event:MatAutocompleteSelectedEvent):void {
    const definition:any = (this.data.definition as any)
    if (!definition[field]) definition[field] = []
    definition[field].push(event.option.value)
  }

  removeRole(field:string, role:string):void {
    const arr:any = (this.data.definition as any)[field]
    arr.splice(this.data.definition.addReferencesRestrictedToRoles?.indexOf(role), 1)
  }

  addDisplayedOnStreamType(event:MatAutocompleteSelectedEvent):void {
    const definition:any = (this.data.definition as any)
    if (!this.data.definition.displayRestrictedToMediaStreamTypes) this.data.definition.displayRestrictedToMediaStreamTypes = []
    this.data.definition.displayRestrictedToMediaStreamTypes?.push(event.option.value)
  }

  removeDisplayedOnStreamType(attachmentType:string):void {
    this.data.definition.displayRestrictedToMediaStreamTypes?.splice(this.data.definition.displayRestrictedToMediaStreamTypes?.indexOf(attachmentType), 1)
  }

  addAllowedValue(event:MatChipInputEvent):void {

    const value = (event.value || '').trim();

    if (value) {
      if (!this.data.definition.allowedValues) this.data.definition.allowedValues = []
      this.data.definition.allowedValues.push(value);
    }

    event.chipInput!.clear();    
  }

  removeAllowedValue(allowedValue:string):void {
    const index = this.data.definition.allowedValues?.indexOf(allowedValue) ?? -1;

    if (index >= 0) {
      this.data.definition.allowedValues!.splice(index, 1);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}


export interface ListMetadataReferencesDialogData {
  metadataGroupId: number;
}

@Component({
  selector: 'dialog-list-metadata-reference',
  templateUrl: './dialog-list-metadata-reference.html',
  styleUrls: [ './dialog-list-metadata-reference.sass' ]
})
export class ListMetadataReferencesDialog {

  references:Page<MetadataReference> = {}

  constructor(
    public dialogRef: MatDialogRef<ListMetadataReferencesDialog>,
    public metadataService: MetadataService,
    @Inject(MAT_DIALOG_DATA) public data: ListMetadataReferencesDialogData,
  ) {}

  ngOnInit() {
    this.metadataService
      .listMetadataReferences(this.data.metadataGroupId)
      .subscribe(s => this.references = s)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}