<h1 mat-dialog-title>{{"mediaDetail.moveMedia.move_media" | i18next}}</h1>
<mat-dialog-content>
  <p>{{"mediaDetail.moveMedia.select_destination" | i18next}}</p>
  <mat-form-field appearance="fill">
    <mat-label>{{"mediaDetail.dialog_outgest.destination" | i18next}}</mat-label>
    <mat-select [(ngModel)]="data.locationId">
      <mat-option *ngFor="let location of data.locations" [value]="location.id">
          {{location.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div>
    <mat-checkbox class="checkbox" [(ngModel)]="data.copy">{{"mediaDetail.moveMedia.copy_only" | i18next}}</mat-checkbox>
  </div>
  <div>
    <mat-checkbox class="checkbox" [(ngModel)]="data.onlyCurrentVersion" [disabled]="data.noVersion">{{"mediaDetail.moveMedia.only_current" | i18next}}</mat-checkbox>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"mediaDetail.moveMedia.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"mediaDetail.moveMedia.ok" | i18next}}</button>
  </div>  
</mat-dialog-content>