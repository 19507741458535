<h1 mat-dialog-title>{{"transcoding_profiles.title" | i18next}}</h1>

<mat-dialog-content class="content">

    <mat-form-field appearance="fill">
        <mat-label>{{"transcoding_profiles.name" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.profile.name">
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>{{"transcoding_profiles.extension" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.profile.extension">
    </mat-form-field>

    <div style="display: flex; gap: 15px;">
        <mat-form-field appearance="fill">
            <mat-label>{{"transcoding_profiles.number_of_video_streams" | i18next}}</mat-label>
            <input matInput type="number" [(ngModel)]="data.profile.numberOfVideoStreams">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{"transcoding_profiles.number_of_audio_streams" | i18next}}</mat-label>
            <input matInput type="number" [(ngModel)]="data.profile.numberOfAudioStreams">
        </mat-form-field> 

        <mat-form-field appearance="fill">
            <mat-label>{{"transcoding_profiles.number_of_subtitle_streams" | i18next}}</mat-label>
            <input matInput type="number" [(ngModel)]="data.profile.numberOfSubtitlesStreams">
        </mat-form-field>    
    </div>

    <div>
        <mat-checkbox [(ngModel)]="data.profile.hasPreroll" style="margin-bottom: 15px; margin-right: 20px">
            {{ "transcoding_profiles.hasPreproll" | i18next }}
        </mat-checkbox>        
        <mat-checkbox [(ngModel)]="data.profile.hasMarkInAndMarkOut" style="margin-bottom: 15px; margin-right: 20px">
            {{ "transcoding_profiles.hasMarkInAndMarkOut" | i18next }}
        </mat-checkbox>        
    </div>

    <mat-form-field appearance="fill">
        <mat-label>{{ "transcoding_profiles.script" | i18next}}</mat-label>
        <textarea [style.height.vh]="48"
          matInput 
          [class.monospaced]="true" 
          [(ngModel)]="data.profile.command"></textarea>
      </mat-form-field>

</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"transcoding_profiles.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data.profile" cdkFocusInitial>{{"transcoding_profiles.ok" | i18next}}</button>
</div>