import { Component, Inject } from '@angular/core';
import { cloneDeep, throttle } from "lodash";
import { MediaDetailContainerComponent } from '../media-detail-container/media-detail-container.component';
import { PartnerUploadService } from 'src/app/services/partner.upload.service';
import { UploadRequestStatus } from 'src/app/models/upload.request';
import { Media, MediaElement, MediaStream, MediaVersion } from 'src/app/models/media';
import { User, UserPreferences } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { MetadataAttachmentType, MetadataDefinition, MetadataGroup, MetadataType } from 'src/app/models/metadata';
import { prepareForm } from 'src/app/components/metadata.display/metadata.display.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserMetadataDialog } from 'src/app/components/dialogs/user.metadatas.dialog';
import { MediaService } from 'src/app/services/media.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { formatDate, handleTableResponsivness } from 'src/app/utils/utils';
import { Observable, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import i18next from 'i18next';
import { QualityCheckService } from 'src/app/services/quality.check.service';
import { QualityCheck } from 'src/app/models/qc';

export interface RequestPartnerUploadData {
  partnerId: number;
  message: string;
}

@Component({
  selector: 'app-media-uploads',
  templateUrl: './media-uploads.component.html',
  styleUrls: ['./media-uploads.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],  
})
export class MediaUploadsComponent {

  selection = new SelectionModel<MediaStream>(true, [])
  proposedVersionsSelection = new SelectionModel<MediaVersion>(true, [])
  expectedStreamsDatasource: MediaStream[] = []
  metadataForm:any
  loading:boolean = true
  excludedProposedVersions:string[] = []
  metadataGroups: MetadataGroup[] = []
  columnsToDisplayOnExpectedStreams: string[] = ['expand', 'select', 'type', 'partner', 'createdBy', 'creationDate', 'tools']
  columnsToDisplayOnProposedVersions: string[] = ['select', 'name', 'video', 'audio', 'subtitles']
  userPreferences: UserPreferences|undefined
  metadataTypes:typeof MetadataType = MetadataType
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType
  expandedElement: MediaStream | undefined;
  formatDate = formatDate
  proposedVersions: MediaVersion[] = [];

  constructor(public parentContainer: MediaDetailContainerComponent,
    private partnerUploadService: PartnerUploadService,
    private userService: UserService,
    private metadataService: MetadataService,
    private mediaService: MediaService,
    private fb: FormBuilder,
    private route: ActivatedRoute, 
    public dialog: MatDialog) 
    {}



  ngOnInit() {
    this.userService.getUserPreferences().subscribe(s => {
      this.userPreferences = s
      console.log(this.userPreferences)
      if (this.userPreferences?.columnsDisplayedOnExpectedStreams?.length)
        this.columnsToDisplayOnExpectedStreams = ['expand', 'select', 'type', 'partner', 'status', ...this.userPreferences.columnsDisplayedOnExpectedStreams, 'tools']
    })
    this.getMetadataGroups()
    this.route.parent!.paramMap.subscribe((params: ParamMap) => {
      const id:number = parseInt(params.get('id') ?? '-1')
      this.getMedia(id, false)
      this.getProposedVersionsForExpectedStreams(id)
    })
  }

  isProposedVersionExcluded(row:MediaVersion) {
    const excluded = this.excludedProposedVersions.find((element) => element === row.name) !== undefined
    return excluded
  }

  getProposedVersionsForExpectedStreams(id: number) {
    this.mediaService.getProposedVersionsForExpectedStreams(id).subscribe(p => {
      this.excludedProposedVersions = []
      p.forEach(pv => { 
        if (this.parentContainer.media?.versions?.find(v => v.name === pv.name)) {
          this.excludedProposedVersions.push(pv.name!)
        }
      });
      this.proposedVersions = p
      this.loading = false
      setTimeout(() => handleTableResponsivness(), 100)
    })
  }
  
  getPartnerRequest(id:number) {
    return this.parentContainer.media?.partnerUploadRequests?.find(p => p.id === id)
  }

  editExpectedStream(stream:MediaStream) {
    this.openUserMetadataDialog(stream, (data:any) => {
      this.metadataService.persistMetadatasForEntity(data).subscribe(() => {
        this.getProposedVersionsForExpectedStreams(this.parentContainer?.media?.id!)
        this.getMedia(this.parentContainer?.media?.id!, true)          
      });      
    })
  }

  deleteExpectedStream(stream:MediaStream) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'media_upload.confirm',
        content: `media_upload.sure_to_delete`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.mediaService.deleteMediaExpectedStream(stream.id!).subscribe(s => {
          this.getMedia(this.parentContainer?.media?.id!, true)
          this.getProposedVersionsForExpectedStreams(this.parentContainer?.media?.id!)
        })
      }
    });    
  }

  submitProposedStreams() {
    // cleanup sent json
    const json:MediaVersion[] = cloneDeep(this.proposedVersionsSelection.selected)
    json.forEach(v => {
      if (v.audio)
        v.audio!.partnerUploadRequest = undefined
      if (v.video)
          v.video!.partnerUploadRequest = undefined
      if (v.subtitles)
        v.subtitles.forEach(s => s.partnerUploadRequest = undefined)
    })
    this.mediaService
      .createVersions(this.parentContainer.media!.id!, json)
      .subscribe(s => {
        this.getMedia(this.parentContainer?.media?.id!, true)
        this.dialog.open(SimpleTextDialog, {
          data: {
            title: 'media_upload.version_created',
            text: 'media_upload.requested_versions',
            type: SimpleTextInputType.TEXT,            
          },
        });          
      })
  }

  addExpectedStream(type:string): void {
    let expectedStream:MediaStream = {
      type
    }
    const attachmentType = type == 'SPARE_FILE' ? 'SPARE_FILE' : 'MEDIA_STREAM'
    // TODO loader
    this.openUserMetadataDialog(expectedStream, (data:any) => {
      this.mediaService.createMediaExpectedStream(this.parentContainer.media!.id!, expectedStream).subscribe(m => {
        const metadataGroups = this.metadataGroups?.filter(g => g.attachmentType === attachmentType)
        const metadataGroupId = metadataGroups[0].id
        data[metadataGroupId].targetId = m.id
        data[metadataGroupId].targetType = attachmentType
        this.metadataService.persistMetadatasForEntity(data).subscribe(() => {
          this.getProposedVersionsForExpectedStreams(this.parentContainer?.media?.id!)
          this.getMedia(this.parentContainer?.media?.id!, true)          
        });
      })
    })
  }

  getMetadataGroups(): void {
    this.metadataService.getMetadataGroups()
      .subscribe(metadataGroups => {
        this.metadataGroups = metadataGroups.content!
          .filter(g => g.attachmentType == 'MEDIA_STREAM' || g.attachmentType === 'SPARE_FILE')!
      })
  }

  openRequestPartnerUploadDialog(freestyle:boolean=false, accessOnly:boolean=false): void {
    console.log('openRequestPartnerUploadDialog', this.selection.selected);
    
    if (!this.selection.selected.length && !freestyle && !accessOnly) {
      this.dialog.open(SimpleTextDialog, {
        data: {
          title: 'media_upload.error',
          text: 'media_upload.select',
          type: SimpleTextInputType.TEXT,            
        },
      });
      return
    }
    const dialogRef = this.dialog.open(RequestPartnerUploadDialog, {
      data: {},
    });
    
    dialogRef.afterClosed().subscribe(form => {
      console.log('The dialog was closed', form.partner.value);
      this.partnerUploadService.createUploadRequest(form.partner.value.id, 
        form.message.value, 
        form.allowStreaming.value, 
        form.qc.value.id, 
        this.parentContainer.media?.id!, 
        this.selection.selected.map(s => s.id!),
        accessOnly)
        .subscribe(() => {
          this.getMedia(this.parentContainer.media?.id ?? -1, true)
        });
    });
  }

  openUserMetadataDialog(element:MediaStream, callback:Function|undefined=undefined): void {
    console.log('openUserMetadataDialog', element);

    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = element.type === 'SPARE_FILE' ? 
      this.metadataGroups?.filter(g => g.attachmentType === 'SPARE_FILE') :
      this.metadataGroups?.filter(g => g.attachmentType === 'MEDIA_STREAM')
 
    this.metadataForm = prepareForm(this.metadataService,
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      element.dynamicMetadatas,
      element.id,
      element.type,
      true);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log('dialog value: ', result.value);
        if (callback) {
          callback(this.metadataForm.value)
        }
      }
    });
  }  

  getMetadataDefinition(name:string) {
    return this.metadataService.getMetadataDefinitionFromCache(name)
  } 

  customColumnsFromMetadatas() {
    return this.userPreferences?.columnsDisplayedOnExpectedStreams?.filter(
      c => c !== "creationDate" && c !== "createdBy")
  }

  cancelUploadRequest(requestId:number) {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'media_upload.confirm',
        content: `media_upload.cancel_upload`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {      
        this.partnerUploadService.updateUploadRequestStatus(requestId, UploadRequestStatus.CANCELED).subscribe(r => {
          this.getMedia(this.parentContainer?.media?.id!, true)
        })
      }
    });    
      
  }

  reload() {
    this.getMedia(this.parentContainer?.media?.id!, true)
  }

  getMedia(id:number, forceReload:boolean): void {
    this.parentContainer.getMedia(id, forceReload, result => {
      this.expectedStreamsDatasource = this.parentContainer.media?.expectedStreams!
    })
  }  

}

// TODO make independant
@Component({
  selector: 'dialog-request-partner-upload',
  templateUrl: './dialog-request-partner-upload.html',
  styleUrls: ['./dialog-request-partner-upload.sass']
})
export class RequestPartnerUploadDialog {
  i18next = i18next
  form = {
    qc: new FormControl(''),
    partner: new FormControl(''),
    allowStreaming: new FormControl(''),
    message: new FormControl('')
  }
  filteredOptions: any = null;
  qcs: QualityCheck[] = [];
  
  constructor(
    public dialogRef: MatDialogRef<RequestPartnerUploadDialog>,
    public qualityCheckService: QualityCheckService, 
    public userService: UserService, 
    @Inject(MAT_DIALOG_DATA) public data: RequestPartnerUploadData,
  ) {
  }

  ngOnInit() {
    this.qualityCheckService.getProfiles().subscribe(p => this.qcs = p.content ?? [])
    this.filteredOptions = this.form.partner.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(val => {
        console.log('val', val)
        let autocompleteString = typeof val === 'string' ? val : (val! as unknown as User).email
        const r = this.userService.getAutocompleteData('ROLE_PARTNER', autocompleteString+'' || '')
        return r
      })       
    );
  }
  displayPartnerAutocomplete(value:User) {
    console.log('displayPartnerAutocomplete', value)
    return value?.email ?? ''
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  filterPartners(val: string): Observable<any[]> {
    console.log('filter', val)

    return this.userService.getAutocompleteData('ROLE_PARTNER', val)
     .pipe(
       map(response => response.filter(option => { 
        console.log('option', option)
         return option.email!.toLowerCase().indexOf(val.toLowerCase()) === 0
       }))
     )
  }  

}
