<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="profile">
    
    <mat-card style="margin-bottom: 20px">
        <mat-card-content>
            <h1>{{profile?.name}}</h1>

            <div>
                <mat-form-field appearance="fill">
                    <mat-label>{{'qc.name' | i18next}}</mat-label>
                    <input matInput [(ngModel)]="profile.name">
                </mat-form-field>          
            </div>            
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>

            <h2>
                <span>{{'qc.conditions' | i18next}}</span>
        
                <button style="transform: scale(0.5) translateY(8px)" 
                        aria-label="Add" mat-fab
                        (click)="addCondition()">
                    <mat-icon>add</mat-icon>
                </button>
            </h2>        
            <div *ngIf="!profile.conditions?.length" class="secondary" style="margin-bottom: 20px">
                {{'qc.noCondition' | i18next}}
            </div>

            <div *ngFor="let condition of profile.conditions" class="condition">
                <app-metadata-select 
                    [(selection)]="condition!.metadataDefinition" 
                    [(value)]="condition.metadataValue"
                    [(operator)]="condition!.operator"
                    [disallowedOperands]="['LIKE', 'UNLIKE', 'NOT_EQUAL', 'GREATER_OR_EQUAL', 'LESS_OR_EQUAL']"
                    [allowOperatorSelection]="true"
                    [metadataDefinitionFilter]="filterMetadatas"></app-metadata-select>
                &nbsp;&nbsp;
                <button style="transform: scale(0.8) translateY(2px)" 
                        aria-label="Remove" mat-icon-button
                        (click)="removeCondition(condition)">
                    <mat-icon>delete_forever</mat-icon>
                </button>                     
            </div>               
        </mat-card-content>
    </mat-card>    


    <button class="small-button" 
        [ngClass]="saving ? 'working' : ''" extended mat-fab
        style="margin-top: 20px; margin-bottom: 40px"
        (click)="save()">
        <mat-icon style="width: 24px;">save</mat-icon>
        {{"qc.save" | i18next}}
        <mat-spinner></mat-spinner>
    </button>

</ng-container>
