import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Location, MediaElement } from '../models/media';
import { EditableSubtitle, SubtitleTime } from '../models/subtitle';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class SubtitleService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  getSubtitles(mediaId:number, mediaElementId:number): Observable<EditableSubtitle> {
    return this.http.get<EditableSubtitle>(`/api/media/${mediaId}/${mediaElementId}/subtitles`)
      .pipe(
        tap(_ => this.log('fetched subtitles')),
        catchError(this.errorService.handleError<EditableSubtitle>('getSubtitles', {}))
      );
  }

  edit(media:MediaElement, mediaElement: MediaElement, startTime: string, result: string) {
    return this.http.put<void>(`/api/media/${media.id}/${mediaElement.id}/subtitle/${startTime}`, result)
      .pipe(
        tap(_ => this.log('edited subtitle')),
        catchError(this.errorService.handleError<EditableSubtitle>('edited subtitle', {}))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
