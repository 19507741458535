<h1>{{"configuration.title" | i18next }}</h1>

<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<table mat-table [dataSource]="dataSource" style="margin-bottom: 60px" class="mat-elevation-z8" *ngIf="!loading">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"configuration.id" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef> {{ 'configuration.key' | i18next }} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.key}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> {{ 'configuration.value' | i18next }} </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.value.substring(0, 100) }}</span>
        <span *ngIf="element.value.length > 100">...</span>
    </td>
    </ng-container>    
    
    <tr mat-row (click)="editConfiguration(row)" *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>


