<h1 mat-dialog-title>
  {{ data.title | i18next }}
</h1>
<mat-dialog-content>
  <p>{{data.text | i18next}}</p>
  
  <mat-selection-list [(ngModel)]="selection">
    <mat-list-option *ngFor="let element of data.elements" [value]="element">
      {{element.representation}}
    </mat-list-option>
  </mat-selection-list>

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"dialog_create.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="selection" cdkFocusInitial>{{"dialog_create.ok" | i18next}}</button>
  </div>  
</mat-dialog-content>