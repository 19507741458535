import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { delay } from 'rxjs';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { Page } from 'src/app/models/page';
import { Translation, TranslationsForKey } from 'src/app/models/translation';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.sass']
})
export class TranslationsComponent {

  availableLocales = ['en', 'fr', 'de']
  loading = true
  dataSource:TranslationsForKey[] = []
  columnsToDisplay = ['key', 'values', 'tools']
  
  constructor(private translationService:TranslationService,
    private dialog:MatDialog) {
    
  }

  ngOnInit(): void {
    this.getTranslations();
  }

  getTranslations(): void {
    this.translationService.getTranslations(this.availableLocales)
        .pipe(delay(500))
        .subscribe(result => {
          this.dataSource = result ?? []
          this.loading = false
        });
  }

  editTranslation(translations:TranslationsForKey) {
    const editableTranslations = cloneDeep(translations)
    const dialogRef = this.dialog.open(EditTranslationsDialog, {
      data: {
        translations: editableTranslations,
        availableLocales: this.availableLocales
      }
    });

    dialogRef.afterClosed().subscribe((result:TranslationsForKey) => {
      console.log('The dialog was closed', result);
      if (result) {    
        this.translationService
          .persistTranslations(result.key, Array.from(result.translations.values()))
            .subscribe(_ => {
              this.loading = true
              this.getTranslations()
            })
      }
    });     
  }

  addTranslation() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'translations.create_key',
        text: 'translations.key_explanation',
        label: 'translations.key',
        value: '',
        type: SimpleTextInputType.TEXT,                    
      },
      maxWidth: '60vw',
      width: '60%', 
    });

    dialogRef.afterClosed().subscribe((name:string) => {
      console.log('The dialog was closed', name);
      if (name) {    
        this.translationService.persistKey(name).subscribe(s => {
          this.loading = true
          this.getTranslations()
        })
      }
    });     
  }

  deleteTranslation(t:TranslationsForKey) {
    this.translationService.deleteKey(t.key).subscribe(_ => {
      this.loading = true
      this.getTranslations()
    })
  }
}

export interface EditTranslationDialogData {
  translations:TranslationsForKey,
  availableLocales: string[]
}

@Component({
  selector: 'dialog-edit-translations',
  templateUrl: './dialog-edit-translations.html',
  styleUrls: [ './dialog-edit-translations.sass' ]
})
export class EditTranslationsDialog {

  Object = Object
  
  constructor(
    public dialogRef: MatDialogRef<EditTranslationsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EditTranslationDialogData,
  ) {}

  ngOnInit() {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
