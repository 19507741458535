import i18next from "i18next";
import { HistoryService } from "../services/history.service";
import { MetadataService } from "../services/metadata.service";
import { MetadataAttachmentType, MetadataType } from "../models/metadata";
import { format } from "date-fns";
import { HistoryEntry, HistoryEntryType, Operation } from "../models/history";
import { Media, MediaStream } from "../models/media";
import { Page } from "../models/page";

export class NotificationBaseComponent {

  dateFormat = i18next.t('global.dateTimeFormat')

  constructor(
    protected historyService: HistoryService,
    protected metadataService: MetadataService
  ) { }

  computeDescription(operation: any): string {
    if (operation.op === 'replace' && !operation.value) {
      operation.op = 'remove'
    }
    const metadataGroupId = parseInt(operation.path.replace('/representation', '').substring(1));
    const matchCount = operation.path.match(/\//g)?.length
    const metadataDefinitionKey = matchCount === 1 && operation.op !== 'remove' ?
      Object.keys(operation.value)[0] :
      operation.path.substring(operation.path.lastIndexOf('/') + 1)
    const metadataGroup = this.metadataService.metadataGroups.find(mg => mg.id === metadataGroupId);
    const metadataDefinition = metadataGroup?.metadatas?.find(m => m.name === metadataDefinitionKey);
    const operationLabel = i18next.t(`activity.operations.${operation.op}`)
    const withValueLabel = i18next.t(`activity.with_value`)
    let value
    if (metadataDefinition?.type == MetadataType.DATE) {
      if (operation.value) {
        if (typeof operation.value === 'string') {
          value = format(new Date(operation.value), this.dateFormat)
        } else if (typeof operation.value === 'object') {
          const val = Object.values(operation.value as object)[0].toString()
          value = format(new Date(), this.dateFormat)
        }
      } else {
        value = '—'
      }
    } else if (typeof operation.value === 'string') {
      value = operation.value
    } else if (matchCount === 1) {
      value = operation.value[metadataDefinitionKey]
    } else if (typeof operation.value === 'object' && operation.value) {
      if (operation.value.hasOwnProperty('representation')) value = operation.value['representation']
      else if (operation.value.hasOwnProperty('email')) value = operation.value['email']
    }
    let result = `${operationLabel} <span class="highlight">${metadataDefinition?.label}</span>`
    if (operation.op !== 'remove') {
      result += ` ${withValueLabel} <span class="highlight">${value}</span>`

      if (!value) {
        console.log('null', operation)
      }
    }

    return result
  }


  computeOperations(data:Page<HistoryEntry>, mediaMap:Map<number,Media>) {
    if (!data || !data.content) return
    for (let entry of data.content ?? []) {
      let label = ''
      
      if (entry.type === HistoryEntryType.COMMENT_ANSWER) {
        entry.computedTitle = `${i18next.t('notifications.on.media', {mediaName: entry.media?.name})}`
        entry.structuredOperations = [{
          description: `${i18next.t('notifications.answered_comment')} « <span class="highlight">${entry.json.comment}</span> ».`
        }]
        entry.computedLink = '/media/' + entry.media?.id
      } else if (entry.type === HistoryEntryType.METADATA_UPDATE) {
        const media = mediaMap.get(entry.mediaId!)
        entry.computedTitle = `${i18next.t('notifications.on.media', {mediaName: entry.media?.name})}`
        if (entry.targetType === MetadataAttachmentType.MEDIA_STREAM) {
          const availableStreams:MediaStream[] = media?.elements?.flatMap(e => e.streams ?? []) ?? []
          const stream = availableStreams.find(s => s.id === entry.targetId)??[][0]
          if (stream && stream.representation !== undefined) {
            entry.computedTitle += ` ${i18next.t('notifications.on.stream')} <span class="highlight">${stream.representation}</span>`
          }
        } 
        if (entry.targetType === MetadataAttachmentType.MEDIA_VERSION) {
          const versionId = entry.targetId
          const version = media?.versions?.find(v => v.id === versionId)??[][0]
          if (version?.name !== undefined) {
            entry.computedTitle += ` ${i18next.t('notifications.on.version')} <span class="highlight">${version?.name}</span>`
          }
        }
        entry.computedLink = '/media/' + entry.media?.id
      } else if (entry.type === HistoryEntryType.NEW_SAVED_SEARCH_ENTRY) {
        entry.computedTitle = `${i18next.t('notifications.new_entries_on_search', 
          {count: entry.json?.mediaIds.length, searchName: entry.json?.searchName})}`
        entry.computedLink = `/search/${entry.json?.searchId}/`
        const medias = entry.json.mediaIds
        const MAX_MEDIA_SHOWN = 4
        const operations:Operation[] = []
        for (const mediaId of medias) {
          const value = mediaMap.get(mediaId)!
          operations.push({
            description: `${value.name}`
          })
          if (operations.length >= MAX_MEDIA_SHOWN-1 && medias.length > MAX_MEDIA_SHOWN) {
            operations.push({
              description: `${i18next.t('notifications.and_others', {count: medias.length-MAX_MEDIA_SHOWN+1})}`
            })
            break;
          }
        }
        entry.structuredOperations = operations
      }
      entry.label = label

      if (entry.type === HistoryEntryType.METADATA_UPDATE) {
        const operations:Operation[] = []
        for (let operation of entry.json) {
          if (!this.isEligible(operation)) continue
          operations.push({
            description: this.computeDescription(operation)
          })
        }
        entry.structuredOperations = operations
      }
    }
    data.content = data.content?.filter(c => 
      (c.structuredOperations ?? []).length > 0 ||
      c.type === HistoryEntryType.NEW_SAVED_SEARCH_ENTRY ||
      c.type === HistoryEntryType.COMMENT_ANSWER)
  }


  isEligible(operation: any) {
    try {
        
      const metadataGroupId = parseInt(operation.path.replace('/representation', '').substring(1));
      const metadataGroup = this.metadataService.metadataGroups.find(mg => mg.id === metadataGroupId);

      const matchCount = operation.path.match(/\//g)?.length
      const metadataDefinitionKey = matchCount === 1 ?
        Object.keys(operation.value)[0] :
        operation.path.substring(operation.path.lastIndexOf('/') + 1)

      const metadataDefinition = metadataGroup?.metadatas?.find(m => m.name === metadataDefinitionKey);
      if (!metadataDefinition) return false

      if (metadataDefinitionKey === 'targetId' || metadataDefinitionKey === 'targetType') return false
      if (operation.path === '') return false
      if (matchCount > 2) return false
      //if (matchCount == 2 && operation.path.indexOf('representation') === -1) return false
      if (operation.op === 'add' && operation.value === '') return false
      if (operation.op === 'copy') return false
      //if (operation.op === 'remove') return false
      return true
    } catch (err) {
      console.warn(err)
      return false
    }
  }
}