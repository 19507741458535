import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import i18next from 'i18next';
import { Observable, forkJoin, tap } from 'rxjs';
import { Status, UserTask, UserTaskType } from 'src/app/models/usertask';
import { SimpleListDialog } from '../dialogs/simple.list.dialog';
import { UserService } from 'src/app/services/user.service';
import { SimpleTextDialog, SimpleTextInputType } from '../dialogs/simple.text.dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserTaskService } from 'src/app/services/usertask.service';
import { Media, MediaStream } from 'src/app/models/media';
import { ConfirmationDialog } from '../dialogs/confirm.dialog';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { Page } from 'src/app/models/page';
import { SimpleMetadataSelectDialog } from '../dialogs/simple.metadata.select.dialog';
import { MetadataAttachmentType, MetadataDefinitionAndValue, MetadataType } from 'src/app/models/metadata';
import { MetadataService } from 'src/app/services/metadata.service';

@Component({
  selector: 'app-usertasks',
  templateUrl: './usertasks.component.html',
  styleUrls: ['./usertasks.component.sass']
})
export class UsertasksComponent {

  dateFormat = i18next.t('global.dateFormat')
  dateTimeFormat = i18next.t('global.dateTimeFormat')
  loading: boolean = true
  datas: UserTask[] = []
  dataSource: MatTableDataSource<UserTask> = new MatTableDataSource(this.datas)
  columnsToDisplay: string[] = ['type', 'version', 'stream', 'status', 'assignableTo', 'assignedTo', 'dueDate', 'label', 'createdBy', 'createdAt', 'completedAt', 'id', 'tools']

  @Input() media:Media|undefined
  @Input() user:User|undefined
  taskTypes: Page<UserTaskType> = {};

  constructor(private dialog:MatDialog, 
    private userTaskService:UserTaskService,
    private router: Router,
    private userService:UserService,
    private metadataService:MetadataService) { }

  ngOnInit(): void {
    if (this.user) this.columnsToDisplay = ['tools', 'media', 'type', 'version', 'stream', 'status', 'assignableTo', 'assignedTo', 'dueDate', 'label', 'createdBy', 'createdAt', 'completedAt', 'id']
    this.load()
  }

  load() {
    if (this.media) {
      this.userTaskService.getUserTasksForMedia(this.media.id!).subscribe(r => {
        this.datas = r.content ?? []
        this.dataSource.data = this.datas
        this.loaded()
      })
    } else if (this.user) {
      this.userTaskService.getUserTasksForUser(this.user.id!).subscribe(r => {
        this.datas = r.content ?? []
        this.dataSource.data = this.datas
        this.loaded()
      })
    }
    this.userTaskService.getUserTaskTypes().subscribe(r => {
      this.taskTypes = r
    })
  }

  loaded() {
    const observables:Observable<any>[] = [];
    this.datas.forEach(t => {
      t.assignableTo?.forEach(c => {
        if (c.metadata?.type == MetadataType.REFERENCE && c.value) {
          observables.push(this.metadataService
            .getMetadataReference(parseInt(c.value))
            .pipe(tap(r => c.value = r)))
        }        
      })
    })       
    if (observables.length == 0) this.loading = false
    forkJoin(observables).subscribe(_ => {
      this.loading = false
    })
  }

  editLabel(element:UserTask) {
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usertasks.label',
        label: 'usertasks.label',
        value: element.label,
        type: SimpleTextInputType.TEXT
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        element.label = result
        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });         
  }

  editStatus(element:UserTask) {
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usertasks.dueDate',
        label: 'usertasks.dueDate',
        value: element.status,
        type: SimpleTextInputType.MULTI_VALUED,
        allowedValues: Object.values(Status)
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        element.status = result
        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });        
  }

  editVersion(element:UserTask) {
    if (this.media === undefined) return
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usertasks.version',
        label: 'usertasks.version',
        value: element.mediaVersion?.value,
        type: SimpleTextInputType.MULTI_VALUED,
        allowedValues: this.media?.versions?.map(s => s.name) ?? [],
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        if (result === '[deleted]') {
          element.mediaVersion = undefined
        } else {
          const version = this.media?.versions?.find(s => s.name === result)
          element.mediaVersion = { id: version?.id, value: version?.name }
        }
        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });        
  }

  delete(element:UserTask, event:Event) {

    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'usertasks.confirm_delete',
        content: `usertasks.confirm_delete_message`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.datas = this.datas.filter(d => d.id !== element.id)
        this.dataSource.data = this.datas        
        this.userTaskService.deleteUserTask(element.id!).subscribe(_ => {})
      }
    });
  }

  editDueDate(element:UserTask) {
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usertasks.dueDate',
        label: 'usertasks.dueDate',
        value: element.dueDate,
        type: SimpleTextInputType.DATE
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        element.dueDate = result
        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });         
  }

  addUserTask() {
    console.log('addUserTask()')
    this.userTaskService.persistUserTask({
      media: {
        id: this.media?.id
      }
    }).subscribe(r => {
      this.datas.push(r as UserTask)
      this.dataSource.data = this.datas
    })
  }
  
  goto(element:UserTask, event:Event) {
    this.router.navigate(['media/' + element.media?.id], { fragment: 'userTasks' });    
  }

  editAssignee(element:UserTask) {
    const dialogRef = this.dialog.open(SimpleListDialog, {
      data: {
        title: 'usertasks.assignedTo',
        label: 'usertasks.assignedTo',
        initialValue: element.assignedTo ? { key: element.assignedTo?.id, value: element.assignedTo?.email, object: element.assignedTo } : undefined,
        loader: (val:any) => new Observable(observer => {
          const value = val instanceof Object ? val.value : val
          this.userService.getAutocompleteData('ALL', value)
            .subscribe(r => {
              const result = r?.map(c => { 
                return { key: c.id, value: c.email, object: c }
              })
              observer.next(result)
            })
        })
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log(`The dialog was closed ${data} <`);
      if (data === undefined) return
      element.assignedTo = data === '' ? undefined : { 
        username: data.object.email, 
        ...data.object 
      }
      this.userTaskService.persistUserTask(element).subscribe(_ => {})
    });  
  }

  editAssignability(element:UserTask) {
    const dialogRef = this.dialog.open(SimpleMetadataSelectDialog, {
      data: {
        title: 'usertasks.assignableTo',
        label: 'usertasks.assignableTo',
        attachedTo: [MetadataAttachmentType.USER],
        definitions: element.assignableTo?.map(a => {
          return {
            definition: a.metadata,
            value: a.value
          } 
        }) ?? [{}]
      },
      minWidth: '490px'
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed', data);
      if (data) {   
        element.assignableTo = data.map((d:MetadataDefinitionAndValue) => {
          return {
            metadata: d.definition,
            value: d.value
          }
        })

        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });  
  }

  editType(element:UserTask) {
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usertasks.type',
        label: 'usertasks.type',
        value: element.taskType?.label,
        type: SimpleTextInputType.MULTI_VALUED,
        allowedValues: this.taskTypes.content?.map(t => t.label) ?? []
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        element.taskType = this.taskTypes.content?.find(t => t.label === result)
        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });            
  }

  editStream(element:UserTask) {
    if (this.media === undefined) return
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usertasks.stream',
        label: 'usertasks.stream',
        value: element.mediaStream?.value,
        type: SimpleTextInputType.MULTI_VALUED,
        allowedValues: this.media?.expectedStreams?.map(s => s.representation) ?? []
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        if (result === '[deleted]') {
          element.mediaStream = undefined
        } else {
          const stream = this.media?.expectedStreams?.find(s => s.representation === result)
          element.mediaStream = { id: stream?.id, value: stream?.representation }
        }
        this.userTaskService.persistUserTask(element).subscribe(_ => {})
      }
    });            
  }
}
