import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Configuration } from '../models/configuration';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimpleTextDialog, SimpleTextInputType } from '../components/dialogs/simple.text.dialog';
import { ErrorDialog } from '../components/dialogs/error.dialog';

@Injectable({ providedIn: 'root' })
export class ErrorService {


  constructor(
    public dialog: MatDialog) {

  }


  private showDialog(error:string): void {
    if (this.dialog.openDialogs.filter(d => d.componentInstance instanceof ErrorDialog).length > 0) {
      console.log('showDialog: already open', error);
      return
    }
    console.log('showDialog');

    const dialogRef = this.dialog.open(ErrorDialog, {
      data: {
        title: 'Error',
        text: error,
        type: SimpleTextInputType.MONOSPACED,            
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }  

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      this.showDialog(`${operation} failed:\n${error.message}\n\n${JSON.stringify(error, null, 2)}`)
      
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
