import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Task } from '../models/task';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Translation, TranslationKey, TranslationsForKey } from '../models/translation';
import { i18n } from 'i18next';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class TranslationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  loadAdditionalTranslations(i18next: i18n) {
    const observables = 
      i18next.languages.map(l => {
        return this.http.get<TranslationKey[]>(`/api/additionalTranslations/${l}/i18nextFormat`).pipe(tap(r => {
          i18next.addResourceBundle(l, 'translation', r, true, true)
          console.log('added', l, i18next.getDataByLanguage(l))
          console.log('added', i18next.t('multi_valued.REFUSED'))
        }))
      })
    return forkJoin(observables)
  }

  
  getTranslations(availableLocales:string[]): Observable<TranslationsForKey[]> {
    return forkJoin([
        this.http.get<TranslationKey[]>(`/api/additionalTranslationKeys`),
        this.http.get<Translation[]>(`/api/additionalTranslations`)
      ]).pipe(
        map(s => {
          var result = new Map<string, Translation[]>()
          const [keys, translations] = s
          keys.forEach(k => result.set(k.key!, []))
          translations.forEach(t => result.get(t.key!)?.push(t))
          const finalResult:TranslationsForKey[] = []
          Array.from(result.keys()).forEach(k => {
            const translationsWithKeys = {
              key: k,
              translations: new Map<string, Translation>(
                result.get(k)!.map(r => [
                  r.language!, r!
                ])
              )
            }
            availableLocales.forEach(l => {
              if (!translationsWithKeys.translations.has(l)) {
                translationsWithKeys.translations.set(l, {
                  key: k,
                  language: l
                })
              }
            })
            finalResult.push(translationsWithKeys)
          })
          return finalResult
        })
      );
  }

  deleteKey(key: string) {
    const url = `/api/additionalTranslationKey?key=${key}`;
    return this.http.delete<void>(url)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistKey`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }  

  persistKey(key:string) {
    const url = `/api/additionalTranslationKey?key=${key}`;
    return this.http.post<void>(url, null)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistKey`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  persistTranslations(key:string, translations:Translation[]) {
    translations.forEach(t => t.key = undefined)
    const url = `/api/additionalTranslations?key=${key}`;
    return this.http.post<void>(url, translations)
      .pipe(
        catchError(this.errorService.handleError<void>(`persistTranslations`))
      ).pipe(
        map(result => {
          return result
        })
      );
  }

  private log(message: string) {
    console.log(`TaskService: ${message}`);
  }
}
