<h1 mat-dialog-title>{{"users.editUser" | i18next}}</h1>
<mat-dialog-content>

    <mat-form-field appearance="fill">
        <mat-label>{{"users.email" | i18next}}</mat-label>
        <input matInput [disabled]="data.user.id !== undefined" [(ngModel)]="data.user.email">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="!data.groupMode">
        <mat-label>{{"users.group" | i18next}}</mat-label>
        <mat-select [(ngModel)]="data.user.group" [compareWith]="compareGroupFn">
            <mat-option [value]="undefined">
                —
            </mat-option>
            <mat-option *ngFor="let group of groups" [value]="group">
                {{group.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>    

    <mat-checkbox [(ngModel)]="data.user.groupAdmin" style="margin-bottom: 15px; margin-right: 20px">
        {{ "users.groupAdmin" | i18next }}
    </mat-checkbox>

    <mat-form-field appearance="fill">
        <mat-label>{{"users.first_name" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.user.firstName">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"users.last_name" | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.user.lastName">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{"users.password" | i18next}}</mat-label>
        <input matInput password [(ngModel)]="data.password" type="password">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="!data.groupMode">
        <mat-label>{{"users.roles" | i18next}}</mat-label>
        <mat-select [(ngModel)]="data.user.roles" [compareWith]="compareFn"  multiple>
            <mat-option *ngFor="let role of roles" [value]="role.name">
                {{role.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{"global.cancel" | i18next}}</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"global.ok" | i18next}}</button>
    </div>
</mat-dialog-content>