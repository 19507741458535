import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { OutgestProfile, OutgestProfileType } from 'src/app/models/outgest.profile';
import { OutgestService } from 'src/app/services/outgest.service';
import { confirmDialog, messageDialog } from 'src/app/utils/utils';

@Component({
  selector: 'app-transcoding-profiles',
  templateUrl: './transcoding-profiles.component.html',
  styleUrls: ['./transcoding-profiles.component.sass']
})
export class TranscodingProfilesComponent {

  loading: boolean = true
  dataSource: OutgestProfile[] = []
  columnsToDisplay: string[] = ['name', 'tools']


  constructor(private outgestService: OutgestService,
    public dialog: MatDialog) {
  }
  
  ngOnInit(): void {
    this.getProfiles();
  }

  addProfile() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'transcoding_profiles.create_profile',
        text: 'transcoding_profiles.name',
        label: 'transcoding_profiles.name',
        value: '',
        type: SimpleTextInputType.TEXT,                    
      },
    });

    dialogRef.afterClosed().subscribe((name:string) => {
      console.log('The dialog was closed', name);
      if (name) {    
        this.outgestService.persistProfile({
          name,
          hasMarkInAndMarkOut: false,
          hasPreroll: false,
          numberOfAudioStreams: 1,
          numberOfFiles: 0,
          numberOfSubtitlesStreams: 1,
          numberOfVideoStreams: 1
        }).subscribe(s => {
          this.loading = true
          this.getProfiles()
        })
      }
    }); 
  }

  editProfile(profile:OutgestProfile) {
    const dialogRef = this.dialog.open(OutgestProfileDialog, {
      data: {
        profile: cloneDeep(profile)
      },
      minWidth: '50vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result == undefined) return
      if (!result.type) result.type = OutgestProfileType.FFMPEG
      this.outgestService.persistProfile(result).subscribe(_ => {
        this.loading = true
        this.getProfiles()
      })
    });  
  }

  deleteProfile(profile:OutgestProfile) {
    confirmDialog(this.dialog, 
      i18next.t('transcoding_profiles.delete_profile')!, () => {
        this.outgestService.deleteProfile(profile.id!).subscribe(_ => {
          this.loading = false
          this.getProfiles()
        })
      })        
  }

  getProfiles(): void {
    this.outgestService.getOutgestProfiles()
        .subscribe(result => {
          this.dataSource = result.content ?? []
          this.loading = false
        });
  }
  
}

@Component({
  selector: 'dialog-outgestprofile',
  templateUrl: './dialog-outgestprofile.html',
  styleUrls: [ './dialog-outgestprofile.sass' ]
})
export class OutgestProfileDialog {

  Object = Object

  constructor(
    public dialogRef: MatDialogRef<OutgestProfileDialog>,
    public outgestService: OutgestService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    
  }

  compareFn(r1:OutgestProfile, r2:OutgestProfile) {
    return (r1.id == r2.id);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
