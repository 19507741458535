<h1>{{"qc.title" | i18next}} </h1>

<div class="buttons">
    <button mat-fab class="add" matTooltip="Add a new QC profile" (click)="addProfile()">
        <mat-icon>add</mat-icon>
    </button>
  </div>
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="result?.numberOfElements; else firstLoad" style="margin-bottom: 50px">
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{"qc.id" | i18next}} </th>
        <td mat-cell *matCellDef="let element"> #{{element.id}} </td>
      </ng-container>
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{"qc.name" | i18next}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>


      <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteProfile(element, $event)">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </td>
    </ng-container>

      <tr mat-row (click)="clickedRow(row)" *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>
  
  <ng-template #firstLoad>
      <div *ngIf="loading; else empty">
          <mat-spinner></mat-spinner>
      </div>
  </ng-template>
  <ng-template #empty>
      <p>{{"empty.profiles" | i18next}}</p>
  </ng-template>