import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { UserGroup } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { confirmDialog, messageDialog } from 'src/app/utils/utils';

@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.sass']
})
export class UsergroupsComponent {

  loading: boolean = true
  dataSource: UserGroup[] = []
  columnsToDisplay: string[] = ['id', 'name', 'tools']


  constructor(private userService: UserService,
    public dialog: MatDialog) {
  }
  
  ngOnInit(): void {
    this.getGroups();
  }

  addGroup() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'usergroups.create_group',
        text: 'usergroups.name',
        label: 'usergroups.name',
        value: '',
        type: SimpleTextInputType.TEXT,                    
      },
    });

    dialogRef.afterClosed().subscribe((name:string) => {
      console.log('The dialog was closed', name);
      if (name) {    
        this.userService.persistGroup({
          name
        }).subscribe(s => {
          this.loading = true
          this.getGroups()
        })
      }
    }); 
  }

  editGroup(group:UserGroup) {
    const dialogRef = this.dialog.open(UserGroupDialog, {
      data: {
        group: cloneDeep(group)
      },
      minWidth: '50vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result == undefined) return
      this.userService.persistGroup(result.group).subscribe(_ => {
        this.loading = true
        this.getGroups()
      })
    });  
  }

  deleteGroup(group:UserGroup) {
    confirmDialog(this.dialog, 
      i18next.t('usergroups.delete_group')!, () => {
        this.userService.deleteGroup(group.id!).subscribe(result => {
          if (result && result.error) {
            messageDialog(this.dialog, i18next.t('usergroups.cannot_delete_group'), i18next.t('usergroups.cannot_delete_group_detail'))
            return    
          } else {
            this.loading = false
            this.getGroups()
          }
        })
      })        
  }

  getGroups(): void {
    this.userService.getUserGroups()
        .subscribe(result => {
          this.dataSource = result.content ?? []
          this.loading = false
        });
  }
  
}

@Component({
  selector: 'dialog-usergroup',
  templateUrl: './dialog-usergroup.html',
  styleUrls: [ './dialog-usergroup.sass' ]
})
export class UserGroupDialog {

  Object = Object

  constructor(
    public dialogRef: MatDialogRef<UserGroupDialog>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    
  }

  compareFn(r1:UserGroup, r2:UserGroup) {
    return (r1.id == r2.id);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
