
<header>
  <h1>{{ "partnerUpload.pendingTitle" | i18next }}</h1>

  <mat-form-field appearance="fill">
    <mat-label>{{ "partnerUpload.filterBy" | i18next }}</mat-label>
    <mat-select [(ngModel)]="filterBy" (selectionChange)="getPartnerUploads()">
        <mat-option *ngFor="let filter of filters" [value]="filter">
            {{'partnerUpload.filters.' + filter | i18next}}
        </mat-option>
    </mat-select>
  </mat-form-field>  
</header>

<p style="padding-bottom: 15px">
  {{ "partnerUpload.description" | i18next }}
</p>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="result?.numberOfElements; else firstLoad">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="media">
      <th mat-header-cell *matHeaderCellDef> Media</th>
      <td mat-cell *matCellDef="let element"> {{element.media.value}} (#{{element.media.id}}) </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ "partnerUpload.status" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{ "enums." + element.status | i18next }}</span> </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef> {{ "partnerUpload.creationDate" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.creationDate | date: dateFormat }} </td>
    </ng-container>    

    <ng-container matColumnDef="forPartner">
      <th mat-header-cell *matHeaderCellDef> {{ "partnerUpload.forPartner" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> {{ element.partner.value }} </td>
    </ng-container>    
    
    <ng-container matColumnDef="tools">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-icon-button (click)="openMedia($event, element)" *ngIf="element.allowStreaming">
          <mat-icon>play_circle</mat-icon>
        </button>        
      </td>
    </ng-container>    
    
    <tr mat-row (click)="clickedRow(row)" *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>

<ng-template #firstLoad>
    <div *ngIf="loading; else empty">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #empty>
</ng-template>