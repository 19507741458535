import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Media } from 'src/app/models/media';
import { MetadataAttachmentType, MetadataDefinition, MetadataType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { SearchCondition } from 'src/app/models/search.condition';
import { MediaService } from 'src/app/services/media.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { SearchService } from 'src/app/services/search.service';
import { SimpleTextDialog, SimpleTextInputType } from '../../components/dialogs/simple.text.dialog';
import { SimpleListDialog } from '../../components/dialogs/simple.list.dialog';
import { Observable, delay, forkJoin, tap } from 'rxjs';
import { Search } from 'src/app/models/search';
import { UserService } from 'src/app/services/user.service';
import { UserPreferences } from 'src/app/models/user';
import { ImportDialog } from 'src/app/components/dialogs/import.dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfigurationService } from 'src/app/services/configuration.service';
import i18next from 'i18next';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { UserActionDefinition } from 'src/app/models/user.action';
import { UserActionsService } from 'src/app/services/user.actions.service';
import { handleTableResponsivness } from 'src/app/utils/utils';

export interface CreateDialogData {
  title: string;
}

enum Using {
  SIMPLE_SEARCH,
  FILTER_SEARCH,
  INITIAL_LOAD
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent {
  constructor(private route: ActivatedRoute, 
    private mediaService: MediaService,
    private configurationService: ConfigurationService,
    private userService:UserService,
    private metadataService:MetadataService,
    private searchService:SearchService,
    public dialog: MatDialog,
    private router: Router,
    private userActionService: UserActionsService,
    private responsive: BreakpointObserver) { }
    
  filters: Array<SearchCondition> = [{ operator: 'LIKE', formula: false, metadataValue: '' }]
  
  result: Page<Media> = {}
  currentlyUsing: Using = Using.INITIAL_LOAD
  currentSort = 'id,DESC,false'
  hasExternalImportAvailable: boolean = false;
  loading: boolean = true
  initialLoading: boolean = true
  metadataLoading: boolean = true
  dataSource: Media[] = [];
  columnsToDisplay: string[] = ['preview', 'name', 'elementCount', 'streamCount']
  userPreferences: UserPreferences|undefined;
  metadataTypes:typeof MetadataType = MetadataType;
  queryParams:ParamMap|undefined;
  paramMap: ParamMap|undefined;
  filtersOpened:boolean = false
  i18next = i18next
  searchValue:string = ''
  isHandset: boolean = false
  executeActionForSavedSearch: boolean = false
  selectedSearch: Search|undefined
  actions: UserActionDefinition[] = []

  additionalSearchMetadatas = [{
    name: 'title',
    type: MetadataType.TEXT,
    internal: true,
    label: 'OpenMAM media title'
  }]

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.paramMap = params
    })
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.queryParams = params
    })
    this.userActionService.getActions().subscribe(a => this.actions = a.content ?? [])
    this.userService.getUserPreferences().subscribe(s => {
      this.userPreferences = s
      console.log(this.userPreferences)
      if (this.userPreferences?.columnsDisplayedOnMedias?.length)
        this.columnsToDisplay = ['preview', ...this.userPreferences.columnsDisplayedOnMedias]
    })

    if (window.location.hash === '#open') {
      this.filtersOpened = true
    }

    this.hasExternalImportAvailable = this.configurationService.configurations
        .filter(c => c.key == 'EXTERNAL_IMPORT_ENDPOINT')[0]?.value !== undefined
    
    this.metadataService.getMetadataGroups().subscribe(() => {
      
      if (this.paramMap?.keys.length) {
        const id:number = parseInt(this.paramMap.get('userSearchId') ?? '-1')
        this.searchService.getSavedSearchById(id).subscribe(search => {
          this.restoreGivenSearch(search)
        })
      } else if (this.queryParams?.keys.length) {
        console.log(this.queryParams)
        const id:number = parseInt(this.queryParams.get('searchId') ?? '-1')
        const value:string = this.queryParams.get('searchValue') ?? ''
        const definition = this.metadataService.metadataDefinitions.find(md => md.id === id)
        console.log('init search with', id, value, definition)
        this.metadataService.getMetadataReference(parseInt(value)).subscribe(reference => {
          definition!.searchable = true
          this.filters = [
            { 
              metadataDefinition: definition,
              operator: 'LIKE',
              formula: false,
              metadataValue: reference
            }
          ]
          this.filtersOpened = true
          this.applyFilter()  
        })
      } else {
        this.getMedias()
      }
      this.metadataLoading = false
    });
    
    this.responsive.observe(Breakpoints.Handset)
      .subscribe(result => {
        this.isHandset = false; 
        if (result.matches) {
          this.isHandset = true;
        }
    });    
  }

  searchFiltersOpened() {
    let state = '/search'
    if (this.paramMap?.has('userSearchId')) state += `/${this.paramMap.get('userSearchId')}`
    state += '#open'
    window.history.replaceState({}, '', state);
  }
  
  searchFiltersClosed() {
    let state = '/search'
    if (this.paramMap?.has('userSearchId')) state += `/${this.paramMap.get('userSearchId')}`
    state += '#'
    window.history.replaceState({}, '', state);
  }

  nextPage() {
    if (this.result.last) return
    this.loading = true
    this.result.pageable!.pageNumber!++
    if (this.currentlyUsing == Using.INITIAL_LOAD) {
      this.getMedias()
    } else {
      this.applyFilter()
    }
  }

  previousPage() {
    if (this.result.first) return
    this.loading = true
    this.result.pageable!.pageNumber!--
    
    if (this.currentlyUsing == Using.INITIAL_LOAD) {
      this.getMedias()
    } else {
      this.applyFilter()
    }
  }  

  getMetadataDefinition(name:string) {
    return this.metadataService.getMetadataDefinitionFromCache(name)
  } 

  restoreSearch() {
    const dialogRef = this.dialog.open(SimpleListDialog, {
      data: {
        title: 'search.restore',
        text: 'search.pick_one',
        label: 'search.saved_search',
        loader: (val:string) => new Observable(observer => {
          this.searchService.getSavedSearch()
            .subscribe(r => {
              const result = r.content
                ?.filter(r => r.name!.toLowerCase().indexOf(val.toLowerCase()) > -1)
                .map(c => { 
                  return { key: c.id, value: c.name, object: c }
                })
              observer.next(result)
            })
        })
      },
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed', data);
      if (data) {   
        // find out the right metadata definition for each condition
        const search = data.object as Search
        this.restoreGivenSearch(search)
      }
    });  
  }

  deleteCurrentSearch() {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'search.please_confirm',
        content: i18next.t('search.sure_to_delete', {})
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.searchService.deleteSavedSearch(this.selectedSearch!.id!).subscribe(_ => {
      
        })        
      }
    });    
  }

  restoreGivenSearch(search:Search) {
    this.selectedSearch = search
    const observables:Observable<any>[] = []
    this.executeActionForSavedSearch = search.actionIdToTriggerWhenMatched !== undefined
    this.currentSort = search.sortMetadatas ?? 'id,DESC,false'
    search.conditions?.forEach(element => {
      element.id = undefined
      element.metadataDefinition = this.metadataService.metadataDefinitions
        .filter(m => m.id == element.metadataDefinition?.id)[0]
      // and do the same for metadata reference pointed values    
      if (element.metadataDefinition.type == MetadataType.REFERENCE &&
          element.metadataValue) {
        observables.push(this.metadataService
          .getMetadataReference(parseInt(element.metadataValue))
          .pipe(tap(r => element.metadataValue = r)))
      }
    });
    
    if (observables.length) {
      forkJoin(observables).subscribe(r => {
        console.log('filled conditions', search.conditions)
        this.filters = search.conditions ?? []
        this.applyFilter()
      })
    } else {
      this.filters = search.conditions ?? []
      this.applyFilter()
    }    

    let state = `/search/${this.selectedSearch.id}${document.location.hash}`
    window.history.replaceState({}, '', state);    
  }

  sortBy(column:string, isMetadata:boolean=false) {
    console.log('sortBy', column)
    let [searchBy, searchOrder] = this.currentSort.split(',')
    if (searchBy === column) {
      if (searchOrder === 'ASC') {
        searchOrder = 'DESC'
      } else {
        searchOrder = 'DESC' 
        searchBy = 'id'
        isMetadata = false               
      }
    } else {
      searchBy = column
      searchOrder = 'ASC'  
    }
    this.currentSort = `${searchBy},${searchOrder},${isMetadata}`
    if (this.currentlyUsing == Using.INITIAL_LOAD) {
      this.getMedias()
    } else {
      this.applyFilter()
    }
  }

  customColumnsFromMetadatas() {
    return this.userPreferences?.columnsDisplayedOnMedias?.filter(
      c => c !== "name" && c !== "preview" && c !== "elementCount" && c !== "streamCount" && c !== "createdBy")
  }

  searchNotificationChanged(value:any) {
    if (!value.value) this.executeActionForSavedSearch = false
    this.saveCurrentSearch()
  }

  saveCurrentSearch() {

    if (this.selectedSearch) {
      if (!this.executeActionForSavedSearch) delete this.selectedSearch.actionIdToTriggerWhenMatched
      this.selectedSearch.conditions = this.filters
      this.selectedSearch.sortMetadatas = this.currentSort
      this.searchService.persistSearch(this.selectedSearch!).subscribe(s => {
        console.log('saved')
      })
      return
    }
    
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'search.save_search',
        text: 'search.name_search',
        label: 'search.Name',
        value: '',
        type: SimpleTextInputType.TEXT,                    
      },
    });

    dialogRef.afterClosed().subscribe(name => {
      console.log('The dialog was closed', name);
      if (name) {    

        const searchFilters = JSON.parse(JSON.stringify(this.filters))
        for (let filter of searchFilters) {
            // filter out these values that aren't properly serialized back
            // for optimization purpose
            if (filter.metadataDefinition) {
              
              if ((filter.metadataDefinition!.type == MetadataType.REFERENCE ||
                  filter.metadataDefinition!.type == MetadataType.USER) && filter.metadataValue) {
                // they're containing the whole object
                filter.metadataValue = (filter.metadataValue as any).id
                filter.metadataDefinition!.referencedMetadataGroup = undefined
              }
            }
        }

        this.searchService.persistSearch({
          conditions: searchFilters,
          name,
          sortMetadatas: this.currentSort,
        }).subscribe(s => {
          console.log('saved')
          this.selectedSearch = s!
        })
      }
    });    

  }

  addFilter() {
    this.filters.push({
        operator: 'EQUAL', formula: false,
    })
  }

  deleteFilter(filter:SearchCondition) {
    this.filters = this.filters.filter(f => f !== filter)
    if (this.filters.length === 0) this.addFilter()
  }

  triggerSimpleSearch() {
    console.log('triggerSimpleSearch')
    this.loading = true
    this.currentlyUsing = Using.SIMPLE_SEARCH
    this.mediaService.searchMedias([{
      metadataDefinition: {
        name: 'title'
      },
      formula: false,
      internal: true,
      metadataValue: this.searchValue + '*',
      operator: 'LIKE'
    }], this.currentSort)
      .pipe(delay(200))
      .subscribe(result => {
        this.result = result
        this.dataSource = this.result.content ?? []
        this.loading = false
        setTimeout(() => handleTableResponsivness(), 0)
      });    
  }

  ngAfterViewInit() {
    window.onresize = () => {
      handleTableResponsivness()
    }
  }
  
  applyFilter() {
    this.loading = true
    this.currentlyUsing = Using.FILTER_SEARCH
    this.filters.forEach(f => {
      if (f.metadataDefinition) f.metadataDefinition!.referencedMetadataGroupEntity = undefined
      if (!f.metadataDefinition?.id) f.internal = true
    })
    console.log('apply filter', JSON.stringify(this.filters))
    const searchFilters = JSON.parse(JSON.stringify(this.filters))
    for (let filter of searchFilters) {
        // filter out these values that aren't properly serialized back
        // for optimization purpose
        if (filter.metadataDefinition) {
          
          if ((filter.metadataDefinition!.type == MetadataType.REFERENCE ||
              filter.metadataDefinition!.type == MetadataType.USER) && filter.metadataValue) {
            // they're containing the whole object
            filter.metadataValue = (filter.metadataValue as any).id
            filter.metadataDefinition!.referencedMetadataGroup = undefined
          }
          
        }
    }
    this.mediaService.searchMedias(searchFilters, this.currentSort, this.result.pageable?.pageNumber ?? 0)
      .pipe(delay(200))    
      .subscribe(result => {
        this.result = result
        this.dataSource = this.result.content ?? []
        this.loading = false
        this.initialLoading = false        
        setTimeout(() => handleTableResponsivness(), 0)
      });
    console.log('apply filter', JSON.stringify(this.filters))
  }

  clickedRow(row:any): void {
    console.log(row)
    this.router.navigate([`/media/${row.id}`]);
  }

  openImportDialog(): void {
    console.log('openImportDialog');

    const dialogRef = this.dialog.open(ImportDialog, {
      data: {
        multipleSelection: true
      },
    });

    dialogRef.afterClosed().subscribe((result:SelectionModel<any>) => {
      console.log('The dialog was closed', result);
      if (result) {
        const datas:any = []
        for (const sel of result.selected.values()) {
          datas.push({
            externalId: sel.id,
            name: sel.representation
          })
        }
        this.mediaService.createMedias(datas).subscribe(result => this.getMedias());
      }
    });
  }

  filterMetadatas(md:MetadataDefinition) {
    return md.parentGroup?.attachmentType !== MetadataAttachmentType.REFERENCEABLE &&
      (md.searchable)
  }  

  openCreateDialog(): void {
    console.log('openCreateDialog');

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'search.create_media',
        text: 'search.enter_name',
        label: 'search.name_placeholder',
        value: '',
        type: SimpleTextInputType.TEXT,                    
      },
    });

    dialogRef.afterClosed().subscribe(title => {
      console.log('The dialog was closed', title);
      if (title) {
        this.mediaService.createMedia(title)
          .subscribe(result => this.getMedias());
      }
    });    
  }

  getMedias(): void {
    this.mediaService.getMedias(this.result.pageable?.pageNumber ?? 0, this.currentSort)
      .subscribe(result => {
        this.result = result
        this.dataSource = this.result.content ?? []
        this.loading = false
        this.initialLoading = false
        setTimeout(() => handleTableResponsivness(), 0)
      });
  }

}

