import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { MetadataAttachmentType, MetadataGroup, MetadataType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { MetadataService } from 'src/app/services/metadata.service';

export interface CreateMetadataGroupDialogData {
  group:MetadataGroup,
  metadataAttachmentTypes: MetadataAttachmentType
}

@Component({
  selector: 'app-metadata-schema',
  templateUrl: './metadata-schema.component.html',
  styleUrls: ['./metadata-schema.component.sass']
})
export class MetadataSchemaComponent {
  
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
  result: Page<MetadataGroup> = {}
  loading: boolean = true
  orderChanged:boolean = false;
  dataSource: MetadataGroup[] = []
  columnsToDisplay: string[] = ['order', 'name', 'attachmentType', 'system']
  metadataTypes:typeof MetadataType = MetadataType;
  @ViewChild('table') table: MatTable<MetadataGroup>|undefined;
  
  constructor(private metadataService: MetadataService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getMetadataGroups(true);
  }

  openMetadataGroupDialog() {

    console.log('openMetadataDefinitionDialog');

    const dialogRef = this.dialog.open(CreateMetadataGroupDialog, {
      data: {
        metadataAttachmentTypes: this.metadataAttachmentTypes,
        group: {
          attachmentType: MetadataAttachmentType.MEDIA
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result == undefined) return
      this.loading = true
      this.metadataService.createMetadataGroup(result.group).subscribe(_ => this.getMetadataGroups(true))
    });  
  }

  saveOrderChange() {
    this.orderChanged = false
    this.metadataService.persistMetadataGroupOrders(this.dataSource.map(md => md.id!)).subscribe()
  }

  dropTable(event: CdkDragDrop<MetadataGroup[]>) {
    const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
    this.table?.renderRows();
    this.orderChanged = true
  }

  clickedRow(row:any): void {
    console.log(row)
    this.router.navigate([`/metadata_schema/${row.id}`]);
  }
  
  getMetadataGroups(forceReload=false): void {
    this.metadataService.getMetadataGroups(forceReload)
      .subscribe(result => {
        this.result = result
        this.loading = false
        this.dataSource = this.result.content ?? []
        console.log('set datasource to', this.dataSource)
      });
  }
}

@Component({
  selector: 'dialog-metadata-group',
  templateUrl: './dialog-metadata-group.html',
  styleUrls: [ './dialog-metadata-group.sass' ]
})
export class CreateMetadataGroupDialog {

  Object = Object

  constructor(
    public dialogRef: MatDialogRef<CreateMetadataGroupDialog>,
    public metadataService: MetadataService,
    @Inject(MAT_DIALOG_DATA) public data: CreateMetadataGroupDialogData,
  ) {}

  ngOnInit() {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
