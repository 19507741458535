import { Location } from 'src/app/models/media';
import { SelectionChange } from '@angular/cdk/collections';
import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, ParamMap } from '@angular/router';
import i18next from 'i18next';
import { BehaviorSubject, Observable, delay, forkJoin, of, tap } from 'rxjs';
import { MoveMediaDialog } from 'src/app/components/dialogs/move.media.dialog';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { MetadataAttachmentType, MetadataDefinition, MetadataDefinitionAndValue, MetadataReference, MetadataType } from 'src/app/models/metadata';
import { TaskType } from 'src/app/models/task';
import { Role, User } from 'src/app/models/user';
import { AutoTriggerOn, UserActionDefinition, UserActionMetadataDefinition } from 'src/app/models/user.action';
import { MetadataService } from 'src/app/services/metadata.service';
import { UserActionsService } from 'src/app/services/user.actions.service';
import { UserService } from 'src/app/services/user.service';
import { Page } from 'src/app/models/page';
import { OutgestDialog } from 'src/app/components/dialogs/outgest.dialog';
import { secondsToTimecode, timecodeToSeconds } from 'src/app/utils/utils';
import { UserTaskService } from 'src/app/services/usertask.service';
import { UserTaskType } from 'src/app/models/usertask';
import { SimpleMetadataSelectDialog } from 'src/app/components/dialogs/simple.metadata.select.dialog';
import { dynamicMetadataValues } from 'src/app/models/constants';

@Component({
  selector: 'app-action-detail',
  templateUrl: './action-detail.component.html',
  styleUrls: ['./action-detail.component.sass']
})
export class ActionDetailComponent {

  hasPostExecutionTask:boolean = false
  hasPostExecutionUserTask:boolean = false
  hasPostExecutionUserTaskToComplete:boolean = false
  action:UserActionDefinition|undefined
  actionAttachmentObserver:Observable<MetadataAttachmentType>|undefined
  autoTriggerOn:typeof AutoTriggerOn = AutoTriggerOn;
  loading:boolean = true
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
  attachmentMetadataTypesAllowed:MetadataAttachmentType[] = [MetadataAttachmentType.MEDIA, MetadataAttachmentType.MEDIA_VERSION, MetadataAttachmentType.MEDIA_STREAM]
  conditionsMetadataTypesAllowed:MetadataAttachmentType[] = []
  consequenceMetadataTypesAllowed:MetadataAttachmentType[] = []
  taskToCreateAssignmentTypesAllowed:MetadataAttachmentType[] = []
  userMetadataDefinitions:MetadataDefinition[] = []
  metadataTypes:typeof MetadataType = MetadataType;
  taskType:typeof TaskType = TaskType;
  Object = Object
  saving:boolean = false
  roles:Role[] = []
  actions: Page<UserActionDefinition> = {};
  taskSupported: boolean = true;
  i18next=i18next
  userTaskTypes: UserTaskType[] = []; 

  constructor(
    private dialog: MatDialog,
    private userActionService: UserActionsService,
    private metadataService: MetadataService,
    private userService: UserService,
    private userTaskService: UserTaskService,
    private route: ActivatedRoute, 
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const actionId:number = parseInt(params.get('actionId') ?? '-1')
      this.getAction(actionId)
    });

    this.userService.getRoles().subscribe(roles => {
      this.roles = roles;
    })
    this.userTaskService.getUserTaskTypes().subscribe(userTaskTypes => {
      this.userTaskTypes = userTaskTypes.content ?? [];
    })
  }

  taskToCreateDetails() {
    const dialogRef = this.dialog.open(SimpleMetadataSelectDialog, {
      data: {
        title: 'usertasks.assignableTo',
        label: 'usertasks.assignableTo',
        attachedTo: [MetadataAttachmentType.USER],
        definitions: this.action?.userTaskToCreate?.assignableTo?.map(a => {
          return {
            definition: a.metadata,
            value: a.value
          } 
        }) ?? [{}]
      },
      minWidth: '490px'
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed', data);
      if (data) {   
        this.action!.userTaskToCreate!.assignableTo = data
          .filter((d:any) => d.value !== undefined)
          .map((d:MetadataDefinitionAndValue) => {
            return {
              metadata: d.definition,
              value: d.value
            }
        })
      }
    });      
  }
 
  attachmentChanged(event:any) {
    console.log(event.value)
    if (event.value === MetadataAttachmentType.MEDIA_VERSION) {
      this.taskToCreateAssignmentTypesAllowed = [MetadataAttachmentType.MEDIA, MetadataAttachmentType.MEDIA_VERSION]
      this.consequenceMetadataTypesAllowed = [MetadataAttachmentType.MEDIA_VERSION, MetadataAttachmentType.MEDIA_STREAM]
      this.conditionsMetadataTypesAllowed = [MetadataAttachmentType.MEDIA_VERSION, MetadataAttachmentType.MEDIA_STREAM]
    } else if (event.value === MetadataAttachmentType.MEDIA_STREAM) {
      this.taskToCreateAssignmentTypesAllowed = [MetadataAttachmentType.MEDIA, MetadataAttachmentType.MEDIA_STREAM]
      this.conditionsMetadataTypesAllowed = [MetadataAttachmentType.MEDIA_VERSION, MetadataAttachmentType.MEDIA_STREAM]
      this.consequenceMetadataTypesAllowed = [MetadataAttachmentType.MEDIA_VERSION, MetadataAttachmentType.MEDIA_STREAM]
    } else {
      this.taskToCreateAssignmentTypesAllowed = [event.value]
      this.consequenceMetadataTypesAllowed = [event.value]
      this.conditionsMetadataTypesAllowed = [event.value]
    }
    this.conditionsMetadataTypesAllowed = [...this.conditionsMetadataTypesAllowed, MetadataAttachmentType.USER]

    this.userMetadataDefinitions = this.metadataService.metadataDefinitions
      .filter(m => this.taskToCreateAssignmentTypesAllowed.indexOf(m.parentGroup?.attachmentType!) > -1)
      .filter(m => m.type === MetadataType.USER)    
    // this.consequenceMetadataTypesAllowed = [...this.consequenceMetadataTypesAllowed, MetadataAttachmentType.USER]
  }

  taskToLaunchChanged(event:any) {
    if (this.action?.attachableTo === MetadataAttachmentType.MEDIA) {
      this.taskSupported = event.value === TaskType.COPY_OR_MOVE_ASSET
    } else if (this.action?.attachableTo === MetadataAttachmentType.MEDIA_VERSION) {
      this.taskSupported = event.value === TaskType.FFMPEG_OUTGEST
    } else {
      this.taskSupported = false
    }
  }

  addCondition() {
    this.action?.conditions?.push({})
  }

  removeCondition(condition:UserActionMetadataDefinition) {
    this.action?.conditions?.splice(this.action?.conditions.indexOf(condition), 1)
  }

  removeConsequence(consequence:UserActionMetadataDefinition) {
    this.action?.consequences?.splice(this.action?.consequences.indexOf(consequence), 1)
  }

  addConsequence() {
    this.action?.consequences?.push({})
  }

  compareObjects(o1: any, o2: any) {
    return o1.id == o2.id
  }  

  postExecutionUserTaskChanged(event:MatCheckboxChange) {
    if (event.checked) {
      this.action!.userTaskToCreate = {}
    } else {
      this.action!.userTaskToCreate = undefined
    }
  }

  postExecutionUserTaskToCompleteChanged(event:MatCheckboxChange) {
    if (!event.checked) {
      this.action!.userTaskToComplete = undefined
    }
  }

  postExecutionTaskChanged(event:MatCheckboxChange) {
    if (event.checked) {
      this.action!.taskToLaunchTemplate = {}
    } else {
      this.action!.taskToLaunchTemplate = undefined
    }
  }

  editPostExecutionTask() {
    if (this.action?.taskToLaunchTemplate?.type === TaskType.COPY_OR_MOVE_ASSET) {

      const dialogRef = this.dialog.open(MoveMediaDialog, {
        data: {
          locationId: this.action.taskToLaunchTemplate?.destinationLocation?.id,
          copy: this.action.taskToLaunchTemplate?.additionalJobInputs?.copy,
        },
      });
      dialogRef.afterClosed().subscribe(resultData => {
        console.log('The dialog was closed', resultData);
        if (!resultData) return
        this.action!.taskToLaunchTemplate!.template = true
        this.action!.taskToLaunchTemplate!.destinationLocation = resultData.locations
                  .filter((l:Location) => l.id === resultData.locationId)[0]
        this.action!.taskToLaunchTemplate!.additionalJobInputs = {
          copy: resultData.copy,
          createdFromAction: this.action!.id,
        }
      });
    } else if (this.action?.taskToLaunchTemplate?.type === TaskType.FFMPEG_OUTGEST) {
      const dialogRef = this.dialog.open(OutgestDialog, {
        data: {
          availableFiles: [],
          availableVideoStreams: [],
          availableAudioStreams: [],
          availableSubtitleStreams: [],
          selectedOutgestProfile: this.action.taskToLaunchTemplate?.additionalJobInputs?.selectedOutgestProfile,
          destinationLocation: this.action.taskToLaunchTemplate?.destinationLocation,
          user: this.userService.currentUser,
          tcIn: this.action.taskToLaunchTemplate?.additionalJobInputs?.tcIn ?? '00:00:00-00',
          tcOut: this.action.taskToLaunchTemplate?.additionalJobInputs?.tcOut ?? '00:00:00-00',
          createSpareFile: this.action.taskToLaunchTemplate?.additionalJobInputs?.createSpareFile,
          spareFileAccessibleToPartners: this.action.taskToLaunchTemplate?.additionalJobInputs?.spareFileAccessibleToPartners,
          streamSelectionHidden: true,
          outputFilenamePrefix: this.action.taskToLaunchTemplate?.additionalJobInputs?.outputFilenamePrefix
        },
      });
      dialogRef.afterClosed().subscribe(resultData => {
        console.log('The dialog was closed', resultData);
        if (!resultData) return
        this.action!.taskToLaunchTemplate!.template = true
        this.action!.taskToLaunchTemplate!.destinationLocation = resultData.destinationLocation,
        this.action!.taskToLaunchTemplate!.additionalJobInputs = {
          outgestProfile: resultData.selectedOutgestProfile,
          tcIn: timecodeToSeconds(resultData.tcIn),
          tcOut: timecodeToSeconds(resultData.tcOut),
          outputFilenamePrefix: resultData.outputFilenamePrefix,
          createSpareFile: resultData.createSpareFile,
          spareFileAccessibleToPartners: resultData.spareFileAccessibleToPartners,
        }
      });      
    }
  }
  
  getAction(actionId: number) {
    this.userActionService.getActions().subscribe(actions => {
      this.actions = actions
    })
    this.userActionService.getAction(actionId).subscribe(action => {
      this.action = action

      const all:UserActionMetadataDefinition[] = []
      if (this.action?.conditions) {
        all.push(...this.action.conditions)
      }
      if (this.action?.consequences) {
        all.push(...this.action.consequences)
      }

      if (this.action?.taskToLaunchTemplate) {
        this.hasPostExecutionTask = true
      }
      if (this.action?.userTaskToCreate) {
        this.hasPostExecutionUserTask = true
      }
      if (this.action?.userTaskToComplete) {
        this.hasPostExecutionUserTaskToComplete = true
      }
      this.attachmentChanged({value: this.action.attachableTo})

      const observables:Observable<any>[] = [];
      (this.action?.conditions || []).forEach(co => {
        if (co.metadata?.type === MetadataType.MULTI_VALUED) {
          co.value = JSON.parse(co.value)
        }
      });

      all.forEach(c => {
        c.metadata = this.metadataService.metadataDefinitions.filter(m => m.id === c.metadata?.id)[0]
        if (c.metadata?.type == MetadataType.REFERENCE && c.value) {
          if (c.value === dynamicMetadataValues.currentUserDefinition.tag) {
            c.value = dynamicMetadataValues.currentUserReferencedValueDefinition            
          } else {
            observables.push(this.metadataService
              .getMetadataReference(parseInt(c.value))
              .pipe(tap(r => c.value = r)))
          }
        } else if (c.metadata?.type == MetadataType.USER && c.value) {
          if (c.value === dynamicMetadataValues.currentUserDefinition.tag) {
            c.value = dynamicMetadataValues.currentUserDefinition
          } else {
            observables.push(this.userService
              .getUserById(parseInt(c.value))
              .pipe(tap(r => c.value = r)))     
          }
        }        
      });
      if (this.action?.userTaskToCreate?.assignableTo?.length) {
        for (let assignability of this.action.userTaskToCreate.assignableTo) {
          if (assignability.value === dynamicMetadataValues.currentUserReferencedValueDefinition.tag) {       
            assignability.value = dynamicMetadataValues.currentUserReferencedValueDefinition
          } else if (assignability.value === dynamicMetadataValues.currentTargetReferencedValueDefinition.tag) {
            assignability.value = dynamicMetadataValues.currentTargetReferencedValueDefinition
          } else if (assignability.value === dynamicMetadataValues.currentMediaReferencedValueDefinition.tag) {
            assignability.value = dynamicMetadataValues.currentMediaReferencedValueDefinition
          } else {
            observables.push(this.metadataService
              .getMetadataReference(parseInt(assignability.value))
              .pipe(tap(r => assignability.value = r)))          
          }
        }
      }
      if (observables.length) {
        forkJoin(observables).subscribe(_ => {
          console.log('filled referenceable')
        })
      }      

      this.loading = false
    })
  }

  conditionDetails(condition:UserActionMetadataDefinition) {
    const dialogRef = this.dialog.open(ConditionDetailsDialog, {
      data: {
        condition,
        action: this.action
      },
    });
    dialogRef.afterClosed().subscribe(resultData => {
      console.log('The dialog was closed', resultData);
      if (!resultData) return
      
      //
    });
  }

  editGroovyScript() {
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: i18next.t("hooks.editDialog.title"),
        text: i18next.t("hooks.editDialog.text"),
        label: i18next.t("hooks.editDialog.label"),
        type: SimpleTextInputType.MONOSPACED_EDIT,
        value: this.action?.groovyScript
      },
      maxWidth: '80vw',
      maxHeight: '80vh',
      height: '80%',
      width: '80%',      
    });

    dialogRef.afterClosed().subscribe(script => {
      console.log('The dialog was closed', script);
      if (script !== undefined) {
        this.action!.groovyScript = script
      }
    });         
  }

  save() {
    this.saving = true
    const actionToSave:UserActionDefinition = JSON.parse(JSON.stringify(this.action))    
    if (actionToSave.label === '') delete actionToSave.label
    if (actionToSave.autoTriggerOn as any === '') delete actionToSave.autoTriggerOn
    if (actionToSave.taskToLaunchTemplate)
      actionToSave.taskToLaunchTemplate!.media = undefined
    for (let condition of actionToSave.conditions ?? []) {
      if (condition.metadata?.type === MetadataType.MULTI_VALUED) {
        condition.value = JSON.stringify(condition.value)
      } else if (condition.metadata!.type == MetadataType.REFERENCE && condition.value) {
        // they're containing the whole object
        condition.value = (condition.value as any).id
        condition.metadata!.referencedMetadataGroup = undefined
      }
    }
    for (let consequence of actionToSave.consequences ?? []) {
      // filter out these values that aren't properly serialized back
      // for optimization purpose
      if (consequence.metadata) {
        
        if (consequence.metadata!.type == MetadataType.REFERENCE && consequence.value) {
          // they're containing the whole object
          const value = (consequence.value as any)
          consequence.value = value.meta ? value.tag : value.id
          consequence.metadata!.referencedMetadataGroup = undefined
        } else if (consequence.metadata!.type == MetadataType.USER && consequence.value) {
          // they're containing the whole object
          const user = (consequence.value as User)
          consequence.value = user.meta ? user.tag : user.id
          consequence.metadata!.referencedMetadataGroup = undefined
        }
      }
  }    
  for (let assignability of actionToSave.userTaskToCreate?.assignableTo ?? []) {
    // they're containing the whole object
    const value = (assignability.value as any)
    if (!value) {
      continue
    } else if (value.meta) {
      assignability.value = value.tag
    } else {
      assignability.value = value.id
    }
    assignability.metadata!.referencedMetadataGroup = undefined
  }  
  this.userActionService.save(actionToSave)
    .pipe(delay(1000))
    .subscribe( s => {
      this.saving = false
    })
  }
}


@Component({
  selector: 'dialog-condition-details',
  templateUrl: './dialog-condition-details.html',
  styleUrls: ['./dialog-condition-details.sass']
})
export class ConditionDetailsDialog {

  availabilityForced:boolean = false

  constructor(
    public dialogRef: MatDialogRef<ConditionDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConditionDetailsDialogData,
  ) {}

  ngOnInit() {
    if (this.data.action.attachableTo == this.data.condition.metadata?.parentGroup?.attachmentType) {
      this.data.condition.requiredForActionAvailability = true
      this.availabilityForced = true
    }
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface ConditionDetailsDialogData {
  action:UserActionDefinition
  condition:UserActionMetadataDefinition
}
