import imsc from 'imsc'
import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Media, Audio, MediaStream, MediaVersion, Subtitle, MediaVersionModificationRequest, MediaElement, MediaVersionDefinition, MediaExpectedStreamDefinition } from 'src/app/models/media';
import { MediaService } from 'src/app/services/media.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetadataService } from 'src/app/services/metadata.service';
import { Page } from 'src/app/models/page';
import { MetadataType, MetadataGroup, MetadataAttachmentType, MetadataDefinition } from 'src/app/models/metadata';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { SimpleTextDialog, SimpleTextInputType } from '../../components/dialogs/simple.text.dialog';
import { ConfirmationDialog } from '../../components/dialogs/confirm.dialog';
import { formatDate, handleTableResponsivness, intersect, secondsToTimecode, timecodeToSeconds } from 'src/app/utils/utils';
import { CommentService } from 'src/app/services/comment.service';
import { TimecodedComment } from 'src/app/models/comment';
import { MediaDetailContainerComponent } from '../media-detail-container/media-detail-container.component';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { EditableSubtitle } from 'src/app/models/subtitle';
import i18next from 'i18next';
import { VersionDefinitionService } from 'src/app/services/version.definition.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { prepareForm } from 'src/app/components/metadata.display/metadata.display.component';
import { UserMetadataDialog } from 'src/app/components/dialogs/user.metadatas.dialog';
import { UserActionsService } from 'src/app/services/user.actions.service';
import { UserActionDefinition } from 'src/app/models/user.action';
import { Observable, forkJoin, tap } from 'rxjs';
import { ImportDialog } from 'src/app/components/dialogs/import.dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { cloneDeep, uniqBy } from 'lodash';
import { RequestPartnerUploadDialog } from '../media-uploads/media-uploads.component';
import { PartnerUploadService } from 'src/app/services/partner.upload.service';
import { ConfigurationKey } from 'src/app/models/configuration';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

declare var Hls:any;

enum Tabs {
  COMMENTS,
  ACTIVITY,
  TASKS,
  USER_TASKS,
  LOCATIONS,
  METADATAS,
  VERSIONS_METADATAS,
  UPLOADS,
  DOWNLOADS,
  STREAMS,
  SUBTITLES
}

export interface CreateVersionDialogData {
  availableLanguages: any[]
  availableVideos: any[]
  availableAudios: any[]
  availableSubtitles: any[]
  selectedVideo: number
  selectedAudio: number
  selectedSubtitles: number[]
  name: string
  isCreation: boolean
  selectedLanguage: string
}

export interface CreateVersionFromDefinitionDialogData {
  versionDefinition:MediaVersionDefinition
}

@Component({
  selector: 'app-media-detail',
  templateUrl: './media-detail.component.html',
  styleUrls: ['./media-detail.component.sass']
})
export class MediaDetailComponent implements OnInit {

  currentUser: User | undefined;
  Tabs = Tabs;
  intersect = intersect;
  formatDate = formatDate;
  i18next = i18next;
  selectedComment: TimecodedComment = {
    comment: "",
    deleted: false,
    showableToPartners: false,
    tcIn: "00:00:00-00",
    tcOut: "00:00:00-00"
  }
  currentTC: string = "00:00:00-00"
  duration: string = "00:00:00-00"
  audios: Audio[] = []
  subtitles: Subtitle[] = []
  selectedVideo:MediaStream|undefined
  selectedAudio:MediaStream|undefined
  // selectedTrack?: string = undefined
  selectedSubtitle?: string = undefined
  videoWatcherInterval:any
  hls: any
  innerHls: any
  currentPlayer:HTMLVideoElement|undefined
  selectedTab:Tabs = Tabs.METADATAS
  tabs:typeof Tabs = Tabs;
  
  drawing:boolean = false
  currentSubtitleMediaElement: MediaElement | undefined;
  editableSubtitle: EditableSubtitle | undefined;
  imscSubtitles: any;
  versionDefinitions: Page<MediaVersionDefinition> = {};
  dateFormat = i18next.t('global.dateTimeFormat')
  imscRenderLoop: any;
  currentSubtitleRenderer: HTMLElement | undefined;
  tracks: MediaStream[] = [];
  commentedTrack: MediaStream|undefined;
  commentTiedToVersion:boolean = true;
  filteredComments:TimecodedComment[] = []
  groupedMediaMetadataGroups: MetadataGroup[] | undefined;
  groupedVersionMetadataGroups: MetadataGroup[] | undefined;
  groupedStreamMetadataGroups: MetadataGroup[] | undefined;
  ttmlShift: number = 0;
  availableActionsForVersion: UserActionDefinition[] = [];
  availableActionsForStreams: UserActionDefinition[] = [];
  uniqueActionsForStreams: UserActionDefinition[] = [];
  isPartnerDisplay: boolean = false;
  spareFiles: MediaElement[] = [];
  seekbarIsHovered: boolean = false;
  @ViewChild("commentMenuInfo") commentMenuInfo: MatMenuTrigger | undefined;
  timecodeOffset: number = 0;

  constructor(private route: ActivatedRoute, 
    private mediaService: MediaService,
    private partnerUploadService: PartnerUploadService,
    private element: ElementRef,
    private userActionsService: UserActionsService,
    public parentContainer: MediaDetailContainerComponent,
    private userService: UserService, 
    private versionService: VersionDefinitionService, 
    private commentService: CommentService, 
    private metadataService: MetadataService,
    private subtitleService: SubtitleService,
    public dialog: MatDialog,
    private configurationService: ConfigurationService,
    private fb: FormBuilder) { 
      this.currentUser = userService.currentUser
      userService.execChange.subscribe((value) => {
        this.currentUser = value
      })      
    }
  
  metadataGroups:Page<MetadataGroup> = {};
  comments: Page<TimecodedComment>|undefined
  metadataTypes:typeof MetadataType = MetadataType;
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType;
  metadataForm:any;
  selectedVersion:MediaVersion = {};
  loading:boolean = false
  audioVariants:MediaStream[] = []
  videoVariants:MediaStream[] = []
  subtitleVariants:MediaStream[] = []

  currentCanvasContainer: HTMLElement|undefined
  currentCanvas: HTMLCanvasElement|undefined

  ngOnInit(): void {
    this.loading = true
    
    this.route.paramMap.subscribe((params: ParamMap) => {
      const versionId:number = parseInt(params.get('versionId') ?? '-1')
      const id:number = this.parentContainer.mediaId
      this.getMedia(id, false, versionId > 0 ? versionId : undefined)
      this.parentContainer.getPendingTaskForMedia(id)
      this.getMetadataGroups(id)
      this.loadVersions()
    });

    this.ttmlShift = parseInt(this.configurationService.configurations.filter(c => c.key == 'TTML_SHIFT')[0]?.value ?? -1)
    this.isPartnerDisplay = !intersect(this.currentUser?.privileges, ['WRITE_PRIVILEGE']).length
    // setInterval(() => console.log(this.result.dynamicMetadatas), 1000)
  }

  initializeSplitter() {
    const container = document.getElementById('container')!
    const closeRight = document.getElementById('close-right')!
    const closeLeft = document.getElementById('close-left')!
    const leftColumn = document.getElementById('left-column')!
    const rightColumn = document.getElementById('right-column')!
    const element = document.getElementById('splitter')!
    const dragger = document.getElementById('dragger')!
    let down:boolean = false
    let rightClosed:boolean = false
    let leftClosed:boolean = false
    closeRight.onclick = () => {
      if (leftClosed) {
        closeLeft.click()
        var tables = document.querySelectorAll('.maybe-table-responsive')
        for (const t of tables.values()) {
          t.classList.add('table-responsive')
        }
        return
      } else if (rightClosed) {
        rightColumn.style.display = 'block'
        leftColumn.style.maxWidth = `50%`               
        container.style.left = '0'
        container.style.marginRight = '0'
        dragger.style.display = 'block'    
      } else {
        rightColumn.style.display = 'none'
        leftColumn.style.maxWidth = `100%`               
        container.style.left = '0'
        container.style.marginRight = '0'
        dragger.style.display = 'none'        
      }
      rightClosed = !rightClosed
    }
    closeLeft.onclick = () => {
      if (rightClosed) {
        closeRight.click()
        return
      } else if (leftClosed) {
        leftColumn.style.display = 'block'
        container.style.left = '0'
        container.style.marginRight = '0'
        dragger.style.display = 'block'    
      } else {
        leftColumn.style.display = 'none'
        container.style.left = '-16px'
        container.style.marginRight = '-16px'
        dragger.style.display = 'none'
      }
      leftClosed = !leftClosed
    }
    element.onmousedown = () => {
      down = true
    }
    document.onmouseup = () => {
      down = false
    }
    document.onmousemove = (event) => {
      if (down) {
        event.stopPropagation()
        event.stopImmediatePropagation()
        event.preventDefault()

        const sideNavClosed = document.getElementById('root')!.classList.contains('sidenavClosed')
        let w = event.clientX - 252
        if (sideNavClosed) w += 230
        if (w < 380) w = 380
        
        const maxWidth = sideNavClosed ? window.innerWidth - 590 : window.innerWidth - 790
        if (w > maxWidth) {
          w = maxWidth
        }

        leftColumn.style.maxWidth = `${w}px` 
        leftColumn.style.minWidth = `${w}px` 
      }
    }    
  }

  loadVersions() {
    this.versionService.get().subscribe(versionDefinitions => {
      this.versionDefinitions = versionDefinitions
      this.orderVersions()
    })
  }

  answerComment(comment:TimecodedComment, event:MouseEvent) {
    event.stopPropagation()
    if (comment.id !== this.selectedComment.id) {
      this.selectComment(comment)
    }
    
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: i18next.t("comments.answerDialog.title"),
        label: i18next.t("comments.answerDialog.label"),
        textAreaHeightVH: 22,
        type: SimpleTextInputType.MULTILINE,
      },
      maxWidth: '60vw',
      maxHeight: '50vh',
      height: '60%',
      width: '50%',      
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed', data);
      if (data !== undefined) {
        this.commentService.persistAnswer(this.parentContainer?.media?.id!, comment, data).subscribe(a => {
          const currentComment = this.comments?.content?.find(c => c.id === comment.id)!
          currentComment.answers?.push(a)
          setTimeout(() => this.selectComment(currentComment, true), 10);
        })
      }
    });       
  }

  deleteComment(comment:TimecodedComment) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.please_confirm',
        content: `mediaDetail.delete_comment`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {    
        comment.deleted = true
        this.saveComment(comment)
      }
    });
  }

  cancelSelection() {
    this.disableCommentButtons()
    const comments = document.getElementById("comments")
    if (comments) comments!.querySelectorAll("li").forEach(el => el.classList.remove("selected"))
    this.selectedComment = {
      comment: "",
      deleted: false,
      showableToPartners: false,
      tcIn: "00:00:00-00",
      tcOut: "00:00:00-00"
    }
    const canvasContainer = document.getElementsByClassName('drawing-board')[0]! as HTMLElement;
    canvasContainer.classList.remove("activated")
  }

  saveComment(comment: TimecodedComment = this.selectedComment): void {
    console.log('saveComment()')
    if (this.drawing) {
      const img = this.currentCanvas!.toDataURL('image/png')
      comment.base64Drawing = img
      comment.drawingWidth = this.currentCanvas!.width;
      comment.drawingHeight = this.currentCanvas!.height;
      this.cancelSelection()
      this.stopDrawing()
    }

    this.commentService.persistTimecodedComment(this.parentContainer?.media?.id!, comment!, 
      this.commentTiedToVersion ? this.selectedVersion.id : undefined,
      this.commentedTrack?.id)
      .subscribe(s => {
        this.loadComments()
      })
  }

  highlightCommentDelayed() {
    // gruik
    setTimeout(() => this.highlightComment(), 10)
  }

  highlightComment(comment:TimecodedComment|null=null) {
    const seekbar:HTMLElement = document.getElementById("seekbar")!
    const highlight = seekbar.querySelector(".commentHighlight") as HTMLElement
    const commentToHighlight = comment ?? this.selectedComment
    if (commentToHighlight) {
      // highlight.style.width = 
      const start = timecodeToSeconds(commentToHighlight.tcIn)
      const end = timecodeToSeconds(commentToHighlight.tcOut)
      const duration = end - start
      highlight.style.width = `${Math.ceil(duration / this.currentPlayer!.duration * 10000)/100}%`
      highlight.style.left = `${Math.ceil(start / this.currentPlayer!.duration * 10000)/100}%`
    } else {
      highlight.style.width = '0'
    }
  }

  disableCommentButtons() {
    const container = document.getElementById("write-comments-container")
    if (container) container.classList.add("disabled")
    console.log('disableCommentButtons()')
  }

  enableCommentButtons() {
    const container = document.getElementById("write-comments-container")
    if (container) container.classList.remove("disabled")
    console.log('enableCommentButtons()')
  }

  selectComment(comment:TimecodedComment, neverClose:boolean=false):void {
    
    if (comment.createdBy?.id !== this.userService.currentUser?.id) {
      this.disableCommentButtons()
    } else {
      this.enableCommentButtons()
    }

    this.stopDrawing()
    const li = document.querySelector(`[data-commentid='${comment.id}']`)!
    
    const previouslySelectedComment = document.querySelector("#comments li.selected .answers") as HTMLElement
    
    if (previouslySelectedComment && !neverClose) {
      previouslySelectedComment.style.height = '0'
    }

    // if it's the same comment, adjust size of the current comment to reopen it
    if (previouslySelectedComment === li.querySelector('.answers') && neverClose) {
      const answers = li.querySelector('.answers') as HTMLElement
      answers!.style.height = 'auto'
    }

    if (!li.classList.contains("selected")) {
      console.log('open answers')

      document.getElementById("comments")!.querySelectorAll("li").forEach(el => el.classList.remove("selected"))
      li.classList.add("selected")
      
      const answers = li.querySelector('.answers') as HTMLElement
      answers!.style.position = 'absolute'
      answers!.style.top = '110vh'
      answers!.style.height = 'auto'
      const targetHeight = answers.offsetHeight
      answers!.style.height = '0'
      answers!.style.position = 'relative'
      answers!.style.top = '0'
      answers!.style.height = '0'
      setTimeout(() => {
        answers!.style.height = `${targetHeight}px`
      }, 100)

      this.selectedComment = cloneDeep(comment)
      this.currentPlayer!.currentTime = timecodeToSeconds(comment.tcIn)
    } else if (!neverClose) {
      console.log('close answers')
      document.getElementById("comments")!.querySelectorAll("li").forEach(el => el.classList.remove("selected"))
    }

    this.drawSelectedComment()
  }

  executeActionOnStreams(action:UserActionDefinition):void {

    const dialogRef = this.dialog.open(ElementsPickerDialog, {
      data: {
        title: 'pickStreamDialog.title',
        text: 'pickStreamDialog.text',
        elements: this.availableActionsForStreams.filter(a => a.id === action.id).map(a => {
          return {
            element: a,
            representation: `${a.stream?.representation} — ${a.stream?.mediaElement?.filename ?? i18next.t('mediaDetail.expected')}`,
            id: a.stream?.id
          }
        })
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        forkJoin(result.map((action:any) => this.userActionsService.executeAction(action.element, action.element.attachableTo!, action.id)))
          .subscribe(_ => {
            this.getMedia(this.parentContainer.media?.id!, true, this.selectedVersion.id, () => {
              if (action.triggerPartnerUploadRequest)
                this.openRequestPartnerUploadDialog(result.map((action:any) => action.id))
            })
          })
      }
    });
  }

  openRequestPartnerUploadDialog(selection:number[]): void {

    const dialogRef = this.dialog.open(RequestPartnerUploadDialog, {
      data: {},
    });
    
    dialogRef.afterClosed().subscribe(form => {
      console.log('The dialog was closed', form.partner.value);
      this.partnerUploadService.createUploadRequest(form.partner.value.id, 
        form.message.value, 
        form.allowStreaming.value, 
        form.qc.value.id,
        this.parentContainer.media?.id!, 
        selection,
        false)
        .subscribe(() => {
          this.getMedia(this.parentContainer.media?.id ?? -1, true)
        });
    });
  }

  executeAction(action:UserActionDefinition, id:number):void {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.please_confirm',
        content: i18next.t('mediaDetail.confirmAction')
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.userActionsService.executeAction(action, action.attachableTo!, id)
          .subscribe(_ => {
            this.getMedia(this.parentContainer.media?.id!, true)
          })
      }
    });
  }

  drawSelectedComment() {
    if (this.drawing) {
      return
    }
    if (this.selectedComment.base64Drawing) {

      console.log('drawSelectedComment')
      const image = new Image();
      image.onload = () => {
        this.currentCanvasContainer!.classList.add("activated")
        const ctx = this.currentCanvas!.getContext('2d') as CanvasRenderingContext2D; 
        this.currentCanvas!.width = this.currentCanvasContainer!.offsetWidth;
        this.currentCanvas!.height = this.currentCanvasContainer!.offsetHeight;
        const currentRatio = this.currentPlayer!.videoWidth / this.currentPlayer!.videoHeight
        const height = this.currentCanvas!.offsetWidth / currentRatio
        ctx.clearRect(0, 0, this.currentCanvas!.offsetWidth, this.currentCanvas!.offsetHeight)
        ctx.drawImage(image, 0, (this.currentCanvas!.offsetHeight - height)/2, this.currentCanvas!.offsetWidth, height);
      };
      image.src = this.selectedComment.base64Drawing
      console.log(this.selectedComment.base64Drawing)
    } else {
      this.currentCanvasContainer!.classList.remove("activated")
    }    
  }

  selectVersion(version:MediaVersion) {

    let state = `/media/${this.parentContainer.media?.id}/versions/${this.selectedVersion.id}`
    if (window.location.hash) state += window.location.hash
    else state += '#metadatas'
    window.history.replaceState({}, '', state);    
    
    this.selectedVersion = version
    this.parentContainer.selectedVersion = version
    this.videoVariants = this.getVideoVariants()!
    this.selectedVideo = this.videoVariants[this.videoVariants.length-1]

    const forcedOffset = this.configurationService.getConfiguration(ConfigurationKey.FORCED_TIMECODE_OFFSET)
    if (forcedOffset) this.timecodeOffset = parseInt(forcedOffset)
    
    this.configurationService.getConfiguration(ConfigurationKey.FIRST_FRAME_TIMECODE_METADATA_LOCATION, (value) => {
      console.log('timecodeoffset key', value)
      const [groupId, definitionKey] = value.split('.')
      const timecodeOffset = this.selectedVideo?.dynamicMetadatas[groupId][definitionKey]
      console.log('timecodeoffset value', timecodeOffset)
      this.timecodeOffset = timecodeToSeconds(timecodeOffset);
    })
        
    this.audioVariants = this.getAudioVariants()!
    this.selectedAudio = this.audioVariants[this.audioVariants.length-1]
    this.subtitleVariants = this.getSubtitlesVariants()!
    // this.selectedSubtitle = this.subtitleVariants[this.subtitleVariants.length-1]
    this.filteredComments = this.comments?.content?.filter(c => !c.mediaVersion || c.mediaVersion.id === this.selectedVersion.id) ?? []
    this.getAvailableActionsForVersion(version.id!);
    this.getAvailableActionsForStreams();
    this.initializeAdditionalSubtitles();
    setTimeout(() => this.initializeUI(), 10)
  }

  getAvailableActionsForStreams() {
    const streams:MediaStream[] = uniqBy([...this.parentContainer?.media?.expectedStreams ?? [], ...this.parentContainer?.media?.elements?.flatMap(e => e.streams ?? []) ?? []], ms => ms.id)
    this.availableActionsForStreams = []
    const tasks:Observable<UserActionDefinition[]>[] = streams.map(stream => {
      return this.userActionsService.getAvailableActionsFor(MetadataAttachmentType.MEDIA_STREAM, stream?.id!)
        .pipe(tap(a => {
          for (const action of a) {
            this.availableActionsForStreams.push({
              ...action,
              stream
            })
          }
        }))
    })
    forkJoin(tasks).subscribe(_ => {
      const uniqueActions = new Set([...this.availableActionsForStreams.map(a => a.id!)])
      this.uniqueActionsForStreams = [...uniqueActions].map(a => this.availableActionsForStreams.filter(ac => ac.id === a)[0])
    })
  }

  getAvailableActionsForVersion(id: number) {
    this.userActionsService.getAvailableActionsFor(MetadataAttachmentType.MEDIA_VERSION, id)
      .subscribe(a => {
        this.availableActionsForVersion = a
        console.log('this.availableActions', this.availableActionsForVersion)
      });
  }

  initializeUI() {
    this.initializeSplitter()    
    this.initializePlayer()
    handleTableResponsivness()

    if (this.isPartnerDisplay) {
      this.selectTab(Tabs.COMMENTS, "#comments")
    } else if (window.location.hash === '#uploads') {
      this.selectTab(Tabs.UPLOADS, 'uploads')
    } else if (window.location.hash === '#downloads') {
      this.selectTab(Tabs.DOWNLOADS, 'downloads')
    } else if (window.location.hash === '#tasks') {
      this.selectTab(Tabs.TASKS, 'tasks')
    } else if (window.location.hash === '#versions') {
      this.selectTab(Tabs.VERSIONS_METADATAS, 'versions')
    } else if (window.location.hash === '#subtitles') {
      this.selectTab(Tabs.SUBTITLES, 'subtitles')
    } else if (window.location.hash === '#streams') {
      this.selectTab(Tabs.STREAMS, 'streams')
    } else if (window.location.hash === '#activity') {
      this.selectTab(Tabs.ACTIVITY, 'activity')
    } else if (window.location.hash === '#locations') {
      this.selectTab(Tabs.LOCATIONS, 'locations')
    } else if (window.location.hash === '#metadatas') {
      this.selectTab(Tabs.METADATAS, 'metadatas')
    } else if (window.location.hash === '#comments') {
      this.selectTab(Tabs.COMMENTS, 'comments')
    } else if (window.location.hash === '#userTasks') {
      this.selectTab(Tabs.USER_TASKS, 'userTasks')
    }
  }

  stopDrawing() {
    console.log('stop drawing')
    const canvasContainer = document.getElementsByClassName('drawing-board')[0]! as HTMLElement;
    const canvas = document.getElementById('drawing-board')! as HTMLCanvasElement;
    canvasContainer.classList.remove("drawing", "activated")
    const img = canvas.toDataURL('image/png')
    this.drawing = false
  }

  initializeDrawing() {
    const ro = new ResizeObserver(() => {
      this.currentCanvas!.width = this.currentCanvasContainer!.offsetWidth;
      this.currentCanvas!.height = this.currentCanvasContainer!.offsetHeight;      
      this.drawSelectedComment()
    })
    if (this.currentCanvasContainer)
      ro.observe(this.currentCanvasContainer)
  }

  startDrawing() {
    this.drawing = true
    const videoOverlay: HTMLElement = document.getElementById('video-overlay') as HTMLElement;
    this.currentPlayer!.currentTime = timecodeToSeconds(this.selectedComment.tcIn)

    videoOverlay.style.display = "none";

    const ctx = this.currentCanvas!.getContext('2d') as CanvasRenderingContext2D;
    
    this.currentCanvas!.width = this.currentCanvasContainer!.offsetWidth;
    this.currentCanvas!.height = this.currentCanvasContainer!.offsetHeight;
    
    let isPainting = false;
    let lineWidth = 5;
    
    const draw = (e:MouseEvent) => {
        ctx.strokeStyle = "#FF0000"
        if(!isPainting) {
            return;
        }
    
        ctx.lineWidth = lineWidth;
        ctx.lineCap = 'round';
    
        ctx.lineTo(e.offsetX, e.offsetY);
        ctx.stroke();
    }
    
    this.currentCanvas!.addEventListener('mousedown', (e) => {
        isPainting = true;
    });
    
    this.currentCanvas!.addEventListener('mouseup', e => {
        isPainting = false;
        ctx.stroke();
        ctx.beginPath();
    });
    
    this.currentCanvas!.addEventListener('mousemove', draw);    

    setTimeout(() => this.currentCanvasContainer!.classList.add("activated", "drawing"), 100)
  }

  trackChanged(event:any) {
    console.log(event)
    if (event.isUserInput) {
      setTimeout(() => this.initializePlayer(), 10)
    }
  }

  getVideoVariants():MediaStream[] {
    if (!this.selectedVersion.id) return []
    const result:MediaStream[] = [this.selectedVersion.video!]
    let currentStream = this.selectedVersion.video
    while (currentStream?.previousVersion) {
      if (currentStream?.previousVersion.status == 'RECEIVED')
        result.push(currentStream.previousVersion)
      currentStream = currentStream?.previousVersion
    }
    currentStream = this.selectedVersion.video
    if (currentStream?.alternatives?.length) {
      for (let alternative of currentStream?.alternatives) {
        if (alternative?.status == 'RECEIVED') result.push(alternative)
        while (alternative?.previousVersion) {
          if (alternative?.previousVersion.status == 'RECEIVED')
            result.push(alternative.previousVersion)
          alternative = alternative?.previousVersion
        }        
      } 
    }
    return result.sort((s1, s2) => s1?.id! - s2?.id!)
  }

  getAudioVariants() {
    if (!this.selectedVersion.id) return []
    const result:MediaStream[] = [this.selectedVersion.audio!]
    let currentStream = this.selectedVersion.audio
    while (currentStream?.previousVersion) {
      if (currentStream?.previousVersion.status == 'RECEIVED')
        result.push(currentStream.previousVersion)
      currentStream = currentStream?.previousVersion
    }
    currentStream = this.selectedVersion.audio
    if (currentStream?.alternatives?.length) {
      for (let alternative of currentStream?.alternatives) {
        if (alternative?.status == 'RECEIVED') result.push(alternative)
        while (alternative?.previousVersion) {
          if (alternative?.previousVersion.status == 'RECEIVED')
            result.push(alternative.previousVersion)
          alternative = alternative?.previousVersion
        }        
      } 
    }
    return result.sort((s1, s2) => s1?.id! - s2?.id!)
  }

  getSubtitlesVariants() {
    if (!this.selectedVersion.id) return []
    const result:MediaStream[] = [ (this.selectedVersion?.subtitles ?? [])[0] ].filter(s => s !== undefined)
    for (let currentStream of this.selectedVersion.subtitles ?? []) {
      while (currentStream?.previousVersion) {
        if (currentStream?.previousVersion.status == 'RECEIVED')
          result.push(currentStream.previousVersion)
        currentStream = currentStream?.previousVersion
      }
    }
    return result.sort((s1, s2) => s1?.id! - s2?.id!)
  }  

  editCurrentVersion() {
    console.log('editCurrentVersion')
    // TODO expected
    this.openCreateVersionDialog({
      name: this.selectedVersion.name,
      selectedLanguage: this.selectedVersion.language,
      selectedVideo: this.selectedVersion.video,
      selectedAudio: this.selectedVersion.audio,
      selectedSubtitles: [...this.selectedVersion?.subtitles ?? []],
    }, false);
  }

  addAlternativeStream(type:string) {
    console.log('addAlternativeVideo');

    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = this.metadataGroups?.content?.filter(g => g.attachmentType === 'MEDIA_STREAM')!
    const datas:any = {}

    this.metadataForm = prepareForm(this.metadataService,
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      datas,
      undefined,
      type,
      true);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log('dialog value: ', result.value);
        const expectedStream = {
          type
        }
        const datas = result.value
        const alternativeFor = type === 'VIDEO' ? this.selectedVersion.video?.id : this.selectedVersion.audio?.id
        this.mediaService.createMediaExpectedStream(this.parentContainer.media!.id!, expectedStream, alternativeFor).subscribe(m => {
          const metadataGroupId = metadataGroups[0].id
          datas[metadataGroupId].targetId = m.id
          datas[metadataGroupId].targetType = 'MEDIA_STREAM'
          this.metadataService.persistMetadatasForEntity(datas).subscribe(() => {
            this.getMedia(this.parentContainer?.media?.id!, true)
          });
        })        
      }
    });    
  }
  
  openCreateVersionFromDefinitionDialog(versionDefinition:MediaVersionDefinition) {
    console.log(versionDefinition)

    const dialogRef = this.dialog.open(CreateVersionFromDefinitionDialog, {
      data: {
        versionDefinition
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      const datas:any = {}
      if (result) {
        for (let [key, value] of result) {
          console.log(key + " = " + value);
          datas[key] = value.id!
        }
        this.mediaService.createVersionFromDefinition(versionDefinition.id!, this.parentContainer?.media?.id!, datas)
          .subscribe(_ => this.getMedia(this.parentContainer?.media?.id!, true))
      }
    });
  }

  openCreateVersionDialog(currentDatas:any=null, isCreation=true): void {
    console.log('openCreateVersionDialog');
    const availableVideos:any[] = []
    const availableAudios:any[] = []
    const availableSubtitles:any[] = []
    if (this.parentContainer?.media?.elements) {
      for (const element of this.parentContainer?.media?.elements) {
        if (element.streams) {
          for (const stream of element.streams) {
            if (stream.type === "VIDEO")
              availableVideos.push({...stream, filename:element.filename})
            else if (stream.type === "AUDIO")
              availableAudios.push({...stream, filename:element.filename})
            else if (stream.type === "SUBTITLE")
              availableSubtitles.push({...stream, filename:element.filename})
          }
        }
      }
    }

    if (this.parentContainer.media?.expectedStreams) {
      for (const stream of this.parentContainer.media?.expectedStreams) {
        if (stream.mediaElement) continue
        if (stream.type === 'VIDEO') {
          availableVideos.push({ ...stream, expected: true })
        } else if (stream.type === "AUDIO") {
          availableAudios.push({...stream, expected: true })
        } else if (stream.type === "SUBTITLE") {
          availableSubtitles.push({...stream, expected: true })
        }
      }
    }

    if (!availableVideos.length) {
      this.dialog.open(SimpleTextDialog, {
        data: {
          title: 'mediaDetail.error',
          text: 'mediaDetail.at_least_a_video',
          type: SimpleTextInputType.TEXT,          
        },
      });
      return      
    }
    
    if (currentDatas && currentDatas.selectedVideo) {
      currentDatas.selectedVideo = availableVideos.find((a:any) => a.id == currentDatas.selectedVideo.id)
    }
    if (currentDatas && currentDatas.selectedAudio) {
      currentDatas.selectedAudio = availableAudios.find((a:any) => a.id == currentDatas.selectedAudio.id)
    }
    if (currentDatas && currentDatas.selectedSubtitles) {
      const newSubtitlesSelection:any[] = []
      currentDatas.selectedSubtitles.forEach((sub:any) => {
        newSubtitlesSelection.push(availableSubtitles.find(as => as.id === sub.id))
      })
      currentDatas.selectedSubtitles = newSubtitlesSelection
    }

    if (availableAudios) {
      const dialogRef = this.dialog.open(CreateVersionDialog, {
        data: {
          isCreation,
          availableVideos,
          availableAudios,
          availableSubtitles,
          availableLanguages: [
            {
              code: 'en',
              name: 'English'
            },
            {
              code: 'fr',
              name: 'French'
            }
          ],
          ... currentDatas
        },
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        console.log('The dialog was closed', dialogResult);
        if (dialogResult && this.parentContainer?.media) {
          const version:MediaVersionModificationRequest = {
            name: dialogResult.name,
            language: dialogResult.selectedLanguage,
            videoId: dialogResult.selectedVideo.id,
            audioId: dialogResult.selectedAudio.id,
            subtitlesId: dialogResult.selectedSubtitles?.filter((s:any) => !s.expected).map((s:any) => s.id),
          };
          if (dialogResult.isCreation) {
            this.mediaService.createVersion(version, this.parentContainer?.media?.id!).subscribe(() => {
              this.getMedia(this.parentContainer?.media?.id ?? -1, true)
            });
          } else {
            version.id = this.selectedVersion.id
            this.mediaService.updateVersion(version, this.parentContainer?.media?.id!, dialogResult.selectedAudio.id).subscribe(() => {
              this.getMedia(this.parentContainer?.media?.id ?? -1, true)
            });
          }
        }
      });  
    }  
  }

  openDeleteVersionConfirmationDialog(): void {
    console.log('openDeleteConfirmationDialog');

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.please_confirm',
        content: i18next.t('mediaDetail.sure_to_delete', { versionName: this.selectedVersion.name })
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.mediaService.deleteMediaVersion(this.parentContainer?.media?.id!, this.selectedVersion.id!).subscribe(() => {
          this.getMedia(this.parentContainer?.media?.id ?? -1, true)
        })
      }
    });
  }    

  reload() {
    this.getMedia(this.parentContainer?.media?.id!, true, this.selectedVersion.id)
  }

  orderVersions() {
    if (!this.parentContainer.media) return
    this.parentContainer.media!.versions = this.parentContainer.media!
      .versions?.sort((v1, v2) => {
        const v1s = this.versionDefinitions.content?.find(vd => vd.name === v1.name)
        const v2s = this.versionDefinitions.content?.find(vd => vd.name === v2.name)
        if (v1s && v2s) return (v1s.orderNumber ?? 0) - (v2s.orderNumber ?? 0)
        return v2.id! - v1.id!
      })
  }

  getMedia(id:number, forceReload:boolean, selectVersionId:number|undefined=undefined, callback:Function|undefined=undefined): void {
    this.loading = true
    this.parentContainer.getMedia(id, forceReload, _ => {

      this.spareFiles = this.isPartnerDisplay ? 
        this.parentContainer?.media?.elements?.filter(e => e.spareFileAccessibleToPartners) ?? [] :
        this.parentContainer?.media?.elements?.filter(e => e.spareFile || e.spareFileAccessibleToPartners) ?? []

      this.selectedVersion = {}

      // select first version automatically
      if (!this.selectedVersion.id && this.parentContainer?.media?.versions?.length) {
        this.selectVersion(
          selectVersionId ? 
          this.parentContainer?.media?.versions.find(v => v.id === selectVersionId)! :
          this.parentContainer?.media?.versions[0])

        setTimeout(() => {
          
          this.tracks = [
            this.selectedVersion.video!,
            this.selectedVersion.audio!,
            ...this.selectedVersion.subtitles?.map(s => s) ?? []
          ].filter(t => t != undefined)
          console.log('tracks', this.tracks)
          this.videoVariants = this.getVideoVariants()!
          this.selectedVideo = this.videoVariants[this.videoVariants.length-1]
          this.audioVariants = this.getAudioVariants()!
          this.selectedAudio = this.audioVariants[this.audioVariants.length-1]

          this.currentCanvasContainer = document.getElementsByClassName('drawing-board')[0]! as HTMLElement;
          this.currentCanvas = document.getElementById('drawing-board')! as HTMLCanvasElement;          
          this.currentSubtitleRenderer = document.getElementById("subtitle-render")!
          this.initializeDrawing()

          this.initializeAdditionalSubtitles()
          this.loadComments()
          this.orderVersions()
        }, 10);        
      }

      setTimeout(() => this.initializeUI(), 100)
      

      setTimeout(() => {
        this.loading = false
        if (callback) callback()
      }, 100)

    })
  }

  initializeAdditionalSubtitles() {

    /*
    const stlSubtitles = this.selectedVersion.subtitles?.filter(s => s.mediaElement?.filename?.split('.').pop() === 'stl')
    console.log('stlSubtitles', stlSubtitles)
    stlSubtitles?.forEach(s => this.subtitles.push({
      code: `IMSC: ${s.representation ?? s.fromFilename}`,
      name: `IMSC: ${s.representation ?? s.fromFilename}`,
      streamId: s.id,
      mediaElementId: s.mediaElement?.id,
      filename: s.fromFilename,
      external: true
    }))
    */
   console.log(this.subtitleVariants)
   this.subtitles = this.subtitles.filter(s => !s.external)
   const mediaStreamMetadataGroup = this.metadataGroups?.content?.find(g => g.attachmentType === MetadataAttachmentType.MEDIA_STREAM && g.system)
   this.subtitleVariants.forEach((s, index) => {
      const isTranscript = s.dynamicMetadatas && 
        s.dynamicMetadatas.hasOwnProperty(mediaStreamMetadataGroup?.id!) && 
        s.dynamicMetadatas[mediaStreamMetadataGroup?.id!].hasOwnProperty('isTranscript') ? 
          s.dynamicMetadatas[mediaStreamMetadataGroup?.id!]['isTranscript'] : false
      let name = `${s.representation ?? s.fromFilename} #${index+1}`
      if (isTranscript) name += ' (AI Generated)'
      this.subtitles.push({
        code: name,
        name,
        streamId: s.id,
        mediaElementId: s.mediaElement?.id,
        filename: s.fromFilename,
        external: true
      })
      
    })
    console.log(this.subtitles)
  }

  loadComments() {
    this.commentService.getTimecodedComments(this.parentContainer?.media!).subscribe(s => {
      this.comments = s
      // this.comments.content?.forEach(c => console.log('check version', c?.mediaVersion?.id === this.selectedVersion.id, c?.mediaVersion?.id,  '===', this.selectedVersion.id))
      this.filteredComments = this.comments.content?.filter(c => !c.mediaVersion || c.mediaVersion.id === this.selectedVersion.id) ?? []
    })
  }  

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    const videoContainer: HTMLElement = document.getElementById('video-container') as HTMLElement
    const comments: HTMLElement = document.getElementById('comments') as HTMLElement
    const writeComments: HTMLElement|null = document.getElementById('write-comments') as HTMLElement
    const seekbar: HTMLElement = document.getElementById('seekbar') as HTMLElement
    const commentButtons: HTMLElement = document.getElementById('commentButtons') as HTMLElement
    const drawingBoard: HTMLElement = document.getElementById('drawing-board') as HTMLElement

    if (comments && !comments.contains(event.target as Node) && 
      !writeComments.contains(event.target as Node) && 
      !commentButtons.contains(event.target as Node) && 
      !seekbar.contains(event.target as Node) && 
      !drawingBoard.contains(event.target as Node) &&
      !videoContainer.contains(event.target as Node) &&
      !this.dialog.openDialogs.length &&
      !this.commentMenuInfo?.menuOpen) {
      console.log('cancelSelection:', event.target)
      this.cancelSelection()
    }
  }

  hasMetadata(stream:MediaStream) {
    if (!stream.dynamicMetadatas) return false
    let count = 0
    for (const [key, value] of Object.entries(stream.dynamicMetadatas)) {
      for (const [innerKey, innerValue] of Object.entries(value as any)) {
        if (innerKey != "targetId" && innerKey != "targetType" && innerValue && innerValue != "") {
          count++
        }
      }
    }
    return count > 0
  }  

  getMetadataGroups(mediaId:Number): void {
    this.metadataService.getMetadataGroups()
      .subscribe(metadataGroups => {
        this.metadataGroups = metadataGroups
        this.groupedMediaMetadataGroups = []
        this.groupedVersionMetadataGroups = []
        this.groupedStreamMetadataGroups = []
        metadataGroups.content?.filter(mg => mg.displayGrouped).forEach(element => {
          if (element.attachmentType === MetadataAttachmentType.MEDIA) {
            this.groupedMediaMetadataGroups?.push(element)
          } else if (element.attachmentType === MetadataAttachmentType.MEDIA_VERSION) {
            this.groupedVersionMetadataGroups?.push(element)
          } else if (element.attachmentType === MetadataAttachmentType.MEDIA_STREAM) {
            this.groupedStreamMetadataGroups?.push(element)
          }
        });
      })
  }

  addMultiEntitiesMetadata(datas:any, metadataGroupId:number, metadataDefinitionName:string, row:any) {
    if (!(datas[metadataGroupId][metadataDefinitionName] instanceof Array))
      datas[metadataGroupId][metadataDefinitionName] = []
    datas[metadataGroupId][metadataDefinitionName].push(row)
  }

  removeMultiEntitiesMetadata(datas:any, metadataGroupId:number, metadataDefinitionName:string, row:any) {
    if (!(datas[metadataGroupId][metadataDefinitionName] instanceof Array))
      datas[metadataGroupId][metadataDefinitionName] = []
    datas[metadataGroupId][metadataDefinitionName].splice(row, 1)
  }  

  getMultiEntitiesMetadata(datas:any, metadataGroupId:number, metadataDefinitionName:string) {
    let result = datas[metadataGroupId][metadataDefinitionName]
    if (result === "") return []
    return datas[metadataGroupId][metadataDefinitionName]
  }

  fullscreen(event:any) {
    //video.requestFullscreen()
    var myWindow = window.open('', '', 'popup=yes,width=400,height=300,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no');
    myWindow!.document.head.innerHTML = `
    <title>OpenMAM external Player</title>
    `
    myWindow!.document.body.innerHTML = `
    <style>
      html, body { margin: 0; background: black; }
      #video {
        background: black;
        width: 100%;
        height: 100%;
      }
      #subtitle-render {
        z-index: 10000;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .drawing-board {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        visibility: hidden;
        pointer-events: none;
      }
      .drawing-board.activated {
        visibility: visible;
        z-index: 10;
      }
      .drawing-board.activated.drawing {
        pointer-events: auto;
      }
      .video-container {
        position: relative;
      }
    </style>
    <div class="video-container">
      <div class="drawing-board">
          <canvas id="drawing-board"></canvas>
      </div>
      <div id="subtitle-render">
      </div>      
      <video id="video" controls playinline></video>
    </div>
    `
    myWindow!.onunload = () => {
      const video = document.getElementById("video") as HTMLVideoElement
      this.currentPlayer = video
      this.hls = this.innerHls
      this.currentCanvasContainer = document.getElementsByClassName('drawing-board')[0]! as HTMLElement;
      this.currentCanvas = document.getElementById('drawing-board')! as HTMLCanvasElement;                
      this.currentSubtitleRenderer = document.getElementById("subtitle-render")!
      console.log('closed')
    }

    myWindow!.onresize = () => {

      const currentRatio = this.currentPlayer!.videoWidth / this.currentPlayer!.videoHeight
      const height = myWindow?.document.body.offsetWidth! / currentRatio
      this.currentCanvasContainer!.style.height = height + "px"
      console.log(myWindow?.document.body.offsetWidth, this.currentPlayer!.videoWidth, this.currentPlayer!.videoHeight, this.currentCanvasContainer!.style.height, height, currentRatio)
      this.currentCanvasContainer!.style.top = `${(myWindow?.document.body.offsetHeight! - height)/2}`
      this.drawSelectedComment()      
    }
  
    this.currentCanvasContainer = myWindow!.document.getElementsByClassName('drawing-board')[0]! as HTMLElement;
    this.currentCanvas = myWindow!.document.getElementById('drawing-board')! as HTMLCanvasElement;          
    this.currentSubtitleRenderer = myWindow!.document.getElementById("subtitle-render")!
    this.initializeDrawing()

    const videoPopup = myWindow!.document.getElementById("video") as HTMLVideoElement
    const isVideoPlaying = !!(this.currentPlayer!.currentTime > 0 && !this.currentPlayer!.paused && !this.currentPlayer!.ended && this.currentPlayer!.readyState > 2);
    if (isVideoPlaying) {
      this.playPause()
    }
    const videoSrc = `/api/static/variants/master_${this.parentContainer?.media?.id}_${this.selectedVersion.id}_${this.selectedVideo?.id}_${this.selectedAudio?.id}.m3u8`
    
    if (Hls.isSupported()) {
      var hls = new Hls()
      this.hls = hls

      hls.loadSource(videoSrc)
      hls.attachMedia(videoPopup)
      hls.on(Hls.Events.MEDIA_ATTACHED, (event: any, data: any) => {
        videoPopup.currentTime = this.currentPlayer!.currentTime
        this.currentPlayer = videoPopup
      })
    } else {
      videoPopup.src = videoSrc
    }
  }
  
  gotoFrame(event:any) {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'player.gotoFrame',
        text: 'player.gotoFrameText',
        timecode: true,
        label: 'player.gotoFramePlaceholder',
        value: '00:00:00-00',
        type: SimpleTextInputType.TIMECODE,
      },
    });

    dialogRef.afterClosed().subscribe(r => {
      console.log('The dialog was closed', r);
      if (r) {
        this.currentPlayer!.currentTime = timecodeToSeconds(r) - this.timecodeOffset
      }
    });        
  }
  nextFrame(event:any, backward:boolean=false): void {
    const videoOverlay: HTMLElement = document.getElementById('video-overlay') as HTMLElement;
    videoOverlay.style.display = "none";
    
    event.preventDefault();
    let step = (1.0/25.0)
    if (backward) step = -step
    if (backward) {
      this.currentPlayer!.currentTime = this.currentPlayer!.currentTime + step
    } else {
      const currentFrame = Math.floor(this.currentPlayer!.currentTime*25.0)+0.5
      const target = (currentFrame+1) * step
      console.log('currentFrame', target, this.currentPlayer!.currentTime, currentFrame)
      
      this.currentPlayer!.currentTime = target
      // this.currentPlayer!.play()
      //setTimeout(() => this.currentPlayer!.pause(), step*1000)
    }
  }
  forward(event:any, xSeconds:number){
    this.stepByOffset(event, xSeconds)
  }

  backward(event:any, xSeconds:number){
    this.stepByOffset(event, xSeconds, true)
  }

  stepByOffset(event:any, xSeconds:number, backward:boolean=false): void {
    const videoOverlay: HTMLElement = document.getElementById('video-overlay') as HTMLElement;
    videoOverlay.style.display = "none";

    event.preventDefault();
    if(backward) xSeconds = -xSeconds
    this.currentPlayer!.currentTime = this.currentPlayer!.currentTime + xSeconds
  }

  initializePlayer(): void {

    if (!this.selectedVersion.audio || !this.selectedVersion.video || !this.selectedAudio?.id) {
      return
    }
    
    const video: HTMLVideoElement|null = document.getElementById('video') as HTMLVideoElement;
    const seekbar: HTMLElement|null = document.getElementById("seekbar")
    const seekbarPreview: HTMLElement|null = document.getElementById("seekbarPreview")
    const seekbarBackground: HTMLElement|null = document.getElementById("seekbarBackground")
    const playhead = seekbar?.querySelector(".playhead") as HTMLElement
    const videoSrc = `/api/static/variants/master_${this.parentContainer?.media?.id}_${this.selectedVersion.id}_${this.selectedVideo?.id}_${this.selectedAudio?.id}.m3u8`
    //const videoSrc = `/api/static/variants/test/test_index.m3u8`
    
    if (seekbarBackground) {
      // background: url('/api/static/variants/2104-waveform.png')
      seekbarBackground.style.backgroundImage = `url(/api/static/variants/${this.parentContainer?.media?.id}-${this.selectedAudio?.id}-waveform.png)`
    }
    if (seekbarPreview) {
      seekbarPreview.style.width = '150px'
      const ratio = this.selectedVersion.video.width!/this.selectedVersion.video.height!
      const height = 150 / ratio
      seekbarPreview.style.height = `${height}px`
      seekbarPreview.style.top = `-${height+5}px`
      
      console.log('seekbarPreview', this.selectedVersion.video.width)
      const seekbarPreviewsUrl = `/api/static/variants/${this.parentContainer?.media?.id}-${this.selectedVideo?.mediaElement?.id}-0_grid.jpg`
      fetch(seekbarPreviewsUrl, {method: 'HEAD'}).then((response) => {
        if (response.ok) {
          seekbarPreview.style.display = 'block'
          seekbarPreview.style.backgroundImage = `url(${seekbarPreviewsUrl})`
        } else {
          seekbarPreview.style.display = 'none'
        }
      })
    }
    if (seekbar) {
      seekbar.onmouseleave = (event) => {
        if (!seekbarPreview) return
        seekbarPreview.classList.remove('shown')
        this.seekbarIsHovered = false
      }
      seekbar.onmousemove = (event) => {
        if (!seekbarPreview) return
        if (!video.duration) return
        this.seekbarIsHovered = true
        let x = event.offsetX - 75
        if (x < 0) x = 0
        else if (x > seekbar.offsetWidth - 150) x = seekbar.offsetWidth - 150
        seekbarPreview.style.left = `${x}px`
        const currentTime = video.duration * event.offsetX / seekbar.offsetWidth
        
        const currentThumbnail = Math.floor(currentTime/10)
        const currentRow = Math.floor(currentThumbnail / 5)
        const currentColumn = currentThumbnail - (currentRow * 5)
        
        const currentX = currentColumn * 150
        const currentY = currentRow * 84
        seekbarPreview.style.backgroundPositionX = `-${currentX}px`
        seekbarPreview.style.backgroundPositionY = `-${currentY}px`
        seekbarPreview.classList.add('shown')

        const currentFrame = Math.floor(this.currentPlayer!.currentTime*25.0)+0.5
        this.currentTC = secondsToTimecode(currentTime, ":", "-", this.timecodeOffset)
        // playhead.style.left = `${currentTime / this.currentPlayer!.duration * 100}%`
        // console.log(currentThumbnail, currentColumn, currentRow, currentX, currentY)
      }
      seekbar.onclick = (event) => {
        this.currentPlayer!.currentTime = video.duration * event.offsetX / seekbar.offsetWidth
        playhead.style.left = `${this.currentPlayer!.currentTime / this.currentPlayer!.duration * 100}%`
      }
    }
    this.currentPlayer = video
    if (video) {
      video.onclick = (event) => {
        this.playPause()
      }
    } else {
      return
    }

    if (Hls.isSupported()) {
      var hls = new Hls();
      this.innerHls = hls;
      this.hls = hls;

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        console.log('loading false')
      });
      const hlsSubtitlesDisabled = this.configurationService.configurations.filter(c => c.key == ConfigurationKey.DISABLE_HLS_SUBTITLES)[0]?.value === 'true' ?? false
      if (!hlsSubtitlesDisabled) {
        hls.on(Hls.Events.SUBTITLE_TRACKS_UPDATED, (event: any, data: any) => {
          console.log('subtitles', event, data)
          this.subtitles = []
          for (const a of data.subtitleTracks) {
            this.subtitles.push({
              code: a.lang,
              name: a.name
            })
            if (a.autoselect) {
              this.selectedSubtitle = a.lang
            }
          }
          this.initializeAdditionalSubtitles()
        });
      }

      /*
      // keep this if we want to implement multi audio per variant one day
      hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (event: any, data: any) => {
        console.log(event, data)
        for (const a of data.audioTracks) {
          this.audios.push({
            code: a.lang,
            name: a.name
          })
          if (a.autoselect) {
            this.selectedTrack = a.lang
          }
        }
      });
      */
  
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
    } else {
      video.src = videoSrc;
    }

    if (video) {
      video.ondurationchange = (event) => {
        this.duration = secondsToTimecode(video.duration, ":", "-", this.timecodeOffset)
      };
    }    

    this.videoWatcherInterval = setInterval(() => {  
      //console.log('aaa')
      if (!seekbar || this.seekbarIsHovered) return
      const playhead = seekbar.querySelector(".playhead") as HTMLElement
      playhead.style.left = `${this.currentPlayer!.currentTime / this.currentPlayer!.duration * 100}%`
      this.currentTC = secondsToTimecode(this.currentPlayer!.currentTime, ":", "-", this.timecodeOffset)
      if (this.currentPlayer) {
        const playing = !!(this.currentPlayer.currentTime > 0 && !this.currentPlayer.paused && !this.currentPlayer.ended && this.currentPlayer.readyState > 2)
        if (!playing) {
          const currentCommentStart = timecodeToSeconds(this.selectedComment.tcIn)
          if (this.currentPlayer.currentTime > currentCommentStart*1.1 && 
              this.currentPlayer.currentTime < currentCommentStart*0.9) 
            this.stopDrawing()
        }
      }
    }, 1000 / 25)    
  }
  
  toggleCommentPane() {
    const comments = document.getElementById('comments-container')!
    const commentsSplitter = document.getElementById('comment-splitter')!
    comments.classList.toggle('closed')
    if (commentsSplitter) commentsSplitter.classList.toggle('closed')
    localStorage.setItem('commentsClosed', comments.classList.contains('closed').toString())
  }

  selectTab(tab:Tabs, hash:string) {
    this.selectedTab = tab
    const state = this.selectedVersion.id ? 
      `/media/${this.parentContainer.media?.id}/versions/${this.selectedVersion.id}#${hash}` :
      `/media/${this.parentContainer.media?.id}#${hash}`
    window.history.replaceState({}, '', state);

    setTimeout(() => {
      const writeComments = document.getElementById('write-comments')!
      if (localStorage.getItem('commentsClosed') == 'true') {
        if (writeComments) writeComments.style.visibility = 'hidden'
        const comments = document.getElementById('comments-container')
        const commentsSplitter = document.getElementById('comment-splitter')
        if (comments) comments.classList.add('closed')
        if (commentsSplitter) commentsSplitter.classList.add('closed')
        if (writeComments) setTimeout(() => writeComments.style.visibility = 'visible', 300)
      }
    }, 0)
  }
  
  playPause(event: KeyboardEvent|undefined=undefined) {
    if (!this.currentPlayer) {
      const dialogRef = this.dialog.open(SimpleTextDialog, {
        data: {
          title: 'mediaDetail.error',
          text: 'mediaDetail.please_create',
          type: SimpleTextInputType.TEXT,          
        },
      });
  
      dialogRef.afterClosed().subscribe(path => {
        console.log('The dialog was closed', path);
      });    
        
      return
    }
    const videoOverlay: HTMLElement = document.getElementById('video-overlay') as HTMLElement;
    const canvasContainer = document.getElementsByClassName('drawing-board')[0]! as HTMLElement;
    canvasContainer.classList.remove("activated")

    const isVideoPlaying = !!(this.currentPlayer!.currentTime > 0 && !this.currentPlayer!.paused && !this.currentPlayer!.ended && this.currentPlayer!.readyState > 2);
    if (isVideoPlaying) {
      console.log('pause')
      this.currentPlayer!.pause(); 
      videoOverlay.style.display = "block";
    } else {
      // break comment input
      // this.cancelSelection()
      console.log('play', this.currentPlayer)
      this.currentPlayer!.play()
      videoOverlay.style.display = "none";
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.dialog.openDialogs.length) return
    const focusedElement = document.activeElement as any;
    if (focusedElement && focusedElement.type && (focusedElement.type === 'text' || focusedElement.type === 'textarea')) return
    if (event.key === 'ArrowLeft') this.nextFrame(event, true)
    else if (event.key === 'ArrowRight') this.nextFrame(event)
    else if (event.key === 'ArrowUp') this.forward(event, 10)
    else if (event.key === 'ArrowDown') this.backward(event, 10)
    else if (event.key === 't') this.gotoFrame(event)
    else if (event.code === 'Space') {
      const type = (event.target as Element).nodeName
      console.log(type)
      if (type != 'TEXTAREA' && type != 'INPUT')
        this.playPause(event)
    }
  }

  openExternalImportDialog(): void {
    console.log('openImportDialog');

    const dialogRef = this.dialog.open(ImportDialog, {
      data: {
        multipleSelection: false
      },
    });

    dialogRef.afterClosed().subscribe((result:SelectionModel<any>) => {
      console.log('The dialog was closed', result);
      if (result) {
        const datas:any = []
        for (const sel of result.selected.values()) {
          datas.push({
            externalId: sel.id,
            name: sel.representation
          })
        }
        if (datas.length) {
          console.log('externalId', datas[0].externalId)
          this.mediaService.attachExternalIdToVersion(this.parentContainer?.media?.id!, 
            this.selectedVersion?.id!, datas[0].externalId).subscribe(_ => {
            this.getMedia(this.parentContainer?.media?.id!, true)
          })
        }
      }
    });
  }  

  subtitleTrackChanged(event:MatOptionSelectionChange, sub:any): void {
    if (event.isUserInput) {
      if (this.imscRenderLoop) clearInterval(this.imscRenderLoop)
      console.log('subtitleTrackChanged', event, sub);
      if (sub.external) {
        // filename
        const isTranscript = sub.filename.indexOf('transcript') > -1
        const ttml = isTranscript ? 
          `/api/static/variants/${sub.filename}.ttml` :
          `/api/static/variants/imsc_${this.parentContainer?.media?.id}_${sub.mediaElementId}_${sub.streamId}.stl.ttml`
        
        fetch(ttml).then((xml)=> {
          
          xml.text().then(t => {
            console.log('subtitleTrackChanged', t)  
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(t, "application/xml")
            // use any forced configuration first
            // or any shift extracted from video stream metadata
            // or any shift extracted from the subtitle metadatas
            if (isTranscript) {
              this.ttmlShift = 0
            } else if (this.ttmlShift === -1) {
              if (this.timecodeOffset) {
                this.ttmlShift = this.timecodeOffset
              }
              const startOfProgram = xmlDoc.querySelector("documentStartOfProgramme")?.textContent;
              if (startOfProgram) {
                const shift = timecodeToSeconds(startOfProgram)
                this.ttmlShift = shift
              }
            }
            this.imscSubtitles = imsc.fromXML(t);        
            
          })
        })
        
        const renderSubtitle = () => {
          this.currentSubtitleRenderer!.innerHTML = ''
          const isd = imsc.generateISD(this.imscSubtitles, this.currentPlayer!.currentTime + this.ttmlShift);
          imsc.renderHTML(isd, this.currentSubtitleRenderer);
        }
        this.imscRenderLoop = setInterval(renderSubtitle, 200)

        this.currentSubtitleMediaElement = this.parentContainer.media?.elements?.find(el => el.id == parseInt(sub.mediaElementId))
        this.subtitleService.getSubtitles(this.parentContainer?.media?.id!, sub.mediaElementId).subscribe(subtitle => {
          this.editableSubtitle = subtitle;
        })        
      } else {

        const selection = this.hls.subtitleTracks.find((a:any) => a.lang === event.source.value)
      
        const TAG = "_subtitle_"
        const uri = selection.attrs.URI
        let mediaElementId = uri.substring(uri.indexOf(TAG) + TAG.length)
        mediaElementId = mediaElementId.substring(0, mediaElementId.indexOf("_"))
        this.currentSubtitleMediaElement = this.parentContainer.media?.elements?.find(el => el.id == parseInt(mediaElementId))
        
        this.hls.subtitleTrack = selection.id
        this.subtitleService.getSubtitles(this.parentContainer?.media?.id!, mediaElementId).subscribe(subtitle => {
          this.editableSubtitle = subtitle;
        })
      }
    }
  }
}

@Component({
  selector: 'dialog-create-version',
  templateUrl: './dialog-create-version.html',
  styleUrls: ['./dialog-create-version.sass']
})
export class CreateVersionDialog {
  constructor(
    public dialogRef: MatDialogRef<CreateVersionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateVersionDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-create-version-from-definition',
  templateUrl: './dialog-create-version-from-definition.html',
  styleUrls: ['./dialog-create-version-from-definition.sass']
})
export class CreateVersionFromDefinitionDialog {

  containsAlternatives:boolean = false
  streamsContainingAlternatives:MediaExpectedStreamDefinition[] = []
  alternativesSelections = new Map<number, MediaExpectedStreamDefinition>()

  constructor(
    public dialogRef: MatDialogRef<CreateVersionFromDefinitionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateVersionFromDefinitionDialogData,
  ) {}

  ngOnInit() {
    const vd = this.dialogRef.componentInstance.data.versionDefinition
    if (vd.expectedStreamDefinitions?.flatMap(a => a.alternatives).length ?? 0 > 0) {
      this.containsAlternatives = true
      vd.expectedStreamDefinitions?.forEach(esd => {
        if (esd.alternatives?.length) {
          this.streamsContainingAlternatives.push(esd)
          this.alternativesSelections.set(esd.id!, esd)
        }
      })
      console.log('this.alternativesSelections', this.alternativesSelections)
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}


@Component({
  selector: 'dialog-elements-picker',
  templateUrl: './dialog-elements-picker.html',
  styleUrls: ['./dialog-elements-picker.sass']
})
export class ElementsPickerDialog {

  selection:any[] = []

  constructor(
    public dialogRef: MatDialogRef<ElementsPickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ElementsPickerDialogData,
  ) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface ElementsPickerDialogData {
  elements:any[]
  title:string
  text:string
}