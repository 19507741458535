import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Task } from '../models/task';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class TaskService {

  private tasksUrl = 'api/tasks'

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  getTasks(page:number=0, mediaId:number=0): Observable<Page<Task>> {
    let url = `${this.tasksUrl}?page=${page}&sort=creationDate,desc`
    if (mediaId > 0) url += `&mediaId=${mediaId}`
    return this.http.get<Page<Task>>(url)
      .pipe(
        catchError(this.errorService.handleError<Page<Task>>('tasks', {}))
      );
  }

  restart(element: Task) {
    let url = `${this.tasksUrl}/${element.id}/restart`
    return this.http.put<void>(url, {})
      .pipe(
        catchError(this.errorService.handleError<void>(`restart title=${name}`))
      );
  }

  cancel(element: Task) {
    let url = `${this.tasksUrl}/${element.id}/cancel`
    return this.http.put<void>(url, {})
      .pipe(
        catchError(this.errorService.handleError<void>(`cancel title=${name}`))
      );
  }

  private log(message: string) {
    console.log(`TaskService: ${message}`);
  }
}
