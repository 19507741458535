import { Component } from "@angular/core";
import { SimpleTextDialog } from "./simple.text.dialog";
import i18next from "i18next";

@Component({
    selector: 'dialog-error',
    templateUrl: './simple.text.dialog.html',
    styleUrls: ['simple.text.dialog.sass']
})
export class ErrorDialog extends SimpleTextDialog {

    override i18next = i18next
}
