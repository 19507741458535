import { SelectionModel } from "@angular/cdk/collections";
import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, catchError, of, tap } from "rxjs";
import { IdWithRepresentation } from "src/app/models/media";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ExternalImportService } from "src/app/services/external.import.service";

export interface ImportDialogData {
    externalDataSource?:string,
    multipleSelection:boolean
}

@Component({
    selector: 'dialog-import',
    templateUrl: './import.dialog.html',
    styleUrls: ['import.dialog.sass']
})
export class ImportDialog {

    searchInput?:string;
    datas:any[] = []
    datasource:MatTableDataSource<any> = new MatTableDataSource(this.datas)
    displayedColumns: string[] = ['select', 'representation']
    selection:SelectionModel<any>
    loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ImportDialog>,
        private externalImportService:ExternalImportService,
        private configurationService:ConfigurationService,
        @Inject(MAT_DIALOG_DATA) public data: ImportDialogData,
    ) { 
        this.selection = new SelectionModel<any>(data.multipleSelection, [])
    }

    triggerSearch() {
      this.loading = true
      this.externalImportService.getDatas(this.searchInput!).subscribe(datas => {
        this.datasource.data = datas.content!
        console.log(this.datasource)
        this.loading = false
      })
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}
