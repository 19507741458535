<h1 mat-dialog-title>{{"edittranslations.title" | i18next}}</h1>

<mat-dialog-content class="content">
    <mat-form-field appearance="fill" *ngFor="let locale of data.availableLocales">
        <mat-label>{{"edittranslations." + locale | i18next}}</mat-label>
        <input matInput [(ngModel)]="data.translations.translations.get(locale)!.value">
    </mat-form-field>
  
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{"edittranslations.cancel" | i18next}}</button>
        <button mat-button [mat-dialog-close]="data.translations" cdkFocusInitial>{{"edittranslations.ok" | i18next}}</button>
    </div>
</mat-dialog-content>