import {Component, Inject} from '@angular/core';
import { Page } from 'src/app/models/page';
import {User, UserGroup} from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
//import {prepareForm, UserMetadataDialog} from "../../components/dialogs/user.metadatas.dialog";
import {Search} from "../../models/search";
import {ConfirmationDialog} from "../../components/dialogs/confirm.dialog";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SimpleTextDialog} from "../../components/dialogs/simple.text.dialog";
import {MetadataAttachmentType, MetadataDefinition, MetadataGroup, MetadataType} from "../../models/metadata";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MetadataService} from "../../services/metadata.service";
import {MatChipInputEvent} from "@angular/material/chips";
import {UserDialog} from "../../components/dialogs/user.dialog";
import {cloneDeep, result} from "lodash";
import i18next from 'i18next';
import { messageDialog } from 'src/app/utils/utils';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ConfigurationKey } from 'src/app/models/configuration';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { prepareForm } from 'src/app/components/metadata.display/metadata.display.component';
import { UserMetadataDialog } from 'src/app/components/dialogs/user.metadatas.dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent {

  result: Page<User> = {}
  loading: boolean = true
  dataSource: User[] = []
  columnsToDisplay: string[] = ['tools', 'email', 'firstName', 'lastName', 'group', 'groupAdmin', 'roles']
  i18next = i18next
  currentUser: User | undefined;
  groupMode: boolean = true;
  metadataGroups: MetadataGroup[] = []
  metadataForm:any
  metadataTypes:typeof MetadataType = MetadataType
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType

  constructor(private userService: UserService,
              private configurationService: ConfigurationService,
              private metadataService: MetadataService,
              private fb: FormBuilder,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser
    this.groupMode = this.currentUser?.privileges?.filter(p => p === 'ADMIN_USERS').length === 0
    this.getUsers()
    this.getMetadataGroups()
  }

  copyClipboard(element:User) {
    navigator.clipboard.writeText(element.id?.toString() ?? '')    

    this.snackBar.open(i18next.t('tasks.clipboard'), i18next.t('tasks.hide') ?? 'Ok', {
      duration: 2000,
    });    
  }
  getUsers(): void {
    this.userService.getUsers(this.groupMode ? this.currentUser?.group?.id : undefined)
      .subscribe(result => {
        this.result = result
        this.loading = false
        this.dataSource = this.result.content ?? []
        console.log('set datasource to', this.dataSource)
      });
  }

  deleteCurrentUser(element: User) {

    console.log('deleteCurrentUser')

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'Please confirm',
        content: `Are you sure you want to delete this user?`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.userService.deleteUser(element.id!).subscribe(s=> {
          this.getUsers()
        })
      }
    });
  }

  createInvitationLink(user:User) {
    const baseURL = this.configurationService.configurations.filter(c => c.key === ConfigurationKey.BASE_URL)[0].value
    this.userService.createInvitationLink(user.id!).subscribe(l => {
      const value = `${baseURL}/onboard?token=${l.token}`
      console.log(value)
      messageDialog(this.dialog, i18next.t('users.invitationLink'), value, true)
    })
  }

  getMetadataGroups(): void {
    this.metadataService.getMetadataGroups()
      .subscribe(metadataGroups => {
        this.metadataGroups = metadataGroups.content!
          .filter(g => g.attachmentType == 'USER')!
        console.log('this.metadataGroups', this.metadataGroups)
      })
  }

  editUserMetadata(user:User) {
    console.log('editUserMetadata', user);

    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = this.metadataGroups?.filter(g => g.attachmentType === 'USER') 
    
    this.metadataForm = prepareForm(this.metadataService,
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      user.dynamicMetadatas,
      user.id,
      'USER');

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log('dialog value: ', result.value);
        const datas = result.value
        this.metadataService.persistMetadatasForEntity(datas).subscribe(() => {
          this.getUsers()
        });        
      }
    });    
  }

  editUser(user:User) {
    const dialogRef = this.dialog.open(UserDialog, {
      data: {
        user: cloneDeep(user),
        groupMode: this.groupMode
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.userService.persistUser(result.user).subscribe(u => {
          if (result.password) {
            this.userService.changePassword(u.id!, result.password).subscribe(_ => {
              this.getUsers()    
            })
          } else {
            this.getUsers()
          }
        })
      }
    });
  }
}