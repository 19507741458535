<h1>{{"translations.title" | i18next}}</h1>

<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<p *ngIf="!loading && !dataSource.length">
    {{ 'translations.empty' | i18next }}
</p>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="!loading && dataSource.length">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef> {{"translations.key" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.key}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="values">
      <th mat-header-cell *matHeaderCellDef> {{"translations.translations" | i18next}} </th>
      <td mat-cell *matCellDef="let element" style="padding: 10px">
        <div *ngFor="let locale of availableLocales">
            <span>{{locale}}:&nbsp;</span>
            <span class="code">{{element.translations.get(locale).value}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editTranslation(element)">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteTranslation(element)">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </td>
    </ng-container>
    
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>

<button mat-fab class="add" (click)="addTranslation()">
    <mat-icon>add</mat-icon>
</button>

