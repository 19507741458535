
<h1>
    <span *ngIf="!user">{{ 'usertasks.title' | i18next }}</span>
    <span *ngIf="user">{{ 'usertasks.your_task' | i18next }}</span>

    <button style="transform: scale(0.5) translateY(8px)" 
        *ngIf="media"
        aria-label="Add" mat-fab
        (click)="addUserTask()">
    <mat-icon>add</mat-icon>
    </button>
</h1>


<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!loading && !datas.length">
    <p>{{ 'usertasks.empty' | i18next }}</p>
</div>
<table mat-table [dataSource]="dataSource" *ngIf="!loading && datas.length" class="table-responsive" style="margin-bottom: 50px;">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.id" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
    </ng-container>

    <ng-container matColumnDef="media">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.media" | i18next }} </th>
      <td mat-cell *matCellDef="let element"> 
        <span [matTooltip]="element?.media.value">{{element?.media.value}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="stream">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.stream" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editStream(element)" class="no-wrap">  
        {{element?.mediaStream?.value ?? '—'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.version" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editVersion(element)"> 
        {{element?.mediaVersion?.value ?? '—'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.type" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editType(element)"> 
        {{element?.taskType?.label}}
      </td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.label" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editLabel(element)"> 
        {{element?.label}}
      </td>
    </ng-container>

    <ng-container matColumnDef="assignedTo">
      <th mat-header-cell *matHeaderCellDef class="no-wrap"> {{ "usertasks.assignedTo" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editAssignee(element)"> 
        <div>
            <app-profile-picture [matTooltip]="element?.assignedTo?.email ?? ''" [user]="element?.assignedTo"></app-profile-picture>
            {{ element?.assignedTo?.username }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="assignableTo">
      <th mat-header-cell *matHeaderCellDef class="no-wrap"> {{ "usertasks.assignableTo" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editAssignability(element)"> 
        <div *ngFor="let assignability of element?.assignableTo" class="no-wrap">
            {{ assignability.metadata.label }} = {{ assignability.value?.representation }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef> {{ "usertasks.createdBy" | i18next }} </th>
        <td mat-cell *matCellDef="let element"> 
          <div>
              <app-profile-picture [matTooltip]="element?.createdBy?.email ?? ''" [user]="element?.createdBy"></app-profile-picture>
              {{ element?.createdBy?.username }}
          </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.dueDate" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editDueDate(element)">
        {{ element?.dueDate | date: dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.status" | i18next }} </th>
      <td mat-cell *matCellDef="let element" (click)="editStatus(element)" [ngClass]="'usertaskstatus-' + element?.status">
        {{ element?.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.createdAt" | i18next }} </th>
      <td mat-cell *matCellDef="let element" class="no-wrap">
        {{ element?.createdAt | date: dateTimeFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="completedAt">
      <th mat-header-cell *matHeaderCellDef> {{ "usertasks.completedAt" | i18next }} </th>
      <td mat-cell *matCellDef="let element" class="no-wrap">
        {{ element?.completedAt | date: dateTimeFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="goto(element, $event)" *ngIf="user">
                <mat-icon>link</mat-icon>
            </button>            
            <button mat-icon-button (click)="delete(element, $event)" *ngIf="media">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>    
</table>