import { Media, MediaStream } from "./media";
import { MetadataAttachmentType, MetadataDefinition } from "./metadata";
import { Task } from "./task";
import { Role } from "./user";
import { UserTaskDefinition, UserTaskType } from "./usertask";

export interface UserActionDefinition {
    id?: number;
    name: string;
    label?: string;
    attachableTo?:MetadataAttachmentType;
    conditions?: UserActionMetadataDefinition[];
    consequences?: UserActionMetadataDefinition[];
    description?: string;
    groovyScript?: string;
    allowedForRoles?: Role[];
    stream?: MediaStream;
    taskToLaunchTemplate?: Task;
    userTaskToCreate?: UserTaskDefinition;
    userTaskToComplete?: UserTaskType;
    autoTriggerOn?: AutoTriggerOn;
    triggerPartnerUploadRequest?: boolean;
}

export interface UserActionMetadataDefinition {
    unset?: boolean;
    id?: number;
    metadata?: MetadataDefinition;
    value?: any;
    requiredForActionAvailability?: boolean;
}

export enum AutoTriggerOn {
    MEDIA_RECEIVED = 'MEDIA_RECEIVED',
    EXPECTED_MEDIA_STREAM_CREATED = 'EXPECTED_MEDIA_STREAM_CREATED',
}