import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import i18next from 'i18next';
import { delay } from 'rxjs';
import { SimpleOrderDialog } from 'src/app/components/dialogs/simple.order.dialog';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { UserMetadataDialog } from 'src/app/components/dialogs/user.metadatas.dialog';
import { prepareForm } from 'src/app/components/metadata.display/metadata.display.component';
import { MediaExpectedStreamDefinition, MediaVersion, MediaVersionDefinition } from 'src/app/models/media';
import { MetadataAttachmentType, MetadataGroup, MetadataType } from 'src/app/models/metadata';
import { Page } from 'src/app/models/page';
import { MetadataService } from 'src/app/services/metadata.service';
import { UserService } from 'src/app/services/user.service';
import { VersionDefinitionService } from 'src/app/services/version.definition.service';
import { confirmDialog } from 'src/app/utils/utils';

@Component({
  selector: 'app-versions-definition',
  templateUrl: './versions-definition.component.html',
  styleUrls: ['./versions-definition.component.sass']
})
export class VersionsDefinitionComponent {

  mediaVersions:Page<MediaVersionDefinition> = {}
  i18next = i18next
  loading = true
  metadataGroups: MetadataGroup[] = []
  metadataForm: any;
  metadataTypes:typeof MetadataType = MetadataType
  metadataAttachmentTypes:typeof MetadataAttachmentType = MetadataAttachmentType

  constructor(
    private dialog:MatDialog,
    private versionDefinitionService:VersionDefinitionService,
    private metadataService:MetadataService,
    private userService:UserService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.loadVersions()
    this.getMetadataGroups()
  }

  getMetadataGroups(): void {
    this.metadataService.getMetadataGroups()
      .subscribe(metadataGroups => {
        this.metadataGroups = metadataGroups.content?.filter(g => g.attachmentType == 'MEDIA_STREAM' || g.attachmentType == 'SPARE_FILE')!
      })
  }

  deleteVersion(version:MediaVersionDefinition) {
    confirmDialog(this.dialog, 
      i18next.t('versions_definition.delete_version')!, () => {
        this.versionDefinitionService.deleteVersion(version.id!)
          .subscribe(_ => this.loadVersions())
      })    
  }

  deleteExpectedStreamDefinition(stream:MediaExpectedStreamDefinition) {
    confirmDialog(this.dialog, 
      i18next.t('versions_definition.delete_stream')!, () => {
        this.versionDefinitionService.deleteExpectedStream(stream.id!)
          .subscribe(_ => this.loadVersions())
      })
  }

  editExpectedStreamDefinition(stream:MediaExpectedStreamDefinition) {

    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = this.metadataGroups?.filter(g => g.attachmentType === 'MEDIA_STREAM')

    this.metadataForm = prepareForm(this.metadataService,
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      stream.dynamicMetadatas,
      undefined,
      stream.type,
      true);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes,
        deleteable: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result === 'delete') {
        confirmDialog(this.dialog, 
          i18next.t('versions_definition.delete_stream')!, () => {
            this.versionDefinitionService.deleteExpectedStream(stream.id!)
              .subscribe(_ => this.loadVersions())
          })
      } else if (result) {
        stream.dynamicMetadatas = result.value
        this.versionDefinitionService.updateExpectedStream(stream)
          .subscribe(_ => this.loadVersions())
      }
    });    
  }

  addExpectedStreamDefinition(version:MediaVersionDefinition, type:string, originalStream:MediaExpectedStreamDefinition|undefined=undefined) {

    // populate form
    const filteredOptions = new Map<string, any>()
    const metadataGroups = type === 'SPARE_FILE' ? 
      this.metadataGroups?.filter(g => g.attachmentType === 'SPARE_FILE') :
      this.metadataGroups?.filter(g => g.attachmentType === 'MEDIA_STREAM')
    const datas = {}

    this.metadataForm = prepareForm(this.metadataService,
      this.userService,
      this.fb, metadataGroups!,
      this.userService.currentUser!,
      filteredOptions,
      datas,
      undefined,
      type,
      true);

    const dialogRef = this.dialog.open(UserMetadataDialog, {
      data: {
        filteredOptions,
        metadataGroups,
        metadataForm: this.metadataForm,
        metadataTypes: this.metadataTypes,
        metadataAttachmentTypes: this.metadataAttachmentTypes
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log(result.value);
        const expectedStreamDefinition:MediaExpectedStreamDefinition = {
          dynamicMetadatas: result.value,
          type
        }
        this.versionDefinitionService
          .addExpectedStreamTo(version.id!, expectedStreamDefinition, originalStream)
          .subscribe(_ => this.loadVersions())
      }
    });    
  }

  addVersionDefinition() {
    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: i18next.t('versions_definition.add_dialog.title'),
        text: i18next.t('versions_definition.add_dialog.text'),
        label: i18next.t('versions_definition.add_dialog.label'),
        type: SimpleTextInputType.TEXT,                    
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (!result) return
      this.versionDefinitionService.create({
        name: result
      }).subscribe(_ => {
        this.loadVersions()
      })
    });        
  }

  reorderVersionDefinitions() {

    const dialogRef = this.dialog.open(SimpleOrderDialog, {
      data: {
        list: this.mediaVersions.content,
        title: i18next.t('versions_definition.reorder_dialog.title'),
        text: i18next.t('versions_definition.reorder_dialog.text'),
        representationFor: (item:any) => item.name
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.loading = true
      this.versionDefinitionService.persistVersionDefinitionOrders(result.map((md:any) => md.id!)).subscribe(_ => this.loadVersions() )
    });   
  }

  loadVersions() {
    this.loading = true
    this.versionDefinitionService.get(true)
      .pipe(delay(250))
      .subscribe(result => {
        this.mediaVersions = result
        this.loading = false
      })
  }
}
