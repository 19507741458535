<div *ngIf="initialLoading" class="loader">
    <mat-spinner class="content"></mat-spinner>    
</div> 

<div class="top-container">
    <h1>
        <span *ngIf="!loading">
            <ng-container *ngIf="result.totalElements ?? 0 !== 0">{{"search.results" | i18next: { count: result.totalElements } }}</ng-container>
            <ng-container *ngIf="result.totalElements == undefined">{{"search.no_results" | i18next }}</ng-container>
        </span>
        <div style="height: 10px; position: relative; top: -25px; left: -20px;" *ngIf="loading">
            <mat-spinner class="content"></mat-spinner>
        </div>
    </h1>

    <mat-form-field class="search-field">
        <mat-label>{{ "search.global" | i18next }}</mat-label>
        <input matInput type="text" [(ngModel)]="searchValue" (keydown.enter)="triggerSimpleSearch()">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    
</div>

<mat-expansion-panel [expanded]="filtersOpened" (opened)="searchFiltersOpened()" (closed)="searchFiltersClosed()" style="margin-bottom: 20px">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <span *ngIf="selectedSearch">{{ "search.your_search" | i18next }} "{{ selectedSearch.name }}"</span>
            <span *ngIf="!selectedSearch">{{ "search.filters" | i18next }}</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="filter-panel">
        <div *ngIf="!filters.length">
            {{ "search.noFilter" | i18next }}
        </div>

        <div style="display: flex; gap: 15px" *ngFor="let filter of filters; let last = last">
            <app-metadata-select 
                [(selection)]="filter!.metadataDefinition" 
                [(value)]="filter!.metadataValue"
                [(operator)]="filter!.operator"
                [(formula)]="filter!.formula!"
                [metadataDefinitionFilter]="filterMetadatas"
                [additionalMetadataDefinitions]="additionalSearchMetadatas"
                [allowOperatorSelection]="true"></app-metadata-select>
            
            <div style="transform: translateY(10px);">
                <button mat-mini-fab (click)="deleteFilter(filter)">
                    <mat-icon>remove</mat-icon>
                </button>
                &nbsp;
                <button mat-mini-fab (click)="addFilter()" *ngIf="last">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
        
        <div style="display: flex; justify-content: space-between; align-items: flex-end;">
            <div style="flex-grow: 1;" *ngIf="!selectedSearch">
            </div>
            <div style="display: flex; flex-direction: column;" *ngIf="selectedSearch">
                <div>
                    <mat-checkbox 
                        class="checkbox" 
                        [(ngModel)]="selectedSearch!.notifyOwner"
                        (ngModelChange)="searchNotificationChanged($event)">
                        {{ "search.notify_owner" | i18next }}
                    </mat-checkbox>
                </div>
                <div style="display: flex; gap: 10px; align-items: center;">
                    <mat-checkbox 
                        class="checkbox" 
                        [disabled]="!selectedSearch.notifyOwner"
                        [(ngModel)]="executeActionForSavedSearch"
                        (ngModelChange)="saveCurrentSearch()">
                        {{ "search.execute_action" | i18next }}
                    </mat-checkbox>

                    <mat-form-field appearance="fill" [style.visibility]="executeActionForSavedSearch ? 'visible' : 'hidden'" style="flex-grow: 1; position: relative; top: 9px;">
                        <mat-label>{{"search.actions" | i18next}}</mat-label>
                        <mat-select [(ngModel)]="selectedSearch!.actionIdToTriggerWhenMatched" (selectionChange)="saveCurrentSearch()">
                            <mat-option *ngFor="let action of actions" [value]="action.id">
                            {{ action.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>                    
                </div>
            </div>
            <div class="add-filter-button">
                

                <button class="small-button" color="secondary" extended mat-fab [matMenuTriggerFor]="searchMenu">
                    <mat-icon>list</mat-icon> {{ "search.options" | i18next }}
                </button>
                <button class="small-button" extended mat-fab (click)="applyFilter()">
                    <mat-icon>done</mat-icon>
                    {{ "search.apply" | i18next }}
                </button>
            </div>
        </div>

        <mat-menu #searchMenu="matMenu">
            <button mat-menu-item (click)="restoreSearch()">{{ "search.restore" | i18next }}</button>
            <button mat-menu-item (click)="saveCurrentSearch()">{{ "search.save" | i18next }}</button>
            <button *ngIf="selectedSearch" mat-menu-item (click)="deleteCurrentSearch()">{{ "search.delete" | i18next }}</button>
        </mat-menu>        
    </div>
</mat-expansion-panel>

<div class="buttons">
    <button mat-fab (click)="previousPage()">
        <!-- *ngIf="result.pageable?.pageNumber && result.pageable?.pageNumber! > 0" -->
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-fab (click)="nextPage()">
        <!-- *ngIf="!result.last" -->
        <mat-icon>chevron_right</mat-icon>
    </button>
    <button mat-fab class="import" (click)="openImportDialog()" *ngIf="hasExternalImportAvailable" [matTooltip]="i18next.t('search.import')">
        <mat-icon>system_update_alt</mat-icon>
    </button>
    <button mat-fab class="add" (click)="openCreateDialog()" [matTooltip]="i18next.t('search.create_media')">
        <mat-icon>add</mat-icon>
    </button>
</div>   

<div *ngIf="!loading"> 

    <table *ngIf="dataSource.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="margin-bottom: 50px" [ngClass]="{'table-responsive': isHandset}" class="maybe-table-responsive">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

        <ng-container matColumnDef="preview" style="width: 100px;">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <div class="preview"
                    [ngStyle]="{'background-image': 'url(/api/static/variants/' + element.id + '-small.png)'}">
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortBy('name')">
                {{ "search.name" | i18next }}
                <mat-icon *ngIf="currentSort === 'name,DESC,false'">expand_more</mat-icon>
                <mat-icon *ngIf="currentSort === 'name,ASC,false'">expand_less</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element"><span class="padding">{{element.name}}</span></td>
        </ng-container>


        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortBy('created_by')">
                {{ "search.created_by" | i18next }}
                <mat-icon *ngIf="currentSort === 'created_by,DESC,false'">expand_more</mat-icon>
                <mat-icon *ngIf="currentSort === 'created_by,ASC,false'">expand_less</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.createdBy}}
            </td>
        </ng-container>

        <ng-container *ngFor="let column of customColumnsFromMetadatas()" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef class="pointer" (click)="sortBy(getMetadataDefinition(column).id+'', true)">
                {{(column | getMetadataDefinition).label}}
                <mat-icon *ngIf="currentSort === getMetadataDefinition(column).id+',DESC,true'">expand_more</mat-icon>
                <mat-icon *ngIf="currentSort === getMetadataDefinition(column).id+',ASC,true'">expand_less</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element" [ngSwitch]="(column | getMetadataDefinition).type">
                <span class="no-wrap">
                    <ng-container *ngSwitchCase="metadataTypes.REFERENCE">
                        {{element.dynamicMetadatas && element.dynamicMetadatas.hasOwnProperty((column | getMetadataDefinition).parentGroup!.id)
                            && element.dynamicMetadatas[getMetadataDefinition(column).parentGroup!.id].hasOwnProperty(getMetadataDefinition(column).name) ? 
                            element.dynamicMetadatas[getMetadataDefinition(column).parentGroup!.id][(column | getMetadataDefinition).name].representation : ''}}
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.DATE">
                        {{element.dynamicMetadatas && element.dynamicMetadatas.hasOwnProperty((column | getMetadataDefinition).parentGroup!.id)
                            && element.dynamicMetadatas[getMetadataDefinition(column).parentGroup!.id].hasOwnProperty((column | getMetadataDefinition).name) ? 
                            (element.dynamicMetadatas[getMetadataDefinition(column).parentGroup!.id][(column | getMetadataDefinition).name] | date:'yyyy/MM/dd') : ''}}
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.COMPUTED">
                        <app-formula-renderer [component]="(column | getMetadataDefinition).renderingComponent" [data]="element | computedMetadata: (column | getMetadataDefinition)"></app-formula-renderer>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{element.dynamicMetadatas && element.dynamicMetadatas.hasOwnProperty((column | getMetadataDefinition).parentGroup!.id)
                            && element.dynamicMetadatas[(column | getMetadataDefinition).parentGroup!.id].hasOwnProperty((column | getMetadataDefinition).name) ? 
                            element.dynamicMetadatas[(column | getMetadataDefinition).parentGroup!.id][(column | getMetadataDefinition).name] : ''}}
                    </ng-container>
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="elementCount">
            <th mat-header-cell *matHeaderCellDef> {{ "search.elements" | i18next }} </th>
            <td mat-cell *matCellDef="let element"> {{element.elementsCount}} </td>
        </ng-container>

        <ng-container matColumnDef="streamCount">
            <th mat-header-cell *matHeaderCellDef> {{ "search.streams" | i18next }} </th>
            <td mat-cell *matCellDef="let element"> {{element.streamsCount}} </td>
        </ng-container>

        <tr mat-row (click)="clickedRow(row)" *matRowDef="let row; columns: columnsToDisplay;"></tr>

    </table>

    <mat-card *ngIf="!loading && !dataSource.length">
        <mat-card-content>
            {{ 'search.empty' | i18next }}
        </mat-card-content>
    </mat-card>
</div>
