import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { delay } from 'rxjs';
import { MetadataAttachmentType, MetadataDefinition, MetadataType } from 'src/app/models/metadata'; 
import { QualityCheck } from 'src/app/models/qc';
import { SearchCondition } from 'src/app/models/search.condition';
import { MetadataService } from 'src/app/services/metadata.service';
import { QualityCheckService } from 'src/app/services/quality.check.service';

@Component({
  selector: 'app-qc-profile-detail',
  templateUrl: './qc-profile-detail.component.html',
  styleUrls: ['./qc-profile-detail.component.sass']
})
export class QcProfileDetailComponent {

  loading: boolean = true
  saving: boolean = false
  profile: QualityCheck|undefined

  constructor(
    private dialog: MatDialog,
    private qualityCheckService: QualityCheckService,
    private metadataService: MetadataService,
    private route: ActivatedRoute, 
  ) {}
  
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const profileId:number = parseInt(params.get('id') ?? '-1')
      this.getProfile(profileId)
    });
  }
  
  getProfile(profileId: number) {
    this.qualityCheckService.getProfile(profileId).subscribe(profile => {
      this.profile = profile
      this.loading = false
    })
  }
  
  save() {
    this.saving = true
    const profileToSave = JSON.parse(JSON.stringify(this.profile))  
    this.qualityCheckService.save(profileToSave)
      .pipe(delay(1000))
      .subscribe( s => {
        this.saving = false
      })
  }

  filterMetadatas(md:MetadataDefinition) {
    return md.type === MetadataType.EXTRACTED_FROM_STREAM && md.extractedFrom?.indexOf('mediaInfo') !== -1
  }  

  addCondition() {
    this.profile?.conditions?.push({})
  }

  removeCondition(condition:SearchCondition) {
    this.profile?.conditions?.splice(this.profile?.conditions.indexOf(condition), 1)
  }

}
