import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from './utils';
import { MetadataService } from '../services/metadata.service';
import { MetadataDefinition } from '../models/metadata';

@Pipe({name: 'getMetadataDefinition'})
export class GetMetadataDefinitionPipe implements PipeTransform {
  
  constructor(private metadataService:MetadataService) {}

  transform(value: string): MetadataDefinition {
    return this.metadataService.getMetadataDefinitionFromCache(value)
  }
}