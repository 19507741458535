<h1 mat-dialog-title>
  {{ data.isCreation ? ('version_dialog.create' | i18next) : ('version_dialog.edit' | i18next) }}
</h1>
<mat-dialog-content>
  <p>{{"dialog_create.name_the_version" | i18next}}</p>

  <mat-form-field appearance="fill">
    <mat-label>{{"dialog_create.version_name" | i18next}}</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>
    
  <p>{{"dialog_create.select" | i18next}}
  </p>
  
  <!--mat-form-field appearance="fill">
    <mat-label>Select version primary language</mat-label>
    <mat-select [(ngModel)]="data.selectedLanguage">
      <mat-option *ngFor="let language of data.availableLanguages" [value]="language.code">
        {{language.name}}
      </mat-option>
    </mat-select>
  </mat-form-field-->

  <mat-form-field appearance="fill">
    <mat-label>{{"dialog_create.pick_video" | i18next}}</mat-label>
    <mat-select [(ngModel)]="data.selectedVideo">
      <mat-option *ngFor="let stream of data.availableVideos" [value]="stream">
        <span *ngIf="stream.expected">{{stream.representation}} ({{"dialog_create.expected_stream" | i18next}})</span>
        <span *ngIf="!stream.expected">
          Stream #{{stream.streamIndex || '0'}}
          ({{stream.type}})
          in {{stream.filename}}          
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{"dialog_create.pick_audio" | i18next}}</mat-label>
    <mat-select [(ngModel)]="data.selectedAudio">
      <mat-option *ngFor="let stream of data.availableAudios" [value]="stream">
        <span *ngIf="stream.expected">{{stream.representation}} ({{"dialog_create.expected_stream" | i18next}})</span>
        <span *ngIf="!stream.expected">
          Stream #{{stream.streamIndex || '0'}}
          ({{stream.type}})
          in {{stream.filename}}          
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{"dialog_create.pick_subtitle" | i18next}}</mat-label>
    <mat-select [(ngModel)]="data.selectedSubtitles" multiple>
      <mat-option *ngFor="let stream of data.availableSubtitles" [value]="stream">
        <span *ngIf="stream.expected">{{stream.representation}} ({{"dialog_create.expected_stream" | i18next}})</span>
        <span *ngIf="!stream.expected">
          Stream #{{stream.streamIndex || '0'}}
          ({{stream.type}})
          in {{stream.filename}}          
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>  

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"dialog_create.cancel" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"dialog_create.ok" | i18next}}</button>
  </div>  
</mat-dialog-content>