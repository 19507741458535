import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { User } from './models/user';
import { MetadataService } from './services/metadata.service';
import { UserService } from './services/user.service';
import { MatSidenav } from '@angular/material/sidenav';
import { VersionDefinitionService } from './services/version.definition.service';
import { forkJoin } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslationService } from './services/translation.service';
import i18next from 'i18next';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'openmam-frontend';
  loading = true
  currentUser: User|undefined;
  isHandset: boolean = false;  
  @ViewChild('sidenav') sidenav: MatSidenav | undefined;
  
  constructor(private userService: UserService,
    private metadataService: MetadataService,
    private versionDefinitionService: VersionDefinitionService,
    private translationService: TranslationService,
    private responsive: BreakpointObserver) {
    this.currentUser = userService.currentUser
    console.log('current user in app component', this.currentUser)
    if (this.currentUser) this.loadInitialDatas()
    else this.loading = false

    userService.execChange.subscribe((value) => {
      console.log('current user in app component', value)
      this.currentUser = value
      if (this.currentUser) this.loadInitialDatas()
    })

    this.responsive.observe(Breakpoints.Handset)
      .subscribe(result => {
        this.isHandset = false; 
        if (result.matches) {
          this.isHandset = true;
        }
    });    

  }

  loadInitialDatas() {
    if (this.currentUser) {
      forkJoin([
        this.metadataService.getMetadataGroups(),
        this.versionDefinitionService.get(),
        this.translationService.loadAdditionalTranslations(i18next)
      ]).subscribe(_ => setTimeout(() => this.loading = false, 150))
    }
  }

  animateSidenav() {
    const isSideNavOpened = this.sidenav?.opened;
    const element = document.getElementById('root')!
    element.classList.toggle('sidenavClosed')
    if (isSideNavOpened) {
      
    } else {
    }

    this.sidenav?.toggle()
  }

  ngOnInit(): void {
    const currentTheme = localStorage.getItem('currentTheme')
    if (currentTheme && currentTheme != "undefined") {
      let element = document.getElementById('root')!
      element.className = currentTheme
    }
  }

}
