
<ng-container *ngIf="copiedLocations.length > 0">
    <h2>
        {{"mediaDetail.media_streams.copies" | i18next}}
    </h2>

    <div class="media-streams" style="margin-bottom: 30px">
        <div *ngFor="let location of copiedLocations">
            <span class="code">{{ location.location?.name }}</span> created {{location.createdAt! | formatDate}}
            —
            <a href="#" (click)="deleteCopiedMedia(parentContainer?.media!, location, $event)">delete</a>
        </div>
    </div>
</ng-container>

<h2>
    {{"mediaDetail.media_streams.files_and_streams" | i18next}}
</h2>

<ng-container *ngIf="parentContainer?.media?.variantsLocation">
    <div>{{"mediaDetail.media_streams.variants_localised" | i18next}} <span class="code">{{parentContainer?.media?.variantsLocation?.name}}</span>.</div>
</ng-container>

{{parentContainer?.media?.elements?.length}} {{"mediaDetail.media_streams.ingested_for_this" | i18next}}

<mat-menu #elementMenu="matMenu">
    <ng-template matMenuContent let-element="element">
        <button mat-menu-item (click)="editMetadata(element)" *ngIf="element.spareFile">
            {{ "mediaDetail.media_streams.editMetadata" | i18next }}
        </button>
        <button mat-menu-item (click)="openMetadata(element)">
            {{ 'mediaDetail.media_streams.full' | i18next }}
        </button>
        <button mat-menu-item (click)="openMetadata(element, true)" *ngIf="element.mediaInfoMetadatas && !element.spareFile">
            {{"mediaDetail.media_streams.media_info" | i18next}}
        </button>
        <button mat-menu-item (click)="changeMediaElementAccessibility(element)">
            <span *ngIf="element.spareFileAccessibleToPartners">{{ "mediaDetail.media_streams.removeAccessibility" | i18next }}</span>
            <span *ngIf="!element.spareFileAccessibleToPartners">{{ "mediaDetail.media_streams.addAccessibility" | i18next }}</span>
        </button>
        <a *ngIf="element.spareFile" mat-menu-item [href]="'/api/static/' + element.filename" target="_blank">
            {{"mediaDetail.media_streams.download" | i18next}}
        </a>     
        <button mat-menu-item (click)="deleteMediaElement(element)">
            {{ "mediaDetail.media_streams.delete" | i18next }}
        </button>   
    </ng-template>
</mat-menu>              

<ul style="margin-top: 20px;">
    <li *ngFor="let element of parentContainer?.media?.elements">

        <div *ngIf="element.spareFile" style="margin-bottom: 20px; padding-top: 20px; position: relative;">
            <span class="code">{{element.filename}} (#{{element.id}})</span><br/><br/>
            {{"mediaDetail.media_streams.in_location" | i18next}}: <span class="code">{{element.location?.name}}</span> 

            <app-metadata-display
                [attr.data-element-id]="element.id"
                [noButton]="true"
                [element]="element"
                [targetId]="element.id"
                [targetType]="metadataAttachmentTypes.SPARE_FILE"
                (onMetadataUpdated)="reload()">
            </app-metadata-display>            
        </div>        


        <div style="position: absolute; top: 5px; right: 5px; z-index: 10">
            <button mat-icon-button style="color: var(--text-secondary-color)" 
                [matMenuTriggerFor]="elementMenu" 
                [matMenuTriggerData]="{element: element}"
                style="transform: scale(0.8)">
                <mat-icon>settings</mat-icon>
            </button>
        </div>

        <div *ngIf="!element.spareFile">
            <span class="code">{{element.filename}} (#{{element.id}})</span><br/><br/>
            {{"mediaDetail.media_streams.in_location" | i18next}}: <span class="code">{{element.location?.name}}</span> 
            <ng-container *ngIf="element.copiedToLocations && element.copiedToLocations!.length > 0">
                <br/>
                {{"mediaDetail.media_streams.copied_locations" | i18next}}: 
                <span *ngFor="let location of element.copiedToLocations; let last = last;">
                    <span class="code">{{location?.location?.name}}</span> by {{location?.createdBy}} {{location?.createdAt! | formatDate}} 
                    <span *ngIf="!last">,</span> 
                </span>
            </ng-container>
            <br/><br/>
            Contains {{element.streams?.length}} streams:
        </div>

        <ul *ngIf="!element.spareFile">
            <li *ngFor="let stream of element.streams" class="stream">

                <div>
                    {{"mediaDetail.media_streams.streams" | i18next}} #{{stream.streamIndex}} {{"mediaDetail.media_streams.of_type" | i18next}} <span class="code">{{stream.type}}</span><br/>
                    {{"mediaDetail.media_streams.codec" | i18next}} {{stream.codecName}} ({{stream.codecType}})<br/>
                    {{"mediaDetail.media_streams.long_name" | i18next}} {{stream.codecLongName}}<br/>
                    {{"mediaDetail.media_streams.tag" | i18next}} {{stream.codecTagString}}
                </div>

            </li>
        </ul>
    </li>
</ul>          