import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { secondsToTimecode } from 'src/app/utils/utils';

@Component({
  selector: 'app-timecode-input',
  templateUrl: './timecode.input.component.html',
  styleUrls: ['./timecode.input.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimecodeInputComponent),
    multi: true
  }]
})
export class TimecodeInputComponent implements ControlValueAccessor {

  onChange = (timecode:string) => {};

  onTouched = () => {};

  writeValue(timecode: string): void {
    console.log('writeValue', timecode)
    if (timecode === '') timecode = '00:00:00-00'
    this.timecode = timecode
  }
  
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(isDisabled: boolean): void {
    // TODO
  }

  @Input() timecode:string = "00:00:00-00"
  @Output() timecodeChange = new EventEmitter<string>();
  @Input() label:string = "";
  @Input() video:any = "";  
  @ViewChild('input') input: ElementRef<HTMLInputElement>|undefined;

  takeVideoTimecode(event:MouseEvent) {
    const tc = secondsToTimecode(this.video.currentTime)
    if (this.input) {
      this.input.nativeElement.value = tc
      this.timecode = this.input.nativeElement.value
    }
    setTimeout(() => this.video.focus(), 0)
    event.preventDefault()
    event.stopPropagation()
    this.timecodeChange.emit(this.timecode)
    return false
  }
  
  focus(options?: FocusOptions): void {
    if (this.input) {
      this.input.nativeElement.focus(options)
      this.input.nativeElement.selectionStart = 0
      this.input.nativeElement.selectionEnd = 1
    }
  }

  willFocus(event: FocusEvent) {
    console.log('focus')
    if (event.target) {
      const elem = (event.target as HTMLInputElement)
      let start = elem.selectionStart!
      if (start == 11) start = 10
      elem.selectionStart = start
      elem.selectionEnd = start + 1
    }
  }

  click(event: MouseEvent) {
    console.log('click')
    const elem = (event.target as HTMLInputElement)
    let start = elem.selectionStart!
    if (start == 11) start = 10
    elem.selectionStart = start
    elem.selectionEnd = start + 1
  }

  keyDown(event: KeyboardEvent) {
    const elem = (event.target as HTMLInputElement)
    
    let start = elem.selectionStart!
    let end = elem.selectionEnd!

    if (Math.abs(start - end) > 1) {
      return false
    }

    console.log('selection', start, end)
    if (event.code === 'Backspace' || event.code === 'ArrowLeft') {
      elem.selectionStart = elem.selectionStart! - 1
      elem.selectionEnd = elem.selectionStart + 1
      if (event.code === 'Backspace') {
        elem.value = elem.value.substring(0, start) + '0' + elem.value.substring(end)
      }
      elem.selectionStart = start! - 1
      elem.selectionEnd = elem.selectionStart + 1
      this.checkPosition(elem, -1)
    } else if (event.code === 'ArrowRight') {
      elem.selectionStart = elem.selectionStart! + 1
      elem.selectionEnd = elem.selectionStart + 1
      this.checkPosition(elem, 1)
    } else if (event.code === 'Tab' || 
        event.ctrlKey || event.metaKey) {
      return true
    } else if (isNaN(+event.key)) {
    } else if (elem.selectionStart! > 10) {
    } else {
      start = elem.selectionStart!
      end = elem.selectionEnd!
      elem.value = elem.value.substring(0, start) + event.key + elem.value.substring(end)
      elem.selectionStart = start + 1
      elem.selectionEnd = end + 1
      this.checkPosition(elem, 1)
    }

    event.stopPropagation()
    event.stopImmediatePropagation()
    
    this.timecode = elem.value
    console.log('this.timecode', this.timecode)
    this.timecodeChange.emit(this.timecode)    
    this.onChange(this.timecode)
    this.onTouched()
    return false
  }

  set value(val:string) {
    this.timecode = val
    this.onChange(val)
    this.onTouched()
  }  

  checkPosition(elem:HTMLInputElement, direction:number=1) {
    const start = elem.selectionStart!
    const end = elem.selectionEnd!
    if ((start == 2) ||
        (start == 5) ||
        (start == 8)) {
      elem.selectionStart = start + direction
      elem.selectionEnd = end + direction
    }
    if (start == 11) {
      elem.selectionStart = 0
      elem.selectionEnd = 1
    }
  }
}
