import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Location, Media } from '../models/media';
import { TimecodedComment } from '../models/comment';
import { cloneDeep } from 'lodash';
import { NotificationLastCheck, NotificationSubscription } from '../models/notification';
import { HistoryEntry } from '../models/history';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class NotificationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  updateNotificationsLastCheck() {
    return this.http.post<void>(`/api/notifications/lastCheck`, null)
      .pipe(
        tap(_ => this.log('updateNotificationsLastCheck')),
        catchError(this.errorService.handleError<void>('updateNotificationsLastCheck'))
      );
  }

  getNotifications(): Observable<Page<HistoryEntry>> {
    return this.http.get<Page<HistoryEntry>>(`/api/notifications`)
      .pipe(
        catchError(this.errorService.handleError<Page<HistoryEntry>>('NotificationSubscription', undefined))
      );
  }

  getNotificationsLastCheck(): Observable<NotificationLastCheck> {
    return this.http.get<NotificationLastCheck>(`/api/notifications/count`)
      .pipe(
        catchError(this.errorService.handleError<NotificationLastCheck>('NotificationSubscription', undefined))
      );
  }

  getNotificationSubscription(mediaId:number): Observable<NotificationSubscription> {
    return this.http.get<NotificationSubscription>(`/api/notifications/subscription/media/${mediaId}`)
      .pipe(
        catchError(this.errorService.handleError<NotificationSubscription>('NotificationSubscription', undefined))
      );
  }

  toggleMediaSubscription(subscribed: boolean | undefined, mediaId: number): Observable<NotificationSubscription> {
    let url = `/api/notifications/subscription/media/${mediaId}`;
    
    return this.http.post<NotificationSubscription>(url, null)
      .pipe(
        catchError(this.errorService.handleError<NotificationSubscription>(`toggleMediaSubscription`))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
