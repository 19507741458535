import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Page } from '../models/page';
import { UploadRequest, UploadRequestStatus } from '../models/upload.request';
import {ErrorService} from "./error.service";


@Injectable({
  providedIn: 'root'
})
export class PartnerUploadService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorService: ErrorService) {
    }

  createUploadRequest<Data>(partnerId:number, message:string, 
                            allowStreaming: boolean, 
                            qc: number, 
                            mediaId:number, expectedStreamsIds:number[],
                            accessOnly:boolean): Observable<User> {
    const url = `/api/partner/${partnerId}/media/${mediaId}/uploadRequest`;
    return this.http.post<UploadRequest>(url, {message, allowStreaming, qc, expectedStreamsIds, accessOnly})
      .pipe(
        catchError(this.errorService.handleError<User>(`createUploadRequest`))
      ).pipe(
        map(result => {
          console.log('createUploadRequest', result)
          return result
        })
      );
  }

  updateUploadRequestStatus<UploadRequest>(requestId:number, newStatus:UploadRequestStatus, 
      updateData:any={}): Observable<UploadRequest> {
    const url = `/api/uploadRequests/${requestId}/status/${newStatus}`;
    return this.http.put<UploadRequest>(url, updateData)
      .pipe(
        catchError(this.errorService.handleError<UploadRequest>(`createUploadRequest`))
      ).pipe(
        map(result => {
          console.log('createUploadRequest', result)
          return result
        })
      );
  }

  triggerUploadRequestThirdPartyIngestion<UploadRequest>(requestId:number, locationId:number): Observable<void> {
    const url = `/api/uploadRequests/${requestId}/3rdPartyIngestionFrom/${locationId}`;
    return this.http.put<void>(url, null)
      .pipe(
        catchError(this.errorService.handleError<void>(`triggerUploadRequestThirdPartyIngestion`))
      ).pipe(
        map(result => {
          console.log('triggerUploadRequestThirdPartyIngestion', result)
          return result
        })
      );
  }

  getUploadRequestsForConnectedUser<Data>(status:UploadRequestStatus): Observable<Page<UploadRequest>> {
    const url = `/api/my/uploadRequests?status=${status}`;
    return this.http.get<Page<UploadRequest>>(url, {})
      .pipe(
        catchError(this.errorService.handleError<Page<UploadRequest>>(`getUploadRequestsForConnectedUser`))
      ).pipe(
        map(result => {
          console.log('getUploadRequestsForConnectedUser', result)
          return result
        })
      );
  }

  private log(message: string) {
    console.log(`UserService: ${message}`);
  }
}
