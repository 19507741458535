import { IdAndToString } from "./metadata";
import { MediaStream } from "./media"
import { SearchCondition } from "./search.condition";

export interface UploadRequest {
  id: number;
  uploadId?: string;
  status: UploadRequestStatus;
  partner: any;
  message: string;
  allowStreaming: boolean;
  accessOnly: boolean;
  presignedUploadURL: string;
  expectedStreamsIds: number[];
  media: IdAndToString;
  expectedStreams: MediaStream[];
  imf?:boolean;
  qualityCheck?: QualityCheck;
}

export interface QualityCheck {
  id?: number;
  name?: string;
  conditions?: SearchCondition[];
}

export interface UploadEvent {
  type: UploadEventType,
  value: number
}

export enum UploadEventType {
  FILE_FINISHED = 'FILE_FINISHED',
  PROGRESS = 'PROGRESS',
  ENDED = 'ENDED',
}


export enum UploadRequestStatus {
  PENDING = 'PENDING',
  ACCESS_ONLY = 'ACCESS_ONLY',
  UPLOADED = 'UPLOADED',
  INGESTING = 'INGESTING',
  CANCELED = 'CANCELED',
  INGESTED = 'INGESTED',
  PENDING_3RD_PARTY_UPLOAD = 'PENDING_3RD_PARTY_UPLOAD'
}
