<h1>{{"rules.Rules" | i18next}}</h1>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="result?.numberOfElements; else firstLoad">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"rules.id" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{"rules.name" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    
    <tr mat-row (click)="clickedRow(row)" *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>


<ng-template #firstLoad>
    <div *ngIf="loading; else empty">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #empty>
    <h1>{{"empty.hell" | i18next}}</h1>
    <p>{{"empty.rules" | i18next}}</p>
</ng-template>