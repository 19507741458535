import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MediaService } from "src/app/services/media.service";

export interface WorkflowDialogData {
    processId: number;
}

@Component({
    selector: 'dialog-workflow',
    templateUrl: './workflow.dialog.html',
})
export class WorkflowDialog {
    constructor(
        public dialogRef: MatDialogRef<WorkflowDialog>,
        private mediaService: MediaService,
        @Inject(MAT_DIALOG_DATA) public data: WorkflowDialogData,
    ) { }

    image: string|undefined

    ngOnInit() {
        this.mediaService.getWorkflowImage(this.data.processId)
        .subscribe(result => {
          this.image = result
        })
        this.data.processId
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
