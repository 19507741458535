<mat-form-field appearance="fill" class="timecode">
    <mat-label>{{label}}</mat-label>
    <input #input [(ngModel)]="timecode" 
            matInput 
            (focus)="willFocus($event)" 
            (mouseup)="click($event)" 
            (keydown)="keyDown($event)">
    <button matSuffix mat-icon-button 
            (click)="takeVideoTimecode($event)"
            color="secondary" 
            class="small" 
            *ngIf="video != ''">
        <mat-icon>assignment_returned</mat-icon>
    </button>
</mat-form-field>