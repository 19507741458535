<mat-card class="mat-elevation-z8" style="margin-bottom: 40px">
  <mat-card-content class="documentation">
    <markdown
      [src]="documentationFile"
      [srcRelativeLink]="true"
      (load)="onLoad($event)"
      (error)="onError($event)">
    </markdown>
  </mat-card-content>
</mat-card>
