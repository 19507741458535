import { Injectable } from '@angular/core';

import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, catchError, concat, concatAll, lastValueFrom, mergeMap, of, retry, switchMap, tap, timeout } from 'rxjs';
import { UploadEvent, UploadEventType, UploadRequest } from '../models/upload.request';
import { flatMap } from 'lodash';
import * as crypto from 'crypto-js'
import * as SparkMD5 from 'spark-md5'
import { md5 } from 'js-md5'
import {ErrorService} from "./error.service";

@Injectable({
  providedIn: 'root'
})
export class S3UploaderService {

  constructor(private http: HttpClient,
              private errorService: ErrorService) { }

  uploadfileAWSS3(partnerUpload:UploadRequest, file:File) { 

    const FILE_CHUNK_SIZE = 20000000; // 20MB
    const fileSize = file.size;
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
    console.log('NUM_CHUNKS', NUM_CHUNKS)
    
    let uploadPartsArray:any[] = [];
    let endedParts = 0;
    const observables = []
    const progresses:number[] = []

    for (let index = 1; index < NUM_CHUNKS + 1; index++) {
      const myObservable = new Observable((subscriber) => {
        (async () => {      
          // wait for the first chunk to be processed
          while (index > 1 && !partnerUpload.uploadId) {
            console.log('–– waiiiit')
            // here timeout
            // await new Promise( resolve => setTimeout(resolve, 5000) );
            await new Promise( resolve => setTimeout(resolve, 200) );
          }
          const start = (index - 1) * FILE_CHUNK_SIZE;
          const end = (index) * FILE_CHUNK_SIZE;
          const blob = (index < NUM_CHUNKS) ? file.slice(start, end) : file.slice(start);

          const arrayBuffer = await blob.arrayBuffer()
          /*
          const spark = new SparkMD5.ArrayBuffer();
          spark.append(arrayBuffer);
          const md5blob = spark.end();

          const md5blobBase64 = btoa(md5blob)
          */

          let hash = md5.create();
          hash.update(arrayBuffer);
          const md5blob = hash.base64();
                    
          console.log( "tag","imo", index , md5blob );

          
          let chunkUrl = `/api/uploadRequests/${partnerUpload.id}/newChunk?filesize=${fileSize}&chunkSize=${blob.size}`
          if (partnerUpload.uploadId)
            chunkUrl += `&uploadId=${partnerUpload.uploadId}&index=${index}`
          const uploadPart = await lastValueFrom(
            this.http.put<UploadRequest>(chunkUrl, md5blob).pipe(
              catchError(this.errorService.handleError<any>('upload', {})),
              timeout(10000), 
              retry({ delay: 500 }))
          )
          console.log('uploadPart.presignedUploadURL', uploadPart.presignedUploadURL)
          const url = uploadPart.presignedUploadURL
          const headers = new HttpHeaders({ 
            'Content-Type': "application/octet-stream",
            'Content-MD5': md5blob
          });
          const req = new HttpRequest(
            'PUT',
            url,
            blob,
            {
              headers,
              reportProgress: true
            })
        
          await lastValueFrom(this.http.request(req).pipe(
            catchError(this.errorService.handleError<any>('upload', {})),
            timeout(2000), 
            retry({ delay: 500 }),
            tap(async event => {
              if (event instanceof HttpResponse) {
                const tag = event.headers!.get('ETag')?.replace(/[|&;$%@"<>()+,]/g, '')
                console.log('tag', index, tag)
                endedParts++
                uploadPartsArray[index-1] = {
                  ETag: tag,
                  PartNumber: index
                };

                subscriber.next({
                  type: UploadEventType.FILE_FINISHED,
                })
                partnerUpload.uploadId = uploadPart.uploadId

                if (endedParts === NUM_CHUNKS) {
                  // complete
                  console.log('call complete', uploadPartsArray)
                  // here timeout
                  // await new Promise( resolve => setTimeout(resolve, 5000) );
                  await lastValueFrom(this.http
                    .put<UploadRequest>(`/api/uploadRequests/${partnerUpload.id}/complete`,
                      uploadPartsArray.map(u => u.ETag))
                  )
                  // here timeout
                  // await new Promise(resolve => setTimeout(resolve, 2000));
                  subscriber.next({
                    type: UploadEventType.ENDED,
                  })

                  subscriber.complete()
                } else {

                  subscriber.complete()
                }
              }
          
              if (event.type === HttpEventType.UploadProgress) {
                const overall = progresses.reduce((previous, current) => previous + current, 0)
                console.log('progress serv', index, event.loaded, overall)
                progresses[index] = event.loaded
                subscriber.next({
                  type: UploadEventType.PROGRESS,
                  value: overall
                })
              }
            }
          )))
        })()
          .then(
            () => {
              console.log('subscriber.complete()')
              //subscriber.complete()
            },
            (err) => subscriber.error(err)
          )
      });
      observables.push(myObservable)
    }
    return observables
  }
}

