import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from './utils';
import { MetadataDefinition } from '../models/metadata';
import { Media } from '../models/media';
import { da } from 'date-fns/locale';

@Pipe({name: 'computedMetadata'})
export class ComputedMetadataPipe implements PipeTransform {
  transform(data:any, definition:MetadataDefinition): string {
    return this.getComputedMetadata(data, definition);
  }

  getComputedMetadata(data:any, definition:MetadataDefinition) {
    var current = data;
    try {
      const formula = definition.formula!.replaceAll("\\n", "").replaceAll("\\t", "")
      var result = eval(formula)
      console.log('computed', data, definition, result)
      return result
    } catch (err) {
      console.error('error during formula evaluation', err)
      return ''
    }
  }  
}