<div class="subtitles" #subtitleEditor>
    <span *ngIf="!loading && !editableSubtitle" style="color: var(--text-secondary-color)">
        <mat-icon style="position: relative; top: 6px; margin-right: 3px">warning</mat-icon>
        {{ "mediaDetail.noSubtitleSelected" | i18next }}
    </span>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>    
    <ul>
        <li *ngFor="let cue of editableSubtitle?.cues" (click)="navigateTo(cue)" [attr.data-id]="cue.id ?? cue.startTime.time">

            <button class="editCue" mat-icon-button (click)="editCue(cue)">
                <mat-icon>edit_note</mat-icon>
            </button>      
            <span class="tc">{{secondsToTimecode((cue.startTime.time+(editableSubtitle?.gsi?.tcp?.time ?? 0))/1000.0)}} &bullet; {{secondsToTimecode((cue.endTime.time+(editableSubtitle?.gsi?.tcp?.time ?? 0))/1000.0)}}</span> <br/>
            <span class="comment">{{cue.text}}</span>
        </li>
    </ul>
</div>