<h1 mat-dialog-title>{{data.title | i18next}}</h1>
<mat-dialog-content>
  <p *ngIf="data.text">{{data.text | i18next}}</p>
  
  <mat-form-field appearance="fill">
    <mat-label *ngIf="data.label">{{data.label | i18next}}</mat-label>
    <input #input cdkFocusInitial type="text" [placeholder]="i18next.t('settings.start_typing')" matInput [formControl]="field" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.value}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>     

  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"simple_list_dialog.cancel_list_dialog" | i18next}}</button>
    <button mat-button [mat-dialog-close]="field.value">{{"simple_list_dialog.ok_list_dialog" | i18next}}</button>
  </div>  
</mat-dialog-content>