<h1 mat-dialog-title>{{data.title | i18next}}</h1>
<mat-dialog-content>

  <div style="height: 200px; width: 100%;">
    <div *ngIf="!uploading">
        <input type="file" id="file" (change)="uploadFile($event)" class="inputfile">
        <label for="file">{{"simple_upload_dialog.click_here_upload_dialog" | i18next}}</label>
    </div>
    <div *ngIf="uploading && !complete" style="position: relative; top: 55px; text-align: center; min-width: 300px;">
        <mat-spinner style="display: inline-block; transform: scale(1.2); margin-right: 5px"></mat-spinner> 
        <span style="font-weight: bold; position: absolute; top: 25px; left: 0; right: 0; text-align: center;">{{progress}}%</span>
    </div>
    <div *ngIf="complete" style="text-align: center; position: relative; top: 60px; min-width: 300px;">
        <mat-icon style="transform: scale(3);">verified</mat-icon>
        <div style="margin-top: 30px">{{"simple_upload_dialog.upload_succeeded" | i18next}}</div>
    </div>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{"simple_upload_dialog.cancel_upload_dialog" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"simple_upload_dialog.ok_upload_dialog" | i18next}}</button>
  </div>
</mat-dialog-content>