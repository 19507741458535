import { Component } from '@angular/core';
import i18next from 'i18next';
import { forkJoin, tap } from 'rxjs';
import { NotificationBaseComponent } from 'src/app/components/notification.base.component';
import { HistoryEntry, HistoryEntryType } from 'src/app/models/history';
import { Media } from 'src/app/models/media';
import { NotificationLastCheck } from 'src/app/models/notification';
import { Page } from 'src/app/models/page';
import { HistoryService } from 'src/app/services/history.service';
import { MediaService } from 'src/app/services/media.service';
import { MetadataService } from 'src/app/services/metadata.service';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent extends NotificationBaseComponent {

  loading: boolean = true;
  notifications: Page<HistoryEntry> = {};
  lastCheck: NotificationLastCheck|undefined;
  i18next = i18next

  constructor(
    protected override historyService: HistoryService,
    protected override metadataService: MetadataService,
    protected notificationService: NotificationService,
    protected mediaService: MediaService,
  ) {
    super(historyService, metadataService);
  }

  ngOnInit() {
    this.notificationService.getNotificationsLastCheck().subscribe(lc => {
      this.lastCheck = lc;
      this.notificationService.updateNotificationsLastCheck().subscribe(_ => {})
    })
    this.notificationService.getNotifications().subscribe(n => {
      this.notifications = n;
      console.log('this.notifications', this.notifications)

      let allMediaIds:number[] = []
      if (n && n.content) {
        allMediaIds = [...n.content?.filter(n => n.mediaId !== undefined).map(n => n.mediaId!) ?? [],
          ...n.content?.filter(no => no.type === HistoryEntryType.NEW_SAVED_SEARCH_ENTRY)
              .flatMap(he => (he.json!.mediaIds as number[])!)!]
      }
      const mediaIds = [...new Set(allMediaIds)]
      const mediaMap = new Map<number, Media>()
      if (!mediaIds.length) {
        this.computeOperations(this.notifications, mediaMap)
        this.loading = false
      }
      console.log('mediaIds', mediaIds)
      forkJoin(mediaIds.map(m => 
        this.mediaService.getMedia(m!)
            .pipe(tap(m => mediaMap.set(m.id!, m)))
      )).subscribe(_ => {
        this.notifications.content?.forEach(n => n.media = mediaMap.get(n.mediaId!))
        this.computeOperations(this.notifications, mediaMap)
        this.loading = false
      })
    });
  }

  notifyMe() {
    if (!('Notification' in window)) {
      alert(i18next.t('notifications.not_available'));
    }
    
    else if (Notification.permission === "granted") {
      const notification = new Notification(i18next.t('notifications.activated'));
    }
  
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = new Notification(i18next.t('notifications.activated'));
        }
      });
    }
  }  
}
