
export interface HookScript {
    id?:number;
    script?:string;
    type?:HookType;
    modifiedAt?:Date;
    modifiedBy?:string;
}

export enum HookType {
    STREAMS_INGESTED = 'STREAMS_INGESTED',
    MEDIA_CREATED = 'MEDIA_CREATED',
    STREAM_METADATA_UPDATED = 'STREAM_METADATA_UPDATED',
    PREPROCESS_BEFORE_MOVE_OR_COPY = 'PREPROCESS_BEFORE_MOVE_OR_COPY',
    GENERATE_ADDITIONAL_JOBS_INPUT = 'GENERATE_ADDITIONAL_JOBS_INPUT',
    RENAMING_BEFORE_MOVE_OR_COPY = 'RENAMING_BEFORE_MOVE_OR_COPY'
}
