import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { Page } from 'src/app/models/page';
import { Privilege, Role } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { confirmDialog, messageDialog } from 'src/app/utils/utils';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.sass']
})
export class RolesComponent {

  loading: boolean = true
  dataSource: Role[] = []
  columnsToDisplay: string[] = ['name', 'privileges', 'tools']


  constructor(private userService: UserService,
    public dialog: MatDialog) {
  }
  
  ngOnInit(): void {
    this.getRoles();
  }

  addRole() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'roles.create_role',
        text: 'roles.name',
        label: 'roles.name',
        value: '',
        type: SimpleTextInputType.TEXT,                    
      },
    });

    dialogRef.afterClosed().subscribe((name:string) => {
      console.log('The dialog was closed', name);
      if (name) {    
        if (!name.startsWith('ROLE_')) {
          messageDialog(this.dialog, i18next.t('roles.error'), i18next.t('roles.start_with_role'))
          return
        }
        this.userService.persistRole({
          name
        }).subscribe(s => {
          this.loading = true
          this.getRoles()
        })
      }
    }); 
  }

  editRole(role:Role) {
    if (role.name === 'ROLE_ADMIN') {
      messageDialog(this.dialog, i18next.t('roles.error'), i18next.t('roles.not_editable'))
      return    
    }
    const dialogRef = this.dialog.open(RolePrivilegesDialog, {
      data: {
        role: cloneDeep(role)
      },
      minWidth: '50vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result == undefined) return
      this.userService.persistRole(result.role).subscribe(_ => {
        this.loading = true
        this.getRoles()
      })
    });  
  }

  deleteRole(role:Role) {
    if (role.name === 'ROLE_ADMIN') {
      messageDialog(this.dialog, i18next.t('roles.error'), i18next.t('roles.not_deletable'))
      return    
    }
    confirmDialog(this.dialog, 
      i18next.t('roles.delete_role')!, () => {
        this.userService.deleteRole(role.id!).subscribe(result => {
          if (result && result.error) {
            messageDialog(this.dialog, i18next.t('roles.cannot_delete_role'), i18next.t('roles.cannot_delete_role_detail'))
            return    
          } else {
            this.loading = false
            this.getRoles()
          }
        })
      })        
  }

  getRoles(): void {
    this.userService.getRoles()
        .subscribe(result => {
          this.dataSource = result
          this.loading = false
        });
  }
  
}

@Component({
  selector: 'dialog-role-privileges',
  templateUrl: './dialog-role-privileges.html',
  styleUrls: [ './dialog-role-privileges.sass' ]
})
export class RolePrivilegesDialog {

  Object = Object
  privileges:Privilege[] = []

  constructor(
    public dialogRef: MatDialogRef<RolePrivilegesDialog>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.userService.getPrivileges().subscribe(p => this.privileges = p)
  }

  compareFn(r1:Role, r2:Role){
    return (r1.id == r2.id);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
