import { Component, ElementRef, HostListener, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import i18next from "i18next";
import {Clipboard} from '@angular/cdk/clipboard';
import { MatSelect } from "@angular/material/select";

export enum SimpleTextInputType {
    TIMECODE = 'TIMECODE',
    TEXT = 'TEXT',
    MULTI_VALUED = 'MULTI_VALUED',
    DATE = 'DATE',
    MULTILINE = 'MULTILINE',
    MONOSPACED = 'MONOSPACED',
    MONOSPACED_EDIT = 'MONOSPACED_EDIT',
}

export interface SimpleTextDialogData {
    copiable: boolean;
    value: string;
    label: string;
    title: string;
    text: string;
    textAreaHeightVH: number;
    type: SimpleTextInputType,
    confirm: boolean;
    allowedValues: string[];
}

@Component({
    selector: 'dialog-simple-text',
    templateUrl: './simple.text.dialog.html',
    styleUrls: ['simple.text.dialog.sass']
})
export class SimpleTextDialog {
    constructor(
        public dialogRef: MatDialogRef<SimpleTextDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleTextDialogData,
        private clipboard: Clipboard
    ) { }
    
    i18next = i18next
    @ViewChild('input') input: ElementRef|undefined;
    @ViewChild('select') select: MatSelect|undefined;

    @HostListener('document:keydown.enter')
    enterShortcut() {
        this.dialogRef.close(this.input?.nativeElement.value)
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
      if (event.code === 'Enter') {
        this.dialogRef.close(this.data.value);
      }
    }
  

    copy() {
        this.clipboard.copy(this.data.text);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
