<h1 mat-dialog-title>{{data.role.name}}</h1>

<mat-dialog-content class="content">
    <mat-form-field appearance="fill">
        <mat-label>{{"roles.roles" | i18next}}</mat-label>
        <mat-select [(ngModel)]="data.role.privileges" [compareWith]="compareFn"  multiple>
            <mat-option *ngFor="let privilege of privileges" [value]="privilege" >
                {{privilege.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{"roles.cancel" | i18next}}</button>
        <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{"roles.ok" | i18next}}</button>
    </div>
</mat-dialog-content>