import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile.picture.component.html',
  styleUrls: ['./profile.picture.component.sass']
})
export class ProfilePictureComponent {
  
  @Input() user:User|undefined;
  @ViewChild('profilePicture') profilePicture: ElementRef|undefined;
  
  ngAfterViewInit() {
    this.setSize()
  }

  setSize() {
    if (this.profilePicture) {
      this.profilePicture!.nativeElement.style.fontSize = (this.profilePicture?.nativeElement.offsetWidth/25) + 'px'
    }    
  }

  ngOnChanges() {
    setTimeout(() => this.setSize(), 0)
  }

}
