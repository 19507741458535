<h1 mat-dialog-title>{{ data.creation ? ('user_metadatas_dialog.create' | i18next) : ('user_metadatas_dialog.edit' | i18next) }}</h1>
<mat-dialog-content [ngClass]="{'is-handset': isHandset}">
    <p *ngIf="shownFields === 0">
        {{ 'user_metadatas_dialog.no_metadata' | i18next }}
    </p>
    <ul class="metadata-form" [class.atLeastTwo]="shownFields > 1" *ngIf="data.metadataForm">
        <form [formGroup]="data.metadataForm">
            <ng-container *ngFor="let metadataGroup of data.metadataGroups">
                <ng-container *ngIf="data.metadataForm.get(''+metadataGroup.id) != null">
                    <div [formGroupName]="metadataGroup.id">
                        <ng-container *ngFor="let metadataDefinition of metadataGroup.metadatas">
                            <li *ngIf="metadataDefinition.type != data.metadataTypes.COMPUTED && 
                                metadataDefinition.type != data.metadataTypes.EXTRACTED_FROM_STREAM && 
                                metadataDefinition.type != data.metadataTypes.MULTI_ENTITIES && 
                                data.metadataForm.get(''+metadataGroup.id).get(''+metadataDefinition.name) != null && 
                                canDisplayMetadata.get(metadataDefinition.id!)">
                                <ng-container [ngSwitch]="metadataDefinition.type">
                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.TEXT">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <input matInput [formControlName]="metadataDefinition.name">
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.LONG_TEXT">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <textarea matInput [formControlName]="metadataDefinition.name"></textarea>
                                    </mat-form-field>
                                    <div *ngSwitchCase="data.metadataTypes.TIMECODE">
                                        <app-timecode-input [label]="metadataDefinition.label ?? ''" [formControlName]="metadataDefinition.name"></app-timecode-input>
                                    </div>
                                    <mat-checkbox style="margin-bottom: 15px" *ngSwitchCase="data.metadataTypes.BOOLEAN" class="checkbox" [formControlName]="metadataDefinition.name">{{metadataDefinition.label}}</mat-checkbox>
                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.DATE">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <input matInput [matDatepicker]="picker" [formControlName]="metadataDefinition.name">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.MULTI_VALUED">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <mat-select [formControlName]="metadataDefinition.name">
                                            <mat-option value="[deleted]">
                                                {{ "media_upload.none" | i18next }}
                                            </mat-option>
                                            <ng-container *ngIf="metadataDefinition.translated">
                                                <mat-option *ngFor="let value of metadataDefinition.allowedValues" [value]="value">
                                                    {{ 'multi_valued.' + value | i18next }}
                                                </mat-option>         
                                            </ng-container>
                                            <ng-container *ngIf="!metadataDefinition.translated">
                                                <mat-option *ngFor="let value of metadataDefinition.allowedValues" [value]="value">
                                                    {{value}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.REFERENCE">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput [formControlName]="metadataDefinition.name" [matAutocomplete]="auto">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAutocomplete">
                                            <mat-option *ngFor="let option of data.filteredOptions.get(metadataGroup.id + '.' + metadataDefinition.name) | async" [value]="option">
                                                {{option.representation}}
                                            </mat-option>
                                        </mat-autocomplete>

                                        <button *ngIf="isRightGranted(metadataDefinition.addReferencesRestrictedToRoles, userService.currentUser?.roles)" matSuffix mat-icon-button aria-label="Add"
                                                style="transform: scale(0.7)"
                                                (click)="addReference(metadataDefinition, data.metadataForm, metadataGroup, $event)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-form-field>


                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.USER">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput [formControlName]="metadataDefinition.name" [matAutocomplete]="autoUser">
                                        <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserAutocomplete">
                                            <mat-option *ngFor="let option of data.filteredOptions.get(metadataGroup.id + '.' + metadataDefinition.name) | async" [value]="option">
                                                {{option.email}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>    

                                    <mat-form-field appearance="fill" *ngSwitchCase="data.metadataTypes.EXTERNAL_KEY_VALUE">
                                        <mat-label>{{metadataDefinition.label}}</mat-label>
                                        <input type="text" [placeholder]="i18next.t('settings.start_typing')" matInput [formControlName]="metadataDefinition.name" [matAutocomplete]="autoExternal">
                                        <mat-autocomplete #autoExternal="matAutocomplete">
                                            <mat-option *ngFor="let option of data.filteredOptions.get(metadataGroup.id + '.' + metadataDefinition.name) | async" [value]="option.key">
                                                {{option.value}}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>    
                                                                           
                                    <div *ngSwitchDefault>
                                        NO {{metadataDefinition.type}}
                                    </div>
                                </ng-container> 
                            </li>
                        </ng-container>
                    </div>                                        
                </ng-container>
            </ng-container>

        </form>
    </ul>    
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-button mat-dialog-close="delete" color="warn" style="margin-right: 50px" *ngIf="data.deleteable">{{"user_metadatas_dialog.delete_metadatas_dialog" | i18next}}</button>
    <button mat-button (click)="onNoClick()">{{"user_metadatas_dialog.cancel_metadatas_dialog" | i18next}}</button>
    <button mat-button [mat-dialog-close]="data.metadataForm" cdkFocusInitial>{{"user_metadatas_dialog.confirm_metadatas_dialog" | i18next}}</button>
</div>