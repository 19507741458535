<h1 mat-dialog-title>{{ "import.title" | i18next  }}</h1>
<mat-dialog-content>

  <div class="head">
    <mat-form-field appearance="fill">
      <mat-label>{{ "import.label" | i18next  }}</mat-label>
      <input matInput (keydown.enter)="triggerSearch()" [(ngModel)]="searchInput">
    </mat-form-field>

    <button mat-fab extended color="primary" (click)="triggerSearch()">
      <mat-icon>search</mat-icon>&nbsp;{{ "import.search" | i18next  }}
    </button>      
  </div>  

  <div class="table-container">

    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <table *ngIf="datasource.data.length && !loading" mat-table [dataSource]="datasource">

      <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>

          </th>
          <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)">
              </mat-checkbox>
          </td>
      </ng-container>

      <ng-container matColumnDef="representation">
          <th mat-header-cell *matHeaderCellDef> Item </th>
          <td mat-cell *matCellDef="let element">{{element.representation}}</td>
      </ng-container>
      
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>  
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="selection" cdkFocusInitial>Ok</button>
</div>  