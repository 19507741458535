<h1>
  {{"users.user" | i18next}}
  <span *ngIf="groupMode">{{"users.ofGroup" | i18next: { group: currentUser?.group?.name } }}</span>
</h1>
     
<mat-menu #toolsMenu="matMenu" style="margin-top: 5px">
  <ng-template matMenuContent let-element="element">
    <button mat-menu-item (click)="copyClipboard(element)">ID: {{element.id}}</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="createInvitationLink(element)"><mat-icon>link</mat-icon> {{ 'users.generateLink' | i18next }}</button>
    <button mat-menu-item (click)="editUser(element)"><mat-icon>edit</mat-icon> {{ 'users.edit' | i18next }}</button>
    <button mat-menu-item (click)="editUserMetadata(element)"><mat-icon>edit_note</mat-icon> {{ 'users.editMetadata' | i18next }}</button>
    <button mat-menu-item (click)="deleteCurrentUser(element)"><mat-icon>delete_forever</mat-icon> {{ "users.delete" | i18next }}</button>
  </ng-template>
</mat-menu>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="result?.numberOfElements; else firstLoad" style="margin-bottom: 60px;">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> {{"users.id" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{"users.email" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> <span class="code">{{element.email}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> {{"users.first_name" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
    </ng-container>    
    
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> {{"users.last_name" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>    

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef> {{"users.group" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> {{element.group?.name}} </td>
    </ng-container>    

    <ng-container matColumnDef="groupAdmin">
      <th mat-header-cell *matHeaderCellDef>
        <span style="position: relative; top: -4px;">
          {{"users.groupAdminShort" | i18next}}
          <mat-icon 
            [matTooltip]="i18next.t('users.groupAdmin')"
            style="cursor:pointer; transform: scale(0.7); position: relative; top: 7px; opacity: 0.6;">
            help
          </mat-icon>
        </span> 
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="element.groupAdmin" disabled></mat-checkbox>
      </td>
    </ng-container>    
    
    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef> {{"users.roles" | i18next}} </th>
      <td mat-cell *matCellDef="let element"> 
        <ul>
          <li class="code" *ngFor="let role of element.roles">{{role}}</li>
        </ul>
      </td>
    </ng-container>
    <ng-container matColumnDef="tools">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
            <a mat-icon-button [matMenuTriggerFor]="toolsMenu" [matMenuTriggerData]="{element}">
              <mat-icon>more_vert</mat-icon>
            </a>
        </td>
    </ng-container>
    
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
</table>

<button mat-fab class="add" (click)="editUser({})">
    <mat-icon>add</mat-icon>
</button>

<ng-template #firstLoad>
    <div *ngIf="loading; else empty">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-template #empty>
    <p>{{"empty.hell" | i18next}}</p>
</ng-template>