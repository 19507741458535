import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { debounce, fromEvent, interval } from 'rxjs';
import { Media, MediaElement } from 'src/app/models/media';
import { EditableSubtitle, SubtitleCue } from 'src/app/models/subtitle';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { pad, secondsToTimecode } from 'src/app/utils/utils';
import { SimpleTextDialog, SimpleTextInputType } from '../dialogs/simple.text.dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-subtitle-editor',
  templateUrl: './subtitle.editor.component.html',
  styleUrls: ['./subtitle.editor.component.sass']
})
export class SubtitleEditorComponent {
  @Input() mediaElement:MediaElement|undefined;
  @Input() media:Media|undefined;
  @Input() video:HTMLVideoElement|undefined;
  @ViewChild('subtitleEditor') subtitleEditor: ElementRef | undefined;
  editableSubtitle: EditableSubtitle|undefined;
  secondsToTimecode = secondsToTimecode;
  loading: boolean = false;
  intervalId: any;

  constructor(private subtitleService:SubtitleService,
              private dialog:MatDialog,
              private configurationService:ConfigurationService) {}

  ngOnChanges() {
    if (this.media && this.mediaElement) {
      this.loading = true
      this.subtitleService.getSubtitles(this.media.id!, this.mediaElement.id!)
        .subscribe(subtitle => {
          this.editableSubtitle = subtitle;
          this.loading = false
        })
    } else {
      this.editableSubtitle = undefined
    }
    if (this.video) {
      this.intervalId = setInterval(() => {
        const selected = this.editableSubtitle?.cues?.find(cue => 
          cue.startTime.time/1000 < this.video!.currentTime &&
          cue.endTime.time/1000 > this.video!.currentTime
        )
        if (this.subtitleEditor) {
          this.subtitleEditor!.nativeElement.querySelectorAll('.selected')
            .forEach((e:HTMLElement) => e.classList.remove('selected'))
          if (selected) {
            this.subtitleEditor!.nativeElement
              .querySelector(`[data-id='${selected!.id ?? selected.startTime.time}']`)
              ?.classList.add('selected')
          }
        }
      }, 100)
      /*
      fromEvent(this.video, 'ontimeupdate').pipe(
        debounce(e => interval(200))
      ).subscribe((ev:any) => {
        console.log('aaa')
        const selected = this.editableSubtitle?.cues?.find(cue => {
          cue.startTime.time/1000 < this.video!.currentTime &&
          cue.endTime.time/1000 > this.video!.currentTime
        })
        console.log(selected)
      })
      */
    }
  }

  editCue(cue:SubtitleCue) {
    console.log('edit', cue)

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'Edit subtitle',
        label: 'Subtitle',
        type: SimpleTextInputType.TEXT,
        value: cue.text
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result === '') return
      const shift = parseInt(this.configurationService.configurations.filter(c => c.key == 'TTML_SHIFT')[0]?.value ?? 0)
      const time = cue.startTime.time + (shift*1000)
      const timeString = secondsToTimecode(time/1000, "", "")
      this.subtitleService.edit(this.media!, this.mediaElement!, timeString, result).subscribe()
    });      
  }

  navigateTo(cue:SubtitleCue) {
    this.video!.currentTime = cue.startTime.time/1000
  }
  
}
