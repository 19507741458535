import { Component, Inject } from "@angular/core";
import {FormBuilder, FormControl} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable, debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs";
import {Media} from "src/app/models/media";
import {MetadataAttachmentType, MetadataGroup, MetadataReference, MetadataType} from "src/app/models/metadata";
import {Page} from "src/app/models/page";
import {User, Role, UserGroup} from "src/app/models/user";
import { MetadataService } from "src/app/services/metadata.service";
import {UserService} from "../../services/user.service";
import {keys} from "lodash";
import i18next from "i18next";

export interface UserDialogData {
    user: User
    password: string
    groupMode: boolean
}

@Component({
    styleUrls: ['./user.dialog.sass'],
    selector: 'dialog-user',
    templateUrl: './user.dialog.html',
})

export class UserDialog {

    roles: Role[] = [];
    groups: UserGroup[] = [];

    constructor(
        public dialogRef: MatDialogRef<UserDialog>,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: UserDialogData,
    ) { }

    ngOnInit() {
        this.userService.getRoles().subscribe(roles => {
            this.roles = roles;
        })
        this.userService.getUserGroups().subscribe(groups => {
            this.groups = groups.content ?? [];
        })
    }

    compareFn(r1 : string, r2 : string){
        return (r1 == r2);
    }
    compareGroupFn(r1 : UserGroup, r2 : UserGroup){
        return (r1.id == r2.id);
    }

    displayAutocomplete(value: MetadataReference) {
        console.log('displayAutocomplete', value)
        return value?.representation ?? ''
    }
    onNoClick(): void {
        this.dialogRef.close();

    }
}