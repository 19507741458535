import { MediaElement, Location, Media } from "./media";
import { UserActionDefinition } from "./user.action";

export interface Task {
    destinationLocation?: Location;
    id?: number;
    type?: TaskType;
    startDate?: string;
    createdBy?: string;
    media?: Media;
    mediaElement?: MediaElement;
    lockedBy?: string;
    progress?: number;
    additionalJobInputs?: any;
    status?: TaskStatus;
    errorReason?: string;
    bytesPerSecond?: number;
    template?: boolean;
    actionToTriggerIfSuccessful?: UserActionDefinition;
}

export enum TaskType {
    COPY_OR_MOVE_ASSET = 'COPY_OR_MOVE_ASSET',
    FFMPEG_OUTGEST = 'FFMPEG_OUTGEST'
}
export enum TaskStatus {
    WORKING = 'WORKING',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    SUCCEEDED = 'SUCCEEDED',
}