import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Location } from '../models/media';
import { TimecodedComment } from '../models/comment';
import { HookScript } from '../models/hooks';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class HookService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  persistHook<Data>(hook: HookScript): Observable<Data> {
    let url = `/api/hooks/${hook.id}`;
    return this.http.post<Data>(url, hook)
      .pipe(
        catchError(this.errorService.handleError<Data>(`persistHook title=${hook.id}`))
      );
  }

  getHooks(): Observable<Page<HookScript>> {
    return this.http.get<Page<HookScript>>(`/api/hooks`)
      .pipe(
        tap(_ => this.log('fetched HookScript')),
        catchError(this.errorService.handleError<Page<HookScript>>('HookScript', {}))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
