import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Location, Media } from '../models/media';
import { TimecodedComment, TimecodedCommentAnswer } from '../models/comment';
import { cloneDeep } from 'lodash';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class CommentService {
  

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  persistTimecodedComment<Data>(mediaId:number, comment: TimecodedComment, 
                                versionId:number|undefined=undefined,
                                trackId:number|undefined=undefined): Observable<Data> {
    let url = `/api/media/${mediaId}/timecodedComment?dummy=dummy`;
    if (comment.mediaStream && comment.mediaStream.mediaElement && comment.mediaStream.partnerUploadRequest) {
      delete comment.mediaStream.partnerUploadRequest
    }
    if (trackId) url += `&trackId=${trackId}`
    if (versionId) url += `&versionId=${versionId}`
    const commentToSave = cloneDeep(comment)
    if (commentToSave.mediaVersion) {
      commentToSave.mediaVersion = undefined
    }
    return this.http.post<Data>(url, commentToSave)
      .pipe(
        catchError(this.errorService.handleError<Data>(`persistTimecodedComment title=${name}`))
      );
  }

  persistAnswer(mediaId:number, to: TimecodedComment, message: string) {
    let url = `/api/media/${mediaId}/timecodedComment/${to.id}/answer`;
    return this.http.post<TimecodedCommentAnswer>(url, {
      comment: message
    }).pipe(
      catchError(this.errorService.handleError<TimecodedCommentAnswer>(`persistAnswer`))
    );
  }  

  getTimecodedComments(media:Media): Observable<Page<TimecodedComment>> {
    return this.http.get<Page<TimecodedComment>>(`/api/media/${media.id}/timecodedComments`)
      .pipe(
        tap(result => {
          const allStreams = media.elements?.flatMap(e => e.streams)!
          result.content?.forEach(tc => {
            if (tc.mediaStream) {
              tc.mediaStream = allStreams.find(ms => tc.mediaStream?.id === ms?.id)!
            }
            if (tc.mediaVersion) {
              tc.mediaVersion = media.versions?.find(v => v.id === tc.mediaVersion?.id)!
            }
          })
        }),
        catchError(this.errorService.handleError<Page<TimecodedComment>>('TimecodedComment', {}))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
