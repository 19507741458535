import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, debounceTime, delay, distinctUntilChanged, map, startWith, switchMap } from 'rxjs';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { SimpleImageDialog } from 'src/app/components/dialogs/simple.image.dialog';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { SimpleUploadDialog } from 'src/app/components/dialogs/simple.upload.dialog';
import { Media, MediaElement, MediaVersion } from 'src/app/models/media';
import { OutgestProfile } from 'src/app/models/outgest.profile';
import { Task } from 'src/app/models/task';
import { UploadRequest, UploadRequestStatus } from 'src/app/models/upload.request';
import { User } from 'src/app/models/user';
import { LocationService } from 'src/app/services/location.service';
import { MediaService } from 'src/app/services/media.service';
import { OutgestService } from 'src/app/services/outgest.service';
import { UserService } from 'src/app/services/user.service';
import { intersect, secondsToTimecode, timecodeToSeconds } from 'src/app/utils/utils';
import { Location } from 'src/app/models/media';
import { MediaStream } from 'src/app/models/media';
import { SelectionModel } from '@angular/cdk/collections';
import { UserActionsService } from 'src/app/services/user.actions.service';
import { UserActionDefinition } from 'src/app/models/user.action';
import i18next from 'i18next';
import { WorkflowDialog } from 'src/app/components/dialogs/workflow.dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { MediaDetailComponent } from '../media-detail/media-detail.component';
import { IngestDialog, IngestDialogData } from 'src/app/components/dialogs/ingest.dialog';
import { PartnerUploadService } from 'src/app/services/partner.upload.service';
import { ImportDialog } from 'src/app/components/dialogs/import.dialog';
import { MetadataAttachmentType } from 'src/app/models/metadata';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationSubscription } from 'src/app/models/notification';
import { MoveMediaDialog } from 'src/app/components/dialogs/move.media.dialog';
import { OutgestDialog } from 'src/app/components/dialogs/outgest.dialog';
import { RequestPartnerUploadDialog } from '../media-uploads/media-uploads.component';

@Component({
  selector: 'app-media-detail-container',
  templateUrl: './media-detail-container.component.html',
  styleUrls: ['./media-detail-container.component.sass']
})
export class MediaDetailContainerComponent {

  currentUser: User | undefined;
  media: Media|undefined;
  mediaId: number = -1;
  loading: boolean = false;
  pendingUploads: UploadRequest[] = [];
  pendingTasks: Task[] = [];
  workflowImage: String | undefined;
  selectedVersion?: MediaVersion;
  intersect = intersect;
  availableActions: UserActionDefinition[] = [];
  i18next = i18next
  versionsProcessId: { name: string, versionId: number; processId: number;}[] = [];
  hasMediaWorkflow: boolean = false;
  currentComponent: any;
  pendingTaskTimeout: any;
  hasExternalImportAvailable: boolean = false;
  notificationSubscription: NotificationSubscription|undefined;

  constructor(private route: ActivatedRoute, 
    private router: Router,
    private mediaService: MediaService,
    private userActionsService: UserActionsService,
    private userService: UserService, 
    private notificationService: NotificationService,
    private configurationService: ConfigurationService,
    private partnerUploadService: PartnerUploadService,
    private locationService: LocationService,
    private outgestService: OutgestService,
    public dialog: MatDialog) {
      this.currentUser = userService.currentUser
      userService.execChange.subscribe((value) => {
        this.currentUser = value
      })      
    }
    
  ngOnDestroy(): void {
    console.log('component destroyed')
    clearTimeout(this.pendingTaskTimeout)
  }

  ngOnInit(): void {
    this.hasExternalImportAvailable = this.configurationService.configurations
        .filter(c => c.key == 'EXTERNAL_IMPORT_ENDPOINT')[0]?.value !== undefined

    this.route.paramMap.subscribe((params: ParamMap) => {
      const id:number = parseInt(params.get('id') ?? '-1')
      this.mediaId = id;
      this.getMedia(id)
      this.getPendingTaskForMedia(id)
      this.pendingTaskTimeout = setInterval(() => this.getPendingTaskForMedia(id), 5000)
      // this.getMetadataGroups(id)
      // this.loadComments(id)
    });
  }

  executeAction(action:UserActionDefinition):void {

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.please_confirm',
        content: i18next.t('mediaDetail.confirmAction')
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.userActionsService.executeAction(action, MetadataAttachmentType.MEDIA, this.media?.id!)
          .subscribe(_ => this.getMedia(this.media?.id!, true))
      }
    });
  }

  openMoveMediaDialog(): void {
    console.log('openMoveMediaDialog');
    let locationId = null;
    if (this.media?.elements?.length) (
      // for now, all elements of a media share the same location
      locationId = this.media?.elements[0].location?.id
    )
    const dialogRef = this.dialog.open(MoveMediaDialog, {
      data: {
        locationId,
        noVersion: !this.media?.versions?.length
      },
    });
    dialogRef.afterClosed().subscribe(resultData => {
      console.log('The dialog was closed', resultData);
      if (!resultData.locationId) return
      const mediaDetail = this.currentComponent as MediaDetailComponent
      this.mediaService.triggerMediaMove(this.media?.id ?? -1, 
          resultData.locationId, 
          resultData.copy, 
          resultData.onlyCurrentVersion ? mediaDetail.selectedVersion.id : undefined)
        .subscribe(() => {
          this.getMedia(this.media?.id ?? -1, true)
          this.getPendingTaskForMedia(this.media?.id ?? -1)
        });
    });
  } 

  toggleNotifications() {
    console.log('toggleNotifications()', this.notificationSubscription)
    this.notificationService.toggleMediaSubscription(this.notificationSubscription?.subscribed, this.mediaId)
      .subscribe(s => {
        this.notificationSubscription = s
      })
  }

  displayWorkflowImage(processId:number) {
    const dialogRef = this.dialog.open(WorkflowDialog, {
      data: {
        processId
      },
      minWidth: '80vw',
      height: '90vh'
    });
  }

  onRouterOutletActivate(event : any) {
    console.log('onRouterOutletActivate', event);
    this.currentComponent = event
  }

  setPreviewImage() {
    if (this.currentComponent instanceof MediaDetailComponent) {

      const mediaDetail = this.currentComponent as MediaDetailComponent
      const time = mediaDetail.currentPlayer?.currentTime ?? 0
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          title: 'mediaDetail.confirmPreviewImage',
          content: 'mediaDetail.confirmPreviewImageMessage',
          i18nData: { timecode: secondsToTimecode(time) }
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result) {
          const versionId = mediaDetail.selectedVersion.id!
          this.mediaService.setPreviewImage(this.media?.id!, versionId, time).subscribe(_ => {})          
        }
      });
    }
  }

  generateTranscript() {
    if (this.currentComponent instanceof MediaDetailComponent) {

      const mediaDetail = this.currentComponent as MediaDetailComponent
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          title: 'mediaDetail.confirmCreateTranscript',
          content: 'mediaDetail.confirmCreateTranscriptMessage',
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if (result) {
          const versionId = mediaDetail.selectedVersion.id!
          this.mediaService.generateWhisperTranscript(this.media?.id!, versionId).subscribe(_ => {})          
        }
      });
    }
  }  

  openOutgestDialog(): void {
    console.log('openOutgestDialog', this.currentUser?.group?.defaultLocation);
    let locationId = null;
    let availableStreams:any[] = this.media?.elements?.flatMap(e => e.streams?.map(s => {
      s.fromFilename = e.filename
      return s
    })) as any[]
    console.log(this.media?.elements)
    const dialogRef = this.dialog.open(OutgestDialog, {
      data: {
        locationId,
        availableFiles: this.media?.elements,
        availableVideoStreams: availableStreams.filter(s => s.type === 'VIDEO'),
        availableAudioStreams: availableStreams.filter(s => s.type === 'AUDIO'),
        availableSubtitleStreams: availableStreams.filter(s => s.type === 'SUBTITLE'),
        outgestProfile: availableStreams.filter(s => s.type === 'SUBTITLE'),
        user: this.currentUser,
        tcIn: '00:00:00-00',
        tcOut: '00:00:00-00',
      },
    });
    dialogRef.afterClosed().subscribe(data => {
      console.log('The dialog was closed', data);
      if (!data) return
      const selectedVideoStreams = (data.selectedVideoStreams as MediaStream[])?.map(s => s.id!)
      const selectedAudioStreams = (data.selectedAudioStreams as MediaStream[])?.map(s => s.id!)
      const selectedSubtitleStreams = (data.selectedSubtitleStreams as MediaStream[])?.map(s => s.id!) 
      const selectedFiles = (data.selectedFiles as MediaElement[])?.map(s => s.id!)
      if (this.currentUser?.group?.defaultLocation) data.destinationLocation = { id: this.currentUser?.group?.defaultLocation }
      this.outgestService.triggerOutgestTask(data.selectedOutgestProfile.id, 
        this.media?.id!, 
        data.destinationLocation.id, 
        selectedVideoStreams, 
        selectedAudioStreams,
        selectedSubtitleStreams,
        selectedFiles,
        data.selectedPreroll?.id,
        timecodeToSeconds(data.tcIn), 
        timecodeToSeconds(data.tcOut),
        data.outputFilenamePrefix,
        data.createSpareFile,
        data.spareFileAccessibleToPartners)
        .pipe(delay(200)).subscribe(result => {
          console.log(result)
          this.getPendingTaskForMedia(this.media?.id ?? -1)
        });
    });    
  }  

  openDeleteConfirmationDialog(): void {
    console.log('openDeleteConfirmationDialog');

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'mediaDetail.please_confirm',
        content: i18next.t('mediaDetail.delete_the_media', {mediaID: this.media?.id})
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.mediaService.deleteMedia(this.media?.id!).subscribe(() => {
          this.router.navigate(['search']);
        })
      }
    });
  }    

  openExternalImportDialog(): void {
    console.log('openImportDialog');

    const dialogRef = this.dialog.open(ImportDialog, {
      data: {
        multipleSelection: false
      },
    });

    dialogRef.afterClosed().subscribe((result:SelectionModel<any>) => {
      console.log('The dialog was closed', result);
      if (result) {
        const datas:any = []
        for (const sel of result.selected.values()) {
          datas.push({
            externalId: sel.id,
            name: sel.representation
          })
        }
        if (datas.length) {
          console.log('externalId', datas[0].externalId)
          this.mediaService.attachExternalId(this.media?.id!, datas[0].externalId).subscribe(_ => {
            this.getMedia(this.mediaId, true)
          })
        }
      }
    });
  }
  
  openIngestUploadDialog():void {
    console.log('openIngestDialog');

    const dialogRef = this.dialog.open(SimpleUploadDialog, {
      data: {
        title: 'mediaDetail.ingest_new',
        mediaId: this.media?.id,
      },
    });

    dialogRef.afterClosed().subscribe(path => {
      console.log('The dialog was closed', path)
      if (path)
        this.getPendingTaskForMedia(this.media?.id ?? -1)
    });       
  }

  openIngestDialog(): void {
    console.log('openIngestDialog');

    const dialogRef = this.dialog.open(IngestDialog, {
      data: {
        uploadRequests: this.media?.partnerUploadRequests
          ?.filter(p => p.status === UploadRequestStatus.PENDING_3RD_PARTY_UPLOAD)
      },
    });

    dialogRef.afterClosed().subscribe((data:IngestDialogData) => {
      console.log('The dialog was closed', data);
      if (data === undefined) return
      if (data.uploadRequest) {
        this.partnerUploadService.triggerUploadRequestThirdPartyIngestion(data.uploadRequest.id, data.location.id!)
          .subscribe(_ => this.getPendingTaskForMedia(this.media?.id ?? -1))
      } else {
        this.mediaService.triggerMediaScan(this.mediaId ?? -1, data.pattern, data.location.id)
          .pipe(delay(200)).subscribe(_ => this.getPendingTaskForMedia(this.media?.id ?? -1));
      }
    });    
  }

  openRequestPartnerUploadDialog(accessOnly:boolean=false): void {

    const dialogRef = this.dialog.open(RequestPartnerUploadDialog, {
      data: {},
    });
    
    dialogRef.afterClosed().subscribe(form => {
      console.log('The dialog was closed', form.partner.value);
      this.partnerUploadService.createUploadRequest(form.partner.value.id, 
        form.message.value, 
        form.allowStreaming.value, 
        form.qc.value.id, 
        this.media?.id!, 
        [],
        accessOnly)
        .subscribe(() => {
          this.getMedia(this.media?.id ?? -1, true)
        });
    });
  }

  openRenameMediaDialog(): void {
    console.log('openRenameMediaDialog');

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'mediaDetail.rename_media',
        text: 'mediaDetail.new_name',
        label: 'mediaDetail.name',
        value: this.media?.name,
        type: SimpleTextInputType.TEXT,        
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (!result) return
      this!.media!.name = result
      this.mediaService.updateMedia(this?.media!)
        .subscribe(result => this.getMedia(this?.media?.id ?? -1, true));
    });    
  }

  getPendingTaskForMedia(id:number): void {
    this.mediaService.getPendingTaskForMedia(id)
      .subscribe(result => {
        this.pendingTasks = result
      })
  }

  getAvailableActionsForMedia(mediaId:number) {
    this.userActionsService.getAvailableActionsFor(MetadataAttachmentType.MEDIA, mediaId)
      .subscribe(a => this.availableActions = a);
  }

  getMedia(id:number, forceReload:boolean=false, callback:((media:Media) => void)|undefined=undefined): void {
    if (!forceReload && this.media) {
      if (callback) callback(this.media)
      return
    }
    // TODO parallelize
    this.notificationService.getNotificationSubscription(id).subscribe(s => this.notificationSubscription = s)
    this.getAvailableActionsForMedia(id);
    this.mediaService.getMedia(id)
      .subscribe(result => {
        this.media = result
        
        // fetch workflow image
        if (result && result.activitiProcessId) {
          this.hasMediaWorkflow = true
        }
        this.versionsProcessId = result.versions?.filter(v => v.activitiProcessId).map(v => {
          return {
            versionId: v.id!,
            name: v.name!,
            processId: v.activitiProcessId!
          }
        }) ?? []
        if (callback) callback(result)
        this.pendingUploads = result.partnerUploadRequests?.filter(p => p.status !== 'INGESTED') || []
        this.loading = false;
      })
  }  
  
}

