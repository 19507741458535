import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'src/app/components/dialogs/confirm.dialog';
import { SimpleTextDialog, SimpleTextInputType } from 'src/app/components/dialogs/simple.text.dialog';
import { Page } from 'src/app/models/page';
import { QualityCheck } from 'src/app/models/qc';
import { QualityCheckService } from 'src/app/services/quality.check.service';

@Component({
  selector: 'app-qc-profiles',
  templateUrl: './qc-profiles.component.html',
  styleUrls: ['./qc-profiles.component.sass']
})
export class QcProfilesComponent {

  loading: boolean = true
  dataSource: QualityCheck[] = []
  columnsToDisplay: string[] = [/*'id', */'name', 'tools']
  result: Page<QualityCheck> = {}

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public qualityCheckService: QualityCheckService
  ) {}

  ngOnInit() {
    this.load()
  }

  load() {
    this.qualityCheckService.getProfiles().subscribe(profiles => {
      this.result = profiles
      this.dataSource = this.result.content ?? []
      this.loading = false
    })    
  }

  deleteProfile(profile:QualityCheck, event:Event) {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'actions.confirm_delete',
        content: `actions.confirm_delete_message`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.qualityCheckService.deleteProfile(profile.id!).subscribe(_ => {
          this.load()
        })
      }
    });
  }

  addProfile() {

    const dialogRef = this.dialog.open(SimpleTextDialog, {
      data: {
        title: 'qc.create',
        text: 'qc.enter_name',
        label: 'qc.name_placeholder',
        value: '',
        type: SimpleTextInputType.TEXT,            
      },
    });

    dialogRef.afterClosed().subscribe(name => {
      console.log('The dialog was closed', name);
      if (name) {
        this.qualityCheckService.save({name})
          .subscribe(_ => this.load());
      }
    });     
  }

  clickedRow(row:any): void {
    this.router.navigate([`/qc_profiles/${row.id}`]);
  }

}
