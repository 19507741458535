<table class="metadata-form maybe-table-responsive table-responsive" *ngIf="!hidden">
    <!-- header when not grouped -->
    <tr class="heading" *ngIf="!grouped && !remote">
        <td colspan="2" style="font-size: 18px;">
            <ng-container *ngIf="!grouped">
                <span *ngIf="targetType == 'MEDIA'">
                    {{ "mediaDetail.mediaRelatedMetadata" | i18next }}
                </span>
                <span colspan="2" *ngIf="targetType == 'MEDIA_VERSION'">
                    {{ "mediaDetail.versionRelatedMetadata" | i18next }}
                </span>
                <span colspan="2" *ngIf="targetType == 'MEDIA_STREAM'">
                    {{ "mediaDetail.streamRelatedMetadata" | i18next }}
                </span>
            </ng-container>

            <div class="groupButtons">
                <button *ngIf="!remote && !noButton" mat-icon-button (click)="openUserMetadataDialog()">
                    <mat-icon>edit_note</mat-icon>
                </button>            
            </div>
        </td>
    </tr>

    <!-- no data -->
    <ng-container *ngIf="!hasData && !hasMultiEntitiesMetadatas">
        <ng-container *ngFor="let metadataGroup of metadataGroups">        
            <tr class="heading" *ngIf="grouped">
                <td colspan="2" style="font-size: 18px;">
                    <span *ngIf="targetType == 'MEDIA' || targetType == 'MEDIA_VERSION' || targetType == 'MEDIA_STREAM'">
                        {{ metadataGroup.name }}
                    </span>       
                    <div class="groupButtons">
                        <button *ngIf="!remote && !metadataGroup.system" mat-icon-button (click)="openUserMetadataDialog()">
                            <mat-icon>edit_note</mat-icon>
                        </button>
                    </div>
                </td>
            </tr>        
            <tr>
                <td colspan="2" style="text-align: left;">
                    <span>{{ "mediaDetail.noMetadataSetYet" | i18next }}</span>                    
                </td>
            </tr>
        </ng-container>
    </ng-container>

    <!-- standard display -->
    <ng-container *ngIf="(hasData || error || hasMultiEntitiesMetadatas)">
        <ng-container *ngFor="let metadataGroup of metadataGroups">

            <tr class="heading" *ngIf="grouped">
                <td colspan="2" style="font-size: 18px">
                    <span *ngIf="targetType == 'MEDIA' || targetType == 'MEDIA_VERSION' || targetType == 'MEDIA_STREAM'">
                        {{ metadataGroup.name }}
                    </span>         
                    <div class="groupButtons">
        
                        <button mat-icon-button (click)="toggleGroup(metadataGroup.id)" *ngIf="metadataGroup.displayCollapsed">
                            <mat-icon *ngIf="!displayedGroups.get(metadataGroup.id)">expand_more</mat-icon>
                            <mat-icon *ngIf="displayedGroups.get(metadataGroup.id)">expand_less</mat-icon>
                        </button>          
            
                        <button *ngIf="!remote && !metadataGroup.system" mat-icon-button (click)="openUserMetadataDialog()">
                            <mat-icon>edit_note</mat-icon>
                        </button>          
            
                        <button *ngIf="remote" [class.rotating]="metadataRefreshing" mat-icon-button (click)="refreshRemoteMetadata(targetType!, targetId!)">
                            <mat-icon>sync</mat-icon>
                        </button>
                    </div>
                </td>
            </tr>
    
            <ng-container 
                *ngIf="!grouped || !metadataGroup.displayCollapsed || displayedGroups.get(metadataGroup.id)"
                [ngTemplateOutlet]="content" 
                [ngTemplateOutletContext]="{metadataGroup: metadataGroup}">
            </ng-container>
            
        </ng-container>
    </ng-container>
</table>

<ng-template #content let-metadataGroup="metadataGroup">

    <ng-container *ngIf="remote && !data">
        <div style="color: #888; margin-left: 10px; margin-top: 5px;">No data.</div>
    </ng-container>
    
    <ng-container *ngIf="!error && !(remote && !data)">
        <ng-container *ngFor="let metadataDefinition of metadataGroup.metadatas">
            <ng-container [ngSwitch]="metadataDefinition.type" 
                        *ngIf="(data 
                                    && data[metadataGroup.id] 
                                    && data[metadataGroup.id][metadataDefinition.name]
                                    && canDisplayMetadata.get(metadataDefinition.id!))
                                || metadataDefinition.type == metadataTypes.COMPUTED
                                || metadataDefinition.type == metadataTypes.MULTI_ENTITIES
                                || metadataDefinition.displayIfEmpty">
                <tr>
                    <ng-container *ngSwitchCase="metadataTypes.REFERENCE">
                        <td class="label">
                            <mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label>
                        </td>
                        <td class="fill">
                            <ng-container *ngIf="data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name)">
                                <span>{{data[metadataGroup.id][metadataDefinition.name].representation}}</span>
                                <span class="buttonContainer">
                                
                                    <span>
                                        <a mat-icon-button [matMenuTriggerFor]="referenceMenu">
                                            <mat-icon>more_vert</mat-icon>
                                        </a>
                                        <mat-menu #referenceMenu="matMenu" style="margin-top: 5px">
                                            <button mat-menu-item (click)="navigateToSearch(metadataDefinition.id!, data[metadataGroup.id][metadataDefinition.name].id)">{{ "mediaDetail.searchOthers" | i18next }}</button>
                                            <button mat-menu-item (click)="openReferenceableMetadataDialog(metadataDefinition, metadataGroup, data)">{{ "mediaDetail.displayMetadatas" | i18next }}</button>
                                        </mat-menu>
                                    </span> 
                                </span>
                            </ng-container>
       
                            <ng-container *ngIf="!(data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name))">
                                —
                            </ng-container>                            
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.USER">
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <td class="fill">
                            <ng-container *ngIf="data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name)">
                                <span>{{data[metadataGroup.id][metadataDefinition.name].email}}</span>
                            </ng-container>
                            <ng-container *ngIf="!(data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name))">
                                —
                            </ng-container>
                        </td>
                    </ng-container>                        
                    <ng-container *ngSwitchCase="metadataTypes.DATE">
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <td class="fill">
                            <ng-container *ngIf="data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name)">
                                {{data[metadataGroup.id][metadataDefinition.name] | date:dateFormat}}
                            </ng-container>
                            <ng-container *ngIf="!(data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name))">
                                —
                            </ng-container>
                        </td>
                    </ng-container> 
                    <ng-container *ngSwitchCase="metadataTypes.BOOLEAN">
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <td class="fill">
                            <ng-container *ngIf="data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name)">
                                <mat-checkbox class="checkbox" [(ngModel)]="data[metadataGroup.id][metadataDefinition.name]" disabled></mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="!(data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name))">
                                —
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.COMPUTED">
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <td class="fill">
                            <app-formula-renderer [component]="metadataDefinition.renderingComponent" [data]="element | computedMetadata: metadataDefinition"></app-formula-renderer>
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.MULTI_ENTITIES">
                        <ng-container *ngIf="metadataDefinition.type === metadataTypes.MULTI_ENTITIES && (!mediaStreamType || (metadataDefinition.displayRestrictedToMediaStreamTypes?.indexOf(mediaStreamType!) ?? -1) >= 0)">
                            <td class="label" style="text-align: left; padding-top: 20px"><mat-label>{{metadataDefinition.label}}</mat-label></td>
                            <td class="fill">&nbsp;</td>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.LINK">
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <td class="fill"><a class="link" target="_blank" [href]="data[metadataGroup.id][metadataDefinition.name]">{{data[metadataGroup.id][metadataDefinition.name]}}</a></td>
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.MULTI_VALUED">
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <ng-container *ngIf="data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name)">
                            <td class="fill" *ngIf="metadataDefinition.translated">{{'multi_valued.' + data[metadataGroup.id][metadataDefinition.name] | i18next}}</td>
                            <td class="fill" *ngIf="!metadataDefinition.translated">{{data[metadataGroup.id][metadataDefinition.name]}}</td>
                        </ng-container>
                        <ng-container *ngIf="!(data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name))">
                            <td class="fill">—</td>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <td class="label"><mat-label [matTooltip]="metadataDefinition.label!" style="cursor: pointer">{{metadataDefinition.label}}</mat-label></td>
                        <td class="fill">
                            <ng-container *ngIf="data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name)">
                                {{data[metadataGroup.id][metadataDefinition.name]}}
                            </ng-container>
                            <ng-container *ngIf="!(data.hasOwnProperty(metadataGroup.id) && data[metadataGroup.id].hasOwnProperty(metadataDefinition.name))">—</ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr *ngIf="metadataDefinition.type === metadataTypes.MULTI_ENTITIES && (!mediaStreamType || (metadataDefinition.displayRestrictedToMediaStreamTypes?.indexOf(mediaStreamType!) ?? -1) >= 0)">
                    <td colspan="2" style="padding-right: 0">
                        <app-multi-entity-metadata style="margin-top: 20px"
                            *ngIf="!reload"
                            [metadataDefinition]="metadataDefinition" 
                            [targetId]="targetId"
                            [targetType]="targetType"
                            [editable]="!remote"
                            (onRowAdded)="addMultiEntitiesMetadata(data, metadataGroup.id, metadataDefinition.name, $event)" 
                            (onRowDeleted)="removeMultiEntitiesMetadata(data, metadataGroup.id, metadataDefinition.name, $event)" 
                            [datas]="getMultiEntitiesMetadata(data, metadataGroup.id, metadataDefinition.name)">
                        </app-multi-entity-metadata>
                    </td>
                </tr>
            </ng-container>
        </ng-container>    
    </ng-container>
</ng-template>