import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import { Observable, debounceTime, distinctUntilChanged, startWith, switchMap } from "rxjs";

export interface SimpleOrderDialogData {
    list: any[];
    title: string;
    text: string;
    representationFor: (item:any) => string;
}

@Component({
    selector: 'dialog-simple-order',
    templateUrl: './simple.order.dialog.html',
    styleUrls: ['simple.order.dialog.sass']
})
export class SimpleOrderDialog {
    
    i18next = i18next;

    constructor(
        public dialogRef: MatDialogRef<SimpleOrderDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleOrderDialogData,
    ) {
      }

    ngOnInit() {
        this.data.list = cloneDeep(this.data.list)
        this.data.list.forEach((item) => {
            item.representation = this.data.representationFor(item)
        })
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.list, event.previousIndex, event.currentIndex);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
