<h1 mat-dialog-title>{{data.title | i18next}}</h1>
<mat-dialog-content>
  <mat-form-field appearance="fill" *ngIf="data.copiable">
    <input matInput [value]="data.text" disabled />
    <button matSuffix mat-icon-button aria-label="Copt"
        style="transform: scale(0.7)"
        [matTooltip]="i18next.t('copy')"
        (click)="copy()">
      <mat-icon>content_copy</mat-icon>
    </button>    
  </mat-form-field>

  <markdown *ngIf="data.type !== 'MONOSPACED' && !data.copiable">{{data.text | i18next}}</markdown>

  <app-timecode-input *ngIf="data.type === 'TIMECODE'" #input [label]="i18next.t(data.label)" [(ngModel)]="data.value"></app-timecode-input>    
  
  <pre *ngIf="data.type  === 'MONOSPACED'" style="max-width: 80vw">{{data.text | i18next}}</pre>

  <mat-form-field appearance="fill" *ngIf="data.label && data.type == 'TEXT'">
    <mat-label>{{data.label | i18next}}</mat-label>
    <input #input cdkFocusInitial matInput [(ngModel)]="data.value">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="data.label && (data.type === 'MULTILINE' || data.type === 'MONOSPACED_EDIT')">
    <mat-label>{{data.label | i18next}}</mat-label>
    <textarea #input cdkFocusInitial [style.height.vh]="data.textAreaHeightVH ?? 48"
      matInput 
      [class.monospaced]="data.type  === 'MONOSPACED_EDIT'" 
      [(ngModel)]="data.value"></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="data.label && data.type === 'DATE'">
    <mat-label>{{data.label | i18next}}</mat-label>
    <input #input cdkFocusInitial matInput [matDatepicker]="picker" [(ngModel)]="data.value">
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="data.label && data.type === 'MULTI_VALUED'">
    <mat-label>{{data.label | i18next}}</mat-label>
    <mat-select cdkFocusInitial [(ngModel)]="data.value">
        <mat-option value="[deleted]">
            —
        </mat-option>
        <mat-option *ngFor="let value of data.allowedValues" [value]="value">
          {{value}}
        </mat-option>        
    </mat-select>
  </mat-form-field>  
</mat-dialog-content>

<div mat-dialog-actions *ngIf="!data.confirm">
  <button *ngIf="data.label" mat-button (click)="onNoClick()">{{"simple_text_dialog.cancel_text_dialog" | i18next}}</button>
  <button mat-button [mat-dialog-close]="data.value">{{"simple_text_dialog.ok_text_dialog" | i18next}}</button>
</div>  
<div mat-dialog-actions *ngIf="data.confirm">
  <button mat-button [mat-dialog-close]="false">{{"simple_text_dialog.no_text_dialog" | i18next}}</button>
  <button mat-button [mat-dialog-close]="true">{{"simple_text_dialog.yes_text_dialog" | i18next}}</button>
</div>  