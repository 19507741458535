<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!loading">
    <ul>
        <li *ngFor="let entry of data.content">
            <div>
                <markdown>
                    {{entry.label}} 
                    <span class="pointer" [matTooltip]="(entry.createdAt | date: dateFormat) ?? ''">{{ entry.createdAt | formatDate }}</span>,
                    {{'activity.by' | i18next}} 
                    <span class="code">{{entry.modifiedBy}}</span>&#160;
                </markdown>
            </div>
            <ul>
                <li *ngFor="let operation of entry.structuredOperations">
                    <markdown>{{operation.description}}</markdown>
                </li>
            </ul>
        </li>
    </ul>
</div>