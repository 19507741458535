import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Page } from '../models/page';
import { Location } from '../models/media';
import {ErrorService} from "./error.service";

@Injectable({ providedIn: 'root' })
export class LocationService {
  
  private locationsUrl = 'api/locations'

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }

  deleteLocation(id: number) {
    return this.http.delete<Location>(`/api/location/${id}`)
      .pipe(
        tap(_ => this.log('fetched locations')),
        catchError(this.errorService.handleError<Location>('locations', {}))
      );
  }
      
  getLocations(includeHiddens:boolean=false): Observable<Page<Location>> {
    return this.http.get<Page<Location>>(`${this.locationsUrl}?includeHiddens=${includeHiddens}`)
      .pipe(
        tap(_ => this.log('fetched locations')),
        catchError(this.errorService.handleError<Page<Location>>('locations', {}))
      );
  }

  saveLocation(location:Location): Observable<Location> {
    return this.http.post<Location>(`/api/location`, location)
      .pipe(
        tap(_ => this.log('fetched locations')),
        catchError(this.errorService.handleError<Location>('locations', {}))
      );
  }

  private log(message: string) {
    console.log(`MetadataService: ${message}`);
  }
}
