<div *ngIf="!loading; else firstLoad">


    <mat-menu #streamMenu="matMenu">   
        <button mat-menu-item (click)="addExpectedStream('VIDEO')">{{"media_upload.add_video" | i18next}}</button>
        <button mat-menu-item (click)="addExpectedStream('AUDIO')">{{"media_upload.add_audio" | i18next}}</button>
        <button mat-menu-item (click)="addExpectedStream('SUBTITLE')">{{"media_upload.add_subtitle" | i18next}}</button>
        <button mat-menu-item (click)="addExpectedStream('SPARE_FILE')">{{"media_upload.add_spare_file" | i18next}}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="openRequestPartnerUploadDialog()">{{"media_upload.request_upload" | i18next}}</button>
        <button mat-menu-item (click)="openRequestPartnerUploadDialog(true)">{{"media_upload.request_freestyle_upload" | i18next}}</button>
        <button mat-menu-item (click)="openRequestPartnerUploadDialog(true, true)">{{"media_upload.simple_access" | i18next}}</button>
    </mat-menu>    

    <button mat-icon-button [matMenuTriggerFor]="streamMenu" style="position: absolute; margin-left: 10px; top: 10px; right: 10px; z-index: 90000;">
        <mat-icon>settings</mat-icon>
    </button>

    <div style="margin: 0 0 40px 0; position: relative;" *ngIf="parentContainer?.media?.partnerUploadRequests?.length">
        <h2>
            {{"media_upload.upload_request" | i18next}}
        </h2>
            
        <ul>
            <li *ngFor="let uploadRequest of parentContainer?.media?.partnerUploadRequests">
                {{"media_upload.from" | i18next}} <span class="code">{{uploadRequest.partner.value}} (#{{uploadRequest.id}})</span> {{"media_upload.with_status" | i18next}} <span
                    class="code">{{uploadRequest.status | i18next}}</span>&nbsp;
                <button *ngIf="uploadRequest.status === 'PENDING' || uploadRequest.status === 'PENDING_3RD_PARTY_UPLOAD' || uploadRequest.status === 'ACCESS_ONLY'" mat-raised-button
                    (click)="cancelUploadRequest(uploadRequest.id)">{{"media_upload.cancel" | i18next}}</button>
            </li>
        </ul>
    </div>

    <div>
    
        <h2>
            {{"media_upload.expected" | i18next}}
        </h2>

        <div style="margin-bottom: 50px" *ngIf="!expectedStreamsDatasource.length">
            {{"media_upload.use_buttons" | i18next}}
        </div>

        <table mat-table multiTemplateDataRows [dataSource]="expectedStreamsDatasource" class="mat-elevation-z8 table-responsive maybe-table-responsive no-scrollbar" style="margin-bottom: 50px; min-width: 100%"
            *ngIf="expectedStreamsDatasource.length">
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayOnExpectedStreams"></tr>
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element"> <span class="code">{{element.id}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.type" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> <span class="code">{{element.representation}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="partner">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.partner" | i18next}} </th>
                <td mat-cell *matCellDef="let element">
                    
                    {{getPartnerRequest(element.partnerUploadRequest)?.partner?.value ?
                    getPartnerRequest(element.partnerUploadRequest)?.partner?.value + '&nbsp;(#' +
                    element.partnerUploadRequest + ')' :
                    '-'}}
                </td>
            </ng-container>

            <ng-container *ngFor="let column of customColumnsFromMetadatas()" [matColumnDef]="column">
                <th mat-header-cell
                    [ngStyle]="getMetadataDefinition(column)?.columnWidth ? { width: getMetadataDefinition(column).columnWidth + 'px' } : {}"
                    *matHeaderCellDef>
                    {{getMetadataDefinition(column)?.label}}
                </th>
                <td mat-cell *matCellDef="let element" [ngSwitch]="getMetadataDefinition(column)?.type">

                    <ng-container *ngSwitchCase="metadataTypes.REFERENCE">
                        {{element.dynamicMetadatas &&
                        element.dynamicMetadatas.hasOwnProperty(getMetadataDefinition(column)?.parentGroup!.id)
                        &&
                        element.dynamicMetadatas[getMetadataDefinition(column)?.parentGroup!.id].hasOwnProperty(getMetadataDefinition(column).name)
                        ?
                        element.dynamicMetadatas[getMetadataDefinition(column)?.parentGroup!.id][getMetadataDefinition(column).name].representation
                        : ''}}
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.DATE">
                        {{element.dynamicMetadatas &&
                        element.dynamicMetadatas.hasOwnProperty(getMetadataDefinition(column)?.parentGroup!.id)
                        &&
                        element.dynamicMetadatas[getMetadataDefinition(column)?.parentGroup!.id].hasOwnProperty(getMetadataDefinition(column).name)
                        ?
                        (element.dynamicMetadatas[getMetadataDefinition(column)?.parentGroup!.id][getMetadataDefinition(column).name]
                        | date:'yyyy/MM/dd') : ''}}
                    </ng-container>
                    <ng-container *ngSwitchCase="metadataTypes.COMPUTED">
                        <app-formula-renderer [component]="(column | getMetadataDefinition)?.renderingComponent"
                            [data]="element | computedMetadata: (column | getMetadataDefinition)"></app-formula-renderer>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{element.dynamicMetadatas &&
                        element.dynamicMetadatas.hasOwnProperty(getMetadataDefinition(column)?.parentGroup!.id)
                        &&
                        element.dynamicMetadatas[getMetadataDefinition(column)?.parentGroup!.id].hasOwnProperty(getMetadataDefinition(column).name)
                        ?
                        element.dynamicMetadatas[getMetadataDefinition(column)?.parentGroup!.id][getMetadataDefinition(column).name]
                        : ''}}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.created_at" | i18next}} </th>
                <td mat-cell *matCellDef="let element">
                    <span class="pointer" [matTooltip]="'' + (element.creationDate | date:'yyyy/MM/dd hh:mm:ss')">
                        {{element.creationDate | formatDate}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.created_by" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.status" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> <span class="code">{{element.status}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="tools">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="editExpectedStream(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteExpectedStream(element)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="expand row"
                        *ngIf="element.previousVersion"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_down</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayOnExpectedStreams.length">
                    <div class="detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div style="height: 16px"></div>
                        <ul>
                            <li *ngFor="let stream of element.allPreviousVersions">
                                {{"media_upload.ingested_from" | i18next}} <span class="code">{{stream.mediaElement.filename}}</span>
                                <span class="pointer" [matTooltip]="'' + (stream.creationDate | date:'yyyy/MM/dd hh:mm:ss')">
                                    {{stream.creationDate | formatDate}}
                                </span>
                            </li>
                        </ul>
                        <div style="height: 16px"></div>
                    </div>
                </td>
            </ng-container>


            <tr mat-row *matRowDef="let row; columns: columnsToDisplayOnExpectedStreams;"                  
                [class.expanded]="expandedElement === row"
                (click)="expandedElement = (expandedElement === row || !row.concreteMediaStreams.length ? null : row)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" 
                [class.expanded]="expandedElement === row"
                class="detail-row"></tr>

        </table>
    </div>

    <div *ngIf="proposedVersions.length">
        <h2>
            {{"media_upload.proposed_versions" | i18next}}
        </h2>
        <table mat-table [dataSource]="proposedVersions" class="mat-elevation-z8" style="margin-bottom: 50px">
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayOnProposedVersions"></tr>
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let row" 
                    matTooltip="This version already exists."
                    [matTooltipDisabled]="!isProposedVersionExcluded(row)">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? proposedVersionsSelection.toggle(row) : null"
                        [checked]="proposedVersionsSelection.isSelected(row)"
                        [disabled]="isProposedVersionExcluded(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.name" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> <span class="code force-text-color">{{element.name}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="video">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.video" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> <span class="code force-text-color">{{element.video.representation}}</span> </td>
            </ng-container>            
            
            <ng-container matColumnDef="audio">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.audio" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> <span class="code force-text-color">{{element.audio?.representation}}</span> </td>
            </ng-container>            
            
            <ng-container matColumnDef="actions">
                <td mat-footer-cell *matFooterCellDef style="padding-left: 0" [colSpan]="columnsToDisplayOnProposedVersions.length"> 
                    <button mat-fab extended 
                        color="primary" 
                        [disabled]="!this.proposedVersionsSelection.selected.length"
                        (click)="submitProposedStreams()" class="small" style="margin: 15px 0 15px 0">
                        <mat-icon>add</mat-icon>&nbsp;{{"media_upload.create_these" | i18next}}
                    </button>
                </td>
            </ng-container>            
            
            <ng-container matColumnDef="subtitles">
                <th mat-header-cell *matHeaderCellDef> {{"media_upload.subtitles" | i18next}} </th>
                <td mat-cell *matCellDef="let element"> 
                    <ng-container *ngIf="element.expectedSubtitles?.length">
                        <div *ngFor="let subtitle of element.expectedSubtitles">
                            <span class="code force-text-color">{{subtitle.representation}}</span>
                        </div>
                    </ng-container>
                    <span *ngIf="!element.expectedSubtitles?.length">-</span>
                </td>
            </ng-container>            
                        
            <tr mat-row *matRowDef="let row; columns: columnsToDisplayOnProposedVersions;"></tr>            
            <tr mat-footer-row *matFooterRowDef="['actions']"></tr>
        </table>
    </div>    
</div>
<ng-template #firstLoad>
    <mat-spinner></mat-spinner>
</ng-template>