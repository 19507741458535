import { Component, ElementRef, HostListener, Inject, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import i18next from "i18next";
import { Observable, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from "rxjs";
import { dynamicMetadataValues } from "src/app/models/constants";
import { MetadataAttachmentType, MetadataDefinition, MetadataDefinitionAndValue, MetadataType } from "src/app/models/metadata";
import { MetadataService } from "src/app/services/metadata.service";
import { UserService } from "src/app/services/user.service";

export interface SimpleMetadataSelectDialogData {
    loader: (val:string) => Observable<Map<String, String>>;
    title: string;
    label: string;
    text: string;
    attachedTo: MetadataAttachmentType[];
    definitions: MetadataDefinitionAndValue[];
}

@Component({
    selector: 'dialog-simple-metadata-select',
    templateUrl: './simple.metadata.select.dialog.html',
    styleUrls: ['simple.metadata.select.dialog.sass']
})
export class SimpleMetadataSelectDialog {
    
    i18next = i18next;
    loading: boolean = true;
    @ViewChild('input') input: ElementRef | undefined;

    constructor(
        public dialogRef: MatDialogRef<SimpleMetadataSelectDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SimpleMetadataSelectDialogData,
    ) {}

    ngOnInit() {
        console.log('this.data.definitions', this.data.definitions)
    }

    addCondition() {
        this.data.definitions.push({
        });
        console.log('this.data.definitions', this.data.definitions)
    }

    removeCondition(condition:MetadataDefinitionAndValue) {
        this.data.definitions?.splice(this.data.definitions?.indexOf(condition), 1)
        if (this.data.definitions.length == 0) this.addCondition()
    }

    @HostListener('document:keydown.enter')
    enterShortcut() {
        this.dialogRef.close('TODO')
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
