<div *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="action">
    <mat-card>
        <mat-card-content>
            <h1>{{action?.name}}</h1>

            <div>
                <mat-form-field appearance="fill">
                    <mat-label>{{'actionDetail.name' | i18next}}</mat-label>
                    <input matInput [(ngModel)]="action.name">
                </mat-form-field>
                &nbsp;
                <mat-form-field appearance="fill">
                    <mat-label>{{'actionDetail.label' | i18next}}</mat-label>
                    <input matInput [(ngModel)]="action.label">
                </mat-form-field>            
            </div>
            <div>
                <mat-form-field appearance="fill" style="width: 630px">
                    <mat-label>{{'actionDetail.description' | i18next}}</mat-label>
                    <textarea #input 
                      matInput 
                      [(ngModel)]="action.description" ></textarea>
                  </mat-form-field>                
            </div>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'actionDetail.attachableTo' | i18next }}</mat-label>
                <mat-select [(ngModel)]="action.attachableTo" (selectionChange)="attachmentChanged($event)">
                    <mat-option *ngFor="let type of attachmentMetadataTypesAllowed" [value]="type">
                        {{type}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;
            <mat-form-field appearance="fill">
                <mat-label>{{"actionDetail.allowedForRoles" | i18next}}</mat-label>
                <mat-select [(ngModel)]="action.allowedForRoles" [compareWith]="compareObjects"  multiple>
                    <mat-option *ngFor="let role of roles" [value]="role" >
                        {{role.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>            
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>

            <h2>
                <span>{{'actionDetail.autoTriggerOn' | i18next}}</span>
                <mat-icon style="cursor: pointer; scale: 0.8; color: var(--text-secondary-color); transform: translate(10px, 7px)"
                   [matTooltip]="i18next.t('actionDetail.autoTriggerOnTooltip')">infos</mat-icon>
            </h2>   

            <mat-form-field appearance="fill">
                <mat-label>{{ 'actionDetail.autoTriggerOn' | i18next }}</mat-label>
                <mat-select [(ngModel)]="action.autoTriggerOn">
                    <mat-option value="">
                        —
                    </mat-option> 
                    <mat-option *ngFor="let trigger of Object.keys(autoTriggerOn)" [value]="trigger">
                        {{trigger}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="secondary" style="margin-bottom: 20px" *ngIf="action.autoTriggerOn">{{ 'actionDetail.autoTriggerOnTooltip.' + action.autoTriggerOn | i18next }}</div>
                        
            <h2>
                <span>{{'actionDetail.conditions' | i18next}}</span>
        
                <button style="transform: scale(0.5) translateY(8px)" 
                        aria-label="Add" mat-fab
                        (click)="addCondition()">
                    <mat-icon>add</mat-icon>
                </button>
            </h2>        
            <div *ngIf="!action.conditions?.length" class="secondary" style="margin-bottom: 20px">
                {{'actionDetail.noCondition' | i18next}}
            </div>

            <div *ngFor="let condition of action.conditions" class="condition">
                <app-metadata-select 
                    [allowMultipleOnMultiValued]="true"
                    [(selection)]="condition!.metadata" 
                    [(value)]="condition.value"
                    [onlyAttachedTo]="conditionsMetadataTypesAllowed"></app-metadata-select>
                &nbsp;&nbsp;
                <button style="transform: scale(0.8) translateY(2px)" 
                        aria-label="Details" mat-icon-button
                        (click)="conditionDetails(condition)"
                        *ngIf="action.attachableTo === 'MEDIA_VERSION' && condition.metadata?.parentGroup?.attachmentType === 'MEDIA_STREAM'">
                    <mat-icon>edit_note</mat-icon>
                </button> 
                <button style="transform: scale(0.8) translateY(2px)" 
                        aria-label="Remove" mat-icon-button
                        (click)="removeCondition(condition)">
                    <mat-icon>delete_forever</mat-icon>
                </button>
            </div>               
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-content>

            <h2>
                <span>{{'actionDetail.consequences' | i18next}}</span>
        
                <button style="transform: scale(0.5) translateY(8px)" 
                        aria-label="Add" mat-fab
                        (click)="addConsequence()">
                    <mat-icon>add</mat-icon>
                </button>
            </h2>     
            <div *ngIf="!action.consequences?.length" class="secondary" style="margin-bottom: 20px">
                {{'actionDetail.noCondition' | i18next}}
            </div>
        
            <div *ngFor="let consequence of action.consequences" class="condition">
                <app-metadata-select 
                    [(selection)]="consequence!.metadata" 
                    [(value)]="consequence.value"
                    [forceCurrentDate]="true"
                    [onlyAttachedTo]="consequenceMetadataTypesAllowed"></app-metadata-select>
                <button style="transform: scale(0.8) translateY(2px)" 
                        aria-label="Remove" mat-icon-button
                        (click)="removeConsequence(consequence)">
                    <mat-icon>delete_forever</mat-icon>
                </button>              
            </div>
        
        </mat-card-content>
    </mat-card>

    <mat-card>
        <mat-card-content>
            <h2>
                <span>{{'actionDetail.postExecution' | i18next}}</span>
            </h2>

            <div style="margin-bottom: 15px;">
                <mat-checkbox 
                    class="checkbox" [(ngModel)]="hasPostExecutionTask"
                    (change)="postExecutionTaskChanged($event)">{{'actionDetail.postExecutionTask' | i18next}}</mat-checkbox>
            </div>

            <div style="margin-bottom: 15px;">
                <mat-checkbox 
                    class="checkbox" [(ngModel)]="hasPostExecutionUserTask"
                    (change)="postExecutionUserTaskChanged($event)">{{'actionDetail.postExecutionUserTask' | i18next}}</mat-checkbox>
            </div>

            <div *ngIf="action.userTaskToCreate">
                <div>
                    <mat-form-field appearance="fill" style="min-width: 50%">
                        <mat-label>{{ 'actionDetail.userTaskType' | i18next }}</mat-label>
                        <mat-select [(ngModel)]="action.userTaskToCreate.taskType" [compareWith]="compareObjects">
                            <mat-option *ngFor="let type of userTaskTypes" [value]="type">
                                {{type.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    &nbsp;

                    <button aria-label="Details" mat-icon-button
                            (click)="taskToCreateDetails()">
                        <mat-icon>edit_note</mat-icon>
                    </button>                
                </div> 
                <div>
                    <mat-form-field appearance="fill" style="min-width: 50%">
                        <mat-label>{{ 'actionDetail.userTaskAssignment' | i18next }}</mat-label>
                        <mat-select [(ngModel)]="action.userTaskToCreate.assignedTo" [compareWith]="compareObjects">
                            <mat-option value="">
                                —
                            </mat-option>                             
                            <mat-option *ngFor="let definition of userMetadataDefinitions" [value]="definition">
                                {{definition.parentGroup?.attachmentType}} &gt; {{definition.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>                    
                </div>
            </div>

            <div style="margin-bottom: 15px;">
                <mat-checkbox 
                    class="checkbox" [(ngModel)]="hasPostExecutionUserTaskToComplete"
                    (change)="postExecutionUserTaskToCompleteChanged($event)">{{'actionDetail.postExecutionUserTaskToComplete' | i18next}}</mat-checkbox>
            </div>

            <div *ngIf="hasPostExecutionUserTaskToComplete">
                <mat-form-field appearance="fill" style="min-width: 50%">
                    <mat-label>{{ 'actionDetail.userTaskType' | i18next }}</mat-label>
                    <mat-select [(ngModel)]="action.userTaskToComplete" [compareWith]="compareObjects">
                        <mat-option *ngFor="let type of userTaskTypes" [value]="type">
                            {{type.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
            
            <div style="margin-bottom: 5px;" *ngIf="action.attachableTo === 'MEDIA_STREAM'">
                <mat-checkbox 
                    class="checkbox" [(ngModel)]="action.triggerPartnerUploadRequest">{{'actionDetail.postExecutionUploadRequest' | i18next}}</mat-checkbox>
            </div>            

            <div *ngIf="action.taskToLaunchTemplate">
                <mat-form-field appearance="fill" style="min-width: 50%">
                    <mat-label>{{ 'actionDetail.taskType' | i18next }}</mat-label>
                    <mat-select [(ngModel)]="action.taskToLaunchTemplate.type" (selectionChange)="taskToLaunchChanged($event)">
                        <mat-option *ngFor="let type of Object.keys(taskType)" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;
                <button *ngIf="taskSupported" aria-label="Edit" mat-icon-button
                        style="color: var(--text-secondary-color)"
                        (click)="editPostExecutionTask()">
                    <mat-icon>edit_note</mat-icon>
                </button>    
                <button *ngIf="!taskSupported" aria-label="Warning" mat-icon-button
                        style="color: var(--text-secondary-color)" 
                        matTooltipPosition="after"
                        [matTooltip]="i18next.t('actionDetail.taskTypeNotSupported')">
                    <mat-icon>warning</mat-icon>
                </button>    
                
            </div>


            <div *ngIf="action.taskToLaunchTemplate">
                <mat-form-field appearance="fill" style="min-width: 50%">
                    <mat-label>{{ 'actionDetail.actionToTriggerIfSuccessful' | i18next }}</mat-label>
                    <mat-select [(ngModel)]="action.taskToLaunchTemplate.actionToTriggerIfSuccessful" [compareWith]="compareObjects">
                        <mat-option *ngFor="let actionToTrigger of actions.content ?? []" [value]="actionToTrigger">
                            {{actionToTrigger.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div style="margin-bottom: 20px">
                <span class="secondary">
                    {{ 'actionDetail.editGroovyScript' | i18next }}
                </span>
                &nbsp;
                <button aria-label="Edit" mat-icon-button
                        style="transform: translateY(8px); color: var(--text-secondary-color)"
                        (click)="editGroovyScript()">
                    <mat-icon>edit_note</mat-icon>
                </button>        
            </div>

        </mat-card-content>
    </mat-card>

    <button class="small-button" 
        [ngClass]="saving ? 'working' : ''" extended mat-fab
        style="margin-top: 20px; margin-bottom: 40px"
        (click)="save()">
        <mat-icon style="width: 24px;">save</mat-icon>
        {{"actionDetail.save" | i18next}}
        <mat-spinner></mat-spinner>
    </button>

</ng-container>