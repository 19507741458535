import { MarkdownModule } from 'ngx-markdown';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA,APP_INITIALIZER,LOCALE_ID, SecurityContext } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { BrowserModule,Title } from '@angular/platform-browser';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { I18NEXT_SERVICE, I18NextModule, I18NextLoadResult, ITranslationService, defaultInterpolationFormat, I18NextTitle  } from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { AppRoutingModule } from './app-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; 
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchComponent } from './pages/search/search.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CreateVersionDialog, CreateVersionFromDefinitionDialog, ElementsPickerDialog, MediaDetailComponent } from './pages/media-detail/media-detail.component';
import { LocationDialog, LocationsComponent } from './pages/locations/locations.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { CreateMetadataGroupDialog, MetadataSchemaComponent } from './pages/metadata-schema/metadata-schema.component';
import { CreateMetadataDefinitionDialog, EditMetadataGroupDialog, ListMetadataReferencesDialog, MetadataSchemaDetailComponent } from './pages/metadata-schema-detail/metadata-schema-detail.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { UserService } from './services/user.service';
import { UsersComponent } from './pages/users/users.component';
import { PartnerUploadComponent, UploadDialog } from './pages/partner-upload/partner-upload.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RulesComponent } from './pages/rules/rules.component';
import { SimpleTextDialog } from './components/dialogs/simple.text.dialog';
import { SimpleListDialog } from './components/dialogs/simple.list.dialog';
import { MySettingsComponent } from './pages/my-settings/my.settings.component';
import { ConfirmationDialog } from './components/dialogs/confirm.dialog';
import { SimpleImageDialog } from './components/dialogs/simple.image.dialog';
import { FormulaRendererComponent } from './components/formula.rendering/formula.renderer.component';
import { ProgressRendererComponent } from './components/formula.rendering/progress.renderer/progress.renderer.component';
import { FormulaRendererDirective } from './components/formula.rendering/formula.renderer.directive';
import { TimecodeInputComponent } from './components/timecode.input/timecode.input.component';
import { TimecodedCommentBlockComponent } from './components/timecoded.comment.block/timecoded.comment.block.component';
import { SimpleUploadDialog } from './components/dialogs/simple.upload.dialog';
import { MultiEntityMetadataComponent } from './components/multi.entity.metadata/multi.entity.metadata.component';
import { UserMetadataDialog } from './components/dialogs/user.metadatas.dialog';
import { UserDialog } from "./components/dialogs/user.dialog";
import { MetadataDisplayComponent } from './components/metadata.display/metadata.display.component';
import { MediaDetailContainerComponent } from './pages/media-detail-container/media-detail-container.component';
import { MediaStreamsComponent } from './pages/media-streams/media-streams.component';
import { MediaUploadsComponent, RequestPartnerUploadDialog } from './pages/media-uploads/media-uploads.component';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { HooksComponent } from './pages/hooks/hooks.component';
import { SubtitleEditorComponent } from './components/subtitle.editor/subtitle.editor.component';
import { VersionsDefinitionComponent } from './pages/versions-definition/versions-definition.component';
import { MatDividerModule } from '@angular/material/divider';
import { initUtils } from './utils/utils';
import { ImportDialog } from './components/dialogs/import.dialog';
import { WorkflowDialog } from './components/dialogs/workflow.dialog';
import { DocumentationComponent } from './pages/documentation/documentation.component';
import { DefaultRendererComponent } from './components/formula.rendering/default.renderer/default.renderer.component';
import { ReportingComponent, ReportingParameterDialog } from './pages/reporting/reporting.component';
import { IngestDialog } from './components/dialogs/ingest.dialog';
import { HistoryViewerComponent } from './components/history.viewer/history.viewer.component';
import { FormatDatePipe } from './utils/formatDate.pipe';
import { GetMetadataDefinitionPipe } from './utils/getMetadataDefinition.pipe';
import { ActionsComponent } from './pages/actions/actions.component';
import { ActionDetailComponent, ConditionDetailsDialog } from './pages/action-detail/action-detail.component';
import { MetadataSelectComponent } from './components/metadata.select/metadata.select.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { RolePrivilegesDialog, RolesComponent } from './pages/roles/roles.component';
import { UserGroupDialog, UsergroupsComponent } from './pages/usergroups/usergroups.component';
import { OnboardComponent } from './pages/onboard/onboard.component';
import { FormatBytesPipe } from './utils/formatBytes.pipe';
import { MoveMediaDialog } from './components/dialogs/move.media.dialog';
import { OutgestDialog } from './components/dialogs/outgest.dialog';
import { ProfilePictureComponent } from './components/profile.picture/profile.picture.component';
import { EditTranslationsDialog, TranslationsComponent } from './pages/translations/translations.component';
import { OutgestProfileDialog, TranscodingProfilesComponent } from './pages/transcoding-profiles/transcoding-profiles.component';
import { ComputedMetadataPipe } from './utils/computedMetadata.pipe';
import { QcProfilesComponent } from './pages/qc-profiles/qc-profiles.component';
import { QcProfileDetailComponent } from './pages/qc-profile-detail/qc-profile-detail.component';
import { SimpleOrderDialog } from './components/dialogs/simple.order.dialog';
import { UsertasksComponent } from './components/usertasks/usertasks.component';
import { UserTaskTypesComponent } from './pages/usertasktypes/usertasktypes.component';
import { ErrorDialog } from './components/dialogs/error.dialog';
import { SimpleMetadataSelectDialog } from './components/dialogs/simple.metadata.select.dialog';

registerLocaleData(localeFr);
registerLocaleData(localeDe);

const i18nextOptions = {
    supportedLngs: ['en', 'fr', 'de'],
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: false,
    ns : [
      'translation'
    ],
    backend: {
      loadPath: '/assets/locales/{{lng}}.{{ns}}.json',
    },

    // lang detection plugin options
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie'],
      // keys or params to lookup language from
      lookupCookie: 'lang',
      lookupQuerystring: 'lng',
      // cache user language on
      caches: ['localStorage', 'cookie'],
      // optional expire and domain for set cookie
      cookieMinutes: 10080, // 7 days
    }
};

export function appInit(i18next: ITranslationService) {
  return () =>
      i18next
        .use(Backend)
        .use<any>(LanguageDetector)
        .init(i18nextOptions)
        .finally(() => {
          initUtils()
          
        });
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
{
  provide: APP_INITIALIZER,
  useFactory: appInit,
  deps: [I18NEXT_SERVICE],
  multi: true
},
{
  provide: Title,
  useClass: I18NextTitle
},
{
  provide: LOCALE_ID,
  deps: [I18NEXT_SERVICE],
  useFactory: localeIdFactory
}];
@NgModule({
  declarations: [
    FormatDatePipe,
    ComputedMetadataPipe,
    FormatBytesPipe, 
    GetMetadataDefinitionPipe,
    AppComponent,
    ElementsPickerDialog,
    MenuComponent,
    SearchComponent,
    HomeComponent,
    ConditionDetailsDialog,
    MediaDetailComponent,
    RequestPartnerUploadDialog,
    RolePrivilegesDialog,
    EditTranslationsDialog,
    SimpleImageDialog,
    SimpleUploadDialog,
    WorkflowDialog,
    OutgestDialog,
    ImportDialog,
    SimpleTextDialog,
    SimpleOrderDialog,
    IngestDialog,
    SimpleListDialog,
    OutgestProfileDialog,
    ConfirmationDialog,
    UserDialog,
    UserMetadataDialog,
    ListMetadataReferencesDialog,
    LoginComponent,
    LocationsComponent,
    MoveMediaDialog, 
    CreateVersionDialog, 
    UploadDialog,
    UserGroupDialog,
    TasksComponent,
    MetadataSchemaComponent,
    ErrorDialog,
    SimpleMetadataSelectDialog,
    EditMetadataGroupDialog,
    MetadataSchemaDetailComponent,
    ReportingParameterDialog,
    LocationDialog,
    LoginComponent,
    UsersComponent,
    CreateMetadataDefinitionDialog,
    PartnerUploadComponent,
    DashboardComponent,
    RulesComponent,
    MySettingsComponent,
    FormulaRendererComponent,
    ProgressRendererComponent,
    FormulaRendererDirective,
    TimecodeInputComponent,
    CreateMetadataGroupDialog,
    CreateVersionFromDefinitionDialog,
    TimecodedCommentBlockComponent,
    MultiEntityMetadataComponent,
    MetadataDisplayComponent,
    MediaDetailContainerComponent,
    MediaStreamsComponent,
    MediaUploadsComponent,
    HooksComponent,
    SubtitleEditorComponent,
    VersionsDefinitionComponent,
    DocumentationComponent,
    DefaultRendererComponent,
    ReportingComponent,
    HistoryViewerComponent,
    ActionsComponent,
    ActionDetailComponent,
    MetadataSelectComponent,
    NotificationsComponent,
    ConfigurationComponent,
    RolesComponent,
    UsergroupsComponent,
    OnboardComponent,
    ProfilePictureComponent,
    TranslationsComponent,
    TranscodingProfilesComponent,
    QcProfilesComponent,
    QcProfileDetailComponent,
    UsertasksComponent,
    UserTaskTypesComponent,
  ],
  imports: [
    MarkdownModule.forRoot({ loader: HttpClient, sanitize: SecurityContext.NONE }),
    BrowserModule,
    MatExpansionModule,
    MatBadgeModule,
    AppRoutingModule,
    MatListModule,
    MatProgressBarModule,
    MatIconModule,
    MatChipsModule,
    MatCardModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatTooltipModule,
    HttpClientModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatNativeDateModule,    
    MatMenuModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatListModule,
    ReactiveFormsModule,
    I18NextModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    UserService,
    I18N_PROVIDERS
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
