import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import i18next from "i18next";
import { Media, MediaStream, Location, MediaElement } from "src/app/models/media";
import { OutgestProfile } from "src/app/models/outgest.profile";
import { User } from "src/app/models/user";
import { LocationService } from "src/app/services/location.service";
import { MediaService } from "src/app/services/media.service";
import { OutgestService } from "src/app/services/outgest.service";

export interface OutgestDialogData {
    prerolls: Media[];
    locations: Location[];
    user: User;
    destinationLocation: Location;
    selectedPreroll: Media;
    selectedOutgestProfile: OutgestProfile;
    outgestProfiles?: OutgestProfile[];
    availableFiles: MediaElement[];
    selectedFiles: MediaElement[];
    availableVideoStreams: MediaStream[];
    selectedVideoStreams: MediaStream[];
    availableSubtitleStreams: MediaStream[];
    selectedSubtitleStreams: MediaStream[];
    availableAudioStreams: MediaStream[];
    selectedAudioStreams: MediaStream[];
    tcIn:string;
    tcOut:string;
    outputFilenamePrefix:string;
    streamSelectionHidden:boolean;
    createSpareFile:boolean;
    spareFileAccessibleToPartners:boolean;
  }

@Component({
    selector: 'dialog-outgest',
    templateUrl: './outgest-dialog.html',
    styleUrls: [ './outgest-dialog.sass' ]
  })
  export class OutgestDialog {
    constructor(
      public dialogRef: MatDialogRef<OutgestDialog>,
      private mediaService: MediaService,
      private locationService: LocationService,
      private outgestService: OutgestService,
      @Inject(MAT_DIALOG_DATA) public data: OutgestDialogData,
    ) {}
  
    i18next = i18next

    ngOnInit() {
      this.mediaService.getMediasUsableAsPreroll().subscribe(prerolls => {
        this.data.prerolls = prerolls.content!
      })
      this.locationService.getLocations().subscribe(locations => {
        this.data.locations = locations.content!
      })
      this.outgestService.getOutgestProfiles().subscribe(profiles => {
        this.data.outgestProfiles = profiles.content
      })      
    }
      
    compareFn(r1:any, r2:any) {
      return (r1.id == r2.id);
    }
    
    onNoClick(): void {
      this.dialogRef.close();
    }
  }