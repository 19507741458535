export enum MediaInfoFields {
    TimeCode_FirstFrame_MXFTC = 'TimeCode_FirstFrame_MXFTC',
    TimeCode_LastFrame_MXFTC = 'TimeCode_LastFrame_MXFTC',
    Format_General = 'Format_General',
    Format_Video = 'Format_Video',
    BitRate_Video = 'BitRate_Video',
}

export namespace MediaInfoFields {
    export function trackToCheckFor(info:MediaInfoFields) {
        switch (info) {
            case MediaInfoFields.Format_General:
                return 'General'
            case MediaInfoFields.Format_Video:
            case MediaInfoFields.BitRate_Video:            
                return 'Video'
            case MediaInfoFields.TimeCode_FirstFrame_MXFTC:
            case MediaInfoFields.TimeCode_LastFrame_MXFTC:
                return 'Time code'
            default:
                throw new Error(`No check for ${info}`)
        }
    }

    export function castValue(info:MediaInfoFields, value:string) {
        switch (info) {
            case MediaInfoFields.BitRate_Video:            
                return parseInt(value)
            default:
                return value
        }
    }    

    export function fieldToCheckFor(info:MediaInfoFields) {
        switch (info) {
            case MediaInfoFields.Format_General:
            case MediaInfoFields.Format_Video:
                return 'Format'                
            case MediaInfoFields.BitRate_Video:            
                return 'BitRate'
            default:
                return info
        }
    }    
}
